import axios from "axios";
import {API_BASE_URL} from "../helper/env";
import {getDeviceType, getLocalAuthData} from "../helper/utils";

export const getPaymentMethodList = (params = {}) => {
  const authData = getLocalAuthData()
  return new Promise((resolve, reject) => {
    axios.get(`${API_BASE_URL}/merchant-payment-method`, {
      headers: {
        'x-auth-token': authData?.token || '',
        'x-api-client': getDeviceType()
      },
      params: params
    })
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        reject(err.message)
      })
  })
}

export const paymentMethodStatusChange = (id, data = {}) => {
  const authData = getLocalAuthData()
  return new Promise((resolve, reject) => {
    axios.patch(`${API_BASE_URL}/merchant-payment-method/${id}`, data, {
      headers: {
        'x-auth-token': authData?.token || '',
        'x-api-client': getDeviceType()
      }
    })
      .then(res => {
        if (res.data.status === 1) {
          resolve(res.data)
        } else {
          reject(res.data.message)
        }
      })
      .catch(err => {
        reject(err.message)
      })
  })
}

export const deletePaymentMethod = (id) => {
  const authData = getLocalAuthData()
  return new Promise((resolve, reject) => {
    axios.delete(`${API_BASE_URL}/merchant-payment-method/${id}`, {
      headers: {
        'x-auth-token': authData?.token || '',
        'x-api-client': getDeviceType()
      }
    })
      .then(res => {
        if (res.data.status === 1) {
          resolve(res.data)
        } else {
          reject(res.data.message)
        }
      })
      .catch(err => {
        reject(err.message)
      })
  })
}

export const savePaymentMethod = (data) => {
  const authData = getLocalAuthData()

  return new Promise((resolve, reject) => {
    axios.post(`${API_BASE_URL}/merchant-payment-method/`, data, {
      headers: {
        'x-auth-token': authData?.token || '',
        'x-api-client': getDeviceType()
      }
    })
      .then(res => {
        if (res.data.status === 1) {
          resolve(res.data)
        } else {
          reject(res.data.message)
        }
      })
      .catch(err => {
        reject(err.message)
      })
  })
}

export const updateStorePaymentMethods = (data, id) => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios.patch(API_BASE_URL + `/store/update-available-payment-methods/${id}`, data, {
      headers: {'x-auth-token': authData.token, 'x-api-client': getDeviceType()}
    }).then(res => {
      resolve(res.data)
    }).catch(err => {
      reject('Something went wrong')
    })
  })
}


export const productPaymentMethods = (id) => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios.get(API_BASE_URL + `/product/available-payment-methods/${id}`, {
      headers: {'x-auth-token': authData.token, 'x-api-client': getDeviceType()}
    }).then(res => {
      resolve(res.data)
    }).catch(err => {
      reject('Something went wrong')
    })
  })
}


export const updateProductPaymentMethods = (data, id) => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios.patch(API_BASE_URL + `/product/update-available-payment-methods/${id}`, data, {
      headers: {'x-auth-token': authData.token, 'x-api-client': getDeviceType()}
    }).then(res => {
      resolve(res.data)
    }).catch(err => {
      reject('Something went wrong')
    })
  })
}