import axios from "axios";
import { API_BASE_URL } from "../helper/env";

export const handleResetPassword = (data) => {
  // console.log("handleResetPassword",data);
  return new Promise((resolve, reject) => {
    console.log("handleResetPassword",data);
    axios.post(`${API_BASE_URL}/forgot-password`, data)
      .then(res => {
        console.log(res);
        if(res.data.status === 1) {
          resolve(res.data)
        } else {
          reject(res.data.message)
        }
      })
      .catch(err => {
        console.log('Error: ', err);
        reject('Some thing went wrong!')
      })
  })
}

export const changePassword = (data) => {
  return new Promise((resolve, reject) => {
    axios.post(`${API_BASE_URL}/reset-password`, data)
      .then(res => {
        if(res.data.status === 1) {
          resolve(res.data)
        } else {
          reject(res.data.message)
        }
      })
      .catch(err => {
        console.log('Error: ', err);
        reject('Some thing went wrong!')
      })
  })
}

export const emailVerification = (token) => {
  return new Promise((resolve, reject) => {
    axios.get(`${API_BASE_URL}/email-verify/${token}`)
      .then(res => {
        if(res.data.status === 1) {
          resolve(res.data)
        } else {
          reject(res.data.message)
        }
      })
      .catch(err => {
        console.log(err);
        reject('Something went wrong!')
      })
  })
}
