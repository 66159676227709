import React from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import {connect} from "react-redux";
function CaptchaWithCheckBox({getCaptchaValue}) {
    // function onChange(value) {
    //     console.log('Captcha value:', value);
    // }
    return (
        <div className="google-recaptcha" style={{marginTop: "10px"}}>
            <ReCAPTCHA
                sitekey="6LdDFlIiAAAAAIjiw_06nZZq-8-ADESp5994QHk4" //invisible
                // onChange={onChange}
                onChange={getCaptchaValue}
            />
        </div>
    )
}

export default CaptchaWithCheckBox