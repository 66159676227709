import {getLocalAuthData} from "../helper/utils";
import axios from "axios";
import {API_BASE_URL} from "../helper/env";
import {getDeviceType} from "../helper/utils";

export const getDisputes = (params = {}) => {
  const authData = getLocalAuthData();
  const defaultData = {
    user_id: authData._id || ''
  }
  return new Promise((resolve, reject) => {
    axios.get(`${API_BASE_URL}/dispute/list`,  {
      headers: {
        'x-auth-token': authData?.token,
        'x-api-client': getDeviceType()
      },
      params: params
    })
      .then(res => {
        if(res.data.status === 1) {
          resolve(res.data)
        } else {
          reject(res.data.message)
        }
      })
      .catch(err => {
        reject(err.message)
      })
  })
}