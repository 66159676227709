import axios from "axios";
import {getDeviceType, getLocalAuthData} from "../helper/utils";
import {API_BASE_URL} from "../helper/env";

export const getRequest = (endPoint, params = {}) => {
  const authData = getLocalAuthData()
  return new Promise((resolve, reject) => {
    axios.get(`${API_BASE_URL + endPoint}`, {
      headers: {
        'x-auth-token': authData?.token,
        'x-api-client': getDeviceType()
      },
      params: params
    })
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        reject(err.message)
      })
  })
}

export const postRequest = (endPoint, data, params = {}) => {
  const authData = getLocalAuthData()
  return new Promise((resolve, reject) => {
    axios.post(`${API_BASE_URL + endPoint}`, data, {
      headers: {
        'x-auth-token': authData?.token,
        'x-api-client': getDeviceType()
      },
      params: params
    })
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        reject(err.message)
      })
  })
}

export const patchRequest = (endPoint, data, params = {}) => {
  const authData = getLocalAuthData()
  return new Promise((resolve, reject) => {
    axios.patch(`${API_BASE_URL + endPoint}`, data, {
      headers: {
        'x-auth-token': authData?.token,
        'x-api-client': getDeviceType()
      },
      params: params
    })
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        reject(err.message)
      })
  })
}

export const deleteRequest = (endPoint, params = {}) => {
  const authData = getLocalAuthData()
  return new Promise((resolve, reject) => {
    axios.delete(`${API_BASE_URL + endPoint}`, {
      headers: {
        'x-auth-token': authData?.token,
        'x-api-client': getDeviceType()
      },
      params: params
    })
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        reject(err.message)
      })
  })
}
