import React, {Component} from 'react';
import App from "../../App";
import DateRangePicker from "react-bootstrap-daterangepicker";
import {Button} from "react-bootstrap";
import Moment from "moment";
import {FaFileExport, FaPrint} from "react-icons/all";
import {Area, AreaChart, CartesianGrid, Label, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";

const today = Moment(new Date()).format('M/D/Y');
const data = [
  {
    "name": "Jan - 01",
    "orders": 80
  },
  {
    "name": "Jan - 02",
    "orders": 30
  },
  {
    "name": "Jan - 03",
    "orders": 90
  },
  {
    "name": "Jan - 04",
    "orders": 55
  },
  {
    "name": "Jan - 05",
    "orders": 65
  },
  {
    "name": "Jan - 06",
    "orders": 63
  },
  {
    "name": "Jan - 07",
    "orders": 82
  },
  {
    "name": "Jan - 08",
    "orders": 32
  },
  {
    "name": "Jan - 09",
    "orders": 70
  },
  {
    "name": "Jan - 10",
    "orders": 75
  },
  {
    "name": "Jan - 11",
    "orders": 73
  },
  {
    "name": "Jan - 12",
    "orders": 81
  },
  {
    "name": "Jan - 13",
    "orders": 72
  },
  {
    "name": "Jan - 14",
    "orders": 73
  },
  {
    "name": "Jan - 15",
    "orders": 75
  },
  {
    "name": "Jan - 16",
    "orders": 68
  },
  {
    "name": "Jan - 17",
    "orders": 79
  },
  {
    "name": "Jan - 18",
    "orders": 81
  },
  {
    "name": "Jan - 19",
    "orders": 74
  },
  {
    "name": "Jan - 20",
    "orders": 66
  }
]

class OrderReport extends Component {
  state = {
    startDate: today,
    endDate: today
  }

  handleOnApply = (e, picker) => {
    const startDate = Moment(picker.startDate).format('M/D/Y');
    const endDate = Moment(picker.endDate).format('M/D/Y')
    this.setState({
      startDate: startDate,
      endDate: endDate,
    })
  }

  render() {
    return (
      <App layout='boxed'>
        <div className="main-content">
          <div className="page-header">
            <div className="form-inline">
              <DateRangePicker onApply={this.handleOnApply} startDate={this.state.startDate}
                               endDate={this.state.endDate}>
                <button
                  className="form-control form-control-sm">{`${this.state.startDate} to ${this.state.endDate}`}</button>
              </DateRangePicker>

              <select name="" className="form-control form-control-sm ml-2 mr-2">
                <option value="">All Store</option>
                <option value="1">Store one</option>
                <option value="1">Store Two</option>
                <option value="1">Store Three</option>
              </select>

              <Button size={"sm"} variant={"primary"}>Generate</Button>
            </div>

            <div className="ml-auto">
              <Button className="mr-2" size={"sm"} variant={"default"}>
                <FaFileExport/> Export
              </Button>
              <Button size={"sm"} variant={"default"}>
                <FaPrint/> Print
              </Button>
            </div>
          </div>

          <div className="card-block">
            <div className="block-header">
              <h4 className="block-title">Sales Statistics</h4>
            </div>

            <div className="block-body">
              <ResponsiveContainer width={'100%'} height={320}>
                <AreaChart data={data}
                           margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                  <defs>
                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
                      <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
                    </linearGradient>
                  </defs>

                  <XAxis dataKey="name">
                    <Label value="Time" offset={0} position="insideBottom" />
                  </XAxis>
                  <YAxis label={{ value: 'Orders', angle: -90, position: 'insideLeft' }} />

                  <CartesianGrid strokeDasharray="3 3" />
                  <Tooltip />
                  {/*<Legend verticalAlign="top" height={36}/>*/}
                  <Area type="monotone" dataKey="orders" stroke="#8884d8" fillOpacity={1} fill="url(#colorUv)" />
                </AreaChart>
              </ResponsiveContainer>
            </div>
          </div>

          <div className="card-block">
            <div className="block-header">
              <h4 className="block-title">List of Active Sales</h4>
            </div>

            <div className="block-body">
              <table className="table table-striped">
                <thead>
                <tr>
                  <th style={{width: '2%'}}>#</th>
                  <th>Date</th>
                  <th className="text-center">No. of Orders</th>
                  <th className='text-right'>Sell Amount (৳)</th>
                </tr>
                </thead>

                <tbody>
                <tr>
                  <td>1</td>
                  <td>01/01/2020</td>
                  <td className="text-center">80</td>
                  <td className="text-right">60,200.00</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>01/01/2020</td>
                  <td className="text-center">80</td>
                  <td className="text-right">60,200.00</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>01/01/2020</td>
                  <td className="text-center">80</td>
                  <td className="text-right">60,200.00</td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>01/01/2020</td>
                  <td className="text-center">80</td>
                  <td className="text-right">60,200.00</td>
                </tr>
                <tr>
                  <td>5</td>
                  <td>01/01/2020</td>
                  <td className="text-center">80</td>
                  <td className="text-right">60,200.00</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </App>
    );
  }
}

export default OrderReport;
