import React, {Component} from 'react';
import {FaRegTrashAlt, IoMdCheckmark} from "react-icons/all";
import {Spinner} from "react-bootstrap";
import {deleteFile} from "../services/fileManagerServices";
import {toast} from "react-toastify";
import {getFileList} from "../redux";
import {connect} from "react-redux";
import {getLocalAuthData} from "../helper/utils";

class FileItem extends Component {
  state = {
    isLoading: false
  }

  handleDeleteFile = (id) => {
    const authData = getLocalAuthData()
    this.setState({isLoading: true}, () => {
      deleteFile(id)
        .then(res => {
          const params = {
            user_id: authData._id,
            image_category: 'product'
          }
          getFileList(params)
          toast.success(res.message)
          this.setState({isLoading: false})
        })
        .catch(errMsg => {
          this.setState({isLoading: false})
          toast.error(errMsg)
        })
    })
  }

  render() {
    const {data, checked} = this.props

    console.log(data.mid_img_path);

    return (
      <div key={data._id} className="file-item">
        <div className={`file-item-inner`}>
          <label className="file" htmlFor={`file-id-${data._id}`}>
            {this.props.isSingle ?
              <input type="radio"
                     name="selected_file"
                     defaultChecked={data._id === checked}
                     value={data._id}
                     onChange={this.props.onChange}
                     id={`file-id-${data._id}`}/>
                     :
              <input type="checkbox"
                     name="selected_file"
                     defaultChecked={data._id === checked}
                     value={data._id}
                     onChange={this.props.onChange}
                     id={`file-id-${data._id}`}/>
            }

            <span className="file-thumb">
              <span
                style={{backgroundImage: `url(${data.mid_img_path})`}}
                className="ratio bg-cover ratio-1-1">
                <span className="ratio-inner">
                  <span className="checked-icon">
                    <IoMdCheckmark/>
                  </span>
                </span>
              </span>
            </span>
          </label>

          {/*image deletion*/}
          {/*{this.state.isLoading ?
            <div className="spinner-overlay">
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            </div>
            :
            <span
              onClick={() => this.handleDeleteFile(data._id)}
              className={`btn-img-delete`}><FaRegTrashAlt/></span>
          }*/}
        </div>
      </div>
    );
  }
}


const mapDispatchToProps = dispatch => {
  return {
    getFileList: (params) => dispatch(getFileList(params)),
  }
}

export default connect(null, mapDispatchToProps)(FileItem);
