import React from "react";
import { Redirect, Route } from "react-router-dom";
import { userIsLoggedin } from "../authUtils";

function PrivateRoute({ children, ...rest }) {
  const isAuthenticated = userIsLoggedin();
  const user = JSON.parse(localStorage.getItem('user'));
  const message = "Please reset your password for security purpose. You can't proceed with system generated password"

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          !user.need_change_password ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: "/reset-password",
                state: { from: location, message: message},
              }}
            />
          )
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export default PrivateRoute;
