import axios from "axios";
import { API_BASE_URL } from "../helper/env";
import { getDeviceType, getLocalAuthData } from "../helper/utils";

/*
 *
 * TODO : Support TOPIC *****
 *
 * */
export const supportTopicList = () => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_BASE_URL}/support-topic`, {
        headers: {
          "x-auth-token": authData.token,
          "x-api-client": "web",
        },
      })
      .then((res) => {
        if (res.data.status === 1) {
          resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => {
        reject(err.message);
      });
  });
};

export const supportTopicAdd = (data) => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios
      .post(`${API_BASE_URL}/support-topic`, data, {
        headers: {
          "x-auth-token": authData.token,
          "x-api-client": "web",
        },
      })
      .then((res) => {
        if (res.data.status === 1) {
          resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => {
        reject(err.message);
      });
  });
};

/*
 *
 * TODO : SUPPORT TICKET ****
 *
 * */

export const supportTicketAdd = (data) => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios
      .post(`${API_BASE_URL}/support-ticket`, data, {
        headers: {
          "x-auth-token": authData.token,
          "x-api-client": "web",
        },
      })
      .then((res) => {
        if (res.data.status === 1) {
          resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => {
        reject(err.message);
      });
  });
};

export const supportTicketList = (params) => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_BASE_URL}/support-ticket`, {
        params,
        headers: {
          "x-auth-token": authData.token,
          "x-api-client": "web",
        },
      })
      .then((res) => {
        if (res.data.status === 1) {
          resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => {
        reject(err.message);
      });
  });
};

export const supportTicketDetails = (id) => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_BASE_URL}/support-ticket/` + id, {
        headers: {
          "x-auth-token": authData.token,
          "x-api-client": "web",
        },
      })
      .then((res) => {
        if (res.data.status === 1) {
          resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => {
        reject(err.message);
      });
  });
};

export const supportTicketUpdate = (id, data) => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios
      .put(`${API_BASE_URL}/support-ticket/` + id, data, {
        headers: {
          "x-auth-token": authData.token,
          "x-api-client": "web",
        },
      })
      .then((res) => {
        if (res.data.status === 1) {
          resolve(res.data);
          console.log(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => {
        reject(err.message);
      });
  });
};

export const supportTicketChangeStatus = (id, data) => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios
      .patch(`${API_BASE_URL}/support-ticket/changeStatus/` + id, data, {
        headers: {
          "x-auth-token": authData.token,
          "x-api-client": "web",
        },
      })
      .then((res) => {
        if (res.data.status === 1) {
          resolve(res.data);
          console.log(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => {
        reject(err.message);
      });
  });
};

export const ticketRepliesList = (id, data) => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios
      .put(`${API_BASE_URL}/support-ticket/reply/` + id, data, {
        headers: {
          "x-auth-token": authData.token,
          "x-api-client": "web",
        },
      })
      .then((res) => {
        if (res.data.status === 1) {
          resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => {
        reject(err.message);
      });
  });
};
