import axios from "axios";
import {API_BASE_URL} from "../helper/env";
import {getDeviceType, getLocalAuthData} from "../helper/utils";

export const checkIsExisting = (type, data) => {
  let url
  if(type === 'phone') {
    url = `${API_BASE_URL}/check-phone-exist`
  } else if(type === 'email') {
    url = `${API_BASE_URL}/check-email-exist`
  } else if(type === 'business_name') {
    url = `${API_BASE_URL}/check-business-name-exist`
  } else if(type === 'store') {
    url = `${API_BASE_URL}/check-store-name-exist`
  }

  return new Promise((resolve, reject) => {
    axios.post(url, data)
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        console.error(err)
        reject(err.message)
      })
  })
}

export const addNewItem = (url, data) => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios.post(`${API_BASE_URL}${url}`, data, {
      headers: {'x-auth-token': authData.token, 'x-api-client': getDeviceType()}
    }).then(res => {
      resolve(res.data)
    }).catch(err => {
      console.error(err)
      reject(err.message)
    })
  })
}

export const updateItem = (url, data) => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios.put(`${API_BASE_URL}${url}`, data, {
      headers: {'x-auth-token': authData.token, 'x-api-client': getDeviceType()}
    }).then(res => {
      resolve(res.data)
    }).catch(err => {
      console.error(err)
      reject(err.message)
    })
  })
}

export const getTableData = (url, paramsObj = {}) => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios.get(`${API_BASE_URL}${url}`, {
      headers: {'x-auth-token': authData.token, 'x-api-client': getDeviceType()},
      params: paramsObj
    }).then(res => {
      resolve(res.data)
    }).catch(err => {
      console.error(err)
      reject(err.message)
    })
  })
}

export const getTableDataWithParams = (url, paramsObj = {}) => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios.get(`${API_BASE_URL}${url}`, {
      headers: {'x-auth-token': authData.token, 'x-api-client': getDeviceType()}, params: paramsObj
    }).then(res => {
      resolve(res.data)
    }).catch(err => {
      console.error(err)
      reject(err.message)
    })
  })
}


export const getTableDataV1 = (url, paramsObj = {}) => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios.get(`${`http://localhost:3000/api`}${url}`, {
    headers: {'x-auth-token': authData.token, 'x-api-client': getDeviceType()}
    } ).then(res => {
      resolve(res.data)
    }).catch(err => {
      console.error(err)
      reject(err.message)
    })
  })
}


export const getDetail = (url, paramsObj = {}) => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios.get(`${API_BASE_URL}${url}`, {
      params: paramsObj, headers: {'x-auth-token': authData.token, 'x-api-client': getDeviceType()}
    }).then(res => {
      resolve(res.data)
    }).catch(err => {
      console.error(err)
      reject(err.message)
    })
  })
}

export const updateItemPartial = (url, data) => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios.patch(`${API_BASE_URL}${url}`, data, {
      headers: {'x-auth-token': authData.token, 'x-api-client': getDeviceType()}
    }).then(res => {
      resolve(res.data)
    }).catch(err => {
      console.error(err)
      reject(err.message)
    })
  })
}

export const deleteItem = (url) => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios.delete(`${API_BASE_URL}${url}`,{
      headers: {'x-auth-token': authData.token, 'x-api-client': getDeviceType()}
    }).then(res => {
      resolve(res.data)
    }).catch(err => {
      console.error(err)
      reject(err.message)
    })
  })
}

export const postData = (url, data = {}) => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios.post(`${API_BASE_URL}${url}`, data, {
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": authData.token,
          "x-api-client": getDeviceType(),
        },
      }).then((res) => {
        resolve(res.data);
      }).catch((err) => {
        console.error(err);
        reject(err.message);
      });
  });
};


export const uploadImage = (data) => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios.post(`${API_BASE_URL}/images`, data, {
      headers: {
        'x-auth-token': authData.token,
        'x-api-client': getDeviceType()
      }
    })
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          console.log(err.message)
        })
  })
}



export const updateDataV2 = (url, data = {}) => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios.put(`${API_BASE_URL}${url}`, data, {
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": authData.token || "",
        "x-api-client": getDeviceType(),
      },
    }).then((res) => {
      console.log('success')
      resolve(res.data);
    }).catch((err) => {
      console.error(err);
      reject(err.message);
    });
  });
};



export const getCustomerDetails = () => {
  const authData = getLocalAuthData()
  return new Promise((resolve, reject) => {
    axios.get(`${API_BASE_URL}/customer/${authData._id}`, {
      headers: {
        'x-auth-token': authData?.token || '',
        'x-api-client': getDeviceType()
      }
    })
        .then(res => {
          if (res.data.status === 1) {
            resolve(res.data)
          } else {
            reject(res.data.message)
          }
        })
        .catch(err => {
          console.error(err.message)
          reject(err.message)
        })
  })
}
