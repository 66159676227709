import React, { useState, useEffect } from "react";
import App from "../../App";
import { Link } from "react-router-dom";
import Moment from "moment";
import { FiClock, IoIosArrowForward, RiReplyLine } from "react-icons/all";
import "../../assets/scss/support-ticket.scss";
import { supportTicketList } from "../../services/support-ticketService";
import { Button } from "react-bootstrap";
import { Pagination } from "react-bootstrap";

const SupportTicket = () => {
  const [ticketList, setTicketList] = useState(null);
  const [pagination, setPagination] = useState({});
  const [queryParams, setQueryParams] = useState({
    limit: 10,
    page: 1,
  });

  useEffect(() => {
    const fetchData = async () => {
      const result = await supportTicketList(queryParams);
      setTicketList(result.items);
      setPagination({ ...pagination, ...result.pagination });
    };
    fetchData();
  }, []);

  const handlePageChange = async (pageNumber) => {
    const params = { ...queryParams, page: pageNumber };
    setQueryParams(params);

    const result = await supportTicketList(params);
    setTicketList(result.items);
    setPagination({ ...pagination, ...result.pagination });
  };

  const renderTicketList = () => {
    return (
      ticketList ?
      ticketList.map((data, index) => {
        return (
          <Link
            key={index}
            to={"/support-ticket/" + data.support_id}
            className="ticketing-item"
          >
            <div className="item-content d-flex justify-content-between align-items-center">
              <div className="ticketing-info">
                <h4 className="ticket-text">
                  {" "}
                  #{data.support_id} <span className="badge badge-info"></span>
                </h4>
                <div className="d-block">
                  Name: <strong>{data.user_info?.name || ""}</strong>
                </div>
                <p className="ticket-meta d-flex">
                  <span className={"mr-2"}># {data.support_topic_name} </span>
                  <span className={"mr-2"}>
                    <FiClock /> {Moment(data.createdAt).fromNow()}
                  </span>
                  <span className={"mr-2"}>
                    <RiReplyLine /> {data.replies?.length || "0"} reply
                  </span>
                </p>
                <p className={"mt-2"}>{data.message}</p>
              </div>

              <div className="d-flex">
                <span className="status-badge bg-info-light ml-3">
                  {data.status}
                </span>
              </div>
            </div>

            <span className="arrow-icon">
              <IoIosArrowForward />
            </span>
          </Link>
        );
      })
          :
          <>
            {
              <div className="alert alert-warning text-bold text-center">
                No Support ticket created. Please <Link to={"/support-faq"} className={"btn btn-sm btn-secondary"}>Take Support</Link>
              </div>
            }
          </>
    );
  };

  return (
    <App layout={"boxed"}>
      <div className="page-header">
        <h2 className="page-title">Support Ticket</h2>
        <Link to={"/support-faq"} className={"btn btn-sm btn-secondary"}>
          + Take Support
        </Link>
      </div>

      <div className="card-block">
        <div className="block-header">
          <h4 className="block-title">Ticket List</h4>
        </div>

        <div className="block-body">
          <div className="ticketing-list">{renderTicketList()}</div>

          <div className="mt-3">
            <Pagination
              itemClass="page-item"
              linkClass="page-link"
              activePage={pagination?.page}
              itemsCountPerPage={pagination?.limit}
              totalItemsCount={pagination?.totalDocs}
              pageRangeDisplayed={10}
              onChange={handlePageChange}
            />
          </div>
        </div>
      </div>
    </App>
  );
};

export default SupportTicket;
