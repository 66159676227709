import React, {Component} from 'react';
import App from "../../App";
import {Link, withRouter} from "react-router-dom";
import {Form} from "react-bootstrap";
import {GoLinkExternal, MdArrowBack} from "react-icons/all";
import campaignMerchantImg from "../../assets/img/merchant-campaign-details.jpg"
import moment from "moment";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import {getDetail} from "../../services/commonServices";
import {getStoreList} from "../../services/storeServices";
import { NonceProvider } from 'react-select';

class CampaignMerchantDetails extends Component {
    constructor(props){
        super(props);
        this.state = { date: new Date() };
    }

    state = {
        isLoading: true,
        activeBlockTab: 'Campaign Detail',
        campaignDetails: {},
        notExisting: false,
        stores: [],

        registered_stores_query: {limit: 20},
        registered_stores: [],
        approved_stores_query: {limit: 20},
        approved_stores: [],

        isShowingProductRegisterModal: false,
        store_products: [],
        store_product_pagination: {},

        registered_products_query: {limit: 20},
        registered_products: [],
        approved_products_query: {limit: 20},
        approved_products: [],
        pagination: {},
        limit: 10,
    }

    handleOnApply = (e, picker) => {
        const queryData = {...this.state.queryData};

        queryData.from_date = moment(picker.startDate).format('DD/MM/YYYY');
        queryData.to_date = moment(picker.endDate).format('DD/MM/YYYY');

        this.setState({queryData}, () => {
            console.log(this.state)
        })
    }

    componentDidMount() {
        const campaignId = this.props.match.params.id

        getStoreList().then(res => {
            this.setState({stores: res.items, loading: false}, () => {
                console.log("my stores");
                console.log(this.state.stores);
                getDetail(`/campaign/${campaignId}`, {}).then(res => {
                    this.setState({campaignDetails: res.campaign, isLoading: false})

                    const registered_stores = this.getRegisteredStores(res.campaign.registered_stores);

                    const approved_stores = this.getApprovedStores(res.campaign.stores);

                    const registered_products = this.getRegisteredProducts(res.campaign.registered_products);

                    const approved_products = this.getApprovedProducts(res.campaign.products);

                    Promise.all([registered_stores, approved_stores, registered_products, approved_products]).then(res => {
                        console.log(res);
                        this.setState({isLoading: false})

                        console.log("my stores");
                        console.log(this.state.stores);
                    }).catch((err) => {
                        this.setState({isLoading: false})
                    })
                }).catch(errMsg => {
                    this.setState({isLoading: false, notExisting: true})
                    console.log(errMsg)
                    this.setState({isLoading: false})
                })
            });
        }).catch(errMsg => {
            this.setState({loading: false});
        })

        setInterval(() => {
            this.setState({
                date: new Date(),
            });
        }, 1000);

    }

    render() {
        const {stores, campaignDetails, registered_stores, approved_stores, store_products, registered_products, approved_products} = this.state
        return (
            <App layout={"boxed"}>
                <div className="main-content">
                    <div className="page-header">
                        <h2 className="page-title">
                            Campaign Details
                            <a target={'_blank'}
                               rel="noopener noreferrer"
                               className={'ml-3 text-primary'}
                                   href="#"><GoLinkExternal/></a>
                        </h2>
                        <div className="ml-auto">
                            <Link to={`${process.env.PUBLIC_URL}/promotions/campaign`}
                                  className={`btn btn-sm btn-link`}><MdArrowBack/> Back to List</Link>
                            {/*<Link to={`${process.env.PUBLIC_URL}/promotions/campaign/add`}
                                  className="btn btn-sm btn-secondary"><FiPlus/> Add Campaign</Link>*/}
                        </div>
                    </div>


                    <div className="card-block">
                        <div className="merchant-campaign-details">
                            <div className="campaign-details-banner" >
                                <img src={campaignMerchantImg} alt="banner Image" />
                                <div className="campaign-text">
                                    <h5 className="campaign-title">Campaign Today</h5>
                                    <p className="campaign-descriptin"><strong>Short Description: </strong>The smartphone comes in a dimension of 161.3 x 74.2 x 7.4 mm 
                                        and weighs 171 grams. It supports Dual SIM (Nano-SIM, dual stand-by) and the resolution of the display is 1080 x 2400 pixels.
                                        It is packed with 8 GB RAM with 128 GB internal storage. The smartphone is powered by Qualcomm SM7125 Snapdragon 720G Octa-core processor.
                                    </p>
                                </div>
                                    <div className="campaign-daterange">
                                    <DateRangePicker>
                                        <input type="text" className="form-control" placeholder="Date Range" />
                                    </DateRangePicker>
                                </div>
                                <div className="campaign-date-count-area">
                                    <div className="last-registration-date">
                                        <Form.Group>
                                            <Form.Control type="date" placeholder="Last Registration Date" title="Last Registration Date"/>
                                        </Form.Group>
                                    </div>
                                    <div className="campaing-coundown-date">
                                        <h5>
                                            {this.state.date.toLocaleTimeString()}
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card-block">
                        <div className="campaign-terms-conditions">
                            <h3 className="term-condition-title">Terms and Conditions</h3>
                            <p className="term-condition-des">The smartphone comes in a dimension of 161.3 x 74.2 x 7.4 mm 
                                    and weighs 171 grams. It supports Dual SIM (Nano-SIM, dual stand-by) and the resolution of the display is 1080 x 2400 pixels.
                                    </p>
                            <p className="term-condition-des">The smartphone comes in a dimension of 161.3 x 74.2 x 7.4 mm 
                                    and weighs 171 grams. It supports Dual SIM (Nano-SIM, dual stand-by) and the resolution of the display is 1080 x 2400 pixels.
                                    It is packed with 8 GB RAM with 128 GB internal storage. The smartphone is powered by Qualcomm SM7125 Snapdragon 720G Octa-core processor.</p>
                        </div>
                    </div>  
                </div>
            </App>
        );
    }
}

    export default withRouter(CampaignMerchantDetails);
