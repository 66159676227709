import React, {Component} from 'react';
import App from "../../App";
import {withRouter} from "react-router-dom";
import {FiEdit, FiTrash} from "react-icons/all";
import "../../assets/scss/banner-management.scss";
import PageTopBanners from "../../components/PageTopBanners";
import {Button, Col, Form, Modal, Row} from "react-bootstrap";
import BrowseFiles from "../../components/BrowseFiles";
import {
    getBanner,
    getSlider,
    deleteSlider,
    deleteBanner,
    detailsBanner,
    submitBanner
} from "../../services/bannerService";
import axios from "axios";
import {API_BASE_URL} from "../../helper/env";
import {getStoreList} from "../../services/storeServices";
import {toast} from "react-toastify";
import styleReactSelect from "../../assets/styleReactSelect";
import CreatableSelect from "react-select/creatable/dist/react-select.esm";
import {confirmAlert} from "react-confirm-alert";
import Select from "react-select";
// import Service from "../../components/Service";
// import axois from "axios";


class Banners extends Component {
    constructor(props) {
        super(props);
        this.state = {
            merchant_types: [],
            merchantList: [],
            storeList: [],
            merchant_type: '',
            store_id: '',
            queryData: {
                from_date: '',
                to_date: '',
                merchant_type: '',
                store_id: '',
            },
            pagination: null,


            selected_image_size_template: 'banner_1500x556',
            image_size_templates: [
                {label: "Banner_1500x556", value: "banner_1500x556"},
                {label: "Banner_750x280", value: "banner_750x280"},
                {label: "Banner_400x150", value: "banner_400x150"},
                {label: "Default_800x800", value: "default"},
            ],

            banner_locations: [
                {label: "Store Homepage Before Category", value: "category"},
                {label: "Store Main Banner", value: "store-top"},
                {label: "Store All Products", value: "all-product"},
            ],
            isSubmitting: false,

            showSliderModal: false,
            formData: {
                banner_type: 1
            },
            bannerData: [],
            sliderData: [],
            detailsBannerData: {},
            showAddBannerModal: false
        }
    }

    componentDidMount() {

        this.setState({...this.state, selected_image_size_template: 'banner_1500x556'})

        getStoreList({/*status: 1, approval_status: 1*/}).then((res) => {
            if (res.items.length < 1) {
                //toast.error("Please create a store to add product");
                this.setState({isSubmitting: true})
                confirmAlert({
                    title: 'No Store',
                    message: 'Please create a store to add product',
                    buttons: [{
                        label: 'Ok',
                        onClick: () => this.props.history.push(`${process.env.PUBLIC_URL}/stores/create`)
                    }]
                });
            }

            const storeList = [...this.state.storeList];
            res.items.forEach(item => {
                const store = {
                    id: item._id,
                    value: item._id,
                    label: item.store_name
                }
                storeList.push(store)
            })
            this.setState({storeList: storeList})
            toast.success(res.message)
            console.log(this.state.storeList);
        }).catch(function (e) {
            console.log(e);
        });
        this.getSlider({})
        this.getBanner({})

    }

    getSlider = (paramsObj = {}) => {
        getSlider(paramsObj).then(res => {

            if (res.status === 1) {
                this.setState({...this.state, sliderData: res.items})
                toast.success(res.message)
            } else if (res.status === 2) {
                this.setState({...this.state, sliderData: res.items})
                toast.warning(res.message)
            }

        }).catch(function (errMsg) {
            toast.error(errMsg)
        });
    }

    getBanner = (paramsObj = {}) => {
        getBanner(paramsObj).then(res => {

            if (res.status === 1) {
                this.setState({...this.state, bannerData: res.items, pagination: res.pagination})
                toast.success(res.message)
            } else if (res.status === 2) {
                this.setState({...this.state, bannerData: res.items, pagination: res.pagination})
                toast.warning(res.message)
            }

        }).catch(function (errMsg) {
            toast.error(errMsg)
        });
    }


    handleSearchFormDataChange = (e) => {
        const fieldName = e.target.name;
        const value = e.target.value;
        this.setState({...this.state, store_id: value})
    };

    setSearchDataOnChange = (value, name) => {
        this.setState({...this.state, [name]: value.value})
    }

    executeSearch = () => {
        this.getBanner({store_id: this.state.store_id, location: this.state.location});
        this.getSlider({store_id: this.state.store_id, location: this.state.location})
    }

    handleInputOnChange = (e) => {
        const formData = {...this.state.formData};
        formData[e.target.name] = e.target.value
        this.setState({formData});
    }

    selectOnChange = (value, name) => {
        console.log(value.value);
        const formData = {...this.state.formData, [name]: value.value}
        if (name === 'image_size_template') {
            this.setState({selected_image_size_template: value.value})
        }
        this.setState({formData})
        console.log(this.state.formData);
    }

    renderMerchantType = () => {
        const merchantTypes = this.state.merchant_types;
        if (merchantTypes) {
            return (
                <>
                    {merchantTypes.map((items, index) =>
                        <option key={index} value={`${items._id}`}>{items.type_name}</option>
                    )}
                </>
            )
        }
    };

    renderStoreList = () => {
        const storeList = this.state.storeList;
        if (storeList.length > 0) {
            return (
                storeList.map((item, index) =>
                    <option key={index} value={`${item.value}`}>{item.label}</option>
                )
            )
        }
    };

    handleAddBannerModalClose = () => {
        this.setState({showAddBannerModal: false})
    }

    handleSliderModal = () => {
        this.setState({showSliderModal: !this.state.showSliderModal})
    }

    handleAddBannerModalShow = (id) => {
        if (id !== null) {
            detailsBanner(id)
                .then(res => {
                    console.log(res);
                    this.setState({detailsBannerData: res})
                }).catch()
        }
        this.setState({showAddBannerModal: true})
    }

    handleImage = (files) => {
        this.setState({formData: {...this.state.formData, image: files[0].actual_img, mid_image: files[0].mid_img, thumb_image: files[0].actual_img, thumb_img: files[0].actual_img}});
        console.log(this.state.formData);
    }
    handleImageOnRemove = (index) => {
        const formData = {...this.state.formData}
        delete formData['image'];
        this.setState({formData})
    }

    handleFormDataChange = (e) => {
        this.setState({formData: {...this.state.formData, [e.target.name]: e.target.value}});
        setTimeout(() => {
            console.log(this.state.formData);
        }, 3000);
    };

    deleteSlider = (id) => {
        deleteSlider(id)
            .then(response => {
                if (response.status === 1) {
                    toast.success(response.message);
                    this.getSlider({});
                }
                if (response.status === 2) {
                    toast.error(response.message);
                }
                this.setState({sliderData: response})
            }).catch();
    };

    deleteBanner = (id) => {
        deleteBanner(id).then(response => {
            if (response.status === 1) {
                toast.success(response.message);
            } else if (response.status === 2) {
                toast.warning(response.message);
            }
            this.getBanner({});
        }).catch(errMsg => {
            toast.error(errMsg);
        });
    };

    renderBannerType = (type) => {
        if (type === 1) {
            return <span className="text-success -badge -badge-success">Landscape</span>
        } else if (type === 2) {
            return <span className="text-warning -badge -badge-warning">Portrait</span>
        } else if (type === 3) {
            return <span className="text-warning -badge -badge-warning">Square</span>
        }
    }

    handleBannerSubmitForm = (e) => {
        e.preventDefault()

        this.setState({isSubmitting: true}, () => {
            submitBanner(this.state.formData).then(res => {
                if (res.status === 1) {
                    toast.success(res.message);
                    this.setState({isSubmitting: false, showAddBannerModal: false})
                    this.getBanner({});
                } else {
                    toast.warning(res.message);
                    this.setState({isSubmitting: false})
                }
            }).catch(errMsg => {
                toast.error(errMsg);
                this.setState({isSubmitting: false})
            });
        });
    };

    editBanner = (id) => {
        axios.get(`${API_BASE_URL}/service/${id}`)
            .then(res => {
                this.setState({
                    editFormData: res.data.service,
                    formData: {
                        ...this.state.formData,
                        name: res.data.service.name,
                        link: res.data.service.link,
                        image: res.data.service.image
                    },
                    editStatus: true
                });
            }).catch(error => {
            toast.error("Error");
        });
    };

    handleSliderSubmitForm = () => {
        const data = this.state.formData;
        let url = `${API_BASE_URL}/slider`;
        axios.post(url, data).then(response => {
            console.log(response);
            if (response.data.status === 1) {
                toast.success(response.data.message);
                this.getSlider({});
            } else {
                toast.error(response.data.message);
            }
        }).catch(error => {
            toast.error("Error");
        });
    };

    render() {
        return (
            <App layout={"boxed"}>
                <div className="main-content">

                    <div className="page-header">
                        <h2 className="page-title">Slider & Banners</h2>
                    </div>

                    <div className="card-block">
                        <div className="block-header">
                            <h4 className="block-title">Sliders</h4>
                        </div>
                        <div className="block-body">
                            <PageTopBanners
                                storeList={this.state.storeList}
                                prps={this.state.sliderData}
                                selectOnChange={this.selectOnChange}
                                handleImage={this.handleImage}
                                handleFormData={this.handleFormDataChange}
                                handleSliderSubmitForm={this.handleSliderSubmitForm}
                                showSliderModal={this.state.showSliderModal}
                                handleSliderModal={this.handleSliderModal}
                                deleteSlider={this.deleteSlider}
                            />
                        </div>
                    </div>

                    <div className="card-block">

                        <Modal size={"lg"} show={this.state.showAddBannerModal} onHide={this.handleAddBannerModalClose}>
                            <Modal.Header closeButton>
                                <Modal.Title>Add Banner</Modal.Title>
                            </Modal.Header>


                            <Form onSubmit={this.handleBannerSubmitForm}>

                                <Modal.Body>

                                    <Form.Group as={Row} controlId="formImageSizeTemplate">
                                        <Form.Label column sm="3">
                                            Select Store <span className="text-danger">*</span>
                                        </Form.Label>
                                        <Col sm="9" md={9} lg={9}>
                                            <Select
                                                styles={styleReactSelect}
                                                placeholder="Select store..."
                                                onChange={(value) => this.selectOnChange(value, 'store')}
                                                options={this.state?.storeList}
                                                name="store"
                                                noOptionsMessage={() => 'Create a Store'}
                                            />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} controlId="formImageSizeTemplate">
                                        <Form.Label column sm="3">
                                            Image Size Template
                                        </Form.Label>
                                        <Col sm="9" md={9} lg={9}>
                                            <Select
                                                required={true}
                                                styles={styleReactSelect}
                                                options={this.state.image_size_templates}
                                                placeholder="Select select image size template..."
                                                defaultValue={this.state.image_size_templates.filter((obj) => {
                                                    return obj.value == this.state.selected_image_size_template;
                                                })[0]}
                                                onChange={(value) => this.selectOnChange(value, 'image_size_template')}
                                                noOptionsMessage={() => 'Please select image size template'}
                                            />
                                        </Col>
                                    </Form.Group>


                                    <Form.Group as={Row} controlId="formPromotionName">
                                        <Form.Label column sm="3">
                                            Banner Image <span className="text-danger">*</span>
                                        </Form.Label>
                                        <Col sm="9" md={8} lg={7}>
                                            <BrowseFiles size={"sm"}
                                                         onRemoveItem={this.handleImageOnRemove}
                                                         onGetFiles={this.handleImage}
                                                         image_category={'banner'}
                                                         images={this.state?.formData?.image ? [this.state?.formData?.image] : ''}
                                                         image_size_templates={this.state.image_size_templates}
                                                         selected_image_size_template={this.state.selected_image_size_template}
                                                         helpText={"Image size 1500 x 556"}/>
                                        </Col>
                                    </Form.Group>


                                    <Form.Group as={Row}>
                                        <Form.Label column sm="3">
                                            Banner Type <span className="text-danger">*</span>
                                        </Form.Label>
                                        <Col sm="9" md={8} lg={7}>
                                            <Form.Check custom inline type="radio" name="banner_type"
                                                        defaultChecked={this.state?.formData?.banner_type === 1}
                                                        id={`banner-type-landscape`} onChange={this.handleInputOnChange}
                                                        value={1} label={`Landscape`}/>
                                            <Form.Check custom inline type="radio" name="banner_type"
                                                        id={`banner-type-portrait`} onChange={this.handleInputOnChange}
                                                        value={2} label={`Portrait`}/>
                                            <Form.Check custom inline type="radio" name="banner_type"
                                                        id={`banner-type-square`} onChange={this.handleInputOnChange}
                                                        value={3} label={`Square`}/>
                                        </Col>
                                    </Form.Group>


                                    <Form.Group as={Row} controlId="formImageSizeTemplate">
                                        <Form.Label column sm="3">
                                            Banner Place
                                        </Form.Label>
                                        <Col sm="9" md={9} lg={9}>
                                            <CreatableSelect
                                                required={true}
                                                styles={styleReactSelect}
                                                defaultValue={this?.state?.location}
                                                placeholder="Select select banner location..."
                                                onChange={(value) => this.selectOnChange(value, 'location')}
                                                options={this.state.banner_locations}
                                                noOptionsMessage={() => 'Please select banner location'}
                                            />
                                        </Col>
                                    </Form.Group>


                                    <Form.Group as={Row} controlId="formPromotionName">
                                        <Form.Label column sm="3">
                                            Banner Link
                                        </Form.Label>
                                        <Col sm="9" md={9} lg={9}>
                                            <Form.Control type="text" placeholder="" name={'link'} onChange={this.handleInputOnChange}/>
                                        </Col>
                                    </Form.Group>


                                </Modal.Body>

                                <Modal.Footer>
                                    <Button variant="default" onClick={this.handleAddBannerModalClose}> Close </Button>
                                    <Button variant="primary" type={'submit'} disabled={this.state.isSubmitting}>
                                        {this.state.isSubmitting ? 'Please Wait...' : 'Save'} </Button>
                                </Modal.Footer>

                            </Form>

                        </Modal>

                        <div className="block-header">
                            <h4 className="block-title">Banners List</h4>
                            <div className="">
                                <Button size={"sm"} onClick={() => this.handleAddBannerModalShow()} variant={"secondary"}>Add Banner</Button>
                            </div>
                        </div>

                        <div className="block-body">
                            <div className="d-flex">
                                <div className="form-inline">

                                    {this.state.merchant_types?.length > 0 &&
                                    <div className="form-group mr-3 mt-2">
                                        <label htmlFor="barndName" className="sr-only">Merchant Type</label>
                                        <select id="merchantType" name="merchant_type"
                                                onChange={this.handleSearchFormDataChange}
                                                className='form-control form-control-sm'>
                                            <option value="">--Select Type--</option>
                                            {this.renderMerchantType()}
                                        </select>
                                    </div>}

                                    {this.state.storeList?.length > 0 ?
                                        <>
                                            <div className="form-group mr-3 mt-2">
                                                <label htmlFor="barndName" className="sr-only">Store</label>
                                                <select
                                                    id="store" name="store_id"
                                                    onChange={this.handleSearchFormDataChange}
                                                    className='form-control form-control-sm'>
                                                    <option value="">--Select Store--</option>
                                                    {this.renderStoreList()}
                                                </select>
                                            </div>
                                        </> : <span>Store Not Found!!</span>
                                    }


                                    {this.state?.image_size_templates?.length > 0 &&
                                    <div className="form-group mr-3 mt-2">
                                        <label htmlFor="bannerLocation" className="sr-only">Location</label>
                                        <CreatableSelect
                                            styles={styleReactSelect}
                                            placeholder="Select select banner location..."
                                            onChange={(value) => this.setSearchDataOnChange(value, 'location')}
                                            options={this.state.banner_locations}
                                            id={'bannerLocation'}
                                            noOptionsMessage={() => 'Please select banner location'}
                                        />
                                    </div>}

                                    <Button size={"sm"} variant={"primary"} onClick={this.executeSearch}>Search</Button>
                                </div>

                            </div>

                            {this.state?.bannerData?.length > 0 ?
                                <>
                                    <div className="mt-4">
                                        <table className="order-table table table-striped">
                                            <thead>
                                            <tr>
                                                <th style={{width: '1%'}}>#</th>
                                                <th style={{width: '35%'}}>Banner Image</th>
                                                <th style={{width: '20%'}}>Banner Type</th>
                                                <th style={{width: '20%'}}>Banner Location</th>
                                                <th style={{width: '20%'}}>Banner Dimension</th>
                                                <th style={{width: '1%'}}/>
                                            </tr>
                                            </thead>

                                            <tbody>
                                            {
                                                this.state.bannerData.length > 0 ? this.state.bannerData.map((banner, index) => (
                                                        <>
                                                            <tr key={index}>
                                                                <td>{this.state.pagination.pagingCounter + index}</td>
                                                                <td>
                                                                    <div className="banner-container">
                                                                        <img src={banner.thumb_image} alt=""/>
                                                                        <div className="actions">
                                                                            {/*<span className="action" onClick={() => this.handleAddBannerModalShow(banner._id)}><FiEdit/></span>*/}
                                                                            {/*<span className="action" onClick={() => this.deleteBanner(banner._id)}><FiTrash/></span>*/}
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="order-item">
                                                                        <h4 className="order-id">{this.renderBannerType(banner?.banner_type)}</h4>
                                                                    </div>
                                                                </td>
                                                                <td>{banner?.location}</td>
                                                                <td>{banner?.image_size_template}</td>
                                                                <td style={{width: '1%'}}>
                                                                    {/*<span className="action" onClick={() => this.handleAddBannerModalShow(banner._id)}><FiEdit/></span>*/}
                                                                    <span className="action" onClick={() => this.deleteBanner(banner._id)}><FiTrash/></span>
                                                                </td>
                                                            </tr>
                                                        </>
                                                    ))
                                                    :
                                                    <tr>
                                                        <td colSpan={6} className={'text-center text-bold'}>No commission found</td>
                                                    </tr>
                                            }
                                            </tbody>
                                        </table>
                                    </div>

                                </>
                                :
                                <div className={`alert alert-warning text-center text-bold mt-4`}>
                                    No Banner found
                                </div>
                            }
                        </div>

                    </div>
                </div>
            </App>
        );
    }
}

export default withRouter(Banners);
