import React from 'react';
import ReactDOM from 'react-dom';
import "react-datepicker/dist/react-datepicker.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-widgets/dist/css/react-widgets.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import './assets/scss/style.scss';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter as Router, Link, Redirect, Route, Switch} from "react-router-dom";
import PrivateRoute from "./auth/components/PrivateRoute";
import Settings from "./views/Settings";
import Login from "./views/Login";
import {Provider} from "react-redux";
import store from "./redux/store";
import ResetPassword from "./views/profile/ResetPassword";
import ProductList from "./views/products/ProductList";
import AddProduct from "./views/products/AddProduct";
import OrderList from "./views/orders/OrderList";
import SellerVoucher from "./views/promotions/SellerVoucher";
import AddSellerVoucher from "./views/promotions/AddSellerVoucher";
import OrderDetails from "./views/orders/OrderDetails";
import Profile from "./views/profile/Profile";
import ProductDetails from "./views/products/ProductDetails";
import OrderReport from "./views/reports/OrderReport";
import SalesReport from "./views/reports/SalesReport";
import Finance from "./views/finance/Finance";
import BulkRequest from "./views/bulk_request/Bulkrequest";
import StoreList from "./views/store/StoreList";
import CreateStore from "./views/store/CreateStore";
import {ToastContainer} from 'react-toastify';
import EditStore from "./views/store/EditStore";
import EmailVerify from "./views/EmailVerify";
import EditProduct from "./views/products/EditProduct";
import Invoice from "./views/orders/Invoice";
import AssistedSellers from "./views/assisted-seller/AssistedSellers";
import AddAssistedSeller from "./views/assisted-seller/AddAssistedSeller";
import EditAssistedSeller from "./views/assisted-seller/EditAssistedSeller";
import AssistedSellerDetails from "./views/assisted-seller/AssistedSellerDetails";
import axios from "axios";
import {getDeviceType, getLocalAuthData} from "./helper/utils";
import EditSellerVoucher from "./views/promotions/EditSellerVoucher";
import BundleOfferList from "./views/bundles/BundleOfferList";
import AddBundleOffer from "./views/bundles/AddBundleOffer";
import EditBundleOffer from "./views/bundles/EditBundleOffer";
import EmailReset from "./views/EmailReset";
import Commissions from "./views/Commissions/Commission";
import Disputes from "./views/disputes/Disputes";
import PaymentMethods from "./views/payment-methods/PaymentMethods";
import AddPaymentMethod from "./views/payment-methods/AddPaymentMethod";
import Banners from "./views/banners/Banners";
import Dashboard from "./views/Dashboard";
import CampaignOfferList from "./views/campaings/CampaignOfferList";
import CampaignMerchantDetails from "./views/campaings/CampaignMerchantDetails";
import AddCampaignOffer from "./views/campaings/AddCampaignOffer";
import CampaignDetails from "./views/campaings/CampaignDetails";
import SupportTicket from "./views/support-ticket/SupportTicket";
import TicketDetails from "./views/support-ticket/TicketDetails";
import SupportFaq from "./views/support-ticket/SupportFAQ";
import CreateTicket from "./views/support-ticket/CreateTicket";
import Messages from './views/Messages';
import ApiSettings from './views/apiSettings/ApiSettings'
import Live from './views/live/Live'
import CreateLive from './views/live/CreateLive';
import EditLive from './views/live/EditLive';
import ComplainList from './views/complain/ComplainList';
import NoticeList from "./views/notice/NoticeList";
import NoticeView from "./views/notice/NoticeView";

const authData = getLocalAuthData()

axios.defaults.headers.common['x-auth-token'] = authData?.token || ''
axios.defaults.headers.common['x-api-client'] = getDeviceType()

axios.interceptors.response.use((response) => {
  return response;
}, (error) => {
  if(error?.response?.status === 410) {
    //localStorage.removeItem('user')
    //window.location.reload()
  }
})


ReactDOM.render(
  <Provider store={store}>
    <Router>
      <Switch>
        <PrivateRoute exact path="/">
          <Redirect
            to={{
              pathname: "/dashboard"
            }}
          />
        </PrivateRoute>

        <Router exact path={`${process.env.PUBLIC_URL}/reset-password`}>
          <ResetPassword/>
        </Router>

        <PrivateRoute exact path="/dashboard">
          <Dashboard/>
        </PrivateRoute>

        <PrivateRoute exact path="/products/list">
          <ProductList/>
        </PrivateRoute>

       <PrivateRoute exact path="/notice/ui">
              <NoticeView/>
            </PrivateRoute>

        <PrivateRoute exact path="/products/details/:id">
          <ProductDetails/>
        </PrivateRoute>

        <PrivateRoute exact path="/products/add">
          <AddProduct/>
        </PrivateRoute>

        <PrivateRoute exact path="/products/edit/:id">
          <EditProduct/>
        </PrivateRoute>

        <PrivateRoute exact path="/orders/list">
          <OrderList/>
        </PrivateRoute>

        <PrivateRoute exact path="/orders/details/:id">
          <OrderDetails/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/orders/invoice/:id`}>
          <Invoice/>
        </PrivateRoute>

        <PrivateRoute exact path="/reports/order">
          <OrderReport/>
        </PrivateRoute>

        <PrivateRoute exact path="/reports/sales">
          <SalesReport/>
        </PrivateRoute>

        <PrivateRoute exact path="/stores/list">
          <StoreList/>
        </PrivateRoute>

        <PrivateRoute exact path="/stores/create">
          <CreateStore/>
        </PrivateRoute>

        <PrivateRoute exact path="/stores/edit/:id">
          <EditStore/>
        </PrivateRoute>

        <PrivateRoute exact path={`/assisted-sellers`}>
          <AssistedSellers/>
        </PrivateRoute>

        <PrivateRoute exact path={`/assisted-sellers/add`}>
          <AddAssistedSeller/>
        </PrivateRoute>

        <PrivateRoute exact path={`/assisted-sellers/edit/:id`}>
          <EditAssistedSeller/>
        </PrivateRoute>

        <PrivateRoute exact path={`/assisted-sellers/details/:id`}>
          <AssistedSellerDetails/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/promotions/seller-voucher`}>
          <SellerVoucher/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/promotions/seller-voucher/add`}>
          <AddSellerVoucher/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/promotions/seller-voucher/edit/:id`}>
          <EditSellerVoucher/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/promotions/bundle-offer`}>
          <BundleOfferList/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/promotions/bundle-offer/add`}>
          <AddBundleOffer/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/promotions/bundle-offer/edit/:id`}>
          <EditBundleOffer/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/promotions/campaign`}>
          <CampaignOfferList/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/promotions/campaign-details-design`}>
          <CampaignMerchantDetails/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/promotions/campaign/add`}>
          <AddCampaignOffer/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/promotions/campaign/details/:id`}>
          <CampaignDetails/>
        </PrivateRoute>

        <PrivateRoute exact path="/commissions">
          <Commissions/>
        </PrivateRoute>

        <PrivateRoute exact path="/disputes">
          <Disputes/>
        </PrivateRoute>

        <PrivateRoute exact path="/payment-methods">
          <PaymentMethods/>
        </PrivateRoute>

        <PrivateRoute exact path="/payment-methods/add">
          <AddPaymentMethod/>
        </PrivateRoute>

        <PrivateRoute exact path="/finance">
          <Finance/>
        </PrivateRoute>

        <PrivateRoute exact path="/bulk-request">
          <BulkRequest/>
        </PrivateRoute>

        <PrivateRoute exact path="/profile">
          <Profile/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/banners`}>
          <Banners/>
        </PrivateRoute>

        <PrivateRoute exact path="/settings">
          <Settings/>
        </PrivateRoute>

        <PrivateRoute exact path="/messages">
          <Messages/>
        </PrivateRoute>

        <PrivateRoute exact path="/api-settings">
            <ApiSettings/>
        </PrivateRoute>

        <PrivateRoute exact path="/live">
            <Live />
        </PrivateRoute>

        <PrivateRoute exact path="/create-live">
            <CreateLive />
        </PrivateRoute>

        <PrivateRoute exact path="/edit-live/:id">
            <EditLive />
        </PrivateRoute>

        <PrivateRoute exact path="/complain/list">
          <ComplainList/>
        </PrivateRoute>

        <PrivateRoute exact path="/notice/list">
          <NoticeList/>
        </PrivateRoute>

        <PrivateRoute exact path="/notice/:id">-
          <NoticeView/>
        </PrivateRoute>

        <Route exact path="/email-verify/:token">
          <EmailVerify/>
        </Route>

        <Route exact path="/reset/:token">
          <EmailReset/>
        </Route>

        <Route exact path="/login">
          <Login/>
        </Route>

        <Route exact path="/support-ticket">
          <SupportTicket/>
        </Route>

        <Route exact path="/support-faq">
          <SupportFaq/>
        </Route>

        <Route exact path="/create-ticket">
          <CreateTicket/>
        </Route>

        <Route exact path="/support-ticket/:id">
          <TicketDetails/>
        </Route>

        <Route>
          <p>404 | Page not found</p>
          <p><Link to="/">Back to Home</Link></p>
        </Route>
      </Switch>
    </Router>

    <ToastContainer position="bottom-right" autoClose={3000}/>
  </Provider>
  , document.getElementById('root'));

serviceWorker.unregister();
