import moment from "moment";
import React, { Component } from 'react';
import { Button, Col, Form, InputGroup, Modal, Row } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import { FiCheckCircle, FiSearch } from "react-icons/all";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import App from "../../App";
import { updateStorePaymentMethods } from "../../services/paymentMethodServices";
import { deleteStore, getStoreList } from "../../services/storeServices";

import { FiEdit } from "@react-icons/all-files/fi/FiEdit";
import { FiTrash } from "@react-icons/all-files/fi/FiTrash";
import { Pagination } from "antd";
import axios from "axios";
import templateThumbOne from "../../assets/template-layouts/template-1.png";
import templateThumbTwo from "../../assets/template-layouts/template-2.png";
import StoreCoverChange from "../../components/StoreCoverChange";
import { API_BASE_URL } from "../../helper/env";
import { getLocalAuthData } from "../../helper/utils";
import { getRequest, patchRequest } from "../../services/baseServices";
import { getTableData } from "../../services/commonServices";
import { getChildLocations } from "../../services/locationServices";
import {
  addShippingOption, deleteShippingOptionPermanent, getSingleShippingOption, shippingOptions, updateShippingOption
} from "../../services/shippingMethodServices";

class StoreList extends Component {
  state = {
    storeList: [],
    loading: false,
    selectedStoreId: '',
    isShowingPaymentMethodSettingModal: false,
    isSubmittingPaymentMethods: false,
    defaultPaymentMethod: ["cash on delivery", "pegepay", "Bank Payment", "sslcommerz"],
    selectedPaymentMethods: [], //["sslcommerz", "cash on delivery", "pegepay", "Bank Payment"],
    activeBlockTab: 'Store Cover & Template',
    store_template_id: "1",


    pagination: null,
    isDataLoading: false,
    merchant: '',
    settings: {
      limit: 50
    },

    locations: [],
    isShowingStoreSettingModal: false,

    activeSettingTab: 'Shipping Options',
    shippingQueryData: {limit: 10, page: 1, store_id: ''},
    selectedShippingPartners: [],
    shippingChildLocations: [],
    shipping_methods: [],
    shippingFormData: {},
    existing_locations: [],
    selectedShippingId: '',
    editStatus: false,


  }

  componentDidMount() {
    this.setState({loading: true});

    this.getShippingOptions()

    //selectedPaymentMethods
    getStoreList()
      .then(res => {
        this.setState({storeList: res.items, loading: false},);
      })
      .catch(errMsg => {
        this.setState({loading: false});
      })

    axios.get(`${API_BASE_URL}/location?type=Country`)
        .then((res) => {
          let existing_locations = [...this.state.existing_locations];
          existing_locations[0] = res.data.locations;
          this.setState(
              {
                existing_locations,
                locations: res.data.locations,
                countries: res.data.locations,
              },
              () => {
                console.log(this.state);
              }
          );
        }).catch((e) => console.log("Error4"));


    this.setNewShippingFormData()
  }


  getSelectedPaymentMethod = (id) =>{
    getTableData('/store/available-payment-methods/'+id).then((res)=>{
      console.log(res)
      this.setState({
        selectedPaymentMethods: res.payment_methods
      })
    })
  }

  renderStatus = (status) => {
    if (status === 0) {
      return <span className={"badge badge-primary"}>Pending</span>
    } else if (status === 1) {
      return <span className={"badge badge-success"}>Active</span>
    } else {
      return <span className={"badge badge-warning"}>Blocked</span>
    }
  }

  handleDeleteStore = (id) => {
    confirmAlert({
      title: 'Delete',
      message: 'Are you sure to delete this store?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => deleteStore(id)
            .then(res => {
              getStoreList()
                .then(storeRes => {
                  this.setState({storeList: storeRes.items});
                })
                .catch(errMsg => {
                  // toast.error(errMsg);
                })
              toast.success(res.message);
            })
            .catch(errMsg => {
              toast.error(errMsg);
            })
        },
        {
          label: 'No',
          // onClick: () => alert('Click No')
        }
      ]
    });
  }

  renderApprovalStatus = (status) => {
    if (status === 0) {
      return <span className={`badge badge-info`}>In Review</span>
    } else if (status === 1) {
      return <span className={`badge badge-success`}>Approved</span>
    } else if (status === 2) {
      return <span className={`badge badge-danger`}>Declined</span>
    }
  }

  handleStorePaymentMethodsModal = (id) => {
    this.setState({selectedStoreId: id}, () => {
      getRequest(`/store/get-store-template/${this.state.selectedStoreId}`)
        .then(res => {
          this.setState({store_template_id: res.store_template_id, isShowingPaymentMethodSettingModal: true})
        })
        .catch(errMsg => {
          this.setState({isShowingPaymentMethodSettingModal: true})
          toast.error(errMsg)
        })
    });
  }

  handleOnChangePaymentMethods = (e, methodName) => {
    const selectedPaymentMethods = [...this.state.selectedPaymentMethods];
    var index = selectedPaymentMethods.indexOf(methodName);
    if (e.target.checked) {
      if (index === -1) {
        selectedPaymentMethods.push(methodName);
      }
    } else {
      if (index > -1) {
        selectedPaymentMethods.splice(index, 1);
      }
    }
    this.setState({selectedPaymentMethods}, () => {
      console.log(this.state.selectedPaymentMethods);
    });
  }

  submitChangeStorePaymentMethods = (e) => {
    e.preventDefault();
    let selectedPaymentMethods = [...this.state.selectedPaymentMethods];

    this.setState({isSubmittingPaymentMethods: true}, () => {
      updateStorePaymentMethods({payment_methods: selectedPaymentMethods}, this.state.selectedStoreId).then(res => {
        if (res.status === 1) {
          this.setState({
            // isShowingPaymentMethodSettingModal: false,
            isSubmittingPaymentMethods: false
          });
          toast.success(res.message);
        } else {
          this.setState({
            selectedStoreId: '',
            // isShowingPaymentMethodSettingModal: false,
            isSubmittingPaymentMethods: false
          });
          toast.warning(res.message);
        }
      }).catch(errMsg => {
        toast.error(errMsg);
        this.setState({isSubmittingPaymentMethods: false})
      })
    });
  }

  handleCloseStorePaymentMethodsModal = () => {
    this.setState({isShowingPaymentMethodSettingModal: false});
  }

  handleOnChangeTemplate = (e) => {
    this.setState({store_template_id: e.target.value}, () => {
      this.handleOnSaveTemplate(this.state.selectedStoreId)
    })
  }

  handleBlockTab = (label) => {
    this.setState({activeBlockTab: label})
  }

  handleOnSaveTemplate = (storeId) => {
    patchRequest(`/store/update-store-template/${storeId}`, {store_template_id: this.state.store_template_id})
      .then(res => {
        toast.success(res.message)
      })
      .catch(errMsg => {
        toast.error(errMsg)
      })
  }



  submitChangeShippingMethods = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    let shippingFormData = {...this.state.shippingFormData};


    let formDatax = {...this.state.shippingFormData , ...{store_id: this.state.selectedStoreId}}

    this.setState({isSubmittingShippingMethods: true}, () => {
      if (this.state.editStatus === false) {
        addShippingOption(formDatax)
            .then((res) => {
              if (res.status === 1) {
                this.getShippingOptions({store_id: this.state.selectedStoreId});
                this.setState({isSubmittingShippingMethods: false});
                this.setNewShippingFormData()
                toast.success(res.message);
              } else {
                this.setState({isSubmittingShippingMethods: false});
                toast.warning(res.message);
              }
            })
            .catch((errMsg) => {
              toast.error(errMsg);
            });
      } else if (this.state.editStatus === true && this.state.selectedShippingId !== '') {
        updateShippingOption(this.state.shippingFormData, this.state.selectedShippingId).then((res) => {
          if (res.status === 1) {
            this.getShippingOptions({store_id: shippingFormData.store_id});
            this.setState({isSubmittingShippingMethods: false});
            this.setNewShippingFormData()
            toast.success(res.message);
          } else {
            this.setState({isSubmittingShippingMethods: false});
            toast.warning(res.message);
          }
        }).catch((errMsg) => {
          toast.error(errMsg);
        });
      } else {
        toast.warning("Required Data missing, refresh and try again or try later.");
      }
    });
  }


  searchShippingOptions = async () => {
    this.getShippingOptions({...this.state.shippingQueryData,
      page: 1,
      destination_location: this.state.shippingFormData.destination_location,
      shipping_option: this.state.shippingFormData.shipping_option,
      store_id: this.state.selectedStoreId
    })
  }


  setNewShippingFormData = () =>{
    this.setState({
      shippingFormData: {
        shipping_option: "ekShop Fulfillment",
        location_hierarchy: [],
        locations: [],
        destination_location: "",
        estimated_time: "",
        fee: 0,
      },
      existing_locations: this.state.existing_locations.splice(0, 1)
    }, ()=>{

    })
  }


  handleOnChangeShippingForm = (e) => {
    console.log(e.target.name);
    const shippingFormData = {...this.state.shippingFormData};
    shippingFormData[e.target.name] = e.target.value;
    this.setState({shippingFormData}, () => {
      console.log("this.state");
      console.log(this.state.shippingFormData);
    });
  }



  handleShippingLocationInputOnChange = (e, index, typePurpose) => {
    const existing_locations = [...this.state.existing_locations];
    if (typePurpose === "shippingChildLocations") {
    } else {
      this.handleReturnInputOnChange(e);
    }

    const shippingFormData = {...this.state.shippingFormData};
    // const updatedShippingAddress = {...this.state.updatedShippingAddress};
    let location_hierarchy = shippingFormData.location_hierarchy;
    let locations = shippingFormData.locations;

    if (e.target.value !== "") {
      if (index >= 0 && existing_locations.length > index) {
        existing_locations.splice(index + 1, existing_locations.length - index);
        location_hierarchy.splice(index, location_hierarchy.length - index);
        locations.splice(index, locations.length - index)
      }

      let selected_location_array = existing_locations[index];
      let selected_location = selected_location_array.find(
          (element) => element._id === e.target.value
      );
      location_hierarchy.push(selected_location._id);
      locations.push(selected_location)
      shippingFormData.location_hierarchy = location_hierarchy;
      shippingFormData.destination_location = selected_location._id;
      shippingFormData.locations = locations

      //   updatedShippingAddress.location_hierarchy = location_hierarchy
      //   updatedShippingAddress.locations = locations

      this.setState({shippingFormData}, () => {
        console.log(this.state.shippingFormData.location_hierarchy);
      });
      // this.setState({shippingFormData, updatedShippingAddress}, () => {
      //     console.log(this.state.shippingFormData.locations);
      // });

      getChildLocations(e.target.value)
          .then((res) => {
            if (res.length > 0) {
              existing_locations[index + 1] = res;
            }
            this.setState({existing_locations}, () => {
              console.log("locations");
              console.log(this.state);
            });
          })
          .catch((errMsg) => {
            console.log(errMsg);
            // childLocations.splice(index, childLocations.length - index)
            // this.setState({[typePurpose]: childLocations})
          });
    } else {
      if (index > 0 && existing_locations.length > index) {
        existing_locations.splice(index + 1, existing_locations.length - index);
        location_hierarchy.splice(index, location_hierarchy.length - 1);
        // locations.splice(index, locations.length - 1)
      }
      shippingFormData.location_hierarchy = location_hierarchy;
      // shippingFormData.locations = locations

      this.setState({shippingFormData, existing_locations}, () => {
        console.log(this.state.shippingFormData.locations);
      });
    }
  };


  getShippingOptions = (params) => {
    shippingOptions(params).then(res => {
      this.setState({shipping_options: res.items, shippingMethodsPagination: res.pagination});
    }).catch(errMsg => {
      toast.error(errMsg.message);
    });
  }

  handleShippingMethodsPageChange = async (pageNumber) => {
    this.setState({shippingQueryData: {...this.state.shippingQueryData, page: pageNumber}}, () => {
      this.getShippingOptions({store_id: this.state.selectedStoreId, limit: 10, page: pageNumber});
    });
  }



  editShippingOption = (id) => {
    getSingleShippingOption(id)
        .then((res) => {
          if (res.status === 1) {
            this.setState(
                {existing_locations: [this.state.existing_locations[0]], selectedShippingId: id, editStatus: true},
                () => {
                  console.log("this.state.selectedShippingId", this.state.selectedShippingId);
                  res.shipping_option.location_hierarchy.forEach((item, index) => {
                    getChildLocations(item)
                        .then((res) => {
                          const existing_locations = [
                            ...this.state.existing_locations,
                          ];
                          existing_locations[index + 1] = res;
                          this.setState({existing_locations});
                        })
                        .catch((errMsg) => {
                          console.log(errMsg);
                        });

                    this.setState({isShowEditShippingAddressModal: true});
                  });
                  this.setState({shippingFormData: res.shipping_option});
                }
            );
          } else {
            toast.error("Error2");
          }
        })
        .catch((err) => {
          toast.error("Error3");
        });
  };

  deleteShippingOption = (id) => {
    deleteShippingOptionPermanent(id).then(res => {
      if (res.status === 1) {
        this.getShippingOptions({store_id: this.state.selectedStoreId});
        this.setNewShippingFormData()
        toast.warning(res.message);
      } else if (res.status === 2) {
        toast.warning(res.message)
      }
    }).catch(errMsg => {
      console.log(errMsg);
      toast.error(errMsg.message);
    })
  }



  render() {
    return (
      <App layout={"boxed"}>
        <div className="page-header">
          <h2 className="page-title">Stores</h2>
          <div className="ml-auto">
            <Link to={"/stores/create"} className={"btn btn-sm btn-secondary"}>Create Store</Link>
          </div>
        </div>

        <div className="card-block">
          <div className="block-header">
            <h4 className="block-title">Store List</h4>
          </div>
          <div className="block-body">
            {this.state.loading ?
              <div>Loading...</div>
              :
              <>
                <Form inline onSubmit={e => {
                  e.preventDefault();
                  getStoreList({search_string: this.state.search_string})
                    .then(storeRes => {
                      this.setState({storeList: storeRes.items});
                    })
                    .catch(errMsg => {
                      // toast.error(errMsg);
                    })
                }} className={"mb-3"}>
                  <InputGroup controlId={"search"}>
                    <Form.Label srOnly>Search:</Form.Label>
                    <Form.Control name={'search_string'}
                                  onChange={(e) => this.setState({[e.target.name]: e.target.value})}
                                  size={"sm"} placeholder={'Search...'} type={`search`}
                                  onKeyDown={(e)=>{
                                    if(e.keyCode === 13){
                                      //Codes to run on enter key press
                                    }
                                  }}/>
                    <InputGroup.Append>
                      <Button size={`sm`}
                              type={`submit`}
                              variant={`primary`}><FiSearch/></Button>
                    </InputGroup.Append>
                  </InputGroup>
                </Form>
                <table className="table table-striped">
                  <thead>
                  <tr>
                    <th style={{width: '20px'}}>#</th>
                    <th>Store Name</th>
                    <th>Created Date</th>
                    <th>Profile Status</th>
                    <th>Status</th>
                    <th style={{width: '1%'}}/>
                  </tr>
                  </thead>

                  <Modal size={'lg'} show={this.state.isShowingPaymentMethodSettingModal}
                         onHide={this.handleCloseStorePaymentMethodsModal}>
                    <Modal.Header closeButton>
                      <Modal.Title>Store Settings</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                      <div className="card-block mb-0">
                        <ul className="desc-header-nav">
                          <li className={this.state.activeBlockTab === 'Store Cover & Template' ? 'active' : ''}>
                            <span
                              onClick={() => this.handleBlockTab('Store Cover & Template')}>Store Cover & Template</span>
                          </li>

                          <li className={this.state.activeBlockTab === 'Shipping Options' ? 'active' : ''}>
                            <span onClick={() => this.handleBlockTab('Shipping Options')}>Shipping Options</span>
                          </li>

                          <li className={this.state.activeBlockTab === 'Payment Methods' ? 'active' : ''}>
                            <span onClick={() => this.handleBlockTab('Payment Methods')}>Payment Methods</span>
                          </li>
                        </ul>

                        <div className="block-body">
                          {this.state.activeBlockTab === 'Store Cover & Template' &&
                          <div>
                            <div className="mb-4">
                              <h6 className={'mb-3'}>Cover Picture</h6>

                              <StoreCoverChange storeId={this.state.selectedStoreId}/>
                            </div>

                            <h6 className={'mb-3'}>Choose Templates</h6>
                            <ul className="template-list">
                              <li>
                                <label className={'template'}
                                       htmlFor={'template-1'}>
                                  <input type="radio" defaultChecked
                                         checked={this.state.store_template_id === "1"}
                                         onChange={this.handleOnChangeTemplate}
                                         name={'template'} value={1} id={'template-1'}/>
                                  <span className={'template-thumb'}>
                                      <span className="icon"><FiCheckCircle/></span>
                                      <img src={templateThumbOne} alt="Default"/>
                                    </span>
                                </label>
                              </li>

                              <li>
                                <label className={'template'} htmlFor={'template-2'}>
                                  <input type="radio" name={'template'}
                                         checked={this.state.store_template_id === "2"}
                                         onChange={this.handleOnChangeTemplate}
                                         value={2} id={'template-2'}/>
                                  <span className={'template-thumb'}>
                                      <span className="icon"><FiCheckCircle/></span>
                                      <img className={'template-img'} src={templateThumbTwo} alt="Default"/>
                                    </span>
                                </label>
                              </li>
                            </ul>
                          </div>
                          }

                          {this.state.activeBlockTab === 'Shipping Options' &&
                          <>
                            <div className="card-block">
                              {
                                // addShippingEnable
                                (false)? 
                                  <Form onSubmit={this.submitChangeShippingMethods}>
                                    <div className="block-header">
                                      <h4 className="block-title">Add Shipping Methods</h4>

                                      <div>

                                        <Button
                                            disabled={this.state.isSubmittingShippingMethods}
                                            variant="primary"
                                            type={`button`}
                                            onClick={this.searchShippingOptions}
                                            className={"btn btn-sm ml-5"}>
                                          {"Search"}
                                        </Button>

                                        <Button
                                            disabled={this.state.isSubmittingShippingMethods}
                                            variant="primary"
                                            type={`button`}
                                            onClick={this.setNewShippingFormData}
                                            className={"btn btn-sm ml-5"}>
                                          {"Reset"}
                                        </Button>


                                        <Button disabled={this.state.isSubmittingShippingMethods}
                                                variant="primary" type={`submit`} className={'btn btn-sm ml-5'}>
                                          {this.state.isSubmittingShippingMethods ? 'Submitting...' : 'Save'}
                                        </Button>
                                      </div>
                                    </div>

                                    <div className="block-body">

                                      <Form.Group as={Row} controlId="formShippingOptions">
                                        <Form.Label column sm="2">Shipping Option</Form.Label>
                                        <Col sm="4" md={4} lg={4}>
                                          <Form.Check
                                              custom inline type="radio" name="shipping_option" id={`shippingSellerDelivery`}
                                              label={`Seller Delivery`} value={"Seller Delivery"}
                                              onChange={e => this.handleOnChangeShippingForm(e)}
                                              checked={
                                                this.state.shippingFormData.shipping_option ===
                                                "Seller Delivery"
                                              }/>

                                          <Form.Check
                                              custom inline type="radio" name="shipping_option" id={`shippingEkshopFulfillment`}
                                              label={`ekShop Fulfillment`} value={"ekShop Fulfillment"}
                                              onChange={e => this.handleOnChangeShippingForm(e)}
                                              checked={
                                                this.state.shippingFormData.shipping_option ===
                                                "ekShop Fulfillment"
                                              }/>
                                        </Col>
                                      </Form.Group>

                                      <Form.Group as={Row} controlId="formCountry">
                                        {this.state?.existing_locations.length > 0 &&
                                        this.state?.existing_locations.map((location, index) => {
                                          return (
                                              <>
                                                {location?.length > 0 && (
                                                    <>
                                                      <Form.Label column sm="2">
                                                        {location[0].type}
                                                      </Form.Label>
                                                      <Col sm="4" md={4} lg={4}>
                                                        <Form.Control
                                                            as="select"
                                                            className="mb-3"
                                                            name={location[0].type.toLowerCase()}
                                                            required={location[0].type === 'Country' ? true : false}
                                                            value={
                                                              this.state.shippingFormData
                                                                  ?.location_hierarchy[index]
                                                                  ? this.state.shippingFormData
                                                                      ?.location_hierarchy[index]
                                                                  : ""
                                                            }
                                                            onChange={(e) =>
                                                                this.handleShippingLocationInputOnChange(
                                                                    e,
                                                                    index,
                                                                    "shippingChildLocations"
                                                                )
                                                            }
                                                        >
                                                          <option value="">--Select--</option>
                                                          {location.map((item, index) => (
                                                              <option key={index} value={item._id}>
                                                                {item.name}
                                                              </option>
                                                          ))}
                                                        </Form.Control>

                                                        <Form.Control.Feedback type="invalid">
                                                          Please select {location[0].type.toLowerCase()}
                                                          .
                                                        </Form.Control.Feedback>
                                                      </Col>
                                                    </>
                                                )}
                                              </>
                                          );
                                        })}
                                      </Form.Group>

                                      <Form.Group key={'deliveryFee'} as={Row}>
                                        <Form.Label column sm="2">Fee <span className="text-danger">*</span></Form.Label>
                                        <Col sm="4" md={4} lg={4}>
                                          <Form.Control
                                              key={`${Math.floor((Math.random() * 1000))}deliveryFeeInput`}
                                              type="number"
                                              onChange={(e) => this.handleOnChangeShippingForm(e)}
                                              defaultValue={this.state.shippingFormData?.fee}
                                              name={"fee"}
                                              id={`deliveryFee`}
                                              min={0}
                                              step={0.5}
                                              required
                                              placeholder={`Enter Delivery Fee`}
                                          />
                                        </Col>

                                        <Form.Label column sm="2">Estimated Time <span
                                            className="text-danger">*</span></Form.Label>
                                        <Col sm="4" md={4} lg={4}>
                                          <Form.Control type="text"
                                                        key={`deliveryEstimatedTimeInput`}
                                                        onChange={e => this.handleOnChangeShippingForm(e)}
                                                        defaultValue={this.state.shippingFormData?.estimated_time}
                                                        name={'estimated_time'} id={`estimatedTime`}
                                                        required placeholder={`Estimated Delivery Time`}/>
                                        </Col>
                                      </Form.Group>

                                    </div>
                                  </Form>
                                  :
                                  <>
                                    <div className="block-header">
                                      <h4 className="block-title">Add Shipping Methods</h4>
                                    </div>
                                    <div className="block-body">
                                      <center>Contact Admin For Adding New Shipping Method</center>
                                    </div>
                                  </>


                              }
                              
                            </div>

                            <div className="card-block">
                              <div className="block-header">
                                <h4 className="block-title">Available Shipping Options</h4>
                              </div>

                              <div className="block-body">
                                {this.state.isLoading ?
                                    <>Loading...</>
                                    :
                                    <>
                                      <table className="table table-striped">
                                        <thead>
                                        <tr>
                                          <th style={{width: '20px'}}>#</th>
                                          <th>Shipping Option</th>
                                          <th>Location</th>
                                          <th>Fee</th>
                                          <th>Time</th>
                                          <th style={{width: `1%`}}/>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {this.state.shipping_options?.length > 0 ?
                                            this.state.shipping_options.map((item, index) => (
                                                <tr key={index}>
                                                  <td>{index + 1}</td>
                                                  <td>{item.shipping_option}</td>
                                                  <td>{item?.destination_location_info}</td>
                                                  <td>{item?.fee}</td>
                                                  <td>{item?.estimated_time}</td>

                                                  <td className="nowrap text-center">
                                                             <span className="action text-bold text-default mr-3 btn btn-outline-info btn-sm"
                                                                   onClick={() => this.editShippingOption(item._id)}><FiEdit/> Edit </span>
                                                    {getLocalAuthData().permission.includes("shipping_options_delete" ) ? (
                                                        <Button size={`sm`} onClick={() => this.deleteShippingOption(item._id)} variant={`outline-danger`}><FiTrash/>Delete</Button>
                                                    ) : null}
                                                  </td>
                                                </tr>
                                            )) :
                                            <tr>
                                              <td colSpan={7} className={`text-bold text-center`}>No item found</td>
                                            </tr>
                                        }
                                        </tbody>
                                      </table>
                                      <div className="mt-3">
                                        <Pagination
                                            itemClass="page-item"
                                            linkClass="page-link"
                                            activePage={this.state?.shippingMethodsPagination?.page}
                                            itemsCountPerPage={this.state?.shippingMethodsPagination?.limit}
                                            totalItemsCount={this.state?.shippingMethodsPagination?.totalDocs}
                                            pageRangeDisplayed={20}
                                            onChange={this.handleShippingMethodsPageChange.bind(this)}/>
                                      </div>
                                    </>
                                }
                              </div>
                            </div>
                          </>
                          }


                          {this.state.activeBlockTab === 'Payment Methods' &&
                          <Form onSubmit={this.submitChangeStorePaymentMethods}>
                            <Form.Group as={Row}>
                              <Col sm="9" md={8} lg={7}>
                                <Form.Check
                                  custom inline type="checkbox" name="payment_methods" id={`paymentMethodCOD`}
                                  label={`Cash On Delivery`} value={"cash on delivery"}
                                  onChange={e => this.handleOnChangePaymentMethods(e, "cash on delivery")}
                                  checked={this.state.selectedPaymentMethods.includes("cash on delivery")}/>
                              </Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                              <Col sm="9" md={8} lg={7}>
                                <Form.Check
                                  custom inline type="checkbox" name="payment_methods" id={`paymentMethodBankPayment`}
                                  label={`Bank Payment`} value={"Bank Payment"}
                                  onChange={e => this.handleOnChangePaymentMethods(e, "Bank Payment")}
                                  checked={this.state.selectedPaymentMethods.includes("Bank Payment")}/>
                              </Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                              <Col sm="9" md={8} lg={7}>
                                <Form.Check
                                  custom inline type="checkbox" name="payment_methods" id={`paymentMethodSSlCommerz`}
                                  label={`sslCommerz (Online Payment)`} value={"sslcommerz"}
                                  onChange={e => this.handleOnChangePaymentMethods(e, "sslcommerz")}
                                  checked={this.state.selectedPaymentMethods.includes("sslcommerz")}
                                />
                              </Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                              <Col sm="9" md={8} lg={7}>
                                <Form.Check
                                  custom inline type="checkbox" name="payment_methods" id={`paymentMethodpegepay`}
                                  label={`pegepay (Malaysia Online Payment)`} value={"pegepay"}
                                  onChange={e => this.handleOnChangePaymentMethods(e, "pegepay")}
                                  checked={this.state.selectedPaymentMethods.includes("pegepay")}
                                />
                              </Col>
                            </Form.Group>

                            <Button size={'sm'}
                                    disabled={this.state.isSubmittingPaymentMethods}
                                    variant="primary" type={`submit`}>
                              {this.state.isSubmittingPaymentMethods ? 'Submitting...' : 'Save Changes'}
                            </Button>
                          </Form>}
                        </div>
                      </div>
                    </Modal.Body>
                  </Modal>

                  <tbody>
                  {this.state.storeList.length > 0 ?
                    this.state.storeList.map((store, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{store.store_name}</td>
                        <td>{moment(store.createdAt).format('lll')}</td>
                        <td>{this.renderApprovalStatus(store?.approval_status)}</td>
                        <td>{this.renderStatus(store.status)}</td>
                        <td className="nowrap">
                          <Link to={`/stores/edit/${store._id}`} className="text-bold text-info mr-3">Edit</Link>
                          {/*<span style={{cursor: "pointer"}}
                                onClick={() => this.handleDeleteStore(store._id)}
                                className="text-bold text-danger">Delete</span>*/}
                          <br/>
                          <span style={{cursor: "pointer"}}
                                onClick={() => {
                                  this.getShippingOptions({store_id: store._id})
                                  this.getSelectedPaymentMethod(store._id)
                                  this.handleStorePaymentMethodsModal(store._id)
                                }}
                                className="text-bold text-default">Store Settings</span>
                        </td>
                      </tr>
                    ))
                    :
                    <tr>
                      <td colSpan={5} className={"text-bold text-center"}>
                        No store found. Please create <Link to={"/stores/create"}>new store</Link>
                      </td>
                    </tr>
                  }
                  </tbody>
                </table>
              </>
            }
          </div>
        </div>
      </App>
    );
  }
}

export default StoreList;
