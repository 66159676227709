import React, {Component} from 'react';
import App from "../../App";
import "../../assets/scss/invoice.scss";
import {getOrderDetails} from "../../services/orderServices";
import {withRouter} from "react-router-dom";
import InvoiceComponent from "../../components/InvoiceComponent";

class Invoice extends Component {
  state = {
    orderDetail: null,
    isLoading: true
  }

  componentDidMount() {
    const orderId = this.props.match.params.id

    getOrderDetails(orderId)
      .then(res => {
        this.setState({orderDetail: res, isLoading: false})
      })
      .catch(errMsg => {
        console.error(errMsg)
        this.setState({isLoading: false})
      })
  }

  render() {
    const {orderDetail} = this.state

    return (
      <App layout="boxed">
        <div className="main-content">

          {this.state.isLoading ?
            <div className={`card-block`}>
              <div className="block-body">
                Loading...
              </div>
            </div>
            :
            <>
              <InvoiceComponent data={orderDetail}/>
            </>
          }
        </div>
      </App>
    );
  }
}

export default withRouter(Invoice);
