import React, {Component} from 'react';
import {FaReply, FiTrash, FiEdit} from "react-icons/all";
import {Button, Form} from "react-bootstrap";
import {replyQuestion,hardDeleteQuestions} from "../services/productServices";
import {toast} from "react-toastify";
import {confirmAlert} from "react-confirm-alert";

class Question extends Component {
  state = {
    isShownReplyBox: false,
    formData: {},
    isReplying: false,
    isFormValidated: false,
    questions: this.props,
  }

  handleInputOnChange = (e) => {
    const formData = {...this.state.formData}
    formData[e.target.name] = e.target.value;
    this.setState({formData})
  }

  handleSubmitForm = e => {
    e.preventDefault();
    const formData = {...this.state.formData};
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      this.setState({isFormValidated: true});
    } else {
      this.setState({formData, isAdding: true}, () => {
        replyQuestion(this.state.questions.data._id, formData)
          .then(res => {
            this.props.getProductDetail();
            toast.success(res.message)
          }).catch(errMsg => {
          toast.error(errMsg)
        })
      })
    }
  }

  handleHardDeletequestion = (id) => {
    confirmAlert({
      title: 'Delete',
      message: 'Are you sure to delete this question?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => hardDeleteQuestions(id)
            .then(msg => {
              console.log(7799)
              // this.props.getProductDetail();
              // toast.success(msg)
              toast.success(msg);
              this.props.getProductDetail()
            }).catch(errMsg => {
              toast.error(errMsg)
            })

        },
        {
          label: 'No',
          // onClick: () => alert('Click No')
        }
      ]
    });
  }

  handleToggleReplyBox = () => {
    this.setState({isShownReplyBox: !this.state.isShownReplyBox})
  }

  handleCloseReplyBox = () => {
    this.setState({isShownReplyBox: false})
  }

  render() {
    const {data} = this.props;


    return (
      <div className="item">
        <div className="question">
          <span className="symbol">Q</span>
          <span className="text">{data.question}</span>
          <time className="time">{data.question_time}</time>
        </div>

        {data.answer &&
        <div className="answer">
          <span className="symbol">A</span>
          <span className='text'>{data.answer}</span>
          <time className="time">{data.answer_time}</time>
        </div>
        }

        <div className="q-a-actions">
          {data.answer ?
            <span onClick={this.handleToggleReplyBox} className="text-info"><FiEdit/> Edit</span>
            :
            <span onClick={this.handleToggleReplyBox} className="text-info"><FaReply/> Reply</span>
          }
          <span color="text-danger" onClick={() => this.handleHardDeletequestion(data._id)}><FiTrash/> Delete</span>
        </div>

        {this.state.isShownReplyBox &&
        <Form style={{padding: '15px 0 0 50px'}}
              noValidate
              validated={this.state.isFormValidated}
              onSubmit={this.handleSubmitForm}>
          <Form.Group>
            <Form.Control
              as="textarea"
              rows={3}
              name={'answer'}
              required
              onChange={this.handleInputOnChange}
              defaultValue={data?.answer}
            />
          </Form.Group>

          <Button className="mr-2"
                  type={"submit"}
                  disabled={this.state.isReplying}
                  variant="primary">
            {this.state.isReplying ? 'Replying...' : 'Reply'}
          </Button>
          <Button onClick={this.handleCloseReplyBox} variant="default">Close</Button>
        </Form>
        }
      </div>
    );
  }
}

export default Question;
