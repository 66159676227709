import React, {Component} from 'react';
import App from "../../App";
import {Link, withRouter} from "react-router-dom";
import {Form} from "react-bootstrap";
import {GoLinkExternal, MdArrowBack} from "react-icons/all";
import campaignMerchantImg from "../../assets/img/merchant-campaign-details.jpg"
import moment from "moment";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import {getDetail} from "../../services/commonServices";
import {getStoreList} from "../../services/storeServices";

class NoticeView extends Component {
  constructor(props){
    super(props);
    this.state = { date: new Date(), notice_info: {} };
  }
  componentDidMount() {
    let noticeId = this.props.match.params.id
    getDetail(`/notice/${noticeId}`, {}).then((res)=>{
      this.setState({notice_info: res.data})
    }).catch(err=>{
      console.log(err);
    })
  }

  render() {
    return (
      <App layout={"boxed"}>
        <div className="main-content">
          <div className="page-header">
            <h2 className="page-title">Notice View</h2>
            <div className="ml-auto">
              <Link to={`${process.env.PUBLIC_URL}/notice/list`} className={`btn btn-sm btn-link`}><MdArrowBack/> Back to List</Link>
            </div>
          </div>
          <div className="card-block">
            <h5 className="page-title form-control-md px-3 py-2 ml-1">Test Notice</h5>
          </div>
          <div className="card-block">
            <div className="merchant-campaign-details">
              <div className="campaign-details-banner" >
                <img src={campaignMerchantImg} alt="banner Image" />
              </div>
            </div>
          </div>

          <div className="card-block">
            <div className="campaign-text p-3 ml-1">
              <p className="campaign-descriptin"><strong>Description: </strong>The smartphone comes in a dimension of 161.3 x 74.2 x 7.4 mm
                and weighs 171 grams. It supports Dual SIM (Nano-SIM, dual stand-by) and the resolution of the display is 1080 x 2400 pixels.
                It is packed with 8 GB RAM with 128 GB internal storage. The smartphone is powered by Qualcomm SM7125 Snapdragon 720G Octa-core processor.
              </p>
            </div>
          </div>
        </div>
      </App>
    );
  }
}

export default withRouter(NoticeView);
