import React, {Component} from 'react';
import App from "../../App";
import {Button, Col, Form, Modal, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import {FaLongArrowAltLeft, IoMdCheckmark} from "react-icons/all";
import {Multiselect} from "react-widgets";
import Moment from "moment";
import {toast} from "react-toastify";
import {submitCampaign} from "../../services/promotionServices";
import {DateRangePicker} from "react-bootstrap-daterangepicker";
import DatePicker from "react-datepicker";
import Pagination from "react-js-pagination";
import {getProductListSecondary} from "../../services/productServices";
import {getStoreList} from "../../services/storeServices";
import BrowseFiles from "../../components/BrowseFiles";
import {getLocalAuthData} from "../../helper/utils";

const today = Moment(new Date()).format('Y-M-D');

class AddCampaignOffer extends Component {
  state = {
    formData: {
      campaign_title: '1',
      campaign_details: '1',
      start_date: today,
      end_date: today,
      campaign_banner: [{url: '#', img: null, mobile_img: null}],
      campaign_cover_image: ""
    },
    startDate: today,
    endDate: today,
    isFormValidated: false,
    isSubmitting: false,
    merchants: [],
    merchantList: [],
    categoryList: [],
    products: [],
    merchantTypes: [],
    selectedProductList: [],
    isLoading: true,
    selectedStores: [],
    isStoreModalStoreShowing: false,
    defaultQuerySettings: {
      merchantList: {lite_data: 1},
      storeList: {status: 1, approval_status: 1, lite_data: 1, limit: 20},
      productList: {limit: 20}
    },
    isStoreLoading: false
  };

  componentDidMount() {
    /*getStoreList(this.state.defaultQuerySettings.storeList).then(res => {
        const stores = []
        res.items.forEach(item => {
            stores.push({label: item.store_name, value: item._id})
        })
        this.setState({storeList: stores})
    })*/
  }

  handleInputOnChange = (e) => {
    const formData = {...this.state.formData}
    formData[e.target.name] = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    this.setState({formData})
  }

  handleOnApply = (e, picker) => {
    const formData = {...this.state.formData};
    const startDate = Moment(picker.startDate).format('Y-M-D');
    const endDate = Moment(picker.endDate).format('Y-M-D')

    formData.start_date = startDate;
    formData.end_date = endDate;

    this.setState({
      formData,
      startDate: startDate,
      endDate: endDate,
    })
  }

  handleSubmitForm = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    const formData = {...this.state.formData};

    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      this.setState({isFormValidated: true})
    } else {
      submitCampaign(this.state.formData).then(res => {
        if (res.status === 2) {
          toast.warning(res.message);
        } else {
          toast.success(res.message);
          this.props.history.push(`${process.env.PUBLIC_URL}/campaign`);
        }

        this.props.history.push(`${process.env.PUBLIC_URL}/campaign`);
        toast.success("Bundle Added");
      }).catch(errMsg => {
        toast.error(errMsg)
      })

      this.setState({isSubmitting: true});

      setTimeout(() => {
        this.setState({isSubmitting: false, isFormValidated: false})
      }, 2000);
    }
  }

  /* HANDLE STORE */


  handleSearchStore = (val) => {
    getStoreList({
      ...this.state.defaultQuerySettings.storeList,
      search_string: val
    }).then(res => {
      this.setState({
        stores: res.stores,
        storePagination: res.pagination
      }, () => console.log(this.state));
    })
  }

  handleRemoveSelectedStore = (index) => {
    const selectedStoreList = [...this.state.selectedStoreList]
    const formData = {...this.state.formData}
    selectedStoreList.splice(index, 1);
    formData.stores.splice(index, 1);
    this.setState({selectedStoreList, formData})
  }

  handleHideStoreModal = () => {
    this.setState({isStoreModalStoreShowing: false})
  }

  handleSelectedStoreOnChange = (e, id) => {
    const selectedStores = [...this.state.selectedStores];

    if (e.target.checked) {
      if (e.target.type === 'checkbox') {
        selectedStores.push(id);
      } else {
        selectedStores[0] = id;
      }
    } else {
      selectedStores.splice(selectedStores.indexOf(id), 1);
    }

    this.setState({selectedStores}, ()=>{
      console.log(this.state.selectedStores);
    });
  }

  handleShowSelectStoreModal = () => {
    this.setState({isStoreLoading: true}, () => {
      getStoreList({
        limit: 20,
        search_string: ""
      }).then(res => {
        this.setState({
          stores: res.items,
          storePagination: res.pagination,
          isStoreModalStoreShowing: true,
          isStoreLoading: false
        })
      }).catch(err => {
        this.setState({isStoreLoading: false})
      })
    })

  }

  handleAddStore = (selectedStores) => {
    console.log(selectedStores)
    const formData = {...this.state.formData}
    const stores = []

    selectedStores.forEach(item => {
      const store = this.state.stores.filter(obj => {
        return obj._id === item
      })
      stores.push(store[0])
    })

    formData.stores = selectedStores
    this.setState({
      selectedStoreList: stores,
      formData,
      isStoreModalStoreShowing: false,
      //selectedStores: []
    })
  }

  handleImageOnBrowse = (images, type = '') => {
    const formData = {...this.state.formData}
    if (type === 'cover_image') {
      formData.campaign_cover_image = images[0]?.actual_img
    } else if (type === 'mobile_img') {
      formData.campaign_banner[0].mobile_img = images[0]?.actual_img
    } else {
      formData.campaign_banner[0].img = images[0]?.actual_img
    }
    this.setState({formData}, () => {
      console.log(formData);
    })
  }

  handleOnRemoveItem = (type = '') => {
    const formData = {...this.state.formData}
    if (type === 'mobile_img') {
      formData.campaign_banner[0].mobile_img = null
    } else {
      formData.campaign_banner[0].img = null
    }
    this.setState({formData})
  }

  render() {
    return (
      <App layout="boxed">
        <div className="page-header">
          <h2 className="page-title">Add Campaign Offer</h2>
          <div className="ml-auto">
            <Link to={`${process.env.PUBLIC_URL}/promotions/campaign`}
                  className="btn btn-sm btn-link"><FaLongArrowAltLeft/> Back to Campaign List</Link>
          </div>
        </div>

        <div className="main-content">


          <Form
            noValidate validated={this.state.isFormValidated}
            onSubmit={this.handleSubmitForm}>
            <div className="card-block">

              <div className="block-header">
                <h4 className="block-title">Define Campaign</h4>
              </div>

              <div className="block-body">

                <Form.Group as={Row} controlId="campaignTitle">
                  <Form.Label column sm="3">
                    Campaign Title <span className="text-danger">*</span>
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <Form.Control name={'campaign_title'} type="text"
                                  onChange={this.handleInputOnChange} placeholder=""/>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="CampaignDetails">
                  <Form.Label column sm="3">
                    Campaign Details <span className="text-danger">*</span>
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <Form.Control name="campaign_details"
                                  onChange={this.handleInputOnChange}
                                  as="textarea" placeholder="Campaign Details" rows={3}/>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="formStartFrom">
                  <Form.Label column sm="3">
                    Date Range <span className="text-danger">*</span>
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <DateRangePicker containerStyles={{textAlign: 'left', width: '100%', display: 'block'}}
                                     onApply={this.handleOnApply}>
                      <input type="button"
                             value={`${this.state.startDate} to ${this.state.endDate}`}
                             style={{textAlign: 'left'}}
                             className="form-control"/>
                    </DateRangePicker>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="formEndTime">
                  <Form.Label column sm="3">
                    Joining Last Date <span className="text-danger">*</span>
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <DatePicker selected={this.state.formData.registration_last_date}
                                dateFormat="dd/MM/yyyy"
                                minDate={new Date()}
                                required
                                className={'form-control'} onChange={date => {
                      const formData = {...this.state.formData}
                      formData.registration_last_date = date
                      this.setState({formData}, () => {
                        console.log('Deposit Info: ', this.state.formData);
                      })
                    }}/>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="formCoverImage">
                  <Form.Label column sm="3">
                    Campaign Cover Image
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <BrowseFiles
                      userId={getLocalAuthData()?._id}
                      isSigle
                      image_category={'banner'}
                      onRemoveItem={() => this.handleOnRemoveItem('cover_image')}
                      images={this.state.formData.campaign_cover_image ? [{thumb_img: this.state.formData.campaign_cover_image}] : []}
                      onGetFiles={(images) => this.handleImageOnBrowse(images, 'cover_image')}/>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="formImages">
                  <Form.Label column sm="3">
                    Campaign Banner
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <BrowseFiles
                      userId={getLocalAuthData()?._id}
                      isSigle
                      image_category={'banner'}
                      onRemoveItem={this.handleOnRemoveItem}
                      images={this.state.formData.campaign_banner[0]?.img ? [{thumb_img: this.state.formData.campaign_banner[0]?.img}] : []}
                      onGetFiles={this.handleImageOnBrowse}/>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="formImages">
                  <Form.Label column sm="3">
                    Campaign Mobile Banner
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <BrowseFiles
                      userId={getLocalAuthData()?._id}
                      isSigle
                      image_category={'banner'}
                      onRemoveItem={() => this.handleOnRemoveItem('mobile_img')}
                      images={this.state.formData.campaign_banner[0]?.mobile_img ? [{thumb_img: this.state.formData.campaign_banner[0]?.mobile_img}] : []}
                      onGetFiles={(images) => this.handleImageOnBrowse(images, 'mobile_img')}/>
                  </Col>
                </Form.Group>

                {/*

                                <Form.Group as={Row} controlId="formEndTime">
                                    <Form.Label column sm="3">
                                        Category <span className="text-danger">*</span>
                                    </Form.Label>
                                    <Col sm="9" md={8} lg={7}>
                                        <Multiselect
                                            onChange={value => this.handleOnChangeCategory(value)}
                                            placeholder="Select varient"
                                            data={this.state.categories}/>
                                    </Col>
                                </Form.Group>


                                <Form.Group as={Row} controlId="formVoucherType">
                                    <Form.Label column sm="3">
                                        Shop Category <span className="text-danger">*</span>
                                    </Form.Label>
                                    <Col sm="9" md={8} lg={7}>
                                        <Form.Control as="select" placeholder="">
                                            <option value="all">All</option>
                                            <option value="1">Uddakta Mall</option>
                                            <option value="2">Location Entrepreneur</option>
                                            <option value="3">Global Entrepreneur</option>
                                        </Form.Control>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} controlId="formVoucherDisplayArea">
                                    <Form.Label column sm="3">
                                        Shops
                                    </Form.Label>
                                    <Col sm="9" md={8} lg={7}>
                                        <Form.Check
                                            custom inline type="radio"
                                            name="display_area"
                                            id={`apply-to-entire-shop`}
                                            label={`All Shop`}
                                        />
                                        <Form.Check
                                            custom inline type="radio"
                                            name="display_area"
                                            id={`apply-to-specific-product`}
                                            label={`Specific Shop`}
                                        />
                                    </Col>
                                </Form.Group>

                                */}

                <Modal show={this.state.isStoreModalStoreShowing} size={'lg'}
                       onHide={this.handleHideStoreModal}>
                  <Modal.Header closeButton>
                    <Modal.Title>Select Store</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div className="mb-3 form-inline">
                      <Form.Group>
                        <Form.Label srOnly>Search</Form.Label>
                        <Form.Control type={`search`}
                                      size={`sm`}
                                      onChange={(e) => {
                                        this.setState({storeSearchString: e.target.value})
                                      }}
                                      placeholder={`Search...`}/>
                      </Form.Group>

                      <Button type={`button`} size={`sm`} variant={`info`}
                              onClick={() => {
                                this.handleSearchStore(this.state.storeSearchString || '')
                              }} className={`ml-2`}>Search</Button>
                    </div>

                    {this.state?.stores?.length < 1 &&
                    <div className="alert alert-warning text-bold text-center">
                      no store found
                    </div>}

                    {this.state?.stores?.length > 0 &&
                    <>
                      <div className="product-checkbox-list">
                        {this.state?.stores?.map((item, index) => (
                          <label className={`product-item`} key={this.state.storePagination?.page + index}
                                 htmlFor={`store-${index}`}>
                            <input id={`store-${index}`}
                                   onChange={e => this.handleSelectedStoreOnChange(e, item._id)}
                                   name={`store`}
                                   type="checkbox"/>
                            <span className={`product`}>
                              <span className="product-img">
                                <img src={item?.store_logo} alt=""/>
                              </span>
                              <span className="product-short-info">
                                <span className="product-name">{item?.store_name}</span>
                              </span>
                              <span className="icon"><IoMdCheckmark/></span>
                            </span>
                          </label>
                        ))}
                      </div>

                      <br/>

                      <Pagination
                        itemClass="page-item"
                        linkClass="page-link"
                        activePage={this.state.storePagination?.page}
                        itemsCountPerPage={this.state.storePagination?.limit}
                        totalItemsCount={this.state.storePagination?.totalDocs}
                        onChange={(page) => {
                            getStoreList({page: page, limit: 20}).then(res => {
                                this.setState({
                                    stores: res.items,
                                    productPagination: res.pagination
                                }, () => console.log(this.state));
                            })
                        }}
                      />
                    </>
                    }
                  </Modal.Body>

                  <Modal.Footer>
                    <Button variant="secondary" onClick={this.handleHideStoreModal}>
                      Close
                    </Button>
                    <Button variant="primary" onClick={() => this.handleAddStore(this.state.selectedStores)}>
                      Save Changes
                    </Button>
                  </Modal.Footer>
                </Modal>

                {
                  <Form.Group as={Row} controlId="formStores">
                    <Form.Label column sm="3">Stores</Form.Label>
                    <Col sm="9" md={8} lg={7}>
                      <table className={`table table-striped table-bordered`}>
                        <thead className={`sm`}>
                        <tr>
                          <th style={{width: '50px'}}/>
                          <th>Store Name</th>
                          <th style={{width: '1%'}}/>
                        </tr>
                        </thead>

                        <tbody>
                        {this.state?.selectedStoreList?.length > 0 ?
                          <>
                            {this.state.selectedStoreList.map((item, index) => (
                              <tr key={index}>
                                <td>
                                  <img src={item?.store_logo} style={{width: '40px'}} alt=""/>
                                </td>
                                <td>{item?.store_name}</td>
                                <td><span style={{cursor: 'pointer'}}
                                          onClick={() => this.handleRemoveSelectedStore(index)}
                                          className={`text-bold text-danger`}>&times;</span></td>
                              </tr>
                            ))}
                          </>
                          :
                          <tr>
                            <td colSpan={3} className={`text-center text-bold`}>
                              No store selected
                            </td>
                          </tr>
                        }
                        </tbody>
                      </table>

                      <Button size="sm"
                              disabled={this.state.isStoreLoading}
                              onClick={() => this.handleShowSelectStoreModal()}
                              variant="link"> {!this.state.isStoreLoading ? 'Select Stores' : 'Please wait...'}</Button>
                    </Col>
                  </Form.Group>
                }

              </div>
            </div>


            <div className="card-block">
              <div className="block-body text-right">
                <Button type={'submit'} size="lg" variant="primary">Save</Button>
              </div>
            </div>

          </Form>

        </div>
      </App>
    );
  }
}

export default AddCampaignOffer;
