import React, {Component} from 'react';
import App from "../../App";
import {Link} from "react-router-dom";
import {AiOutlineEdit, FiPlus, IoMdClose} from "react-icons/all";
import {Button} from "react-bootstrap";
import {
  deletePaymentMethod,
  getPaymentMethodList,
  paymentMethodStatusChange
} from "../../services/paymentMethodServices";
import {confirmAlert} from "react-confirm-alert";
import {toast} from "react-toastify";

class PaymentMethods extends Component {
  state = {
    activeBlockTab: 'Banks',
    bankList: [],
    mfsList: [],
    methodStatus: null
  }

  componentDidMount() {
    getPaymentMethodList()
      .then(res => {
        this.setStatePaymentData(res)
      })
  }

  handleBlockTab = (label) => {
    this.setState({activeBlockTab: label})
  };

  handleStatusChange = (id) => {
    if (!this.state.methodStatus) return null
    confirmAlert({
      title: 'Change Status',
      message: 'Are you sure to change the status?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => paymentMethodStatusChange(id, {status: this.state.methodStatus})
            .then(res => {
              this.setState({methodStatus: null})
              toast.success(res.message);
            })
            .catch(errMsg => {
              this.setState({methodStatus: null})
              toast.error(errMsg);
            })
        },
        {
          label: 'No',
          onClick: () => {
            this.setState({methodStatus: null})
          }
        }
      ]
    });
  }

  setStatePaymentData = (res) => {
    const bankList = res.items.filter(obj => {
      return obj.method_type === 'Bank'
    })

    const mfsList = res.items.filter(obj => {
      return obj.method_type === 'Mobile'
    })

    this.setState({bankList, mfsList, pagination: res.pagination})
  }

  handleDeletePaymentMethod = (id) => {
    confirmAlert({
      title: 'Delete',
      message: 'Are you sure to delete the payment method?',
      buttons: [{
        label: 'Yes',
        onClick: () => deletePaymentMethod(id)
          .then(res => {
            getPaymentMethodList({page: this.state.pagination.page})
              .then(res => {
                this.setStatePaymentData(res)
              })
            toast.success(res.message);
          })
          .catch(errMsg => {
            toast.error(errMsg);
          })
      },
        {
          label: 'No'
        }]
    });
  }

  render() {
    return (
      <App layout={'boxed'}>
        <div className="page-header">
          <h2 className="page-title">Payment Methods</h2>
          <div className="ml-auto">
            <Link to="/payment-methods/add" className="btn btn-sm btn-secondary"><FiPlus/> Add Payment Method</Link>
          </div>
        </div>


        <div className="card-block">
          <ul className="desc-header-nav">
            <li className={this.state.activeBlockTab === 'Banks' ? 'active' : ''}>
              <span onClick={() => this.handleBlockTab('Banks')}>Banks</span>
            </li>
            <li className={this.state.activeBlockTab === 'Mobile Banking' ? 'active' : ''}>
              <span onClick={() => this.handleBlockTab('Mobile Banking')}>Mobile Banking (MFS)</span>
            </li>
          </ul>

          <div className="block-body">
            {this.state.activeBlockTab === 'Banks' &&
            <table className={'table table-striped'}>
              <thead>
              <tr>
                <th>#</th>
                <th>Bank Name</th>
                <th>Bank Account Name</th>
                <th>Account No</th>
                <th>Status</th>
                <th style={{width: '1%'}}/>
              </tr>
              </thead>

              <tbody>
              {this.state.bankList?.length > 0 ?
                this.state.bankList.map((bank, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{bank.bank_name}</td>
                    <td>{bank.bank_account_name}</td>
                    <td>{bank.bank_account_number}</td>
                    <td>
                      <label htmlFor={`changeStatus-bank-${index}`}>
                        <select name="status" id={`changeStatus-bank-${index}`}
                                defaultValue={bank.status}
                                onChange={(e) => {
                                  this.setState({methodStatus: e.target.value}, () => {
                                    this.handleStatusChange(bank._id)
                                  })
                                }}
                                className="simple-form-control">
                          <option value={'Active'}>Active</option>
                          <option value={'inactive'}>Inactive</option>
                        </select>
                      </label>
                    </td>
                    <td className="nowrap">
                      {/*<Link to={`/payment-methods/edit/${bank._id}`} className={'btn btn-sm btn-link'}>
                        <AiOutlineEdit/> Edit</Link>*/}
                      <Button variant={'link'} className={'text-danger'} onClick={() => {
                        this.handleDeletePaymentMethod(bank._id)
                      }} size={'sm'}><IoMdClose/> Remove</Button>
                    </td>
                  </tr>
                ))
                :
                <tr>
                  <td colSpan={'6'} className={'text-center text-bold text-warning'}>No bank found</td>
                </tr>
              }
              </tbody>
            </table>
            }

            {this.state.activeBlockTab === 'Mobile Banking' &&
            <table className={'table table-striped'}>
              <thead>
              <tr>
                <th>#</th>
                <th>Mobile Number</th>
                <th>Operator Name</th>
                <th>Status</th>
                <th style={{width: '1%'}}/>
              </tr>
              </thead>

              <tbody>
              {this.state.mfsList?.length > 0 ?
                this.state.mfsList.map((mfs, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{mfs.mobile_number}</td>
                    <td>{mfs.operator_name}</td>
                    <td>
                      <label htmlFor={`changeStatus-mfs-${index}`}>
                        <select name="status" id={`changeStatus-mfs-${index}`}
                                defaultValue={mfs.status}
                                onChange={(e) => {
                                  this.setState({methodStatus: e.target.value}, () => {
                                    this.handleStatusChange(mfs._id)
                                  })
                                }}
                                className="simple-form-control">
                          <option value={'Active'}>Active</option>
                          <option value={'inactive'}>Inactive</option>
                        </select>
                      </label>
                    </td>
                    <td className="nowrap">
                      {/*<Link to={`/payment-methods/edit/${mfs._id}`} className={'btn btn-sm btn-link'}>
                        <AiOutlineEdit/> Edit</Link>*/}
                      <Button variant={'link'} className={'text-danger'} onClick={() => {
                        this.handleDeletePaymentMethod(mfs._id)
                      }} size={'sm'}><IoMdClose/> Remove</Button>
                    </td>
                  </tr>
                ))
                :
                <tr>
                  <td colSpan={'6'} className={'text-center text-bold text-warning'}>No MFS found</td>
                </tr>}
              </tbody>
            </table>
            }
          </div>
        </div>
      </App>
    );
  }
}

export default PaymentMethods;