import React, {Component} from "react"
import "../../assets/scss/business-block-card.scss"
import "../../assets/scss/dashboard.scss"
//import Chart from "chart.js"
import {Col, Row} from "react-bootstrap"
import InfoCard from "../InfoCard";
import {
    AiOutlineCloseCircle,
    AiOutlineDollar, AiOutlineHourglass,
    AiOutlineWarning,
    BsCheckCircle, BsListCheck,
    FcSalesPerformance,
    FiFileText,
    GoListOrdered
} from "react-icons/all";

class UsersStats extends Component {
    // userStatsChartRef = React.createRef()
    //
    // componentDidMount() {
    //   setTimeout(() => {
    //     if (Object.keys(this.props.usersReports).length > 0) {
    //       const userStatsRef = this.userStatsChartRef.current.getContext("2d")
    //       new Chart(userStatsRef, {
    //         type: 'bar',
    //         data: {
    //           labels: Object.values(this.props.usersReports.datasets).map(o => o.name),
    //           datasets: [
    //             {
    //               yAxisID: 'A',
    //               label: 'Users',
    //               backgroundColor: ["#6f42c1", "#34bfa3", "#ffb822", "#f4516c"],
    //               radius: 0,
    //               borderWidth: 0,
    //               data: Object.values(this.props.usersReports.datasets).map(o => o.value),
    //               fill: true
    //             }
    //           ]
    //         },
    //         options: {
    //           scales: {
    //             xAxes: [{
    //               display: true,
    //               scaleLabel: {
    //                 display: false,
    //                 labelString: ''
    //               },
    //               ticks: {
    //                 padding: 10
    //               }
    //             }],
    //             yAxes: [{
    //               id: 'A',
    //               display: true,
    //               scaleLabel: {
    //                 display: true,
    //                 labelString: 'Users'
    //               },
    //               ticks: {
    //                 beginAtZero: true,
    //                 userCallback: function (label) {
    //                   if (Math.floor(label) === label) {
    //                     return label;
    //                   }
    //                 },
    //               }
    //             },
    //               {
    //                 id: 'B',
    //                 position: 'left',
    //                 display: false,
    //                 scaleLabel: {
    //                   display: true,
    //                   labelString: 'Users'
    //                 },
    //                 ticks: {
    //                   beginAtZero: true,
    //                   userCallback: function (label) {
    //                     if (Math.floor(label) === label) {
    //                       return label;
    //                     }
    //                   }
    //                 }
    //               },
    //               {
    //                 id: 'C',
    //                 position: 'right',
    //                 display: false,
    //                 scaleLabel: {
    //                   display: true,
    //                   labelString: 'KPI Percentage(%)'
    //                 },
    //                 ticks: {
    //                   beginAtZero: true,
    //                   userCallback: function (label) {
    //                     if (Math.floor(label) === label) {
    //                       return label + '%';
    //                     }
    //                   },
    //                   suggestedMin: 0,
    //                   suggestedMax: 100,
    //                 }
    //               }]
    //           },
    //           responsive: false,
    //           maintainAspectRatio: false,
    //           title: {
    //             display: false,
    //             text: 'Over all'
    //           },
    //           tooltips: {
    //             mode: 'index',
    //             intersect: false,
    //           },
    //           legend: {
    //             display: false
    //           },
    //           hover: {
    //             mode: 'nearest',
    //             intersect: true
    //           },
    //           barRadius: 0,
    //           elements: {
    //             line: {},
    //             point: {}
    //           }
    //         }
    //       })
    //     }
    //   }, 1000)
    // }
    //
    // componentDidUpdate(prevProps, prevState, snapshot) {
    //   this.componentDidMount();
    //
    // }

    render() {
        return (
            <div className="card-block">
                <div className="block-body">
                    <div className="body-header">
                        <h4 className="body-title">User Statistics</h4>
                    </div>
                    <Row>
                        {this.props.usersReports.datasets && this.props.usersReports.datasets.map((data, i) => {

                            return (
                                <Col className={"col-20"}>
                                    <InfoCard title={data?.name} className="yellow" icon={<FcSalesPerformance/>}
                                              value={data?.value}/>
                                </Col>
                            )

                        })}
                    </Row>
                </div>
            </div>
            // <Row>
            //   <Col md={6}>
            //     <div className="card-block">
            //       <div className="block-body">
            //         <div className="info-stats-list">
            //           {
            //             this.props.usersReports && this.props.usersReports.datasets && Object.values(this.props.usersReports.datasets).map(user => {
            //               return (
            //                 <div className="info-stats">
            //                   <div className="stats-text">
            //                     <h4 className="title">{user.name}</h4>
            //                     <p className="sub-title">{user.name}</p>
            //                   </div>
            //                   <div className="stats-value">
            //                     {user.value}
            //                   </div>
            //                 </div>
            //               )
            //             })
            //           }
            //         </div>
            //       </div>
            //     </div>
            //   </Col>
            //
            //   <Col md={6}>
            //     <div className="card-block">
            //       <div className="block-body">
            //         <div className="body-header">
            //           <h4 className="body-title">User Statistics</h4>
            //         </div>
            //         <canvas
            //           style={{height: '300px', width: '100%'}}
            //           ref={this.userStatsChartRef}
            //         />
            //       </div>
            //     </div>
            //   </Col>
            // </Row>
        )
    }
}

export default UsersStats;
