import axios from 'axios';
import {getLocalAuthData} from "../helper/utils";
import {API_BASE_URL} from "../helper/env";

export const getAssistedSellerDetails = (id) => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios.get(`${API_BASE_URL}/assisted-seller/${id}`, {
      headers: {
        "x-auth-token": authData.token,
        "x-api-client": 'web'
      }
    })
      .then(res => {
        if(res.data.status === 1) {
          resolve(res.data)
        }
      })
      .catch(err => {
        console.log(err.message)
      })
  })
}


export const deleteAssistedSeller = (id) => {
  const authData = getLocalAuthData();

  return new Promise((resolve, reject) => {
    axios.delete(`${API_BASE_URL}/assisted-seller/${id}`, {
      headers: {
        "x-auth-token": authData.token,
        "x-api-client": 'web'
      }
    })
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        console.log('Catch error: ', err)
        reject('Something went wrong!!')
      })
  })
}

export const deleteAssistedSellerPermanently = (id) => {
  const authData = getLocalAuthData();

  return new Promise((resolve, reject) => {
    axios.delete(`${API_BASE_URL}/assisted-seller/${id}/permanent`, {
      headers: {
        "x-auth-token": authData.token,
        "x-api-client": 'web'
      }
    })
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        console.log('Catch error: ', err)
        reject('Something went wrong!!')
      })
  })
}

export const getAge = (timestamp) =>{
  const birthdate = new Date(timestamp);
  const nbYearRounded = Math.floor((Date.now() - birthdate.getTime()) / (1000 * 3600 * 24 * 365));
  return nbYearRounded
}
