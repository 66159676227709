import axios from "axios";
import {API_BASE_URL} from "../helper/env";
import {getLocalAuthData} from "../helper/utils";

export const getLocationList = (paramsObj) => {
  const authData = getLocalAuthData();
  const defaultParamsObj = {type: 'Country', ...paramsObj}

  return new Promise((resolve, reject) => {
    axios.get(`${API_BASE_URL}/location`, {
      headers: {
        'x-auth-token': authData?.token || '',
        'x-api-client': 'web'
      },
      params: defaultParamsObj
    })
      .then(res => {
        if(res.data.status === 1 || res.data.status === 2) {
          resolve(res.data.locations)
        }
      })
      .catch(err => {
        console.log('Get location list error: ', err)
        reject('Something went wrong!')
      })
  })
}

export const getChildLocations = (id) => {
  return new Promise((resolve, reject) => {
    axios.get(`${API_BASE_URL}/location/child-location/${id}`)
      .then(res => {
        if(res.data.status === 1 || res.data.status === 2) {
          resolve(res.data.locations);
        } else {
          reject(res.data.message)
        }
      })
  })
}
