// noinspection JSUnresolvedVariable,JSUnresolvedFunction

import React, {Component} from 'react';
import "../assets/scss/product.scss";
import {Link} from "react-router-dom";
import moment from 'moment';
import {getLocalAuthData} from "../helper/utils";
import {FaStore, GoLinkExternal} from "react-icons/all";
import {STORE_URL} from "../helper/env";

class Product extends Component {
    state = {
        userType: null
    }

    componentDidMount() {
        const authData = getLocalAuthData()
        this.setState({userType: authData?.type})
    }

    renderStatus = (status) => {
        if (status === 1) {
            return ""/*<>|| <span className="text-success -badge -badge-success">/!*Active*!/</span></>*/
        } else if (status === 2) {
            return <> || <span className="text-warning -badge -badge-warning">Blocked</span></>
        }
    }

    renderBStatus = (status) => {
        let sta = "";
        if (status === 1) {
            sta = "2"
        } else {
            sta = "1"
        }
        return sta;
    }

    renderPublishStatus = (status) => {
        let sta = {1: "2", 2: "3", 3: "2"};
        return sta[status];
    }

    renderPublishStatusText = (status) => {
        let sta = {1: "Drafted", 2: "Published", 3: "Unpublished"};
        let staText = {1: "success", 2: "warning", 3: "danger"};
        let staBadge = {1: "badge-success", 2: "badge-warning", 3: "badge-danger"};
        return <>|| <span className={`text-${staText[status]} -badge -${staBadge[status]}`}> {sta[status]} </span></>;
    }

    renderBtnText = (status) => {
        let btnText = "";
        if (status === 1) {
            btnText = "Block"
        } else {
            btnText = "Unblock"
        }
        return btnText
    }

    renderPublishBtnText = (status) => {
        let btnText = {1: "Publish", 2: "Un-Publish", 3: "Publish"};
        return btnText[status]
    }

    renderAvailabilityStatus = (qnt) => {
        if (qnt === 0) {
            return <span className="badge badge-warning">Out of Stock</span>
        } else if (qnt > 0 && qnt <= 5) {
            return <span className="badge badge-warning">Low</span>
        } else {
            return <span className="badge badge-success">Available</span>
        }
    }

    render() {
        const {data} = this.props;
        const {showCorders} = this.props


        {console.log(showCorders)}

        return (
            <div className="product">
                <Link to={`${process.env.PUBLIC_URL}/products/details/${data._id}`} className="product-thumb" target={'_blank'}>
                    <img src={data.thumb_img} alt=""/>
                </Link>

        <div className="product-desc">
          <h4 className="product-title">
            <Link to={`${process.env.PUBLIC_URL}/products/details/${data._id}`} target={'_blank'}>{data.name}</Link>
            <a target={'_blank'}
               rel="noopener noreferrer"
               className={'ml-3 text-primary'}
               href={`${STORE_URL}/products/details/${data.slug?data.slug:data._id}`}><GoLinkExternal/></a>
          </h4>
          <p className="date mb-0">
              <em>Created at : </em>  {moment(data.createdAt).format('LL')}
              {this.renderStatus(data.status)} {this.renderPublishStatusText(data.publish_status)}
          </p>
          <p className=""><span
            className="d-inline-block mr-2"><FaStore/></span> {data?.store_info?.store_name}</p>

            {showCorders ?
                <div className="attr">
                    <span className="attr-title">Campaign Orders : </span>
                    <span className="attr-value">{data?.c_orders ? data?.c_orders : 0}</span>
                </div>
                : null
            }

          {data?.sku_info &&
          <div className="product-attr">



            {/*<div className="attr">
              <span className="attr-title">SKU</span>
              <span className="attr-value">{data.sku_info.sku}</span>
            </div>*/}
            {data.sku_info?.special_price &&
            <div className="attr">
              <span className="attr-title">Sale Price</span>
              <span className="attr-value">৳ {data.sku_info?.special_price?.toFixed(2)}</span>
            </div>}
            <div className="attr">
              <span className="attr-title">Regular Price</span>
              <span className="attr-value">৳ {data.sku_info?.price?.toFixed(2)}</span>
            </div>
            <div className="attr">
              <span className="attr-title">Available</span>
              <span className="attr-value">{data.sku_info.quantity}</span>
            </div>
            <div className="attr">
              <span className="attr-title">Stock</span>
              <span className="attr-value">{this.renderAvailabilityStatus(data.sku_info.quantity)}</span>
            </div>

            {data.campaign_product ?  <div className="attr">
              <span className="attr-title"><span className="badge badge-info">Campaign Product</span></span>
            </div> : null }
          </div>}
        </div>

                <div className="actions">
                    <Link to={`${process.env.PUBLIC_URL}/products/details/${data._id}`} className="btn btn-block btn-sm btn-outline-info" target={'_blank'}>View</Link>
                    <Link to={`${process.env.PUBLIC_URL}/products/edit/${data._id}`} className="btn-action btn-block btn-outline-primary" target={'_blank'}>Edit</Link>

                    {this.props.handleProductPublishStatus && <button className="btn btn-block btn-sm btn-outline-success" onClick={() => this.props.handleProductPublishStatus(this.props.data._id, this.renderPublishStatus(this.props.data.publish_status))}>{this.renderPublishBtnText(this.props.data.publish_status)}</button>}

                    {/*{this.props.productDelete && <button onClick={() => this.props.productDelete(data._id)} className="btn-action btn-block btn-outline-danger">Delete</button>}*/}

                    {(this.props.approveCampaignProduct) && <button onClick={() => this.props.approveCampaignProduct(this.props.data._id)} className="btn btn-block btn-sm btn-outline-success">Approve</button>}

                    {(this.props.removeCampaignProduct) && <button onClick={() => this.props.removeCampaignProduct(this.props.data._id)} className="btn btn-block btn-sm btn-outline-danger">Remove</button>}


                    {/*Product Settings*/}
                   <button className="btn btn-block btn-sm btn-outline-primary" onClick={() => this.props.handleProductSettings(this.props.data._id)}>Product Settings</button>


                    {/* managing from campaign details */}
                    {this.props.removeFromCampaignRegisteredProducts && this.props.approvedProducts?.length > 0 && this.props.approvedProducts.findIndex((elm) => elm._id.toString() === data._id.toString()) === -1 && <button onClick={() => this.props.removeFromCampaignRegisteredProducts(this.props.data._id)} className="btn btn-block btn-sm btn-outline-danger">Remove</button>}
                    {this.props.removeFromCampaignRegisteredProducts && this.props.approvedProducts?.length === 0 && <button onClick={() => this.props.removeFromCampaignRegisteredProducts(this.props.data._id)} className="btn btn-block btn-sm btn-outline-danger">Remove</button>}

                    {this.props.removeFromCampaignApprovedProducts && this.props.registeredProducts?.length > 0 && this.props.registeredProducts.findIndex((elm) => elm._id.toString() === data._id.toString()) === -1 && <button onClick={() => this.props.removeFromCampaignApprovedProducts(this.props.data._id)} className="btn btn-block btn-sm btn-outline-danger">Remove</button>}

                    {/* managing from campaign details */}
                    {this.props.addProductInCampaign && ((this.props.registeredProducts?.length > 0 && this.props.registeredProducts.findIndex((elm) => elm._id.toString() === data._id.toString()) === -1) && (this.props.approvedProducts?.length > 0 && this.props.approvedProducts.findIndex((elm) => elm._id.toString() === data._id.toString()) === -1)) && <button onClick={() => this.props.addProductInCampaign(this.props.data._id)} className="btn btn-block btn-sm btn-outline-primary">Add to Campaign</button>}
                    {this.props.addProductInCampaign && ((this.props.registeredProducts?.length > 0 && this.props.registeredProducts.findIndex((elm) => elm._id.toString() === data._id.toString()) === -1) && this.props.approvedProducts?.length === 0) && <button onClick={() => this.props.addProductInCampaign(this.props.data._id)} className="btn btn-block btn-sm btn-outline-primary">Add to Campaign</button>}
                    {this.props.addProductInCampaign && this.props.registeredProducts?.length === 0 && this.props.approvedProducts?.length === 0 && <button onClick={() => this.props.addProductInCampaign(this.props.data._id)} className="btn btn-block btn-sm btn-outline-primary">Add to Campaign</button>}
                    {this.props.addProductInCampaign && this.props.registeredProducts?.length > 0 && this.props.registeredProducts.findIndex((elm) => elm._id.toString() === data._id.toString()) !== -1 && <span className="badge badge-warning">Registered</span>}
                    {this.props.addProductInCampaign && this.props.approvedProducts?.length > 0 && this.props.approvedProducts.findIndex((elm) => elm._id.toString() === data._id.toString()) !== -1 && <span className="badge badge-success">Approved</span>}
                </div>
            </div>
        );
    }
}

export default Product;
