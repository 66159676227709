import React, { useEffect, useState } from "react";
import App from "../../App";
import Moment from "moment";
import { BsArrowLeft, FiClock, RiReplyLine } from "react-icons/all";
import { Button, Form } from "react-bootstrap";
import { Link, useParams, useHistory, useLocation } from "react-router-dom";
import {
  supportTicketAdd,
  supportTicketDetails,
  supportTicketUpdate,
  supportTicketChangeStatus,
  ticketRepliesList,
} from "../../services/support-ticketService";
import { toast } from "react-toastify";

const TicketDetails = () => {
  const [isReplyFormOpen, setIsReplyFormOpen] = useState(false);
  const [ticketDetails, setTicketDetails] = useState({});
  const [formData, setFormData] = useState({});
  const [status, setStatus] = useState(null);
  const [repliesData, setRepliesData] = useState([]);
  const [isSubmitted, setSubmitted] = useState(false);
  const [isStatusUpdated, setIsStatusUpdated] = useState(false);

  const handleToggleReplyForm = () => {
    setIsReplyFormOpen(!isReplyFormOpen);
  };

  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      const result = await supportTicketDetails(id);
      setTicketDetails(result.supportTicket);
      setRepliesData(result.supportTicket.replies);
      console.log("repliesData", result);
    };
    fetchData();
  }, [isSubmitted]);

  useEffect(() => {
    if (status !== null) {
      supportTicketChangeStatus(ticketDetails._id, { status: status }).then(
        (res) => {
          if (res.status === 1) {
            setTicketDetails({ ...ticketDetails, status: status });
            toast.success(res.message);
            console.log(res);
          } else {
            toast.error(res.message);
          }
        }
      );
    }
  }, [status]);

  const onChangeListener = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmitForm = async (e) => {
    e.preventDefault();
    e.currentTarget.reset();
    console.log(formData);
    const result = await ticketRepliesList(id, formData);
    console.log(result);
    if (result.status === 1) {
      toast.success(result.message);
      setSubmitted(!isSubmitted);
    } else {
      toast.error(result.message);
    }
  };

  const ticketDetailsElement = () => {
    return (
      <>
        <div className="ticket-header mb-3">
          <div className="d-flex justify-content-between">
            <div>
              <h3 className="ticket-title">#{ticketDetails.support_id}</h3>
              <div className="d-block">
                Name: <strong>{ticketDetails.user_info?.name || ""}</strong>
              </div>
            </div>

            {/* <div>
              {ticketDetails.status === "Pending" && (
                <Form.Control
                  name={"status"}
                  onChange={(e) => {
                    debugger;
                    setStatus(e.target.value);
                    setIsStatusUpdated(true);
                  }}
                  as={"select"}
                  size={"sm"}
                >
                  <option label="--Select--" />
                  <option value="Solved">Solved</option>
                  <option value="Reject">Reject</option>
                  <option value="Close">Close</option>
                </Form.Control>
              )}
            </div> */}
          </div>

          <div className="item-attr mt-3">
            <div className="attr">
              <span className="attr-title">Topic</span>
              <span className="attr-value">
                {ticketDetails.support_topic_name}
              </span>
            </div>
            <div className="attr">
              <span className="attr-title">Open at</span>
              <span className="attr-value">
                {Moment(ticketDetails.createdAt).format("ll")}
              </span>
            </div>
            <div className="attr">
              <span className="attr-title">Current Status</span>
              <span className="attr-value">
                <span className="status-badge bg-info-light">
                  {ticketDetails.status}
                </span>
              </span>
            </div>
          </div>
        </div>

        <p>{ticketDetails.message}</p>
      </>
    );
  };

  const ticketReply = () => {
    //onsole.log(repliesData)
    return (
      <>
        {repliesData.map((data, index) => (
          <li key={index}>
            <p className="replies-meta">
              replied by <strong>{data.sender_info?.name || ""} </strong>
            </p>
            <span className={"mr-2"}>
              <FiClock /> {Moment(data.createdAt).fromNow()}
            </span>
            <p>{data.message}</p>
          </li>
        ))}
      </>
    );
  };

  return (
    <App layout={"boxed"}>
      <div className="page-header">
        <Link to={"/support-ticket"} className={"btn btn-sm btn-link"}>
          <BsArrowLeft /> Back to Support Ticket
        </Link>
      </div>
      <div className="card-block">
        <div className="block-body">
          {ticketDetailsElement()}

          <div className="ticket-replies">
            <div className="replies-header">
              <h2 className="title">Replies</h2>
            </div>

            <ul className="replies-list">{ticketReply()}</ul>

            {!isReplyFormOpen &&
              !isStatusUpdated &&
              ticketDetails.status === "Pending" && (
                <p className={"replies-action"}>
                  <span
                    className="text-bold text-info"
                    onClick={handleToggleReplyForm}
                    style={{ cursor: "pointer" }}
                  >
                    <RiReplyLine /> <span className="ml-2">Reply</span>
                  </span>
                </p>
              )}

            {isReplyFormOpen && !isStatusUpdated && (
              <Form onSubmit={handleSubmitForm} className={"reply-form"}>
                <Form.Group>
                  <Form.Control
                    onChange={(e) => onChangeListener(e)}
                    as={"textarea"}
                    name={`message`}
                    rows={4}
                    placeholder={"Leave your comment"}
                  />
                </Form.Group>

                <Button type={`submit`} variant={"primary"}>
                  Submit
                </Button>
                <Button
                  variant={"default"}
                  onClick={handleToggleReplyForm}
                  className={"ml-3"}
                >
                  Cancel
                </Button>
              </Form>
            )}
          </div>
        </div>
      </div>
    </App>
  );
};

export default TicketDetails;
