import React, {Component} from 'react';
import App from "../../App";
import {toast} from 'react-toastify';
import {Button, Col, Form, Row} from "react-bootstrap";
import {getChildLocations, getLocationList} from "../../services/locationServices";
import {saveStore} from "../../services/storeServices";
import {withRouter} from "react-router-dom";
import {getLocalAuthData} from "../../helper/utils";
import BrowseFiles from "../../components/BrowseFiles";
import {getCategoryList} from "../../services/categoryServices";
import {checkIsExisting, getTableData} from "../../services/commonServices";
import Select from "react-select";
import styleReactSelect from "../../assets/styleReactSelect";

class CreateStore extends Component {
  state = {
    activeBlockTab: 'Business Information',
    countries: [],
    wareHouseChildLocations: [],
    returnChildLocations: [],
    assistedSellerChildLocations: [],
    generalInfoChildLocations: [],
    formData: {
      warehouse_locations: {},
      return_location: {},
      assisted_selling: false,
      assisted_seller_info: {},
    },
    isSubmitting: false,
    isFormValidated: false,
    categories: [],
    storeExistingStatus: 2,
    storeExistingMsg: null,
    splittedPhone: ['+880', ''],
  };

  componentDidMount() {
    getLocationList()
      .then(res => {
        this.setState({countries: res})
      })
    getCategoryList('store')
      .then(res => {
        this.setState({categories: res})
      })
  }

  handleBlockTab = label => {
    this.setState({
      activeBlockTab: label
    })
  };

  handleLocationInputOnChange = (e, index, typePurpose) => {
    const childLocations = [...this.state[typePurpose]];
    const value = e.target.value;
    if (typePurpose === 'generalInfoChildLocations') {
      this.handleInputOnChange(e)
    } else if (typePurpose === 'wareHouseChildLocations') {
      this.handleWareHouseInputOnChange(e)
    } else if (typePurpose === 'assistedSellerChildLocations') {
      this.handleAssistedSellerInputOnChange(e)
    } else {
      this.handleReturnInputOnChange(e)
    }

    if (e.target.value !== '') {
      getChildLocations(e.target.value)
        .then(res => {
          childLocations.splice(index, childLocations.length - index);
          if (res.length > 0) {
            childLocations[index] = res
          }
          this.setState({[typePurpose]: childLocations})
        })
        .catch(errMsg => {
          console.log(errMsg)
          childLocations.splice(index, childLocations.length - index)
          this.setState({[typePurpose]: childLocations})
        })
    } else {
      childLocations.splice(index, childLocations.length - index);
      this.setState({[typePurpose]: childLocations})
    }
  }

  handleInputOnChange = (e) => {
    const formData = {...this.state.formData};
    formData[e.target.name] = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    this.setState({formData});
  }

  handleSelectInputOnChange = (name, value) => {
    this.setState({formSubmitError: ""});
    const formData = {...this.state.formData};
    formData[name] = value.value;
    this.setState({formData}, () => {
      console.log(this.state.formData);
    });
  }

  handleSubmitForm = (e) => {
    e.preventDefault();
    const authData = getLocalAuthData();

    const formData = {...this.state.formData};
    formData.merchant = authData.merchant_id;

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      this.setState({isFormValidated: true})
    } else {
      this.setState({formData, isSubmitting: true}, () => {
        saveStore(this.state.formData)
          .then(res => {
            this.setState({isSubmitting: false, isFormValidated: false});
            this.props.history.push('/stores/list');
            toast.success(res.message);
          })
          .catch(errMsg => {
            this.setState({isSubmitting: false, isFormValidated: false});
            this.setState({isFormValidated: false})
            toast.error(errMsg);
          })
      })
    }
  }

  handleWareHouseInputOnChange = (e) => {
    const formData = {...this.state.formData};
    formData.warehouse_locations[e.target.name] = e.target.value;
    this.setState({formData});
  }

  handleReturnInputOnChange = (e) => {
    const formData = {...this.state.formData};
    formData.return_location[e.target.name] = e.target.value;
    this.setState({formData});
  }

  handleAssistedSellerInputOnChange = (e) => {
    const formData = {...this.state.formData};
    formData.assisted_seller_info[e.target.name] = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    this.setState({formData});
    console.log(this.state.formData.assisted_seller_info);
  }

  inputSellerPhoneOnChange = (e, index) => {
    const splittedPhone = [...this.state.splittedPhone]
    splittedPhone[index] = e.target.value
    const formData = {...this.state.formData}
    formData.assisted_seller_info.phone = splittedPhone.join('-')
    this.setState({splittedPhone})
  }

  handleAddProductImages = async (files) => {
    const formData = {...this.state.formData}
    formData.store_logo = files[0].thumb_img;
    this.setState({formData});
  }

  handleCheckSameAddress = (e) => {
    const formData = {...this.state.formData};
    formData[e.target.name] = e.target.value === "true";
    if(e.target.value === 'true') {
      formData.return_location = this.state.formData.warehouse_locations
      this.setState({formData, returnChildLocations: this.state.wareHouseChildLocations})
    } else {
      formData.return_location = {}
      this.setState({formData, returnChildLocations: []})
    }
  }

  handleOnRemoveItem = () => {
    const formData = {...this.state.formData}
    formData.store_logo = null
    this.setState({formData})
  }

  handleGetAssistedSellerList = (string='') => {
    if (string.length > 2) {
      this.getAssistedSellers(string)
    }
  }

  getAssistedSellers = (search_string='') =>{
    getTableData(`/assisted-seller`, {limit: 20, search_string: search_string, merchant_id: this.state.formData.merchant}).then(res => {
      if (res.status === 1) {
        const assisted_sellers = [];
        if (res.items.length > 0) {
          res.items.forEach(item => {
            assisted_sellers.push({id: item._id, label: item.name, value: item._id});
          })
        }
        this.setState({assisted_sellers})
      } else {
        toast.warning("assisted seller not found");
      }
    })
  }

  render() {
    return (
      <App layout={"boxed"}>
        <div className="page-header">
          <h2 className="page-title">Create Stores</h2>
        </div>

        <div className="card-block">
          <ul className="desc-header-nav">
            <li className={this.state.activeBlockTab === 'Business Information' ? 'active' : ''}>
              <span onClick={() => this.handleBlockTab('Business Information')}>Business Information</span>
            </li>
          </ul>

          <Form noValidate validated={this.state.isFormValidated}
                onSubmit={this.handleSubmitForm} className="block-body">
            {/*General Information*/}
            <Form.Group as={Row} controlId="">
              <Form.Label column sm="3"/>
              <Col sm="9" md={8} lg={7}>
                <strong>General Information</strong>
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="storeName">
              <Form.Label column sm="3">
                Store Name <span className="text-danger">*</span>
              </Form.Label>

              <Col sm="9" md={8} lg={7}>
                <Form.Control
                  required
                  onChange={this.handleInputOnChange}
                  name={"store_name"} type={"text"}/>

                  <Form.Control.Feedback type="invalid">
                      Store name required
                  </Form.Control.Feedback>
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="storeName">
              <Form.Label column sm="3">
                Logo <span className="text-danger">*</span>
              </Form.Label>

              <Col sm="9" md={8} lg={7}>
                <BrowseFiles isSingle={true}
                             onRemoveItem={this.handleOnRemoveItem}
                             images={this.state.formData?.store_logo ? [{thumb_img: this.state.formData?.store_logo}] : []}
                             image_category={'logo'}
                             onGetFiles={this.handleAddProductImages}/>

                  <Form.Control.Feedback type="invalid">
                      Store logo required
                  </Form.Control.Feedback>
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="category">
              <Form.Label column sm="3">
                Category <span className="text-danger">*</span>
              </Form.Label>

              <Col sm="9" md={8} lg={7}>
                <Form.Control
                  required
                  onChange={this.handleInputOnChange}
                  name={"category"} as={"select"}>
                  <option value="">--Select Category--</option>
                  {this.state.categories.length > 0 &&
                  this.state.categories.map((cat, index) => <option key={index} value={cat._id}>{cat.name}</option>)
                  }
                </Form.Control>

                  <Form.Control.Feedback type="invalid">
                      Please select store category
                  </Form.Control.Feedback>
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="address">
              <Form.Label column sm="3">
                Address <span className="text-danger">*</span>
              </Form.Label>

              <Col sm="9" md={8} lg={7}>
                <Form.Control
                  required
                  onChange={this.handleInputOnChange}
                  name={"address"} type={"text"}/>

                  <Form.Control.Feedback type="invalid">
                      Store address required
                  </Form.Control.Feedback>
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formCountry">
              <Form.Label column sm="3"> Country <span className="text-danger">*</span></Form.Label>
              <Col sm="9" md={8} lg={7}>
                <Form.Control as="select"
                              required
                              name="country"
                              onChange={(e) => this.handleLocationInputOnChange(e, 0, 'generalInfoChildLocations')}>
                  <option value="">--Select Country--</option>
                  {this.state.countries.map((country, index) => (
                    <option key={index} value={country._id}>{country.name}</option>
                  ))}
                </Form.Control>

                  <Form.Control.Feedback type="invalid">
                      Store country required
                  </Form.Control.Feedback>
              </Col>
            </Form.Group>

            {this.state.generalInfoChildLocations.length > 0 &&
            this.state.generalInfoChildLocations.map((location, index) => (
              <Form.Group key={index} as={Row} controlId={`location${location[0].type}`}>
                <Form.Label column sm="3">{location[0].type}</Form.Label>
                <Col sm="9" md={8} lg={7}>
                  <Form.Control as="select"
                                name={location[0].type.toLowerCase()}
                                onChange={(e) => this.handleLocationInputOnChange(e, index + 1, 'generalInfoChildLocations')}>
                    <option value="">--Select--</option>
                    {location.map((item, index) => (
                      <option key={index} value={item._id}>{item.name}</option>
                    ))}
                  </Form.Control>

                    <Form.Control.Feedback type="invalid">
                        Store {location[0].type} required
                    </Form.Control.Feedback>
                </Col>
              </Form.Group>
            ))}

            <Form.Group as={Row} controlId="">
              <Form.Label column sm="3">
                Postcode <span className="text-danger">*</span>
              </Form.Label>

              <Col sm="9" md={8} lg={7}>
                <Form.Control
                  required
                  onChange={this.handleInputOnChange}
                  name={"postcode"} type={"text"}/>
              </Col>

                <Form.Control.Feedback type="invalid">
                    Store postcode required
                </Form.Control.Feedback>
            </Form.Group>

            {/*Warehouse Details*/}
            <Form.Group as={Row} controlId="">
              <Form.Label column sm="3"/>
              <Col sm="9" md={8} lg={7}>
                <strong>Warehouse Details</strong>
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="contactPerson">
              <Form.Label column sm="3">
                In Charge Name
              </Form.Label>

              <Col sm="9" md={8} lg={7}>
                <Form.Control
                  onChange={this.handleWareHouseInputOnChange}
                  name={"contact_person"} type={"text"}/>

                  <Form.Control.Feedback type="invalid">
                      Store warehouse contact person required
                  </Form.Control.Feedback>
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="">
              <Form.Label column sm="3">
                Phone Number
              </Form.Label>

              <Col sm="9" md={8} lg={7}>
                <Form.Control
                  onChange={this.handleWareHouseInputOnChange}
                  name={"contact_number"} type={"text"}/>

                  <Form.Control.Feedback type="invalid">
                      Store warehouse contact person phone number required
                  </Form.Control.Feedback>
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="address">
              <Form.Label column sm="3">
                Address <span className="text-danger">*</span>
              </Form.Label>

              <Col sm="9" md={8} lg={7}>
                <Form.Control
                  required
                  onChange={this.handleWareHouseInputOnChange}
                  name={"address"} type={"text"}/>
                  <Form.Control.Feedback type="invalid">
                      Store warehouse address required
                  </Form.Control.Feedback>
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formCountry">
              <Form.Label column sm="3"> Country </Form.Label>
              <Col sm="9" md={8} lg={7}>
                <Form.Control as="select" name="country"
                              required
                              onChange={(e) => this.handleLocationInputOnChange(e, 0, 'wareHouseChildLocations')}>
                  <option value="">--Select Country--</option>
                  {this.state.countries.map((country, index) => (
                    <option key={index} value={country._id}>{country.name}</option>
                  ))}
                </Form.Control>
                  <Form.Control.Feedback type="invalid">
                      Store warehouse country required
                  </Form.Control.Feedback>
              </Col>
            </Form.Group>

            {this.state.wareHouseChildLocations.length > 0 &&
            this.state.wareHouseChildLocations.map((location, index) => (
              <Form.Group key={index} as={Row} controlId={`location${location[0].type}`}>
                <Form.Label column sm="3">{location[0].type}</Form.Label>
                <Col sm="9" md={8} lg={7}>
                  <Form.Control as="select"
                                name={location[0].type.toLowerCase()}
                                onChange={(e) => this.handleLocationInputOnChange(e, index + 1, 'wareHouseChildLocations')}>
                    <option value="">--Select--</option>
                    {location.map((item, index) => (
                      <option key={index} value={item._id}>{item.name}</option>
                    ))}
                  </Form.Control>
                    <Form.Control.Feedback type="invalid">
                        Store warehouse {location[0].type} required
                    </Form.Control.Feedback>
                </Col>
              </Form.Group>
            ))
            }

            <Form.Group as={Row} controlId="">
              <Form.Label column sm="3">
                Postcode <span className="text-danger">*</span>
              </Form.Label>

              <Col sm="9" md={8} lg={7}>
                <Form.Control
                  required
                  onChange={this.handleWareHouseInputOnChange}
                  name={"postcode"} type={"text"}/>
                  <Form.Control.Feedback type="invalid">
                      Store warehouse postcode required
                  </Form.Control.Feedback>
              </Col>
            </Form.Group>

            {/* Return Address */}
            <Form.Group as={Row} controlId="">
              <Form.Label column sm="3"/>
              <Col sm="9" md={8} lg={7}>
                <strong>Return Address</strong>
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="">
              <Form.Label column sm="3">
                Copy from warehouse address
              </Form.Label>
              <Col sm="9" md={8} lg={7}>
                <Form.Check
                  custom
                  inline
                  type="radio"
                  name="is_same_as_warehouse_locations"
                  onChange={this.handleCheckSameAddress}
                  id={`sameAddressYes`}
                  label={`Yes`}
                  value={true}
                />
                <Form.Check
                  custom
                  inline
                  type="radio"
                  value={false}
                  onChange={this.handleCheckSameAddress}
                  name="is_same_as_warehouse_locations"
                  id={`sameAddressNo`}
                  label={`No`}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="">
              <Form.Label column sm="3">
                Contact Person
              </Form.Label>

              <Col sm="9" md={8} lg={7}>
                <Form.Control
                    required
                  defaultValue={this.state.formData.return_location.contact_person}
                  onChange={this.handleReturnInputOnChange}
                  name={"contact_person"} type={"text"}/>
                  <Form.Control.Feedback type="invalid">
                      Contact person name for product return address is required
                  </Form.Control.Feedback>
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="">
              <Form.Label column sm="3">
                Contact Number
              </Form.Label>

              <Col sm="9" md={8} lg={7}>
                <Form.Control
                    required
                  defaultValue={this.state.formData.return_location.contact_number}
                  onChange={this.handleReturnInputOnChange}
                  name={"contact_number"} type={"text"}/>
                  <Form.Control.Feedback type="invalid">
                      Contact person's phone number for product return address is required
                  </Form.Control.Feedback>
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="">
              <Form.Label column sm="3">
                Address
              </Form.Label>

              <Col sm="9" md={8} lg={7}>
                <Form.Control
                    required
                  name={"address"}
                  defaultValue={this.state.formData.return_location.address}
                  onChange={this.handleReturnInputOnChange} type={"text"}/>
                  <Form.Control.Feedback type="invalid">
                      Product return address required
                  </Form.Control.Feedback>
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formCountry">
              <Form.Label column sm="3"> Country </Form.Label>
              <Col sm="9" md={8} lg={7}>
                <Form.Control as="select"
                              required
                              value={this.state.formData.return_location.country}
                              name="country"
                              onChange={(e) => this.handleLocationInputOnChange(e, 0, 'returnChildLocations')}>
                  <option value="">--Select Country-{this.state.formData.return_location.country}--</option>
                  {this.state.countries.map((country, index) => (
                    <option key={index} value={country._id}>{country.name}</option>
                  ))}
                </Form.Control>
                  <Form.Control.Feedback type="invalid">
                      Country for store product return address is required
                  </Form.Control.Feedback>
              </Col>
            </Form.Group>

            {this.state.returnChildLocations.length > 0 &&
            this.state.returnChildLocations.map((location, index) => (
              <Form.Group key={index} as={Row} controlId={`location${location[0].type}`}>
                <Form.Label column sm="3">{location[0].type}</Form.Label>
                <Col sm="9" md={8} lg={7}>
                  <Form.Control as="select"
                                value={this.state.formData.return_location[location[0].type.toLowerCase()]}
                                name={location[0].type.toLowerCase()}
                                onChange={(e) => this.handleLocationInputOnChange(e, index + 1, 'returnChildLocations')}>
                    <option value="">--Select - {this.state.formData.return_location[location[0].type.toLowerCase()]} --</option>
                    {location.map((item, index) => (
                      <option key={index} value={item._id}>{item.name}</option>
                    ))}
                  </Form.Control>
                    <Form.Control.Feedback type="invalid">
                        {location[0].type} for store product return address is required
                    </Form.Control.Feedback>
                </Col>
              </Form.Group>
            ))
            }

            <Form.Group as={Row} controlId="">
              <Form.Label column sm="3">
                Postcode <span className="text-danger">*</span>
              </Form.Label>

              <Col sm="9" md={8} lg={7}>
                <Form.Control
                    required
                  defaultValue={this.state.formData.return_location.postcode}
                  name={"postcode"}
                  onChange={this.handleReturnInputOnChange}
                  type={"text"}/>
                  <Form.Control.Feedback type="invalid">
                      Postcode for store product return address is required
                  </Form.Control.Feedback>
              </Col>
            </Form.Group>


            <hr/>
            {/*Assisted Selling*/}

            <Form.Group as={Row}>
              <Form.Label column sm="3">
                Assisted Selling
              </Form.Label>
              <Col sm="9" md={8} lg={7}>
                <Form.Check
                    custom inline type="checkbox"
                    name="assisted_selling" id={`assistedSelling`}
                    label={``} value={true} onChange={this.handleInputOnChange}
                    checked={this.state?.formData?.assisted_selling}
                />
              </Col>
            </Form.Group>


            {this.state.formData?.assisted_selling &&
            <>
              <Form.Group as={Row} controlId="formAssistedSeller">
                <Form.Label column sm="3">
                  Assisted Seller <span className="text-danger">*</span>
                </Form.Label>
                <Col sm="9" md={8} lg={7}>
                  <Select styles={styleReactSelect} required
                          placeholder="Type for Select assisted seller..."
                          onInputChange={this.handleGetAssistedSellerList}
                          id="assisted_seller_id" name="assisted_seller_id"
                          defaultValue={this.state?.formData?.assisted_seller_id}
                          onChange={(value) => {
                            this.handleSelectInputOnChange('assisted_seller_id', value)
                          }}
                          options={this.state?.assisted_sellers}
                          noOptionsMessage={() => 'Please insert new value...'}
                  />

                    <Form.Control.Feedback type="invalid">
                        Please Select your Store seller
                    </Form.Control.Feedback>
                  {<span className="text-help text-muted">{'Please search and select assisted seller'}</span>}
                </Col>
              </Form.Group>
            </>
            }


            <div>
              <Button size="lg" type={"submit"}
                      disabled={this.state.isSubmitting}
                      variant="primary">{this.state.isSubmitting ? 'Saving...' : 'Save'}</Button>
            </div>
          </Form>
        </div>
      </App>
    );
  }
}

export default withRouter(CreateStore);
