import axios from "axios";
import {getDeviceType, getLocalAuthData} from "../helper/utils";
import {API_BASE_URL} from "../helper/env";

export const getOrderList = (paramsData = {}) => {
  const authData = getLocalAuthData()
  const params = {
    ...paramsData,
    merchant: authData.merchant_id
  }
  return new Promise((resolve, reject) => {
    axios.get(`${API_BASE_URL}/order-parcel`, {
      headers: {
        'x-auth-token': authData.token,
        'x-api-client': getDeviceType()
      },
      params: params
    })
      .then(res => {
        if(res.data.status === 1) {
          resolve(res.data)
        } else {
          reject(res.data.message)
        }
      })
      .catch(err => {
        console.log(err)
        reject('Something went wrong')
      })
  })
}


export const getBulkRequestList = (params = {}) => {
  const authData = getLocalAuthData();

  return new Promise((resolve, reject) => {
    axios.get(API_BASE_URL + '/bulk-request', {
      headers: {
        'x-auth-token': authData.token,
        'x-api-client': getDeviceType()
      },
      params: params
    })
      .then(res => {
        if (res.data.status === 1) {
          resolve(res.data);
        } else {
          reject(res.data.message)
        }
      })
      .catch(err => {
        reject(err.message)
      })
  })
}


export const orderStatusChange = (id, data) => {
  const authData = getLocalAuthData()
  return new Promise((resolve, reject) => {
    axios.patch(`${API_BASE_URL}/order/parcel/${id}/change-status`, data, {
      headers: {
        'x-auth-token': authData.token,
        'x-api-client': getDeviceType()
      }
    })
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        console.error(err)
        reject('Something went wrong')
      })
  })
}

export const getOrderDetails = (id) => {
  const authData = getLocalAuthData()
  return new Promise((resolve, reject) => {
    axios.get(`${API_BASE_URL}/order/parcel/${id}`, {
      headers: {
        'x-auth-token': authData.token,
        'x-api-client': getDeviceType()
      }
    })
      .then(res => {
        if(res.data.status === 1) {
          resolve(res.data.order_detail)
        } else {
          reject(res.data.message)
        }
      })
      .catch(err => {
        console.error(err)
        reject('Something went wrong')
      })
  })
}

export const updateDepositPaymentStatus = (data = {}) => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios.patch(API_BASE_URL + '/payment/bank-deposit-status', data, {
      headers: {'x-auth-token': authData.token, 'x-api-client': getDeviceType()}
    }).then(res => {
      resolve(res.data);
    }).catch(err => {
      reject(err.message)
    })
  })
}
