import {
  FILE_IS_UPLOADING, FILE_UPLOAD_COMPLETE,
  FILE_UPLOAD_FAILURE,
  FILE_UPLOAD_REQUEST,
  FILE_UPLOAD_SUCCESS,
  GET_FILE_LIST_FAILURE,
  GET_FILE_LIST_REQUEST,
  GET_FILE_LIST_SUCCESS
} from "./actionTypes";


const initialState = {
  files: [],
  isGettingFile: false,
  gettingFileErrMsg: null,
  fileIsUploading: false,
  uploadComplete: 0
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_FILE_LIST_REQUEST:
      return {
        ...state,
        isGettingFile: true
      }
    case GET_FILE_LIST_SUCCESS:
      return {
        ...state,
        isGettingFile: false,
        files: action.payload
      }
    case GET_FILE_LIST_FAILURE:
      return {
        ...state,
        isGettingFile: false,
        gettingFileErrMsg: action.payload
      }
    case FILE_UPLOAD_REQUEST:
      return {
        ...state,
        fileIsUploading: true
      }
    case FILE_IS_UPLOADING:
      return {
        ...state,
        uploadComplete: action.payload
      }
    case FILE_UPLOAD_SUCCESS:
      return {
        ...state,
        fileIsUploading: false,
        uploadComplete: 0
      }
    case FILE_UPLOAD_COMPLETE:
      return {
        ...state,
        uploadComplete: 100
      }
    case FILE_UPLOAD_FAILURE:
      return {
        ...state,
        fileIsUploading: false,
        uploadComplete: 0
      }
    default:
      return state
  }
}

export default reducer;
