import { Upload } from "antd";
import ImgCrop from "antd-img-crop";
import 'antd/dist/antd.css';
import React, { Component } from 'react';
import { Button, Col, Form, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import App from "../../App";
import BrowseFiles from "../../components/BrowseFiles";
import { getLocalAuthData, objectToFormData } from "../../helper/utils";
import { INCOMPLETE } from "../../helper/variables";
import { checkIsExisting, getCustomerDetails, getTableData, updateDataV2, uploadImage } from "../../services/commonServices";
import { getChildLocations, getLocationList } from "../../services/locationServices";
import { getMerchantDetails, updateProfile } from "../../services/merchantServices";
import { getMerchantType } from "../../services/merchantTypeServices";
import { savePaymentMethod } from "../../services/paymentMethodServices";
import { getBankList } from "../../services/settingServices";

class Profile extends Component {
  state = {
    locationTypes: [
      {
        type: 'Country'
      },
      {
        type: 'Division',
        isParentLocationType: true
      },
      {
        type: 'State',
        isParentLocationType: true
      },
      {
        type: 'District',
        isParentLocationType: true
      },
      {
        type: 'Upazilla',
        isParentLocationType: true
      },
      {
        type: 'Union',
        isParentLocationType: true
      }
    ],
    fileList: [],
    activeBlockTab: 'General',
    countries: [],
    businessChildLocations: [],
    merchantTypes: [],
    formData: {
      bank_info: {}
    },
    oldData:{},
    isLoading: true,
    merchant_id: null,
    isUpdating: false,
    isFormValidated: false,
    isMFSFormValidated: true,
    profileStatus: null,
    emailExistingStatus: 2,
    emailExistingMsg: null,
    businessNameExistingStatus: 2,
    businessNameExistingMsg: null,
    userId: null,
    bankList: [],
    branchNameList: [],
    singleInVerificationField: {},
    profileInVerificationFields: [],

    formData2: {
      method_type: ''
    },
    operatorList: []
  };



  handleMethodOnChange2 = (e) => {
    const formData2 = {}
    if (e.target.checked === true){
      formData2.method_type = 'Mobile'
    }else {
      formData2.method_type = ''
    }

    this.setState({formData2})

  }

  handleRemoveImg2 = (key) => {
    const formData2 = {...this.state.formData2};
    formData2[key] = "";
    this.setState({formData2});
  }

  handleOnBrowsePic2 = (files, name) => {
    const formData2 = {...this.state.formData2}
    formData2[name] = files[0].actual_img;
    this.setState({formData2})
  }

  handleInputOnChange2 = e => {
    const formData2 = {...this.state.formData2};
    formData2[e.target.name] = e.target.value;
    if (e.target.name === 'bank_id') {
      const bank = this.state.bankList.filter(obj => {
        return obj._id === e.target.value
      })
      formData2.bank_name = bank[0].name
    }
    this.setState({formData2});
  }

  handlePaymentMethodTypeSave = (e) => {
    e.preventDefault()

    this.setState({isSaving: true}, () => {
      savePaymentMethod(this.state.formData2)
          .then(res => {
            this.setState({isSaving: false})
            this.props.history.push('/payment-methods')
            toast.success(res.message)
          })
          .catch(errMsg => {
            this.setState({isSaving: false})
            toast.error(errMsg)
          })
    })
  }




  componentDidMount() {
    const authData = getLocalAuthData();
    this.setState({merchant_id: authData.merchant_id})

    getCustomerDetails().then(res => {
          const customer = res.customer
          this.setState({
            fileList: [{url: customer.profile_picture}]
          })
          const formData = {...this.state.formData}
          formData.profile_picture = customer?.profile_picture ? customer?.profile_picture : ""

          this.setState({formData}, () => {
            console.log('dd', this.state)
          })
        })
        .catch(err => {
          alert(err)
        })


    getLocationList()
      .then(res => {
        this.setState({countries: res})
      })

    getMerchantType()
      .then(res => {
        this.setState({merchantTypes: res})
      })

    getBankList()
      .then(res => {
        this.setState({bankList: res})
        this.getMerchantDetails()
      })
      .catch(errMsg => {
        console.log(errMsg)
        this.getMerchantDetails()
      })


    getTableData(`/merchant-payment-method`, {method_type: 'Mobile'}).then(res => {
      this.setState({merchant_payment_methods: res.items})
    }).catch(errMsg => {
      console.log(errMsg)
      this.getMerchantDetails()
    })
  }

  getMerchantDetails = () => {
    getMerchantDetails()
      .then(res => {
        const formData = {
          ...this.state.formData,
          merchant_code: res?.merchant_code,
          user: res.user_info._id || '',
          merchant_type: res.merchant_type || '',
          owner_name: res.user_info.name || '',
          name: res.name || '',
          phone: res.user_info.phone || '',
          phone_verified: res.phone_verified || true,
          email: res.user_info.email || '',

          id_type: res.id_type || '',
          identification_number: res.identification_number || '',
          id_front_img: res.id_front_img || '',
          id_back_img: res.id_back_img || '',

          business_registration_number: res.business_registration_number || '',
          address: res.address,
          country: res.country,
          division: res.division,
          district: res.district,
          upazila: res.upazila,
          union: res.union,
          province: res.province,
          state: res.state,
          city: res.city,
          postcode: res.postcode,
          legal_form: res?.legal_form,
          bank_info: {
            bank_account_branch: res?.bank_info?.bank_account_branch,
            bank_account_cheque_img: res?.bank_info?.bank_account_cheque_img,
            bank_account_name: res?.bank_info?.bank_account_name,
            bank_account_number: res?.bank_info?.bank_account_number,
            bank_routing_number: res?.bank_info?.bank_routing_number,
            bank_name: res?.bank_info?.bank_name
          }
        }
        const authData = getLocalAuthData()

        authData.profile_status = res.user_info?.profile_status
        localStorage.setItem('user', JSON.stringify(authData))

        res.locations.forEach((item, index) => {
          getChildLocations(item._id)
            .then(res => {
              const businessChildLocations = [...this.state.businessChildLocations]
              businessChildLocations[index] = res
              this.setState({businessChildLocations})
            })
            .catch(errMsg => {
              console.log(errMsg)
            })
        })

        this.setState({
          oldData: formData,
          formData,
          isLoading: false,
          locations: res?.locations,
          userId: res?.user_info?._id,
          profileStatus: res?.user_info?.profile_status,
          profile_completion_status: res.user_info?.profile_completion_status,
          declinedMessage: res.user_info?.declined_reason
        }, () => {
          const bank = this.state.bankList.filter(obj => {
            return obj._id === this.state.formData.bank_info.bank_name
          })

          if (bank.length > 0) {
            this.setState({branchNameList: bank[0].branches})
          } else {
            this.setState({branchNameList: []})
          }
        })
      })
      .catch(errMsg => {
        this.setState({isLoading: false});
      })
  }

  handleBlockTab = label => {
    this.setState({
      activeBlockTab: label
    })
  };

  handleLocationInputOnChange = (e, index, typePurpose) => {
    this.handleInputOnChange(e);
    this.getChildLocations(e, e.target.value, index, typePurpose);
  }

  getChildLocations = (e, value, index, typePurpose) => {
    const childLocations = [...this.state[typePurpose]];

    if (e.target.value !== '') {
      getChildLocations(value)
        .then(res => {
          childLocations.splice(index, childLocations.length - index);
          if (res.length > 0) {
            childLocations[index] = res
          }
          this.setState({[typePurpose]: childLocations})
        })
        .catch(errMsg => {
          console.log(errMsg)
          childLocations.splice(index, childLocations.length - index)
          this.setState({[typePurpose]: childLocations})
        })
    } else {
      childLocations.splice(index, childLocations.length - index);
      this.setState({[typePurpose]: childLocations})
    }
  }

  handleInputOnChange = (e) => {
    const formData = {...this.state.formData}
    formData[e.target.name] = e.target.value;
    this.setState({formData})
    const inVerificationField = {field_name: e.target.name, status: 1, previousValue: this.state.oldData[e.target.name]};
    this.setState({profileInVerificationFields: [...this.state.profileInVerificationFields, inVerificationField]});
  }

  handleBankInputOnChange = (e) => {
    const formData = {...this.state.formData}
    formData.bank_info[e.target.name] = e.target.value;
    this.setState({formData})
    const inVerificationField = {field_name: e.target.name, status: 1, previousValue: this.state.oldData.bank_info[e.target.name]};
    this.setState({profileInVerificationFields: [...this.state.profileInVerificationFields, inVerificationField]});
  }

  handleSubmitForm = e => {
    e.preventDefault();
    const authData = getLocalAuthData();
    const formData = {...this.state.formData, profile_in_verification_fields: [...new Map(this.state.profileInVerificationFields.map(item=> [item.field_name, item])).values() ]};
    formData.user = authData._id;
    console.log(formData);

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      this.setState({isFormValidated: true, isMFSFormValidated: true})
    } else {
      this.setState({formData, isUpdating: true}, () => {
        updateProfile({...this.state.formData , ...{merchant_payment_methods: this.state.formData2}}).then(res => {
            const authData = getLocalAuthData();
            authData.profile_status = res.merchant?.user_info?.profile_status;
            localStorage.setItem('user', JSON.stringify(authData));
            this.setState({isUpdating: false, isFormValidated: false, isMFSFormValidated: false});
            toast.success(res.message)
            window.location.reload();
          }).catch(errMsg => {
            this.setState({isUpdating: false, isFormValidated: false, isMFSFormValidated: false});
            toast.error(errMsg);
          })
      })
    }
  }

  handleOnBrowsePic = (files, name) => {
    const formData = {...this.state.formData}
    formData[name] = files[0].actual_img;
    this.setState({formData})
    const inVerificationField = {field_name: name, status: 1, previousValue: this.state.oldData[name]};
    this.setState({profileInVerificationFields: [...this.state.profileInVerificationFields, inVerificationField]});
  }

  handleBankOnBrowsePic = (files, name) => {
    const formData = {...this.state.formData}
    formData.bank_info[name] = files[0].actual_img;
    this.setState({formData})
    const inVerificationField = {field_name: name, status: 1, previousValue: this.state.oldData.bank_info[name]};
    this.setState({profileInVerificationFields: [...this.state.profileInVerificationFields, inVerificationField]});
  }

  handleRemoveImg = (key) => {
    const formData = {...this.state.formData};
    formData[key] = "";
    this.setState({formData});
  }

  handleRemoveBankImg = (key) => {
    const formData = {...this.state.formData};
    formData.bank_info[key] = "";
    this.setState({formData});
  }

  checkIsExisting = (type, data, msgKey, statusKey) => {
    checkIsExisting(type, data)
      .then(res => {
        if (res.status === 2) {
          this.setState({[msgKey]: res.message, [statusKey]: res.status})
        } else {
          this.setState({[msgKey]: null, [statusKey]: res.status})
        }
      })
      .catch(errMsg => {
        console.log(errMsg)
      })
  }

  onChange = ({ fileList: newFileList }) => {
    // console.log(newFileList);
    // this.setState({
    //   fileList: newFileList
    // })
    this.setState({
      fileList: newFileList,
      image: newFileList[0]?.originFileObj
    }, () => {

      console.log(this.state)

      uploadImage(objectToFormData({image: newFileList[0]?.originFileObj, image_category: 'profile_picture'}))
          .then(res => {
            updateDataV2(`/customer/${getLocalAuthData()._id}`, {profile_picture: res?.image?.actual_img_path}).then((res) => {
              console.log(res)
            })
          })
          .catch(() => {
          })

    })
  };

  onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);

    if (imgWindow) {
      imgWindow.document.write(image.outerHTML);
    } else {
      window.location.href = src;
    }
  };

  render() {
    const {formData} = this.state;

    return (
      <App layout="boxed">
        <div className="page-header">
          <h2 className="page-title">Profile</h2>
        </div>

        {this.state.profileStatus === "Need Verification"&&
        <div className={`alert alert-info`}>
          Please update your profile and business information. Then Ekshop team will
          review it within 72 hours.
        </div>
        }

        {(this.state.profileStatus === "In Verification" || this.state.profile_completion_status === INCOMPLETE)  &&
        <div className={`alert alert-info`}>
          Your profile now in verification. Once your profile approve, you can access all the features.
        </div>
        }

        {this.state.profileStatus === "Declined" &&
        <div className={`alert alert-danger`}>
          <p className={'mb-0'}>Your profile has declined</p>
          <p className={'text-bold'}>{this.state.declinedMessage}</p>
        </div>
        }

        <div className="card-block">
          <ul className="desc-header-nav">
            <li className={this.state.activeBlockTab === 'General' ? 'active' : ''}>
              <span onClick={() => this.handleBlockTab('General')}>General</span>
            </li>
            {/*<li className={this.state.activeBlockTab === 'Commission' ? 'active' : ''}>*/}
            {/*  <span onClick={() => this.handleBlockTab('Commission')}>Commission</span>*/}
            {/*</li>*/}
            {/*<li className={this.state.activeBlockTab === 'Shipping' ? 'active' : ''}>*/}
            {/*  <span onClick={() => this.handleBlockTab('Shipping')}>Shipping</span>*/}
            {/*</li>*/}
            {/*<li className={this.state.activeBlockTab === 'Invoice Number' ? 'active' : ''}>*/}
            {/*  <span onClick={() => this.handleBlockTab('Invoice Number')}>Invoice Number</span>*/}
            {/*</li>*/}
          </ul>

          <div className="block-body">
            {this.state.isLoading ?
              <div>Loading...</div>
              :
              <>
                {this.state.activeBlockTab === 'General' &&
                <Form noValidate validated={this.state.isFormValidated && this.state.isMFSFormValidated}
                      onSubmit={this.handleSubmitForm}>


                  <Form.Group as={Row} controlId="sellerID">
                    <Form.Label column sm="3">
                      Profile Picture
                    </Form.Label>
                    <Col sm="9" md={8} lg={7}>
                      <ImgCrop grid>
                        <Upload
                            listType="picture-card"
                            fileList={this.state.fileList}
                            onChange={this.onChange}
                            onPreview={this.onPreview}
                        >
                          {this.state.fileList.length < 1 && '+ Upload'}
                        </Upload>
                      </ImgCrop>

                    </Col>
                  </Form.Group>


                  <Form.Group as={Row} controlId="">
                    <Form.Label column sm="3"/>

                    <Col sm="9" md={8} lg={7}>
                      <strong style={{fontSize: '1.1rem'}}>Seller Account</strong>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} controlId="sellerID">
                    <Form.Label column sm="3">
                      Seller ID
                    </Form.Label>
                    <Col sm="9" md={8} lg={7}>
                      {this.state.formData?.merchant_code}
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} controlId="displayOrShopName">
                    <Form.Label column sm="3">
                      Merchant Type
                    </Form.Label>
                    <Col sm="9" md={8} lg={7}>
                      {this.state.merchantTypes.length > 0 &&
                      this.state.merchantTypes.map((type, index) => {
                        return (<strong key={index}>{type._id === formData?.merchant_type && type.type_name}</strong>)
                      })
                      }
                      <Form.Control.Feedback type="invalid">Field required</Form.Control.Feedback>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} controlId="nameId">
                    <Form.Label column sm="3">
                      Owner Name <span className="text-danger">*</span>
                    </Form.Label>
                    <Col sm="9" md={8} lg={7}>
                      <Form.Control
                        name={"owner_name"}
                        required
                        defaultValue={formData?.owner_name}
                        onChange={this.handleInputOnChange} type="text"/>
                      <Form.Control.Feedback type="invalid">Please enter owner name</Form.Control.Feedback>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} controlId="sellerEmail">
                    <Form.Label column sm="3">
                      Email Address <span className="text-danger">*</span>
                    </Form.Label>
                    <Col sm="9" md={8} lg={7}>
                      <Form.Control name={"email"}
                                    required
                                    disabled= {formData.email? true : false}
                                    onBlur={() => this.checkIsExisting('email', {
                                      email: this.state.formData.email,
                                      user_id: this.state?.userId
                                    }, 'emailExistingMsg', 'emailExistingStatus')}
                                    defaultValue={formData?.email}
                                    onChange={this.handleInputOnChange}
                                    type="email"/>

                      {this.state.emailExistingMsg && <Form.Text className={'text-danger'}>
                        {this.state.emailExistingMsg}
                      </Form.Text>}

                      <Form.Control.Feedback type="invalid">Please enter a valid email address</Form.Control.Feedback>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} controlId="phoneNumber">
                    <Form.Label column sm="3">
                      Phone Number <span className="text-danger">*</span>
                    </Form.Label>
                    <Col sm="9" md={8} lg={7}>
                      <Form.Control disabled={true}
                                    required
                                    defaultValue={formData?.phone}
                                    type="Phone number"/>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} controlId="">
                    <Form.Label column sm="3">
                      ID Type <span className="text-danger">*</span>
                    </Form.Label>

                    <Col sm="9" md={8} lg={7}>
                      <Form.Control
                        onChange={this.handleInputOnChange}
                        name={"id_type"}
                        required
                        value={formData?.id_type}
                        as={"select"}>0
                        <option value="">--Select--</option>
                        <option value="NID">NID</option>
                      </Form.Control>

                      <Form.Control.Feedback type="invalid">Please select ID type</Form.Control.Feedback>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} controlId="">
                    <Form.Label column sm="3">
                      Identification Number <span className="text-danger">*</span>
                    </Form.Label>

                    <Col sm="9" md={8} lg={7}>
                      <Form.Control name={'identification_number'}
                                    required
                                    defaultValue={formData?.identification_number}
                                    onChange={this.handleInputOnChange}
                                    type={"text"}/>

                      <Form.Control.Feedback type="invalid">Please enter Identification Number.</Form.Control.Feedback>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} controlId="">
                    <Form.Label column sm="3">
                      Upload ID Copy - Front Side {/*<span className="text-danger">*</span>*/}
                    </Form.Label>

                    <Col sm="9" md={8} lg={7}>
                      <BrowseFiles onGetFiles={(files) => {
                        this.handleOnBrowsePic(files, 'id_front_img')
                      }} image_category={'profile'}/>

                      {this.state.formData.id_front_img &&
                      <div className="existing-images">
                        <div className="existing-img">
                          <span onClick={() => this.handleRemoveImg('id_front_img')}
                                className="btn-remove">&times;</span>
                          <img src={this.state.formData.id_front_img} alt=""/>
                        </div>
                      </div>}
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} controlId="">
                    <Form.Label column sm="3">
                      Upload ID Copy - Back Side {/*<span className="text-danger">*</span>*/}
                    </Form.Label>

                    <Col sm="9" md={8} lg={7}>
                      <BrowseFiles onGetFiles={(files) => {
                        this.handleOnBrowsePic(files, 'id_back_img')
                      }} image_category={'profile'}/>

                      {this.state.formData.id_back_img &&
                      <div className="existing-images">
                        <div className="existing-img">
                          <span onClick={() => this.handleRemoveImg('id_back_img')}
                                className="btn-remove">&times;</span>
                          <img src={this.state.formData.id_back_img} alt=""/>
                        </div>
                      </div>}
                    </Col>
                  </Form.Group>

                  {/*Business Information*/}
                  <Form.Group as={Row} controlId="displayOrShopName">
                    <Form.Label column sm="3"/>
                    <Col sm="9" md={8} lg={7}>
                      <strong style={{fontSize: '1.1rem'}}>Business Information</strong>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} controlId="displayOrShopName">
                    <Form.Label column sm="3">
                      Business Name
                    </Form.Label>
                    <Col sm="9" md={8} lg={7}>
                      <strong>{formData?.name}</strong>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId="displayBusinessRegNumber">
                    <Form.Label column sm="3">
                      Business Registration Number
                    </Form.Label>
                    <Col sm="9" md={8} lg={7}>
                      <Form.Control
                        name={"business_registration_number"}
                        defaultValue={formData?.business_registration_number}
                        onChange={this.handleInputOnChange}
                        type="text"/>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} controlId="">
                    <Form.Label column sm="3">
                      Legal Form
                    </Form.Label>

                    <Col sm="9" md={8} lg={7}>
                      <Form.Control
                        defaultValue={formData?.legal_form}
                        onChange={this.handleInputOnChange}
                        name={"legal_form"}
                        as="select">
                        <option value="">--Select Legal Form--</option>
                        <option value="Proprietorship">Proprietorship</option>
                        <option value="Partnership company">Partnership company</option>
                        <option value="Limited company">Limited company</option>
                      </Form.Control>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} controlId="">
                    <Form.Label column sm="3">
                      Address <span className="text-danger">*</span>
                    </Form.Label>
                    <Col sm="9" md={8} lg={7}>
                      <Form.Control name={"address"}
                                    required
                                    defaultValue={formData?.address}
                                    onChange={this.handleInputOnChange}
                                    type={"text"}/>

                      <Form.Control.Feedback type="invalid">Please enter address.</Form.Control.Feedback>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row}
                              controlId="formCountries">
                    <Form.Label column sm="3">
                      Country <span className="text-danger">*</span>
                    </Form.Label>
                    <Col sm="9" md={8} lg={7}>
                      <Form.Control
                        name={"country"}
                        required
                        defaultValue={this.state.formData.country}
                        onChange={(e) => this.handleLocationInputOnChange(e, 0, 'businessChildLocations')}
                        as="select">
                        <option value="">--Select Country--</option>
                        {this.state.countries.length > 0 &&
                        this.state.countries.map((country, index) => (
                          <option key={index} value={country._id}>{country.name}</option>))
                        }
                      </Form.Control>

                      <Form.Control.Feedback type="invalid">Please select country.</Form.Control.Feedback>
                    </Col>
                  </Form.Group>

                  {this.state.businessChildLocations.length > 0 &&
                  this.state.businessChildLocations.map((location, index) => (
                    location?.length > 0 &&
                    <Form.Group key={index} as={Row} controlId={`location${location[0].type}`}>
                      <Form.Label column sm="3">{location[0].type}</Form.Label>
                      <Col sm="9" md={8} lg={7}>
                        <Form.Control as="select"
                                      defaultValue={this.state.locations[index + 1]?._id}
                                      name={location[0].type.toLowerCase()}
                                      onChange={(e) => this.handleLocationInputOnChange(e, index + 1, 'businessChildLocations')}>
                          <option value="">--Select--</option>
                          {location.map((item, index) => (
                            <option key={index} value={item._id}>{item.name}</option>
                          ))}
                        </Form.Control>
                      </Col>
                    </Form.Group>
                  ))
                  }


                  <Form.Group as={Row} controlId="">
                    <Form.Label column sm="3">
                      Postcode <span className="text-danger">*</span>
                    </Form.Label>

                    <Col sm="9" md={8} lg={7}>
                      <Form.Control
                        name={"postcode"}
                        required
                        defaultValue={formData?.postcode}
                        onChange={this.handleInputOnChange}
                        type={"text"}/>

                      <Form.Control.Feedback type="invalid">Please enter postcode.</Form.Control.Feedback>
                    </Col>
                  </Form.Group>

                  {/*Bank Information*/}
                  <Form.Group as={Row} controlId="">
                    <Form.Label column sm="3"/>

                    <Col sm="9" md={8} lg={7}>
                      <strong style={{fontSize: '1.1rem'}}>Bank Information</strong>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} controlId="">
                    <Form.Label column sm="3">
                      Bank Account Name <span className="text-danger">*</span>
                    </Form.Label>

                    <Col sm="9" md={8} lg={7}>
                      <Form.Control name={"bank_account_name"}
                                    required
                                    defaultValue={formData?.bank_info?.bank_account_name}
                                    onChange={this.handleBankInputOnChange}
                                    type={"text"}/>

                      <Form.Control.Feedback type="invalid">Please select bank account name.</Form.Control.Feedback>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} controlId="">
                    <Form.Label column sm="3">
                      Account Number <span className="text-danger">*</span>
                    </Form.Label>

                    <Col sm="9" md={8} lg={7}>
                      <Form.Control
                        name={"bank_account_number"}
                        required
                        defaultValue={formData?.bank_info?.bank_account_number}
                        onChange={this.handleBankInputOnChange}
                        type={"text"}/>
                      <Form.Control.Feedback type="invalid">Please enter bank account number.</Form.Control.Feedback>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} controlId="">
                    <Form.Label column sm="3">
                      Bank Name <span className="text-danger">*</span>
                    </Form.Label>

                    <Col sm="9" md={8} lg={7}>
                      <Form.Control
                        name={"bank_name"}
                        required
                        value={this.state.formData.bank_info.bank_name}
                        onChange={(e) => {
                          this.handleBankInputOnChange(e)
                          const bank = this.state.bankList.filter(obj => {
                            return obj._id === e.target.value
                          })

                          if (bank.length > 0) {
                            this.setState({branchNameList: bank[0].branches})
                          } else {
                            this.setState({branchNameList: []})
                          }
                        }}
                        as={"select"}>
                        <option value="">--Select Bank--</option>
                        {this.state.bankList.map((bank, index) => (
                          <option
                            key={index}
                            selected={formData?.bank_info?.bank_name === 'Agrani Bank Ltd'}
                            value={bank._id}>{bank.name}</option>
                        ))}
                      </Form.Control>

                      <Form.Control.Feedback type="invalid">Please select a bank</Form.Control.Feedback>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} controlId="">
                    <Form.Label column sm="3">
                      Branch Name
                    </Form.Label>

                    <Col sm="9" md={8} lg={7}>
                      <Form.Control type={'text'}
                                    name={"bank_account_branch"}
                                    value={formData?.bank_info?.bank_account_branch || ''}
                                    onChange={this.handleBankInputOnChange}/>
                      {/*<Form.Control
                        as={'select'}
                        name={"bank_account_branch"}
                        disabled={this.state.branchNameList.length < 1}
                        value={formData?.bank_info?.bank_account_branch || ''}
                        onChange={this.handleBankInputOnChange}>
                        <option value="">--Select Branch--</option>
                        {this.state.branchNameList.length > 0 &&
                        this.state.branchNameList.map((branch, index) => (
                          <option value={branch._id}>{branch.name}</option>
                        ))
                        }
                      </Form.Control>*/}
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} controlId="">
                    <Form.Label column sm="3">
                      Upload Bank Documents {/*<span className="text-danger">*</span>*/}
                    </Form.Label>

                    <Col sm="9" md={8} lg={7}>
                      <BrowseFiles onGetFiles={(files) => {
                        this.handleBankOnBrowsePic(files, 'bank_account_cheque_img')
                      }} helpText={`Check /Card/ Trade Licence`} image_category={'profile'}/>

                      {this.state.formData.bank_info.bank_account_cheque_img &&
                      <div className="existing-images">
                        <div className="existing-img">
                          <span onClick={() => this.handleRemoveBankImg('bank_account_cheque_img')}
                                className="btn-remove">&times;</span>
                          <img src={this.state.formData.bank_info.bank_account_cheque_img} alt=""/>
                        </div>
                      </div>}
                    </Col>
                  </Form.Group>


                  {this.state?.merchant_payment_methods?.length === 0 &&
                      <>
                        <Form.Group as={Row} controlId="displayOrShopName">
                          <Form.Label column sm="3"/>
                          <Col sm="9" md={8} lg={7}>
                            <strong style={{fontSize: '1.1rem'}}>Payment Method Details</strong>
                          </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId="checkboxMethodType">
                          <Form.Label column sm="3">
                            Method Type
                          </Form.Label>
                          <Col sm="9" md={8} lg={7}>

                            <Form.Check
                                custom inline type="checkbox"
                                name="method_type" id={`commissionAvailableForMerchant`}
                                label={`Add MFS Payment Method`}
                                defaultChecked={this.state.formData2.method_type === 'Mobile'}
                                onChange={this.handleMethodOnChange2}
                            />

                            {/*  <Form.Check
                          custom
                          inline
                          onChange={this.handleMethodOnChange2}
                          checked={this.state.formData2.method_type === 'Mobile'}
                          value={'Mobile'}
                          label="Mobile"
                          name={'method_type'}
                          type={'checkbox'}
                          id={`methodType-2`}/>*/}
                          </Col>
                        </Form.Group>

                        {this.state.formData2.method_type === 'Bank' && <>
                          <Form.Group as={Row} controlId="inputBankAccountName">
                            <Form.Label column sm="3">
                              Bank Account Name
                            </Form.Label>
                            <Col sm="9" md={8} lg={7}>
                              <Form.Control type={'text'}
                                            onChange={this.handleInputOnChange2}
                                            name={'bank_account_name'}
                                            placeholder={'Bank Account Name'}/>
                            </Col>
                          </Form.Group>

                          <Form.Group as={Row} controlId="inputBankAccountNumber">
                            <Form.Label column sm="3">
                              Bank Account Number
                            </Form.Label>
                            <Col sm="9" md={8} lg={7}>
                              <Form.Control type={'text'}
                                            onChange={this.handleInputOnChange2}
                                            name={'bank_account_number'}
                                            placeholder={'Bank account number'}/>
                            </Col>
                          </Form.Group>

                          <Form.Group as={Row} controlId="inputBank">
                            <Form.Label column sm="3">
                              Bank
                            </Form.Label>
                            <Col sm="9" md={8} lg={7}>
                              <Form.Control name={'bank_id'}
                                            onChange={this.handleInputOnChange2}
                                            as={'select'}>
                                <option label={'--Select a Bank--'}/>
                                {this.state.bankList.map((bank, index) => (
                                    <option
                                        key={index}
                                        value={bank._id}>{bank.name}</option>
                                ))}
                              </Form.Control>
                            </Col>
                          </Form.Group>

                          <Form.Group as={Row} controlId="inputBank">
                            <Form.Label column sm="3">
                              Branch
                            </Form.Label>
                            <Col sm="9" md={8} lg={7}>
                              <Form.Control type={'text'}
                                            onChange={this.handleInputOnChange2}
                                            name={'bank_account_branch'} placeholder={'Branch name'}/>
                            </Col>
                          </Form.Group>

                          <Form.Group as={Row} controlId="inputBankRoutingNumber">
                            <Form.Label column sm="3">Bank Routing Number</Form.Label>
                            <Col sm="9" md={8} lg={7}>
                              <Form.Control type={'text'}
                                            onChange={this.handleInputOnChange2}
                                            name={'bank_routing_number'}
                                            placeholder={'Bank routing number'}/>
                            </Col>
                          </Form.Group>

                          <Form.Group as={Row} controlId="inputBank">
                            <Form.Label column sm="3">
                              Cheque Image
                            </Form.Label>

                            <Col sm="9" md={8} lg={7}>
                              <BrowseFiles onGetFiles={(files) => {
                                this.handleOnBrowsePic2(files, 'bank_account_cheque_img')
                              }}/>

                              {this.state.formData2.bank_account_cheque_img &&
                              <div className="existing-images">
                                <div className="existing-img">
                          <span onClick={() => this.handleRemoveImg2('bank_account_cheque_img')}
                                className="btn-remove">&times;</span>
                                  <img src={this.state.formData2.bank_account_cheque_img} alt=""/>
                                </div>
                              </div>}
                            </Col>
                          </Form.Group>
                        </>}

                        {this.state.formData2.method_type === 'Mobile' && <>
                          <Form.Group as={Row} controlId="selectOperatorName">
                            <Form.Label column sm="3">
                              Operator Name
                            </Form.Label>
                            <Col sm="9" md={8} lg={7}>
                              <Form.Control name={'operator_name'}
                                            required
                                            onChange={this.handleInputOnChange2}
                                            as={'select'}>
                                <option label={'--Select a Operator--'}/>
                                {this.state.operatorList.map((ope, index) => (
                                    <option
                                        key={index}
                                        value={ope._id}>{ope.name}</option>
                                ))}
                                <option value={'Nagad'}>Nagad</option>
                                <option value={'bKash'}>bKash</option>
                                <option value={'Rocket'}>Rocket</option>
                              </Form.Control>
                            </Col>
                          </Form.Group>

                          <Form.Group as={Row} controlId="radioAccountType">
                            <Form.Label column sm="3">Account Type</Form.Label>
                            <Col sm="9" md={8} lg={7}>
                              <Form.Check
                                  custom
                                  inline
                                  onChange={this.handleInputOnChange2}
                                  checked={this.state.formData2.account_type === 'Personal'}
                                  value={'Personal'}
                                  label="Personal"
                                  name={'account_type'}
                                  type={'radio'}
                                  id={`account-type-1`}/>

                              <Form.Check
                                  custom
                                  inline
                                  onChange={this.handleInputOnChange2}
                                  checked={this.state.formData2.account_type === 'Merchant'}
                                  value={'Merchant'}
                                  label="Merchant"
                                  name={'account_type'}
                                  type={'radio'}
                                  id={`account-type-2`}/>
                            </Col>
                          </Form.Group>

                          <Form.Group as={Row} controlId="inputMobileNumber">
                            <Form.Label column sm="3">Mobile Number</Form.Label>
                            <Col sm="9" md={8} lg={7}>
                              <Form.Control type={'text'}
                                            required
                                            onChange={this.handleInputOnChange2}
                                            name={'mobile_number'}
                                            placeholder={'Enter your mobile number'}/>
                            </Col>
                          </Form.Group>
                        </>}
                      </>
                  }


                  <div className="mt-3">
                    <Button size="lg"
                            type={"submit"}
                            disabled={this.state.isUpdating}
                            variant="primary">{this.state.isUpdating ? 'Updating...' : 'Update'}</Button>
                  </div>
                </Form>
                }



                {this.state.activeBlockTab === 'Invoice Number' &&
                <>
                  <Form.Group as={Row} controlId="generationType">
                    <Form.Label column sm="3">
                      Generation Type
                    </Form.Label>
                    <Col sm="9" md={8} lg={7}>
                      <Form.Control as="select">
                        <option value="">Use Autoincrement Number</option>
                        <option value="">Use Order Number</option>
                        <option value="">Provide number manually</option>
                      </Form.Control>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId="generationType">
                    <Form.Label column sm="3">
                      Invoice Prefix
                    </Form.Label>
                    <Col sm="9" md={8} lg={7}>
                      <Form.Control type="text"/>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId="generationType">
                    <Form.Label column sm="3">
                      Next Number (Insert numbers only)
                    </Form.Label>
                    <Col sm="9" md={8} lg={7}>
                      <Form.Control type="number"/>
                    </Col>
                  </Form.Group>
                </>
                }
              </>
            }
          </div>
        </div>
      </App>
    );
  }
}

export default Profile;
