import React, {useEffect, useState} from 'react';
import {FiMove, FiPlus, FiTrash} from "react-icons/all";
import defaultStoreBanner from "../assets/img/default-store-banner.png";
import {Button, Col, Form, Modal, Row} from "react-bootstrap";
import BrowseFiles from "./BrowseFiles";
import {deleteRequest, getRequest, postRequest} from "../services/baseServices";
import {toast} from "react-toastify";
import {confirmAlert} from "react-confirm-alert";
import {deleteProduct, getProductList} from "../services/productServices";

const StoreCoverChange = ({storeId}) => {
  const [coverList, setCoverList] = useState([])
  const [isShowingModal, setIsShowingModal] = useState(false)
  const [coverImgData, setCoverImgData] = useState({store: storeId})

  useEffect(() => {
    getSliderList()
  }, [])

  const handleHideModal = () => {
    setIsShowingModal(false)
  }

  const handleShowModal = () => {
    setIsShowingModal(true)
  }

  const handleOnInputChange = (e) => {
    setCoverImgData({...coverImgData, [e.target.name]: e.target.value})
  }

  const handleSaveSlider = (e) => {
    e.preventDefault()

    const preSetObj = {
      banner_type: 1
    }

    postRequest(`/slider`, {...coverImgData, ...preSetObj})
      .then(res => {
        toast.success(res.message)
        setCoverImgData({store: storeId})
        getSliderList()
        handleHideModal()
      })
      .catch(errMsg => {
        toast.error(errMsg)
      })
  }

  const getSliderList = () => {
    getRequest(`/slider`, {store: storeId})
      .then(res => {
        setCoverList(res.items)
      })
  }

  const handleDeleteCover = (id) => {
    confirmAlert({
      title: 'Delete',
      message: 'Are you sure to delete this cover?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => deleteRequest(`/slider/${id}/permanent`)
            .then(res => {
              toast.success(res.message)
              getSliderList()
            })
            .catch(errMsg => {
              toast.error(errMsg)
            })
        },
        {
          label: 'No',
          // onClick: () => alert('Click No')
        }
      ]
    })
  }

  return (
    <div>
      <Modal show={isShowingModal} onHide={handleHideModal}>
        <Modal.Header closeButton>
          <Modal.Title>Store Cover Image</Modal.Title>
        </Modal.Header>

        <Form onSubmit={handleSaveSlider}>
          <Modal.Body>
            <Form.Group as={Row} controlId="formPromotionName">
              <Form.Label column sm="3">
                Slide Image <span className="text-danger">*</span>
              </Form.Label>
              <Col sm="9" md={8} lg={7}>
                <BrowseFiles
                  size={"sm"}
                  images={coverImgData?.mid_image ? [{thumb_img: coverImgData?.mid_image}] : []}
                  onGetFiles={images => {
                    const imgObj = {
                      image: images[0]?.actual_img,
                      mid_image: images[0]?.mid_img,
                      thumb_image: images[0]?.thumb_img,
                      thumb_img: images[0]?.thumb_img
                    }
                    setCoverImgData({...coverImgData, ...imgObj})
                  }}
                  helpText={"Image size 1250 x 280"}
                  image_category={'slider'}/>
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formPromotionName">
              <Form.Label column sm="3">
                Slide Link (optional)
              </Form.Label>
              <Col sm="9" md={9} lg={9}>
                <Form.Control
                  type="text"
                  placeholder=""
                  name="link"
                  onChange={handleOnInputChange}
                />
              </Col>
            </Form.Group>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="default" onClick={handleHideModal}>
              Close
            </Button>
            <Button type="submit" variant="primary">
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <Row>
        {coverList.length > 0 &&
        coverList.map((item, index) => (
          <Col key={index} md={4}>
            <div className="page-top-banner">
              <img src={item.image} alt=""/>
              <div className="banner-actions">
                <div className="actions">
                  <span className="action" onClick={() => handleDeleteCover(item._id)}><FiTrash/></span>
                  {/*<span className="action"><FiMove/></span>*/}
                </div>
              </div>
            </div>
          </Col>
        ))}

        <Col md={4}>
          <div onClick={handleShowModal} className="main-slide btn-add-slide">
            <img src={defaultStoreBanner} alt=""/>
            <div className="slide-actions">
              <div className="actions">
                <span className="action"><FiPlus/></span>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default StoreCoverChange;