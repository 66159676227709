import axois from "axios";
import {API_BASE_URL} from "../helper/env";
import {getDeviceType, getLocalAuthData} from "../helper/utils";

export const getCategoryList = (categoryType = '') => {
  const authData = getLocalAuthData();
  return new Promise((resolve, error) => {
    axois.get(API_BASE_URL + '/category', {
      headers: {
        'x-auth-token': authData.token,
        'x-api-client': getDeviceType()
      },
      params: {
        category_type: categoryType
      }
    })
      .then(res => {
        if(res.data.status) {
          resolve(res.data.categories);
        } else {
          error(res.data.message)
        }
      })
      .catch(err => {
        error(err.message);
      })
  })
}

export const categoryDetails = (id) => {
  return new Promise((resolve, reject) => {
    axois.get(API_BASE_URL + '/category/' + id)
      .then(res => {
        if(res.data.status === 1) {
          resolve(res.data.category);
        } else {
          reject(res.data.message)
        }
      })
      .catch(err => {
        reject(err.message);
      })
  })
}
