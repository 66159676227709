import axios from "axios";
import {API_BASE_URL} from "../helper/env";
import {getDeviceType, getLocalAuthData} from "../helper/utils";

export const getStoreList = (paramsObj = {}) => {
  const authData = getLocalAuthData();

  return new Promise((resolve, reject) => {
    axios.get(`${API_BASE_URL}/store`, {
      params: {...paramsObj, merchant: authData.merchant_id},
      headers: {
        'x-auth-token': authData.token,
        'x-api-client': getDeviceType()
      }
    }).then(res => {
      resolve(res.data)
    }).catch(err => {
      reject(err.message)
    })
  })
}

export const getCampaignStoreList = (paramsObj = {}) => {
  const authData = getLocalAuthData();

  return new Promise((resolve, reject) => {
    axios.get(`${API_BASE_URL}/campaign/stores`, {
      params: {...paramsObj},
      headers: {
        'x-auth-token': authData.token,
        'x-api-client': getDeviceType()
      }
    }).then(res => {
      resolve(res.data)
    }).catch(err => {
      reject(err.message)
    })
  })
}

export const saveStore = (data) => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios.post(`${API_BASE_URL}/store`, data, {
      headers: {
        'x-auth-token': authData.token,
        'x-api-client': 'web'
      }
    })
      .then(res => {
        if (res.data.status === 1) {
          resolve(res.data)
        } else {
          reject(res.data.message)
        }
      })
      .catch(err => {
        reject(err.message)
      })
  })
}

export const deleteStore = (id) => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios.delete(`${API_BASE_URL}/store/${id}`, {
      headers: {
        'x-auth-token': authData.token,
        'x-api-client': 'web'
      }
    })
      .then(res => {
        if (res.data.status === 1) {
          resolve(res.data)
        } else {
          reject(res.data.message)
        }
      })
      .catch(err => {
        reject(err.message)
      })
  })
}

export const getStoreDetails = (id) => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios.get(`${API_BASE_URL}/store/${id}`, {
      headers: {
        "x-auth-token": authData.token,
        "x-api-client": "web"
      }
    })
      .then(res => {
        if (res.data.status === 1) {
          resolve(res.data.store_info);
        } else {
          reject(res.data.message);
        }
      })
      .catch(err => {
        console.log('Store details error: ', err);
        reject('Something went wrong');
      })
  })
}

export const updateStore = (data, id) => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios.put(`${API_BASE_URL}/store/${id}`, data, {
      headers: {
        "x-auth-token": authData.token,
        "x-api-client": "web"
      }
    })
      .then(res => {
        if (res.data.status === 1) {
          resolve(res.data);
        } else {
          reject(res.data.messages);
        }
      })
      .catch(err => {
        console.log('Update store error: ', err);
        reject("Something went wrong!")
      })
  })
}
