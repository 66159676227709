import React, { useState, useEffect } from "react";
import App from "../../App";
import { Alert, Button, Col, Form, Row } from "react-bootstrap";

import { BsArrowLeft } from "react-icons/all";
import { Link, useHistory } from "react-router-dom";
import {
  supportTopicList,
  supportTicketAdd,
} from "../../services/support-ticketService";
import { toast } from "react-toastify";
import Select from "react-select";
import styleReactSelect from "../../assets/styleReactSelect";
import { getStoreList } from "../../services/storeServices";

const CreateTicket = () => {
  const [topics, setTopics] = useState([]);
  const [formData, setFormData] = useState({
    support_topic_id: null,
    message: null,
  });

  const [storeList, setStoreList] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  let history = useHistory();

  useEffect(() => {
    const fetchData = async () => {
      const result = await supportTopicList();
      setTopics(result.items);
    };
    fetchData();
  }, []);

  const onChangeHandler = (e) => {
    setErrorMessage("");
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmitForm = async (e) => {
    e.preventDefault();
    console.log(formData);
    const form = e.currentTarget;
    if(form.checkValidity() === false){
      setErrorMessage("Please enter all data");
    }else{
      setErrorMessage("");
      const result = await supportTicketAdd(formData);
      if (result.status === 1) {
        toast.success(result.message);
        history.push("/support-ticket")
      } else {
        toast.error(result.message);
      }
    }
    
  };

  const onMerchantChange = (id) => {
    setErrorMessage("");
    console.log(id);
    setFormData({ ...formData, user_id: id });
    console.log(formData);
    /*getStoreList({merchant: id, status: 1, approval_status: 1, lite_data: 1})
        .then(res => {
          const storeList = [];

          if (res.items?.length > 0) {
            res.items.forEach(item => {
              const store = {
                id: item._id,
                value: item._id,
                label: item.store_name
              }
              storeList.push(store)
             // setStoreList(...storeList} , store);
            })
          }

          setStoreList({...storeList, merchant: id})
        })*/
  };

  return (
    <App layout={"boxed"}>
      <div className="card-block">
        <div className="block-header">
          <h4 className="block-title">Ticket Details</h4>
        </div>

        <div className="block-body">
          {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
          
          <Form onSubmit={handleSubmitForm} noValidate>
            <Form.Group as={Row} controlId={"ticketDetails"}>
              <Form.Label column className={"text-right"} sm="3">
                Topic
              </Form.Label>
              <Col sm="2" md={8} lg={7}>
                <Form.Control
                  onChange={(e) => onChangeHandler(e)}
                  as={"select"}
                  name={"support_topic_id"}
                  required
                >
                  <option value="">--Select Topic--</option>
                  {topics.map((item, index) => (
                    <option key={index} value={item._id}>
                      {" "}
                      {item.name}{" "}
                    </option>
                  ))}
                </Form.Control>
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId={"ticketDetails"}>
              <Form.Label column className={"text-right"} sm="3">
                Message
              </Form.Label>
              <Col sm="2" md={8} lg={7}>
                <Form.Control
                  onChange={(e) => onChangeHandler(e)}
                  as={"textarea"}
                  rows={4}
                  placeholder={"Type your message"}
                  name={"message"}
                  required
                />
              </Col>
            </Form.Group>

            <div className="mt-3">
              <Form.Group as={Row}>
                <Form.Label column className={"text-right"} sm="3" />
                <Col sm="2" md={8} lg={7}>
                  <Button
                    type="submit"
                    variant={"primary"}
                  >
                    Submit
                  </Button>
                  <Link
                    to={"/support-faq"}
                    className={"btn btn-default ml-3"}
                  >
                    <BsArrowLeft /> Back to FAQ page
                  </Link>
                </Col>
              </Form.Group>
            </div>
          </Form>
        </div>
      </div>
    </App>
  );
};

export default CreateTicket;
