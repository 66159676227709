import React, {Component} from 'react';
import LoadingSpinner from "../components/LoadingSpinner";
import {Link, withRouter} from "react-router-dom";
import {emailVerification} from "../services/authServices";
import "../assets/scss/verifying-screen.scss";
import {FiCheckCircle, FiShoppingCart, FiUser, TiWarningOutline} from "react-icons/all";
import {STORE_URL} from "../helper/env";

class EmailVerify extends Component {
  state = {
    isVerifying: true,
    errMsg: null
  }

  componentDidMount() {
    const token = this.props.match.params.token;

    emailVerification(token)
      .then(res => {
        this.setState({isVerifying: false, errMsg: null});
      })
      .catch(errMsg => {
        this.setState({errMsg: errMsg, isVerifying: false});
      })
  }

  render() {
    return (
      <>
        {this.state.isVerifying ?
          <LoadingSpinner
            variant={"primary"}
            text={"Verifying..."}
            style={{color: '#424242', backgroundColor: 'transparent'}}
            size={"lg"}/>
          :
          <div className="verifying-screen">
            {this.state.errMsg ?
              <div className="page-content">
                <span className={'notify-icon icon text-warning'}><TiWarningOutline/></span>
                <h4>{this.state.errMsg}</h4>
                <p>You already verified your email or you URL is wrong</p>
                <a href={STORE_URL}
                      style={{padding: '0.25rem .75rem .4rem'}}
                      className={"btn btn-sm btn-outline-warning"}><FiShoppingCart/> <span className="text">Go to Homepage</span></a>
              </div>
              :
              <div className={"page-content"}>
                <span className={'notify-icon icon text-success'}><FiCheckCircle/></span>
                <h4>Email Verified!</h4>
                <p>Please Login your merchant panel and add your business information. Then Ekshop team will
                  review it will review it within 72 hours.</p>
                <Link to={'/login'}
                      style={{padding: '0.25rem .75rem .4rem'}}
                      className={"btn btn-sm btn-outline-success"}><FiUser/> <span className="text">Login Now</span></Link>
              </div>}
          </div>
        }
      </>
    );
  }
}

export default withRouter(EmailVerify);
