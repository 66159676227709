import React, {useState, useEffect, Component} from "react";
import App from "../../App";
import { Link , withRouter} from "react-router-dom";
import Moment from "moment";
import {FaLongArrowAltLeft, FiClock, IoIosArrowForward, IoMdCheckmark, RiReplyLine} from "react-icons/all";
import "../../assets/scss/create-live.scss";
import { supportTicketList } from "../../services/support-ticketService";
import {Button, Col, Form, Modal, Row, ToggleButton} from "react-bootstrap";
import Pagination from "react-js-pagination";
import AsyncSelect from "react-select/async/dist/react-select.esm";
import styleReactSelect from "../../assets/styleReactSelect";
import Select from "react-select";
import {getStoreList} from "../../services/storeServices";
import {getSkuProductList} from "../../services/productServices";
import {toast} from "react-toastify";
import {submitBundleOffer} from "../../services/promotionServices";
import {saveData ,addNewItem} from "../../services/liveServices";
import {getLocalAuthData} from "../../helper/utils";
import DateRangePicker from "react-bootstrap-daterangepicker";
import LoadingSpinner from "../../components/LoadingSpinner";
import BrowseFiles from "../../components/BrowseFiles";
import DatePicker from "react-datepicker";

const today = Moment(new Date()).format('Y-M-D');


class CreateLive extends Component{

    state = {

        formData: {
            product_list: [],
            is_finish: false,
            store_name: ""
        },
        queryData: {limit: 20, search_string: ''},
        productListPagination: {},
        isProductModalProductShowing: false,
        product_list: [],
        storeList: [{
            id: null,
            value: null,
            label: "Type for search"
        }],
        productList: [],
        isLoading: true,
        productType: '',
        selectedProducts: [],
        selectedProducts_sku: [],
        Products_sku: [],
        isFormValidated: false,
        isSubmitting: false,
        price_sum: 0,
        gift_sum: 0,
        selected_gift_products: [],
        selected_products: [],
        isProductListLoading: false,
        errorMessage: ""
    }

    componentDidMount() {
        const history = this.props.history;

        const storeList = getStoreList({status: 1, approval_status: 1, lite_data: 1, limit: 10})
            .then(res => {
                const storeList = [];

                res.items.forEach(item => {
                    const store = {
                        id: item._id,
                        value: item._id,
                        label: item.store_name
                    }

                    storeList.push(store)
                    this.setState({storeList})
                })
            })

        Promise.all([storeList])
            .then(() => {
                this.setState({isLoading: false})
            })
            .catch(() => {
                this.setState({isLoading: false})
            })
    }

    handleImageOnBrowse = (images) => {
        const brandData = {...this.state.brandData}
        if (images.length > 0) {
            brandData.logo = images[0].thumb_img

            const formData = {...this.state.formData}

            this.setState({formData})
        }
        this.setState({brandData})
    }

    handleOnRemoveItem = () => {
        const brandData = {...this.state.brandData}
        brandData.logo = null
        this.setState({brandData})
    }

    filterSearchable = (inputValue) => {

        if (inputValue.length >= 2) {
            getStoreList({search_string: inputValue, lite_data: 1, limit: 50})
                .then(res => {
                    const storeList = [];

                    res.items.forEach(item => {
                        const store = {
                            id: item._id,
                            value: item._id,
                            label: item.store_name
                        }

                        storeList.push(store)
                        this.setState({storeList})
                    })
                }).catch(errMsg => {
                console.log(errMsg)
            })
        }


        return this.state.storeList;
    };

    promiseOptions = inputValue => {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve(this.filterSearchable(inputValue));
            }, 0);
        });
    };

    handlePromotionTypeOnChange = (e) => {
        const formData = {...this.state.formData}
        formData.product_list = []



        formData[e.target.name] = e.target.value
        this.setState({
            formData,
            product_list: [],


            // Products_sku: [],
            // selectedProducts: [],
            selected_products: [],
            selected_gift_products: []
        })
    }

    handleInputOnChange = (e) => {
        const formData = {...this.state.formData};
        formData[e.target.name] = e.target.value;

        if (e.target.name === "title"){
            formData['slug'] =  e.target.value
        }

        this.setState({formData,  productList: []})

        const queryData = {...this.state.queryData};
        queryData.search_string = e.target.value;

        this.setState({queryData}, ()=>{
            this.getProducts();
        });
    }

    handleSearchProduct = (e) => {
        const formData = {...this.state.formData};
        const queryData = {...this.state.queryData};
        if (this.state.formData?.stores?.[0]) {
            this.getProducts(queryData);
        }
    }

    getProducts = (queryData = {}) => {
        let query = {
            ...queryData,
            limit: this.state.queryData.limit,
            merchants: JSON.stringify(this.state.formData?.merchants || []),
            // stores: JSON.stringify(this.state.formData?.stores || []),
            store_id: this.state.formData?.stores?.[0],
            categories: JSON.stringify(this.state.formData?.categories || [])
        }

        if (this.state.queryData.search_string) {
            query.search_string = this.state.queryData.search_string;
        }

        this.setState({isProductListLoading: true});
        getSkuProductList(query).then(res => {
            if(res.status === 1){
                this.setState({productList: res.products, productListPagination: res.pagination, isProductListLoading: false, errorMessage: ""})
            }else{
                this.setState({productList: res.products, productListPagination: res.pagination, isProductListLoading: false, errorMessage: "No product available for this store"})
            }
        }).catch(err => {
            this.setState({isProductLoading: false, isProductListLoading: false})
        })
    }

    handleOfferTypeOnChange = (e) => {
        const formData = {...this.state.formData};
        formData.offer_value = [{
            item: '',
            discount_value: ''
        }];
        formData[e.target.name] = e.target.value;
        this.setState({formData})
    }

    addMoreOfferValue = () => {
        const formData = {...this.state.formData};
        formData.offer_value.push({
            item: '',
            discount_value: ''
        });
        this.setState({formData})
    }

    handleRemoveItem = (index) => {
        const formData = {...this.state.formData};
        formData.offer_value.splice(index, 1);
        this.setState({formData})
    }

    handleSelectMultiOnChange = (array, name) => {

        let query = {...this.state.queryData}
        const formData = {...this.state.formData};
        query.search_string = "";
        formData.product_list = [];

        formData.store_id  = array.id
        formData.store_name = array.label

        this.setState({queryData: query, selected_products: [], selected_gift_products: [], price_sum: 0, gift_sum: 0,formData })

        const data = [];
        if (name === "stores") {
            data.push(array.id)

            this.setState({product_list: []})
        } else {

            if (array) {
                array.forEach(val => {
                    data.push(val.id)
                })
            }
        }

        formData[name] = data;
        this.setState({formData}, () => {
            this.getProducts(query);
        })
    }



    /*START HANDLE MODAL*/
    handleShowSelectProductModal = (type) => {
        console.log(type)
        this.setState({
            isProductModalProductShowing: true,
            productType: type,
        })
    }

    handleHideProductModal = () => {
        this.setState({isProductModalProductShowing: false})
    }

    isProductIncluded = (id) => {
        let type = "product_list";

        if (this.state.formData[type] === undefined) return false;
        if (this.state.formData[type] === null) return false;
        if (this.state.formData[type].length === 0) return false;
        console.log('sku',id)
        console.log("matched product",this.state.productList.filter(item=> item.sku_info._id === id))
        console.log("isIncluded",this.state.formData.product_list.includes(id))
        if (this.state.formData[type].includes(id))
            return true;
        else
            return false;
    }

    handleSelectedProductOnChange = (e, id, sku) => {
        let selected_products = [...this.state.selected_products];
        let selected_gift_products = [...this.state.selected_gift_products];
        let formData = {...this.state.formData};
        let price_sum = this.state.price_sum;
        let gift_sum = this.state.gift_sum;

        let checkedProduct = this.state.productList.filter(item => item.sku_info._id === sku);

        if (this.state.productType === "products") {
            if (e.target.checked) {
                selected_products.push(checkedProduct[0])

                formData['product_list'].push(sku)
                price_sum += checkedProduct[0].sku_info.price;
            } else {
                selected_products = selected_products.filter(item => item.sku_info._id !== checkedProduct[0].sku_info._id);
                formData['product_list'] = formData['product_list'].filter(item => item !== sku);

                price_sum -= checkedProduct[0].sku_info.price;
            }
        } else {
            if (e.target.checked) {
                selected_gift_products.push(checkedProduct[0])


                gift_sum += checkedProduct[0].sku_info.price;
            } else {
                selected_gift_products = selected_gift_products.filter(item => item.sku_info._id !== checkedProduct[0].sku_info._id);


                gift_sum -= checkedProduct[0].sku_info.price;
            }
        }
        this.setState({selected_products, selected_gift_products, formData, price_sum, gift_sum}, ()=>{

            console.log(this.state.formData);
        });
    }

    handleRegisteredStorePageChange = async (page) => {
        this.setState({...this.state.queryData, page: page}, () => {
            this.getProducts();
        });
    }

    handleRemoveSelectedProduct = (type, sku) => {
        let selected_products = [...this.state.selected_products];
        let selected_gift_products = [...this.state.selected_gift_products];
        let formData = {...this.state.formData};
        let price_sum = this.state.price_sum;
        let gift_sum = this.state.gift_sum;

        let checkedProduct = this.state.productList.filter(item => item.sku_info._id === sku);

        if (type === "product_list") {
            selected_products = selected_products.filter(item => item !== checkedProduct[0]);
            formData['product_list'] = formData['product_list'].filter(item => item !== sku);
            price_sum -= checkedProduct[0].sku_info.price;
        } else {
            selected_gift_products = selected_gift_products.filter(item => item !== checkedProduct[0]);

            gift_sum -= checkedProduct[0].sku_info.price;
        }

        this.setState({selected_products, selected_gift_products, formData, price_sum, gift_sum})
    }
    /*END HANDLE MODAL*/

    handleOnApply = (e, picker) => {
        const formData = {...this.state.formData};
        const startDate = Moment(picker.startDate).format('Y-M-D');
        const endDate = Moment(picker.endDate).format('Y-M-D')

        formData.start_time = startDate;
        formData.end_time = endDate;

        this.setState({
            formData,
            startDate: startDate,
            endDate: endDate,
        })
    }

    handleDiscountValueOnChange = (e, index) => {
        const formData = {...this.state.formData};
        formData.offer_value[index][e.target.name] = e.target.value;
        this.setState({formData})
    }

    handleSubmitForm = (e) => {
        e.preventDefault();
        const formData = {...this.state.formData};
        const form = e.currentTarget;

        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
            this.setState({isFormValidated: true})
        }else {
            console.log(".... >>")
            console.log(formData)

            if (this.state.formData.type === "null" || this.state.formData.type === null){
                toast.warning("Please Select Type");
            }else {
                addNewItem('/live',formData).then(res => {
                    console.log(res)
                    if (res.data.status === 2) {
                        toast.warning(res.message);

                    } else {
                        toast.success(res.data.message);
                        this.props.history.push("/live");
                    }
                    //  toast.success("Live Added");
                }).catch(errMsg => {
                    toast.error(errMsg)
                })

                this.setState({isSubmitting: true});
                setTimeout(() => {
                    this.setState({isSubmitting: false, isFormValidated: false})
                }, 2000)
            }



        }



  /*      if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
            this.setState({isFormValidated: true})
        } else {


            saveData('/live',formData)
                .then(res => {
                    if (res.status === 2) {
                        toast.warning(res.message);
                    } else {
                        toast.success(res.message);
                        this.props.history.push(`${process.env.PUBLIC_URL}/promotions/seller-voucher`);
                    }

                    this.props.history.push(`${process.env.PUBLIC_URL}/promotions/bundle-offer`);
                    toast.success("Bundle Added");
                })
                .catch(errMsg => {
                    toast.error(errMsg)
                })
            this.setState({isSubmitting: true});
            setTimeout(() => {
                this.setState({isSubmitting: false, isFormValidated: false})
            }, 2000)
        }*/
    }





 render() {
        return(
            <App layout="boxed">
                <div className="page-header">
                    <h2 className="page-title">Create new Live</h2>
                    <div className="ml-auto">
                        <Link to={`${process.env.PUBLIC_URL}/live`}
                              className="btn btn-sm btn-link"><FaLongArrowAltLeft/> Back to Live List</Link>
                    </div>
                </div>

                <div className="main-content">
                    
                        <Form
                            noValidate validated={this.state.isFormValidated}
                            onSubmit={this.handleSubmitForm}>
                            <div className="card-block">
                                <div className="block-header">
                                    <h4 className="block-title">Define Live Details</h4>
                                </div>

                                <div className="block-body">
                                 <Form.Group as={Row} controlId="formPromotionName">
                                        <Form.Label column sm="3">
                                           Title <span className="text-danger">*</span>
                                        </Form.Label>
                                        <Col sm="9" md={8} lg={7}>
                                            <Form.Control type="text"
                                                          onChange={this.handleInputOnChange}
                                                          name="title"
                                                          required
                                                          placeholder=""/>
                                        </Col>
                                    </Form.Group>



                                    <Form.Group as={Row} controlId="formPromotionName">
                                        <Form.Label column sm="3">
                                            Live url <span className="text-danger">*</span>
                                        </Form.Label>
                                        <Col sm="9" md={8} lg={7}>
                                            <Form.Control type="text"
                                                          onChange={this.handleInputOnChange}
                                                          name="live_url"
                                                          required
                                                          placeholder=""/>
                                        </Col>
                                    </Form.Group>



                                    <Form.Group as={Row} controlId="formVoucherType">
                                        <Form.Label column sm="3">
                                           Type <span className="text-danger">*</span>
                                        </Form.Label>
                                        <Col sm="9" md={8} lg={7}>
                                            <Form.Control as="select"
                                                          required
                                                          name={`type`}
                                                          onChange={this.handlePromotionTypeOnChange} placeholder="">
                                                {/*<option value="1">Quantity</option>*/}
                                                <option value="null"
                                                        selected={this.state.formData.type === "null"}
                                                        label="--Select--"/>
                                                <option value="youtube" selected={this.state.formData.type === "youtube"}>
                                                    Youtube
                                                </option>
                                                <option value="facebook" selected={this.state.formData.type === "facebook"}>
                                                    Facebook
                                                </option>

                                            </Form.Control>
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} controlId="formEndTime">
                                        <Form.Label column sm="3">
                                           Live start time <span className="text-danger">*</span>
                                        </Form.Label>
                                        <Col sm="9" md={8} lg={7}>

                                            <DatePicker selected={this.state.formData.start_time}
                                                        dateFormat="dd/MM/yyyy"
                                                        minDate={new Date()}
                                                        required
                                                        className={'form-control'} onChange={date => {
                                                const formData = {...this.state.formData}
                                                formData.start_time = date
                                                this.setState({formData}, () => {
                                                    console.log('Deposit Info: ', this.state.formData);
                                                })
                                            }}/>
                                        </Col>
                                    </Form.Group>



                                </div>
                            </div>

                            <div className="card-block">
                                <div className="block-body">
                                    <Form.Group as={Row} controlId="applicableFor">
                                        <Form.Label column sm="3">
                                            Applicable For <span className="text-danger">*</span>
                                        </Form.Label>
                                        <Col sm="9" md={8} lg={7}>
                                            <Form.Control
                                                required
                                                name="apply_to"

                                                as="select" placeholder="">
                                                <option value="2">Specific Stores</option>
                                            </Form.Control>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="forStores">
                                        <Form.Label column sm="3">
                                            Store(s) <span className="text-danger">*</span>
                                        </Form.Label>
                                        <Col sm="9" md={8} lg={7}>
                                            <AsyncSelect
                                                isMulti={false}
                                                required
                                                // value={this.state.attribute}
                                                styles={styleReactSelect}
                                                placeholder="Select Attribute..."

                                                onChange={(value) => {
                                                    this.handleSelectMultiOnChange(value, 'stores');
                                                }}
                                                cacheOptions
                                                defaultOptions
                                                loadOptions={this.promiseOptions}
                                                options={this.state.storeList}
                                                name="attributes"

                                                noOptionsMessage={() => 'Select store...'}
                                            />
                                        </Col>
                                    </Form.Group>
                                    {this.state.isApplyForCat &&
                                    <Form.Group as={Row} controlId="forCategories">
                                        <Form.Label column sm="3">
                                            Categories <span className="text-danger">*</span>
                                        </Form.Label>
                                        <Col sm="9" md={8} lg={7}>
                                            <Select
                                                isMulti
                                                // value={this.state.attribute}
                                                styles={styleReactSelect}
                                                placeholder="Select Attribute..."
                                                onChange={(value) => {
                                                    this.handleSelectMultiOnChange(value, 'categories')
                                                }}
                                                options={[{
                                                    id: 1,
                                                    value: 'Men\'s Fashion',
                                                    label: 'Men\'s Fashion'
                                                },
                                                    {
                                                        id: 2,
                                                        value: 'Electronic',
                                                        label: 'Electronic'
                                                    }]}
                                                name="attributes"
                                                noOptionsMessage={() => 'Select store...'}
                                            />
                                        </Col>
                                    </Form.Group>}
                                </div>
                            </div>

                            {this.state.formData?.promotion_type === '1' ?
                                <>
                                    <div className="card-block">
                                        <div className="block-header">
                                            <h4 className="block-title">Discount Details</h4>
                                        </div>

                                        <div className="block-body">
                                            <Form.Group as={Row} controlId="formBundleDiscountType">
                                                <Form.Label column sm="3">
                                                    Discount Type
                                                </Form.Label>
                                                <Col sm="9" md={8} lg={7}>
                                                    <Form.Check
                                                        custom
                                                        inline
                                                        required
                                                        type="radio"
                                                        value={`1`}
                                                        onChange={this.handleOfferTypeOnChange}
                                                        name="discount_type"
                                                        id={`money-value-on-quantity`}
                                                        label={`Flat Value`}
                                                    />
                                                    <Form.Check
                                                        custom
                                                        inline
                                                        required
                                                        value={`2`}
                                                        onChange={this.handleOfferTypeOnChange}
                                                        type="radio"
                                                        name="discount_type"
                                                        id={`percentage-on-quantity`}
                                                        label={`Percentage`}
                                                    />
                                                </Col>
                                            </Form.Group>

                                            {this.state.formData?.offer_value?.length > 0 &&
                                            <>
                                                {this.state.formData?.discount_type === '2' ?
                                                    <>
                                                        <table className={`table table-striped`}>
                                                            <thead className={`sm`}>
                                                            <tr>
                                                                <th>Item Quantity</th>
                                                                <th>Discount Amount (%)</th>
                                                                <th style={{width: '1%'}}/>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {this.state.formData?.offer_value.map((offer, index) => (
                                                                <tr key={index}>
                                                                    <td>
                                                                        <Form.Control type={`number`}
                                                                                      name={`item`}
                                                                                      required
                                                                                      defaultValue={this.state.formData.offer_value[index].discount_value}
                                                                                      onChange={(e) => this.handleDiscountValueOnChange(e, index)}
                                                                                      placeholder={`Quantity`} size={`sm`}/>
                                                                    </td>
                                                                    <td>
                                                                        <Form.Control type={`number`}
                                                                                      name={`discount_value`}
                                                                                      required
                                                                                      defaultValue={this.state.formData.offer_value[index].discount_value}
                                                                                      onChange={(e) => this.handleDiscountValueOnChange(e, index)}
                                                                                      placeholder={`Percentage`}
                                                                                      size={`sm`}/>
                                                                    </td>
                                                                    <td>
                              <span className={`text-danger`}
                                    onClick={() => this.handleRemoveItem(index)}
                                    style={{fontWeight: 'bold', cursor: 'pointer'}}>&times;</span>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                            </tbody>
                                                        </table>

                                                        <span style={{fontWeight: 'bold', cursor: `pointer`}}
                                                              onClick={this.addMoreOfferValue}
                                                              className={`text-primary`}>+ Add more</span>

                                                        <hr/>

                                                        <Form.Group as={Row} controlId="formBundleDiscountType">
                                                            <Form.Label column sm="3">
                                                                Maximum Discount Amount
                                                            </Form.Label>
                                                            <Col sm="9" md={8} lg={7}>
                                                                <Form.Control
                                                                    onChange={this.handleInputOnChange}
                                                                    name="maximum_discount_amount"
                                                                    required placeholder={`Maximum discount amount`}/>
                                                            </Col>
                                                        </Form.Group>
                                                    </>
                                                    :
                                                    <>
                                                        <table className={`table table-striped`}>
                                                            <thead className={`sm`}>
                                                            <tr>
                                                                <th>Item Quantity</th>
                                                                <th>Discount Amount</th>
                                                                <th style={{width: '1%'}}/>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {this.state.formData?.offer_value.map((offer, index) => (
                                                                <tr key={index}>
                                                                    <td>
                                                                        <Form.Control type={`number`}
                                                                                      name={`item`}
                                                                                      required
                                                                                      defaultValue={this.state.formData.offer_value[index].discount_value}
                                                                                      onChange={(e) => this.handleDiscountValueOnChange(e, index)}
                                                                                      placeholder={`Quantity`} size={`sm`}/>
                                                                    </td>
                                                                    <td>
                                                                        <Form.Control type={`number`}
                                                                                      name={`discount_value`}
                                                                                      required
                                                                                      defaultValue={this.state.formData.offer_value[index].discount_value}
                                                                                      onChange={(e) => this.handleDiscountValueOnChange(e, index)}
                                                                                      placeholder={`Amount`} size={`sm`}/>
                                                                    </td>
                                                                    <td><span className={`text-danger`}
                                                                              onClick={() => this.handleRemoveItem(index)}
                                                                              style={{
                                                                                  fontWeight: 'bold',
                                                                                  cursor: 'pointer'
                                                                              }}>&times;</span></td>
                                                                </tr>
                                                            ))}
                                                            </tbody>
                                                        </table>

                                                        <span style={{fontWeight: 'bold', cursor: `pointer`}}
                                                              onClick={this.addMoreOfferValue}
                                                              className={`text-primary`}>+ Add more</span>

                                                        <hr/>

                                                        <Form.Group as={Row} controlId="formBundleDiscountType">
                                                            <Form.Label column sm="3">
                                                                Minimum Spend Amount
                                                            </Form.Label>
                                                            <Col sm="9" md={8} lg={7}>
                                                                <Form.Control
                                                                    onChange={this.handleInputOnChange}
                                                                    name="minimum_spend_amount"
                                                                    required placeholder={`Minimum discount amount`}/>
                                                            </Col>
                                                        </Form.Group>
                                                    </>}
                                            </>


                                            }
                                        </div>
                                    </div>
                                    <Modal show={this.state.isProductModalProductShowing}
                                           onHide={this.handleHideProductModal}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>
                                                Select Product
                                            </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>

                                            <div className="mb-3 form-inline">
                                                <Form.Group>
                                                    <Form.Label srOnly>Search</Form.Label>
                                                    <Form.Control type={`search`}
                                                                  onKeyDown={(e)=> {
                                                                      if(e.keyCode   === 13){
                                                                          this.handleInputOnChange(e)
                                                                      }
                                                                  }}
                                                                  name="search_product"
                                                                  size={`sm`}
                                                                  placeholder={`Type & Search`}/>
                                                    <Button va>Search</Button>
                                                </Form.Group>
                                                <Button type={`button`} onClick={e => this.handleSearchProduct(e)}
                                                        size={`sm`} variant={`info`}
                                                        className={`ml-2`}>Search</Button>
                                            </div>

                                            {
                                                this.state.errorMessage?
                                                    <h5>{this.state.errorMessage}</h5> :
                                                    <>
                                                        <div className="product-checkbox-list">
                                                            {
                                                                this.state.isProductListLoading && <LoadingSpinner text={`Loading...`}/>
                                                            }

                                                            {this.state.productList.map((product, index) => (
                                                                <label className={`product-item`} key={index}
                                                                       htmlFor={`product-${index}`}>
                                                                    {this.state.formData.promotion_type === '3' ?
                                                                        <input id={`product-${index}`}
                                                                               onChange={e => this.handleSelectedProductOnChange(e, product.sku_info._id)}
                                                                               name={`product`}
                                                                               defaultChecked={this.isProductIncluded(product.sku_info._id)}
                                                                               type="checkbox"/>
                                                                        :
                                                                        <input id={`product-${index}`}
                                                                               onChange={e => this.handleSelectedProductOnChange(e, product._id, product.sku_info._id)}
                                                                               name={`product`}
                                                                               defaultChecked={this.isProductIncluded(product.sku_info._id)}
                                                                               type="checkbox"/>
                                                                    }
                                                                    <span className={`product`}>
                            <span className="product-img">
                              <img
                                  src={product?.sku_info?.images[0] ? product?.sku_info?.images[0]?.thumb_img : product?.thumb_img}
                                  alt=""/>
                            </span>
                            <span className="product-short-info">
                              <span
                                  className="product-name">{product.name} xxx {product?.sku_info?.attr_combo ? product?.sku_info?.attr_combo.join('-') : ''}</span>
                            </span>
                            <span className="icon"><IoMdCheckmark/></span>
                          </span>
                                                                </label>
                                                            ))}
                                                        </div>
                                                    </>
                                            }
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="secondary" onClick={this.handleHideProductModal}>
                                                Close
                                            </Button>
                                            {/*<Button variant="primary"
                            onClick={() => this.handleAddProduct(this.state.productType, this.state.selectedProducts, this.state.Products_sku)}>
                      Save Changes
                    </Button>*/}
                                        </Modal.Footer>
                                    </Modal>

                                    <div className="card-block">
                                        <div className="block-header">
                                            <h4 className="block-title">Product</h4>
                                        </div>

                                        <div className="block-body">
                                            <table className={`table table-striped`}>
                                                <thead className={`sm`}>
                                                <tr>
                                                    <th style={{width: '50px'}}/>
                                                    <th>Product Name</th>
                                                    <th>Product Price</th>
                                                    <th style={{width: '1%'}}/>
                                                </tr>
                                                </thead>

                                                <tbody>
                                                {this.state.product_list.length > 0 ?
                                                    <>

                                                        {this.state.product_list.map((product, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td>
                                                                        <img
                                                                            src={product?.sku_info?.images[0] ? product?.sku_info?.images[0]?.thumb_img : product?.thumb_img}
                                                                            style={{width: '40px'}} alt=""/>
                                                                    </td>
                                                                    <td>{product?.name} {product?.sku_info?.attr_combo ? product?.sku_info?.attr_combo.join('-') : ''}</td>
                                                                    <td>{product?.sku_info?.price}</td>
                                                                    <td><span style={{cursor: 'pointer'}}
                                                                              onClick={() => this.handleRemoveSelectedProduct('product_list', product.sku_info._id)}
                                                                              className={`text-bold text-danger`}>&times;</span>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                        <tr>
                                                            <td>&nbsp;</td>
                                                            <td>&nbsp;</td>
                                                            <td>{this.state.price_sum}</td>
                                                            <td>&nbsp;</td>
                                                        </tr>
                                                    </>
                                                    :
                                                    <tr>
                                                        <td colSpan={3} className={`text-center text-bold`}>
                                                            No product selected
                                                        </td>
                                                    </tr>
                                                }

                                                </tbody>
                                            </table>

                                            <Button size="sm"
                                                    onClick={() => this.handleShowSelectProductModal('product_list')}
                                                    variant="primary">
                                                {this.state.formData.promotion_type === '4' ?
                                                    "Select Mother Product"
                                                    :
                                                    "Select Product"
                                                }
                                            </Button>
                                        </div>
                                    </div>
                                </>

                                :
                                <>

                                    <Modal show={this.state.isProductModalProductShowing}
                                           onHide={this.handleHideProductModal}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>
                                                Select Product
                                            </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>

                                            <div className="mb-3 form-inline">
                                                <Form.Group>
                                                    <Form.Label srOnly>Search</Form.Label>
                                                    <Form.Control type={`search`}
                                                                  onKeyDown={(e)=> {
                                                                      if(e.keyCode === 13){
                                                                          this.handleInputOnChange(e)
                                                                      }
                                                                  }}
                                                                  name="search_product"
                                                                  size={`sm`}
                                                                  placeholder={`Type & Search`}/>
                                                </Form.Group>
                                                <Button type={`button`} onClick={e => this.handleSearchProduct(e)}
                                                        size={`sm`} variant={`info`}
                                                        className={`ml-2`}>Search</Button>

                                            </div>

                                            {
                                                this.state.errorMessage?
                                                    <h5>{this.state.errorMessage}</h5> :
                                                    <>
                                                        <div className="product-checkbox-list">
                                                            {
                                                                this.state.isProductListLoading && <LoadingSpinner text={`Loading...`}/>
                                                            }
                                                            {this.state.productList.map((product, index) => (
                                                                <label className={`product-item`} key={index}
                                                                       htmlFor={`product-${index}`}>
                                                                    {console.log("isProductIncluded",this.isProductIncluded(product.sku_info._id))}
                                                                    {console.log("formData",this.state.formData)}
                                                                    <input id={`product-${index}`}
                                                                           onChange={e => this.handleSelectedProductOnChange(e, product._id, product.sku_info._id)}
                                                                           name={`product`}
                                                                           defaultChecked={this.isProductIncluded(product.sku_info._id)}
                                                                           type="checkbox"/>
                                                                    <span className={`product`}>
                            <span className="product-img">
                              <img
                                  src={product?.sku_info?.images?.length>0 ? product?.sku_info?.images[0]?.thumb_img : product?.thumb_img}
                                  alt=""/>
                            </span>
                            <span className="product-short-info">
                               <span
                                   className="product-name">{product.name} {product?.sku_info?.attr_combo ? product?.sku_info?.attr_combo.join('-') : ''}</span>
                            </span>
                            <span className="icon"><IoMdCheckmark/></span>
                          </span>
                                                                </label>
                                                            ))}
                                                        </div>

                                                        <Pagination
                                                            itemClass="page-item"
                                                            linkClass="page-link"
                                                            activePage={this.state?.productListPagination?.page}
                                                            itemsCountPerPage={this.state?.productListPagination?.limit}
                                                            totalItemsCount={this.state?.productListPagination?.totalDocs}
                                                            onChange={this.handleRegisteredStorePageChange.bind(this)}
                                                        />
                                                    </>
                                            }
                                        </Modal.Body>
                                        <Modal.Footer>
                                            {/*<Button variant="secondary" onClick={this.handleHideProductModal}>
                      Close
                    </Button>
                    <Button variant="primary"
                            onClick={() => this.handleAddProduct(this.state.productType, this.state.selectedProducts, this.state.Products_sku)}>
                      Save Changesyy
                    </Button>*/}
                                        </Modal.Footer>
                                    </Modal>

                                    <div className="card-block">
                                        <div className="block-header">
                                            <h4 className="block-title">Product</h4>
                                        </div>

                                        <div className="block-body">
                                            <table className={`table table-striped`}>
                                                <thead className={`sm`}>
                                                <tr>
                                                    <th style={{width: '50px'}}/>
                                                    <th>Product Name</th>
                                                    <th>Product Price</th>
                                                    <th style={{width: '1%'}}/>
                                                </tr>
                                                </thead>

                                                <tbody>
                                                {this.state.selected_products.length > 0 ?
                                                    <>

                                                        {this.state.selected_products.map((product, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td>
                                                                        <img
                                                                            src={product?.sku_info?.images[0] ? product?.sku_info?.images[0]?.thumb_img : product?.thumb_img}
                                                                            style={{width: '40px'}} alt=""/>
                                                                    </td>
                                                                    <td>{product?.name} {product?.sku_info?.attr_combo ? product?.sku_info?.attr_combo.join('-') : ''}</td>
                                                                    <td>{product?.sku_info?.price}</td>
                                                                    <td><span style={{cursor: 'pointer'}}
                                                                              onClick={() => this.handleRemoveSelectedProduct('product_list', product.sku_info._id)}
                                                                              className={`text-bold text-danger`}>&times;</span>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                        <tr>
                                                            <td>&nbsp;</td>
                                                            <td>&nbsp;</td>
                                                            <td>{this.state.price_sum}</td>
                                                            <td>&nbsp;</td>
                                                        </tr>
                                                    </>
                                                    :
                                                    <tr>
                                                        <td colSpan={3} className={`text-center text-bold`}>
                                                            No product selected
                                                        </td>
                                                    </tr>
                                                }

                                                </tbody>
                                            </table>

                                            <Button size="sm"
                                                    onClick={() => this.handleShowSelectProductModal('products')}
                                                    variant="primary">
                                                {this.state.formData.promotion_type === '4' ?
                                                    "Select Mother Product"
                                                    :
                                                    "Select Product"
                                                }
                                            </Button>
                                        </div>
                                    </div>

                                    {(this.state.formData.promotion_type === '3' || this.state.formData.promotion_type === '4') &&
                                    <div className="card-block">
                                        <div className="block-header">
                                            <h4 className="block-title">
                                                {this.state.formData.promotion_type === '4' ?
                                                    "Bundle Product"
                                                    :
                                                    "Gift Product"
                                                }

                                            </h4>
                                        </div>

                                        <div className="block-body">
                                            <table className={`table table-striped`}>
                                                <thead className={`sm`}>
                                                <tr>
                                                    <th style={{width: '50px'}}/>
                                                    <th>Product Name</th>
                                                    <th>Product Price</th>
                                                    <th style={{width: '1%'}}/>
                                                </tr>
                                                </thead>

                                                <tbody>
                                                {this.state.selected_gift_products.length > 0 ?
                                                    <>
                                                        {this.state.selected_gift_products.map((product, index) => (
                                                            <tr key={index}>
                                                                <td>
                                                                    <img
                                                                        src={product?.sku_info?.images[0] ? product?.sku_info?.images[0]?.thumb_img : product?.thumb_img}
                                                                        style={{width: '40px'}} alt=""/>
                                                                </td>
                                                                <span
                                                                    className="product-name">{product.name} {product?.sku_info?.attr_combo ? product?.sku_info?.attr_combo.join('-') : ''}</span>
                                                                <td>{product?.sku_info?.price}</td>

                                                            </tr>
                                                        ))}
                                                        <tr>
                                                            <td>&nbsp;</td>
                                                            <td>&nbsp;</td>
                                                            <td>{this.state.gift_sum}</td>
                                                            <td>&nbsp;</td>
                                                        </tr>
                                                    </>
                                                    :
                                                    <tr>
                                                        <td colSpan={3} className={`text-center text-bold`}>
                                                            No product selected
                                                        </td>
                                                    </tr>
                                                }
                                                </tbody>
                                            </table>
                                            <Button size="sm"
                                                    onClick={() => this.handleShowSelectProductModal('gift_products')}
                                                    variant="primary"> + Add Product</Button>
                                        </div>
                                    </div>
                                    }

                                    {this.state.formData?.promotion_type === '4' &&
                                    <div className="card-block">
                                        <div className="block-header">
                                            <h4 className="block-title">Bundle Info</h4>
                                        </div>

                                        <div className="block-body">

                                            <Form.Group as={Row} controlId="formBundleDiscountType">
                                                <Form.Label column sm="3">
                                                    Bundle Actual Price
                                                </Form.Label>
                                                <Col sm="9" md={8} lg={7}>
                                                    <span>{this.state.gift_sum + this.state.price_sum}</span>
                                                </Col>

                                            </Form.Group>


                                            <Form.Group as={Row} controlId="formBundleDiscountType">
                                                <Form.Label column sm="3">
                                                    Bundle Discounted Price
                                                </Form.Label>
                                                <Col sm="9" md={8} lg={7}>
                                                    <Form.Control

                                                        name="bulk_price"
                                                        required placeholder={`Bundle Discounted Price`}/>
                                                </Col>

                                            </Form.Group>

                                            <Form.Group as={Row} controlId="formBundleDiscountType">

                                                <Form.Label column sm="3">
                                                    Bundle Image
                                                </Form.Label>

                                                <Col sm="9" md={8} lg={7}>
                                                    <BrowseFiles
                                                        name="bundle_banner"
                                                        isSingle={true}
                                                        image_category={'banner'}
                                                        onRemoveItem={this.handleOnRemoveItem}
                                                        images={this.state.brandData.logo ? [{thumb_img: this.state.brandData.logo}] : []}
                                                        onGetFiles={this.handleImageOnBrowse}/>
                                                </Col>

                                            </Form.Group>
                                        </div>
                                    </div>
                                    }
                                </>
                            }

                            <div className="card-block">
                                <div className="block-body text-right">
                                    <Link to={`/promotions/bundle-offer`}
                                          className="btn btn-lg btn-default mr-3">Cancel</Link>
                                    <Button size="lg" type={`submit`}
                                            disabled={this.state.isSubmitting}
                                            variant="primary">{this.state.isSubmitting ? 'Saving...' : 'Save'}</Button>
                                </div>
                            </div>
                        </Form>
                </div>

            </App>
        )
 }


}

export default withRouter(CreateLive);
