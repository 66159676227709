import React, {Component} from 'react';
import App from "../../App";
import {Link, withRouter} from "react-router-dom";
import "../../assets/scss/order-list.scss";
import moment from "moment";
import ReactPaginate from 'react-paginate';
import LoadingSpinner from "../../components/LoadingSpinner";
import {getBulkRequestList} from "../../services/orderServices";
import DateRangePicker from "react-bootstrap-daterangepicker";
import {Button} from "react-bootstrap";
import {urlPramsToObj} from "../../helper/utils";

class Bulkrequest extends Component {
  state = {
    activeBlockTab: 'All',
    requestList: [],
    isLoading: true,
    pagination: null,
    isDataLoading: false,
    defaultSetting: {
      limit: 20,
      from_date: '',
      to_date: ''
    },
    status: null,
    changingStoreId: null,
    changeStatusMsg: '',
    isShowingStatusChangeConfirmModal: false,
    isSubmitting: false,
    errorMessage: '',
  };

  componentDidMount() {
    getBulkRequestList(this.state.defaultSetting)
      .then(res => {
        this.setState({requestList: res.items, pagination: res.pagination, isLoading: false})
      })
      .catch(errMsg => {
        this.setState({isLoading: false})
        console.log('err: ', errMsg)
      })
  }

  handlePagination = (pageNo) => {
    const history = this.props.history
    let params = null

    if (history.location.search !== '') {
      params = urlPramsToObj(history)
    }

    this.setState({isDataLoading: true}, () => {
      const paramsObj = {
        ...params,
        page: pageNo.selected + 1
      }

      this.setState({isDataLoading: true}, () => {
        getBulkRequestList({...this.state.defaultSetting, ...paramsObj})
          .then(res => {
            this.setState({requestList: res.items, pagination: res.pagination, isDataLoading: false})
          })
          .catch(errMsg => {
            this.setState({isDataLoading: false})
            console.log('err: ', errMsg)
          })
      })

    })
  }

  handleOnApply = (e, picker) => {
    const defaultSetting = {...this.state.defaultSetting};
    defaultSetting.from_date = moment(picker.startDate).format('DD/MM/YYYY');
    defaultSetting.to_date = moment(picker.endDate).format('DD/MM/YYYY');
    this.setState({defaultSetting})
  }

  handleOnSearch = (e) => {
    e.preventDefault()

    this.setState({isDataLoading: true}, () => {
      getBulkRequestList(this.state.defaultSetting)
        .then(res => {
          this.setState({requestList: res.items, pagination: res.pagination, isDataLoading: false})
        })
        .catch(errMsg => {
          this.setState({isDataLoading: false, errorMessage: errMsg, requestList: []})
          console.log('err: ', errMsg)
        })
    })

  }

  render() {
    return (
      <App layout="boxed">
        <div className="main-content">
          <div className="page-header">
            <h2 className="page-title">Bulk Request List</h2>
          </div>

          {this.state.isLoading ?
            <div className={`card-block`}>
              <div className="block-body">
                Loading...
              </div>
            </div>
            :
            <div className="card-block">
              <div className="block-body position-relative">
                {this.state.isDataLoading &&
                <LoadingSpinner text={`Loading...`}/>
                }

                <form onSubmit={this.handleOnSearch} className="form-inline mb-4">
                  <div className="form-group mr-3 mt-2">
                    <label htmlFor="inputSearch" className="sr-only">Search</label>
                    <input type="search" defaultValue={this.state.defaultSetting.search_string}
                           name={'search_string'}
                           onChange={e => {
                             const defaultSetting = {...this.state.defaultSetting}
                             defaultSetting[e.target.name] = e.target.value
                             this.setState({defaultSetting})
                           }}
                           className="form-control form-control-sm" placeholder={'Search...'}
                           onKeyDown={(e)=>{
                             if(e.keyCode === 13){
                              this.handleOnSearch(e)
                             }
                           }}/>
                  </div>
                  <div className="form-group mr-3 mt-2">
                    <label htmlFor="inputSearch" className="sr-only">Date range</label>
                    <DateRangePicker
                      initialSettings={{}}
                      containerStyles={{textAlign: 'left', width: '100%', display: 'block'}}
                      onApply={this.handleOnApply}>
                      <input type="button"
                             placeholder={`Select date range`}
                             value={(this.state.defaultSetting.from_date && this.state.defaultSetting.to_date) ? `${this.state.defaultSetting.from_date} - ${this.state.defaultSetting.to_date}` : 'Select date range'}
                             style={{textAlign: 'left', minWidth: '150px'}}
                             className="form-control form-control-sm"/>
                    </DateRangePicker>
                  </div>

                  <Button variant={'default'} className={'mt-2'} size={'sm'} type={'submit'}>Search</Button>
                </form>
                
                <table className="order-table table table-striped">
                  <thead>
                  <tr>
                    <th style={{width: '1%'}}>#</th>
                    <th>Date</th>
                    <th style={{width: '150px'}}>Customer Info</th>
                    <th>Product Info</th>
                    <th>Message</th>
                    <th style={{width: '1%'}}>Quantity</th>
                  </tr>
                  </thead>
                  <tbody>
                  {this.state.requestList.length > 0 ?
                    this.state.requestList.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td style={{width: '150px'}}>
                            <div className="order-item">
                              <span className="order-date">{moment(item?.createdAt).format('ll')}</span>
                              <span className="order-time">{moment(item?.createdAt).format('LT')}</span>
                            </div>
                          </td>
                          <td style={{width: '150px'}}>
                            <div className="order-item">
                              <h4 className="order-id">{item.user_info?.name}</h4>
                             {/* <ul className="ordered-items">
                                <li>{item?.user_info?.phone}</li>
                                <li>{item?.user_info?.email}</li>
                              </ul>*/}
                            </div>
                          </td>
                          <td>
                            <Link target={'_blank'}
                                  to={`/products/details/${item?.product_info?._id}`}>{item?.product_info?.name}</Link>
                          </td>
                          <td>{item.message}</td>
                          <td>{item.quantity}</td>
                        </tr>
                      )
                    })
                    :
                    <tr>
                      <td colSpan={7} className={`text-bold text-center`}>
                        No data found
                      </td>
                    </tr>
                  }
                  </tbody>
                </table>

                {this.state.pagination &&
                <div className="mt-3">
                  <ReactPaginate
                    previousLabel={'«'}
                    nextLabel={'»'}
                    breakLabel={'...'}
                    pageClassName={'page-item'}
                    pageLinkClassName={'page-link'}
                    pageCount={this.state.pagination.totalPages}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    forcePage={this.state.pagination.page - 1}
                    onPageChange={this.handlePagination}
                    containerClassName={'pagination'}
                    activeClassName={'active'}
                    activeLinkClassName={`disabled`}
                    disabledClassName={`disabled`}
                  />
                </div>
                }
              </div>
            </div>
          }
        </div>
      </App>
    );
  }
}

export default withRouter(Bulkrequest);