import React, {Component} from 'react';
import App from "../../App";
import {Button, Form, Row} from "react-bootstrap";
import Pagination from 'react-js-pagination';
import {FaFileExport, FiPlus} from "react-icons/all";
import "../../assets/scss/order-list.scss";
import axios from "axios";
import {API_BASE_URL} from "../../helper/env";
import {getChildLocations, getLocationList} from "../../services/locationServices";
import {toast} from "react-toastify";
import {confirmAlert} from "react-confirm-alert";
import {deleteAssistedSellerPermanently, getAge} from "../../services/assistedSellerServices";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import {Link} from "react-router-dom";
import {getDeviceType, getLocalAuthData} from "../../helper/utils";
import {getTableData} from "../../services/commonServices";

class AssistedSellers extends Component {

    constructor(props) {
        super(props);
        this.state = {
            assisted_sellers: [],
            locations: [],
            businessChildLocations: [],
            queryData: {
                selected_location: '',
                from_date: '',
                end_date: ''
            },
            activePage: 1,
            pagination: {},
            isLoading: true,
            limit: 50
        }
    }

    handlePageChange = async (pageNumber) => {
        await this.setState({queryData: {...this.state.queryData, page: pageNumber}, activePage: pageNumber});
        this.getQueryData();
    }

    componentDidMount() {
        const authData = getLocalAuthData()
        this.getQueryData()

        getTableData(`/location/`, {type: 'Country'}).then(res => {
            this.setState({locations: res.locations})
        }).catch(err => {
            console.log(err);
            toast("Something went wrong please reload the. or try again later.")
        });
    }

    changeStatus = async (statusObj, id) => {
        const authData = getLocalAuthData()
        axios.patch(`${API_BASE_URL}/assisted-seller/${id}/changeStatus`, statusObj, {
            headers: {
                "x-auth-token": authData.token,
                "x-api-client": getDeviceType()
            }
        }).then(response => {
            if (response.data.status === 1) {
                toast.success(response.data.message);
            }
            if (response.data.status === 2) {
                toast.warning(response.data.message);
            }
        }).catch(error => {
            toast.error("Error");
        });
    };

    handlePermanentDeleteAssistedSeller = (id) => {
        confirmAlert({
            title: 'Delete',
            message: 'Are you sure to delete this assisted seller permanently?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => deleteAssistedSellerPermanently(id).then(res => {
                        toast.success('Successfully deleted');
                        const authData = getLocalAuthData()
                        axios.get(`${API_BASE_URL}/assisted-seller?limit=${this.state.limit}`, {
                            headers: {
                                "x-auth-token": authData.token,
                                "x-api-client": getDeviceType()
                            }
                        }).then(res => {
                            console.log(res.items);
                            this.setState({assisted_sellers: res.items, pagination: res.pagination, isLoading: false});
                        }).catch(e => console.log("Error"));
                    }).catch(errMsg => {
                        toast.error(errMsg)
                    })
                },
                {
                    label: 'No',
                    // onClick: () => alert('Click No')
                }
            ]
        });
    }

    getQueryData = async () => {
        this.setState({isLoading: true});
        getTableData(`/assisted-seller`, this.state.queryData).then(res => {
            this.setState({...this.state, assisted_sellers: res.items, pagination: res.pagination, isLoading: false});
        }).catch(err => {
            console.log(err);
            toast("Something went wrong please try again")
        });
    }

    handleSearchFormDataChange = async (e) => {
        await this.setState({queryData: {...this.state.queryData, [e.target.name]: e.target.value}}, () => {
            console.log(this.state?.queryData);
        });
    };

    handleLocationInputOnChange = (e, index, typePurpose) => {
        const childLocations = [...this.state[typePurpose]];
        if (typePurpose === 'businessChildLocations') {
            this.setState({queryData: {...this.state.queryData, ['selected_location']: e.target.value}}, () => {
                console.log(this.state?.queryData);
            });
        } else {
            this.setState({queryData: {...this.state.queryData, ['selected_location']: e.target.value}}, () => {
                console.log(this.state?.queryData);
            });
        }
        if (e.target.value !== '') {
            getChildLocations(e.target.value)
                .then(res => {
                    childLocations.splice(index, childLocations.length - index);
                    if (res.length > 0) {
                        childLocations[index] = res
                    }
                    this.setState({[typePurpose]: childLocations})
                })
                .catch(errMsg => {
                    console.log(errMsg)
                    childLocations.splice(index, childLocations.length - index)
                    this.setState({[typePurpose]: childLocations})
                })
        } else {
            childLocations.splice(index, childLocations.length - index);
            this.setState({[typePurpose]: childLocations})
        }
    }

    renderLocations = () => {
        const locations = this.state.locations;
        if (locations) {
            return (
                <>
                    {locations.map((items, index) =>
                        <option value={`${items._id}`}>{items.name}</option>
                    )}
                </>
            )
        }
    };

    handleOnApply = (e, picker) => {
        const queryData = {...this.state.queryData};

        queryData.from_date = moment(picker.startDate).format('DD/MM/YYYY');
        queryData.to_date = moment(picker.endDate).format('DD/MM/YYYY');

        this.setState({queryData}, () => {
            console.log(this.state)
        })
    }

    handleExportFile = async (fileType) => {
        let queryData = "";
        Object.entries(this.state.queryData).forEach(([key, val]) => {
                queryData = queryData.concat(key + "=" + val + "&");
            }
        )
        await this.setState({isExporting: true});
        const authData = getLocalAuthData();
        axios.get(`${API_BASE_URL}/assisted-seller?export=${fileType}&${queryData}`, {
            headers: {"x-auth-token": authData.token, "x-api-client": getDeviceType()}
        }).then(res => {
            console.log(res)
            this.setState({isExporting: false});
        }).catch(e => console.log("Error"));
    }

    getAge = async (timestamp) => {
        return getAge(timestamp);
    }

    render() {
        return (
            <App layout="boxed">
                <div className="main-content">
                    <div className="page-header">
                        <h2 className="page-title">Assisted Sellers</h2>
                        <div className="ml-auto">
                            <Button size={"sm"} variant={"defalut"} className="mb-0 ml-2" onClick={() => this.handleExportFile('xlsx')}><FaFileExport/> Export</Button>
                            {getLocalAuthData().permission.includes('assisted_seller_add') ? <Link to={`${process.env.PUBLIC_URL}/assisted-sellers/add`} className="btn btn-sm btn-secondary"><FiPlus/> Add Assisted Seller</Link> : null}
                        </div>
                    </div>


                    <div className="card-block">
                        <div className="block-header">
                            <h4 className="block-title">Assisted Seller List</h4>
                        </div>

                        <div className="block-body">

                            <form onSubmit={(e)=> e.preventDefault()} className="form-inline mb-3">

                                <div className="form-group mr-3">
                                    <label htmlFor="status" className="sr-only">Locations</label>
                                    <select id="status" name="country" className='form-control form-control-sm'
                                            onChange={(e) => this.handleLocationInputOnChange(e, 0, 'businessChildLocations')}>
                                        <option value="">--Select Country--</option>
                                        {this.renderLocations()}
                                    </select>
                                </div>

                                {(getLocalAuthData().permission.includes('assisted_seller_list') && this.state.businessChildLocations.length > 0) &&
                                this.state.businessChildLocations.map((location, index) => (
                                    <div className="form-group mr-3" key={index} as={Row}
                                         controlId={`location${location[0].type}`}>
                                        <label htmlFor={`location${location[0].type}`}
                                               className="sr-only">{location[0].type}</label>
                                        <select id={`location${location[0].type}`}
                                                name={location[0].type.toLowerCase()}
                                                className='form-control form-control-sm'
                                                onChange={(e) => this.handleLocationInputOnChange(e, index + 1, 'businessChildLocations')}>
                                            <option value="">--Select--</option>
                                            {location.map((item, index) => (
                                                <option key={index} value={item._id}>{item.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                ))
                                }
                                {getLocalAuthData().permission.includes('assisted_seller_active_inactive') ? <div className="form-group mr-3">
                                    <label htmlFor="status" className="sr-only">Status</label>
                                    <select id="status" name="status" className='form-control form-control-sm'
                                            onChange={this.handleSearchFormDataChange}>
                                        <option value="">--Select Status--</option>
                                        <option value="1">Active</option>
                                        <option value="2">Inactive</option>
                                    </select>
                                </div> : null}

                                <div className="form-group mr-3">
                                    <label htmlFor="searchInput" className="sr-only">Search Key</label>
                                    <input type="search" name="search_string" id="orderNumber"
                                           onChange={this.handleSearchFormDataChange}
                                           className='form-control form-control-sm' placeholder="Search Key"
                                           onKeyDown={(e)=>{
                                               if(e.keyCode === 13) {
                                                this.getQueryData()
                                               }
                                           }}/>
                                </div>

                                <Form.Group className={`mr-2`}>
                                    <DateRangePicker
                                        initialSettings={{}}
                                        containerStyles={{textAlign: 'left', width: '100%', display: 'block'}}
                                        onApply={this.handleOnApply}>
                                        <input type="button"
                                               placeholder={`Select date range`}
                                               value={(this.state.queryData.from_date && this.state.queryData.to_date) ? `${this.state.queryData.from_date} - ${this.state.queryData.to_date}` : 'Select date range'}
                                               style={{textAlign: 'left', minWidth: '150px'}}
                                               className="form-control form-control-sm"/>
                                    </DateRangePicker>
                                </Form.Group>

                                <Button size="sm" variant="secondary" onClick={this.getQueryData}>Search</Button>
                            </form>

                            {this.state.isLoading ? <div>Loading...</div> :

                                this.state?.assisted_sellers?.length > 0 ?
                                    <>
                                        <table className="order-table table table-striped">
                                            <thead>
                                            <tr>
                                                <th style={{width: '1%'}}>#</th>
                                                <th style={{width: '20%'}}>Name</th>
                                                <th style={{width: '20%'}}>Contact Info</th>
                                                <th style={{width: '25%'}}>Merchant Info</th>
                                                <th style={{width: '10%'}}>Total Orders</th>
                                                <th style={{width: '10%'}}>Delivered Orders</th>
                                                <th style={{width: '10%'}}>Total Sale</th>
                                                <th style={{width: '8%'}}>Status</th>
                                                <th style={{width: '8%'}}/>
                                            </tr>
                                            </thead>
                                            <tbody>

                                            {this.state.assisted_sellers.map((seller, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>
                                                            <div className="order-item">
                                                                <strong className="order-id">{seller.name ? seller.name.toUpperCase() : seller.phone}</strong>
                                                                <ul className="ordered-items">
                                                                    <li>
                                                                        {seller?.gender && <><strong className="order-id">{seller?.gender ? seller?.gender[0].toUpperCase() : '--'}</strong></>}, &nbsp; &nbsp;
                                                                        {seller?.birthdate && <><strong className="order-id">{
                                                                            moment().diff(seller?.birthdate, 'years',false) }
                                                                        </strong> years old</>}

                                                                    </li>
                                                                    <li>{moment(seller?.createdAt).format('ll')}</li>
                                                                </ul>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="order-item">
                                                                <ul className="ordered-items">
                                                                    <li>Address: {seller?.address}</li>
                                                                    <li>{seller.phone}</li>
                                                                    <li>{seller.email}</li>
                                                                </ul>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <h6>{seller?.merchant_info?.name?.toUpperCase()}</h6>
                                                            <div className="order-item">
                                                                <ul className="ordered-items">
                                                                    <li>{seller?.merchant_info?.merchant_type_info?.name?.toUpperCase()}</li>
                                                                </ul>
                                                            </div>
                                                        </td>
                                                        <td> {seller?.total_order ? seller?.total_order : 0}</td>
                                                        <td> {seller?.total_delivered_order ? seller?.total_delivered_order : 0}</td>
                                                        <td> {seller?.total_sale ? seller?.total_sale : 0}</td>

                                                        <td>
                                                            <select
                                                                style={{width: '150px'}}
                                                                className="form-control form-control-sm"
                                                                onChange={(e) => {
                                                                    this.changeStatus({status: e.target.value}, seller._id)
                                                                }}
                                                                name="status">
                                                                <option value="1" selected={(seller.status === 1) ? 'selected' : ''}>Active</option>
                                                                <option value="2" selected={(seller.status === 2) ? 'selected' : ''}>Inactive</option>
                                                            </select>
                                                        </td>
                                                        <td>
                                                            <Link to={`/assisted-sellers/details/${seller._id}`} className="btn btn-outline-info btn-action ml-3">View</Link>
                                                            <Link to={`/assisted-sellers/edit/${seller._id}`} className="btn-outline-primary btn-action ml-3">Edit</Link>
                                                            <button className="btn-action btn-outline-danger ml-3" onClick={() => this.handlePermanentDeleteAssistedSeller(seller._id)}>Delete</button>
                                                        </td>
                                                    </tr>
                                                );
                                            })}

                                            </tbody>
                                        </table>
                                        <div className="mt-3">
                                            <Pagination
                                                itemClass="page-item"
                                                linkClass="page-link"
                                                activePage={this.state?.pagination?.page}
                                                itemsCountPerPage={this.state?.pagination?.limit}
                                                totalItemsCount={this.state?.pagination?.totalDocs}
                                                pageRangeDisplayed={10}
                                                onChange={this.handlePageChange.bind(this)}
                                            />
                                        </div>
                                    </>
                                    :
                                    <>
                                        {<div className="alert alert-warning text-bold text-center">
                                            No assisted seller found. Please <Link to={`${process.env.PUBLIC_URL}/assisted-seller/add`}>Add Seller</Link>
                                        </div>}
                                    </>
                            }

                        </div>
                    </div>

                </div>

            </App>
        );
    }
}

export default AssistedSellers;
