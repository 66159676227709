import React, {Component} from 'react';
import App from "../../App";
import {Button, Container, Form, Modal, Col, Row} from "react-bootstrap";
import {FaLongArrowAltLeft} from "react-icons/all";
import "../../assets/scss/invoice.scss";
import {Link, withRouter} from "react-router-dom";
import DeliveryProgressTimeline from "../../components/DeliveryProgressTimeline";
import {getOrderDetails} from "../../services/orderServices";
import moment from "moment";
import OrderActivities from "../../components/OrderActivities";
import {STORE_URL} from "../../helper/env";
import {getLocalAuthData} from '../../helper/utils';

class OrderDetails extends Component {
  state = {
    orderDetails: null,
    isLoading: true,
    isDisputeResolvedModalShow: false,
    offers: [],
  }

  componentDidMount() {
    const orderId = this.props.match.params.id
    getOrderDetails(orderId)
      .then(res => {
        this.setState({orderDetails: res, isLoading: false}, () => {
          const offers = {}

          for (let i = 0; i < this.state.orderDetails.order_parcels?.order_items.length; i++) {
            let itemDetail = this.state.orderDetails.order_parcels?.order_items[i]
            if (itemDetail?.bundle_offer) {
              if (offers[itemDetail?.bundle_offer?._id] === undefined) {
                offers[itemDetail?.bundle_offer?._id] = []
              }
              offers[itemDetail?.bundle_offer?._id].push(itemDetail)
            } else {
              if (offers[itemDetail?._id] === undefined) {
                offers[itemDetail?._id] = []
              }
              offers[itemDetail?._id].push(itemDetail)
            }
          }

          this.setState({offers})
        })
      })
      .catch(errMsg => {
        this.setState({isLoading: false})
      })

  }

  renderPaymentStatus = (status) => {
    let payment_status = {
      0: "Unpaid",
      1: "Partially Paid",
      2: "Paid",
      3: "Cancelled",
      4: "Failed",
      5: "Refund",
      6: "Bank deposit payment in review",
      7: "Bank deposit declined",
    }
    return payment_status[status];
  }

  handleDisputeResolveInfoModalOpen = (id, item_info) => {
    console.log(item_info);
    this.setState({
      isDisputeResolvedModalShow: true,
      item_info: item_info,
      item_id: id
    }, () => {
      console.log(this.state);
    });
  }
  handleDisputeResolveInfoModalClose = () => {
    this.setState({isDisputeResolvedModalShow: false})
  }

  // promotion_type: 1=quantity; 2=buy1get1; 3=free gift
  renderPromotionLabel = (type) => {
    if (type === '1') {
      return <span className={'promo-badge'}>Quantity</span>
    } else if (type === '2') {
      return <span className={'promo-badge'}>Buy 1 Get 1</span>
    } else if (type === '3') {
      return <span className={'promo-badge'}>Free Gift</span>
    } else if (type === '4') {
      return <span className={'promo-badge'}>Bundle Offer</span>
    }
  }

  showItemDetail = itemDetail => {
    let price = 0;
    itemDetail.forEach(item => {
      if (item?.item_grand_total > price) {
        price = item?.item_grand_total;
      }
    });
    return (
      <tr>
        <td colSpan={2}>
          {itemDetail.map((eachItem) => {
            // noinspection HtmlUnknownAttribute
            return <>
              <span className="d-block">
              <strong>
                {eachItem?.product_info.length > 0 ?
                  <a href={`${process.env.PUBLIC_URL}/products/details/${eachItem?.product_info[0]?._id}`}
                     target={`_blank`}>
                    {eachItem?.product_name}
                    {eachItem?.bundle_offer?.promotion_type === "4" && parseInt(eachItem.price) === parseInt(price) && eachItem?.bundle_offer && this.renderPromotionLabel(eachItem?.bundle_offer?.promotion_type)}
                    {eachItem?.bundle_offer?.promotion_type === "3" && parseInt(eachItem.price) !== parseInt(price) && eachItem?.bundle_offer && this.renderPromotionLabel(eachItem?.bundle_offer?.promotion_type)}

                  </a>
                  : <>
                    <span>{eachItem?.product_name} {eachItem?.product_name}
                      {eachItem?.bundle_offer?.promotion_type === "4" && parseInt(eachItem.price) === parseInt(price) && eachItem?.bundle_offer && this.renderPromotionLabel(eachItem?.bundle_offer?.promotion_type)}
                      {eachItem?.bundle_offer?.promotion_type === "3" && parseInt(eachItem.price) !== parseInt(price) && eachItem?.bundle_offer && this.renderPromotionLabel(eachItem?.bundle_offer?.promotion_type)}
                    </span>
                  </>}
              </strong>
          </span>
              <span className="d-block text-muted">{eachItem?.product_sku_attr_combo.join('-')}</span>
              {eachItem?.product_dispute?.status === 'Resolved' && <>
                <span className="d-block text-muted">Refund Amount: {eachItem?.refund_amount ?? 0}</span>
                <span className="d-block text-muted">Note: {eachItem?.disputeResolveInfo?.note}</span>
                <a href={'#'} onClick={() => this.handleDisputeResolveInfoModalOpen(eachItem?._id, eachItem)}
                   size={"sm"}
                   variant={true}>Dispute Info
                </a>
              </>}
              {eachItem?.product_dispute?.status === 'Pending' && <>
                <span className="d-block text-muted">Dispute Status: {eachItem?.product_dispute?.status}</span>
              </>}
            </>
          })}
        </td>
        <td>{itemDetail[0]?.quantity}</td>
        <td className="text-right">{price ?? 0}</td>
      </tr>
    );
  }

  render() {
    return (
      <App layout="boxed">
        <div className="main-content">
          <div className="page-header">
            <h2 className="page-title">Order Details</h2>
            <div className="ml-auto">
              <Link to="/orders/list" className="btn btn-sm btn-link"><FaLongArrowAltLeft/> Back to Order
                List</Link>
            </div>
          </div>

          {this.state.isLoading ?
            <div className={`card-block`}>
              <div className="block-body">
                Loading...
              </div>
            </div>
            :
            <>
              <div className="card-block">
                {/*<div className="block-header">
                  <h4 className="block-title">InvoiceComponent</h4>
                </div>*/}

                <Modal show={this.state.isDisputeResolvedModalShow}
                       onHide={this.handleDisputeModalClose}>
                  <Modal.Header closeButton>
                    <Modal.Title>Product Dispute Status</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <span>Message: Dispute {this.state?.item_info?.product_dispute?.status}</span>

                    {this.state?.item_info?.product_dispute?.status === 'Resolved' &&
                    <>
                      <br/><span>Method: {this.state?.item_info?.disputeResolveInfo?.method}</span>
                      {this.state?.item_info?.disputeResolveInfo?.method === 'MFS' &&
                      <>
                        <br/><span><strong>Mobile Number:</strong> {this.state?.item_info?.disputeResolveInfo?.mobile_number}</span>
                        <br/><span><strong>Operator Info:</strong> {this.state?.item_info?.disputeResolveInfo?.operator_name}</span>
                        <br/><span><strong>Account Type:</strong> {this.state?.item_info?.disputeResolveInfo?.account_type}</span>
                      </>
                      }
                      <br/><span>Note: {this.state?.item_info?.disputeResolveInfo?.note}</span>
                      <br/><span>Refund Amount: {this.state?.item_info?.refund_amount}</span>
                    </>
                    }
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="default" onClick={this.handleDisputeResolveInfoModalClose}>
                      Ok
                    </Button>
                  </Modal.Footer>
                </Modal>

                <div className="block-body">
                  <div className="invoice-table-container">
                  <table className="invoice-table">
                    <tbody>
                    {/*<tr className="v-top">
                      <td>
                        <img src={logo} alt="" className="brand-logo"/>
                      </td>
                      <td className="text-right">
                        <span className="d-block"><strong>Ajkerdeal</strong></span>
                        <span className="d-block">8048 Edgemont St. Hudson, NH 03051</span>
                        <span className="d-block">+8801234567898</span>
                        <span className="d-block">example@ekshop.com</span>
                        <span className="d-block">http://google.com</span>
                      </td>
                    </tr>*/}

                    <tr>
                      <td><h3
                        className="invoice-title">{this.state.orderDetails.order_parcels?.order_parcel_code}</h3>
                      </td>

                      <td className="text-right">
                                                <span
                                                  className="d-block text-bold">{this.state.orderDetails?.order_parcels?.store_info?.store_name}</span>
                        <span
                          className="d-block">{this.state.orderDetails?.order_parcels?.store_info?.address}</span>
                        <span
                          className="d-block">{this.state.orderDetails?.order_parcels?.store_info?.phone}</span>
                        <span
                          className="d-block">{this.state.orderDetails?.order_parcels?.store_info?.email}</span>
                        <a target={`_blank`}
                           href={`${STORE_URL}/store/details/${this.state.orderDetails?.order_parcels?.store_info?._id}`}
                           className="d-block">
                          {`${STORE_URL}store/${this.state.orderDetails?.order_parcels?.store_info?.store_url}`}
                        </a>
                      </td>
                    </tr>

                    <tr>
                      <td colSpan={2} className={'pb-2 pt-2'}>&nbsp;</td>
                    </tr>

                    <tr>
                      <td colSpan="2">
                        <table>
                          <tbody>
                          <tr>
                          <td style={{paddingBottom: '20px'}} width={'20%'}>
                                  <h4 className="invoice-title-alt">Bill To</h4>
                                  {this.state.orderDetails?.billing_address ? <>
                                    <span className="d-block">{this.state.orderDetails?.order_parcels?.billing_address?.name}</span>
                                  <span className="d-block">{this.state.orderDetails?.order_parcels?.billing_address?.phone}</span>
                                  <span className="d-block">{this.state.orderDetails?.order_parcels?.billing_address?.address}</span>
                                  <span className="d-block">
                                    {this.state.orderDetails?.order_parcels?.billing_address?.locations?.length > 0  ? this.state.orderDetails?.order_parcels?.billing_address?.locations?.reverse()?.map((loc, index)=>(
                                        <span>{loc?.name}{this.state.orderDetails?.order_parcels?.billing_address?.locations[index+1]? ", ": ""}</span>
                                    )):""}
                                  </span>
                                  </> : <span className="d-block">Shipping address</span>}
                                  
                                </td>

                                <td style={{paddingBottom: '20px'}} width={'20%'}>
                                  <h4 className="invoice-title-alt">Ship To</h4>
                                  <span className="d-block">{this.state.orderDetails?.order_parcels?.delivery_address?.recipient_name}</span>
                                  <span className="d-block">{this.state.orderDetails?.order_parcels?.delivery_address?.recipient_phone}</span>
                                  <span className="d-block">{this.state.orderDetails?.order_parcels?.delivery_address?.address}</span>
                                  <span className="d-block">
                                    {this.state.orderDetails?.order_parcels?.delivery_address?.locations?.length > 0 ? this.state.orderDetails?.order_parcels?.delivery_address?.locations?.reverse()?.map((loc, index)=>(
                                        <span>{loc?.name}{this.state.orderDetails?.order_parcels?.order_parcels?.delivery_address?.locations[index+1]? ", ": ""}</span>
                                    )):""}
                                  </span>
                                </td>

                            <td style={{paddingBottom: '20px'}}>
                              <h4 className="invoice-title-alt">Payment Method</h4>
                              <span
                                className="d-block">{this.state.orderDetails.payment_method?.name}</span>
                            </td>
                            <td style={{paddingBottom: '20px'}}>
                              <h4 className="invoice-title-alt">Payment Status</h4>
                              <span
                                className="d-block">{this.renderPaymentStatus(this.state.orderDetails.payment_status)}</span>
                            </td>

                            <td style={{paddingBottom: '20px'}}>
                              <span
                                className="d-block"><strong>Invoice:</strong> {this.state.orderDetails.order_parcels?.order_parcel_code}</span>
                              <span
                                className="d-block"><strong>D.O.P:</strong> {moment(this.state.orderDetails.createdAt).format('ll')}</span>
                              {/*<span className="d-block"><strong>P.O.:</strong>CB-123-325</span>
                              <span className="d-block"><strong>Due Date:</strong>12/03/2020</span>*/}
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>

                    <tr>
                      <td colSpan={2}>
                        <table className="table table-striped border">
                          <thead>
                          <tr>
                            <th colSpan={2}>Item</th>
                            <th>Quantity</th>
                            <th className="text-right">Amount (৳)</th>
                          </tr>
                          </thead>

                          <tbody>

                          {Object.keys(this.state.offers)?.map(item =>
                            this.showItemDetail(this.state.offers[item])
                          )}

                          <tr className="text-bold">
                            <td colSpan={2} className="text-right">Subtotal</td>
                            <td
                              className="text-right">{this.state.orderDetails.order_parcels?.parcel_total_price.toFixed(2)}</td>
                            <td/>
                          </tr>

                          <tr className="text-bold">
                            <td colSpan={2} className="text-right">Discount</td>
                            <td
                              className="text-right">{this.state.orderDetails?.order_parcels?.parcel_total_discount.toFixed(2)}</td>
                            <td/>
                          </tr>

                          <tr className="text-bold">
                            <td colSpan={2} className="text-right">Shipping Cost</td>
                            <td
                              className="text-right">{this.state.orderDetails?.order_parcels?.parcel_shipping_cost}</td>
                            <td/>
                          </tr>

                          <tr className="text-bold">
                            <td colSpan={2} className="text-right">Grand Total</td>
                            <td
                              className="text-right">{this.state.orderDetails?.order_parcels?.parcel_grand_total}</td>
                            <td/>
                          </tr>

                          {(this.state.orderDetails?.order_parcels?.parcel_wallet_amount > 0) &&
                          <tr className="text-bold">
                            <td colSpan={2} className="text-right">Paid by Wallet Amount
                            </td>
                            <td
                              className="text-right">{this.state.orderDetails?.order_parcels?.parcel_wallet_amount}</td>
                            <td/>
                          </tr>}

                          {/* {(this.state.orderDetails?.order_parcels?.parcel_total_paid > 0) &&
                          <tr className="text-bold">
                            <td colSpan={2} className="text-right">Total Paid</td>
                            <td
                              className="text-right">{this.state.orderDetails?.order_parcels?.parcel_total_paid}</td>
                            <td/>
                          </tr>} */}

                          {(this.state.orderDetails?.order_parcels?.parcel_wallet_amount > 0) &&
                          <tr className="text-bold">
                            <td colSpan={2} className="text-right">Due</td>
                            <td
                              className="text-right">{this.state.orderDetails?.order_parcels?.parcel_total_due}</td>
                            <td/>
                          </tr>}

                          {/*{this.state.orderDetails?.voucher_code_discount &&
                          <tr className="text-bold">
                            <td colSpan={2} className="text-right">Overall Ekshop Discount
                            </td>
                            <td
                              className="text-right">{this.state.orderDetails?.voucher_code_discount}</td>
                            <td/>
                          </tr>}*/}
                          {(getLocalAuthData()?.token && getLocalAuthData().type === "2") &&
                          <tr className="text-bold">
                            <td colSpan={2} className="text-right">Merchant Commission</td>
                            <td
                              className="text-right">{this.state.orderDetails?.order_parcels?.merchant_commission}</td>
                            <td/>
                          </tr>}

                          {(this.state.orderDetails?.order_parcels?.refund_amount > 0) &&
                          <tr className="text-bold">
                            <td colSpan={2} className="text-right">Refund Amount</td>
                            <td
                              className="text-right">{this.state.orderDetails?.order_parcels?.refund_amount}</td>
                            <td/>
                          </tr>}


                          {/*
                          <tr className="text-bold">
                            <td colSpan={2} className="text-right">Subtotal</td>
                            <td className="text-right">{this.state.orderDetails.order_parcels?.parcel_total_price}</td>
                          </tr>

                          <tr className="text-bold">
                            <td colSpan={2} className="text-right">Discount</td>
                            <td className="text-right">{this.state.orderDetails?.order_parcels?.parcel_total_discount}</td>
                          </tr>

                          {(this.state.orderDetails?.order_parcels?.parcel_wallet_amount > 0) && <tr className="text-bold">
                            <td colSpan={2} className="text-right">Wallet Amount</td>
                            <td className="text-right">৳{this.state.orderDetails?.order_parcels?.parcel_wallet_amount.toFixed(2)}</td>
                          </tr>}

                          <tr className="text-bold">
                            <td colSpan={2} className="text-right">Grand Total</td>
                            <td className="text-right">{this.state.orderDetails?.order_parcels?.parcel_grand_total}</td>
                          </tr> */}


                          </tbody>
                        </table>
                      </td>
                    </tr>
                    {/*<tr>
                      <td colSpan={2} style={{fontSize: '.875rem', lineHeight: 1.2}}>
                        <p className="text-muted mb-1">* Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
                        <p className="text-muted">* Lorem ipsum dolor sit amet, consectetur adipisicing elit. A,
                          officia!</p>
                      </td>
                    </tr>*/}
                    </tbody>
                  </table>
                  </div>

                  {this.state.orderDetails?.deposit_info &&
                  <>
                    <div className="alert alert-info">
                      <h6 className="d-block mb-3">Deposit Information</h6>
                      <Row>
                        <Col sm={6} lg={3}>
                          <Form.Group className={'mb-2'} controlId={'deposit_date'}>
                            <Form.Label>Bank Name</Form.Label>
                            <strong className="d-block">Bank Asia</strong>
                          </Form.Group>
                        </Col>
                        <Col sm={6} lg={3}>
                          <Form.Group className={'mb-2'} controlId={'deposit_date'}>
                            <Form.Label>Account Name</Form.Label>
                            <strong className="d-block">ASPIRE</strong>
                          </Form.Group>
                        </Col>
                        <Col sm={6} lg={3}>
                          <Form.Group className={'mb-2'} controlId={'deposit_date'}>
                            <Form.Label>Branch</Form.Label>
                            <strong className="d-block">BALBBDDH</strong>
                          </Form.Group>
                        </Col>
                        <Col sm={6} lg={3}>
                          <Form.Group className={'mb-2'} controlId={'deposit_date'}>
                            <Form.Label>Account Number</Form.Label>
                            <strong className="d-block">06933001008</strong>
                          </Form.Group>
                        </Col>
                        <Col sm={6} lg={3}>
                          <Form.Group className={'mb-2'} controlId={'deposit_date'}>
                            <Form.Label>Routing No</Form.Label>
                            <strong className="d-block">070264034</strong>
                          </Form.Group>
                        </Col>
                      </Row>
                    </div>
                    <div className="alert alert-info mb-2">
                      <Row>
                        <Col md={6}>
                          {/*account_number: "06933001008"
                                amount: "95243"
                                bank_name: "Bank Asia"
                                branch_name: "Gulshan"
                                deposit_by: "John Doe"
                                deposit_date: "2021-01-05T13:01:30.000Z"
                                deposit_slip: "https://ekshop-dev.s3-us-west-1.amazonaws.com/1609851712005_presentboxwithbigbowjpg.webp"
                                slip_number: "123456"*/}
                          <strong className="d-block mb-2">Bank Information</strong>
                          <table className="info-table bank-info"
                                 style={{backgroundColor: 'transparent'}}>
                            <tbody>
                            <tr>
                              <td style={{width: '200px'}}>Deposit By</td>
                              <td style={{width: '20px'}}>:</td>
                              <td>{this.state.orderDetails?.deposit_info?.deposit_by}</td>
                            </tr>
                            <tr>
                              <td style={{width: '200px'}}>Deposit Date</td>
                              <td style={{width: '20px'}}>:</td>
                              <td>{moment(this.state.orderDetails?.deposit_info?.deposit_date).format('ll')}</td>
                            </tr>
                            <tr>
                              <td style={{width: '200px'}}>Deposited Branch</td>
                              <td style={{width: '20px'}}>:</td>
                              <td>{this.state.orderDetails?.deposit_info?.branch_name}</td>
                            </tr>
                            <tr>
                              <td style={{width: '200px'}}>Slip Number</td>
                              <td style={{width: '20px'}}>:</td>
                              <td>{this.state.orderDetails?.deposit_info?.slip_number}</td>
                            </tr>
                            </tbody>
                          </table>
                        </Col>

                        <Col md={6}>
                          <strong className="d-block mb-2">Deposit Slip</strong>
                          <img src={this.state.orderDetails?.deposit_info?.deposit_slip}
                               style={{maxWidth: '100%', height: "auto"}} alt=""/>
                        </Col>
                      </Row>
                    </div>
                  </>
                  }

                </div>
              </div>

              <div className="card-block">
                <div className="block-header">
                  <h4 className="block-title">Delivery Progress</h4>
                </div>

                <div className="block-body">
                  <DeliveryProgressTimeline
                    data={this.state.orderDetails?.order_parcels?.order_status_progress}/>

                  <hr/>

                  <OrderActivities
                    data={this.state.orderDetails?.order_parcels?.order_activity_tracks}/>
                </div>
              </div>
            </>
          }
        </div>
      </App>
    );
  }
}

export default withRouter(OrderDetails);
