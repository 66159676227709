/* LOCALHOST */
// export const API_BASE_URL = "http://localhost:3002/api";
// export const HOMEPAGE = "http://localhost:3033/";
// export const STORE_URL = "http://localhost:3031/";
// export const ADMIN_URL = "http://localhost:3032/";
// export const SOCKET = 'http://localhost:4000/';


/* Development */
// export const API_BASE_URL = "https://api.merchant.sandbox.ekshop.gov.bd/api";
// export const HOMEPAGE = "https://ekshop-merchant.parallaxlogic.dev";
// export const STORE_URL = "https://ekshop.parallaxlogic.dev";
// export const ADMIN_URL = "https://admin.sandbox.ekshop.gov.bd/";
// export const SOCKET = 'https://socket.ekshop.gov.bd/';
// export const SOCKET = 'https://socket.parallaxlogic.dev/';

/*
* Live (Root) */
export const API_BASE_URL = "https://api-merchant.ekshop.gov.bd/api";
export const HOMEPAGE = "https://merchant.ekshop.gov.bd";
export const STORE_URL = "https://ekshop.gov.bd";
export const ADMIN_URL = "https://admin.ekshop.gov.bd/";
export const SOCKET = 'https://socket.ekshop.gov.bd';

/*
* GOTIPATH Live (Root) */
// export const API_BASE_URL = "https://api.ekshop.gov.bd:3003/api";
// export const HOMEPAGE = "https://merchant.ekshop.gov.bd";
// export const STORE_URL = "https://ekshop.gov.bd";
// export const ADMIN_URL = "https://admin.ekshop.gov.bd";
// export const SOCKET = 'https://socket.ekshop.gov.bd';

export const OTP_RESEND_TIME = 60
