import axios from "axios";
import {API_BASE_URL} from "../helper/env";


export const addCollection = (data) => {
  return new Promise((resolve, reject) => {
    axios.post(`${API_BASE_URL}/collection`, data)
      .then(res => {
        if (res.data.status === 1) {
          resolve(res.data)
        } else {
          reject(res.data.message)
        }
      })
      .catch(err => {
        reject(err.message)
      })
  })
}

export const getCollectionList = () => {
  return new Promise((resolve, reject) => {
    axios.get(`${API_BASE_URL}/collection`, {
      params: {
        page: '',
        limit: '',
        search_string: ''
      }
    })
      .then(res => {
        if (res.data.status === 1) {
          resolve(res.data.collections);
        } else {
          reject(res.data.message)
        }
      })
      .catch(err => {
        reject(err.message)
      })
  })
}

export const getSingleCollection = (id) => {
  return new Promise((resolve, reject) => {
    axios.get(`${API_BASE_URL}/collection/${id}`)
      .then(res => {
        if(res.data.status === 1) {
          resolve(res.data.collection);
        } else {
          reject(res.data.message)
        }
      })
      .catch(err => {
        reject(err.message)
      })
  })
}

export const updateCollection = (id, data) => {
  return new Promise((resolve, reject) => {
    axios.put(`${API_BASE_URL}/collection/${id}`, data)
      .then(res => {
        if(res.data.status === 1) {
          resolve(res.data)
        } else {
          reject(res.data.message)
        }
      })
      .catch(err => {
        reject(err.message)
      })
  })
}

export const deleteCollection = (id) => {
  return new Promise((resolve, reject) => {
    axios.delete(`${API_BASE_URL}/collection/${id}/permanent`)
      .then(res => {
        if (res.data.status === 1) {
          resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch(err => {
        reject(err.message);
      })
  })
}
