import axios from 'axios';
import {API_BASE_URL} from "../helper/env";
import {getDeviceType, getLocalAuthData, objectToFormData} from "../helper/utils";

let cancelToken;

export const addProduct = (data) => {
  const authData = getLocalAuthData();

  return new Promise((resolve, reject) => {
    axios.post(API_BASE_URL + '/product', data, {
      headers: {
        "x-auth-token": authData.token,
        "x-api-client": "web"
      }
    })
      .then(res => {
        if (res.data.status === 1) {
          resolve(res.data)
        } else {
          reject(res.data.message)
        }
      })
      .catch(err => {
        reject('Something went wrong')
      })
  })
}

export const updateProduct = (id, data) => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios.put(`${API_BASE_URL}/product/${id}`, data, {
      headers: {
        'x-auth-token': authData.token,
        'x-api-client': getDeviceType()
      }
    })
      .then(res => {
        if (res.data.status === 1) {
          resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch(err => {
        console.log(err);
        reject('Something went wrong')
      })
  })
}


export const getProductList = (paramsObj = {}) => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios.get(API_BASE_URL + `/product`,{
      headers: {
        'x-auth-token': authData.token,
        'x-api-client': getDeviceType()
      },
      params: paramsObj
    }).then(res => {
      if (res.data.status === 1 || res.data.status === 2) {
        resolve(res.data);
      }
    })
      .catch(err => {
        reject(err.message)
      })
  })
}

export const getProductDetails = (id) => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios.get(API_BASE_URL + `/product/${id}`, {
      headers: {
        'x-auth-token': authData.token,
        'x-api-client': getDeviceType()
      }
    }).then(res => {
      if (res.data.status === 1) {
        resolve(res.data.product);
      } else {
        reject(res.data.message);
      }
    })
      .catch(err => {
        reject(err.message);
      })
  })
}

export const replyQuestion = (id, formData) => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    let url = `${API_BASE_URL}/product-qa/${id}/save-answer`;
    axios.post(url, formData, {
      headers: {
        'x-auth-token': authData.token,
        'x-api-client': getDeviceType()
      }
    }).then(res => {
      if (res.data.status === 1) {
        resolve(res.data);
      } else {
        reject(res.data);
      }
    }).catch(errMsg => {
      reject(errMsg.message);
    })
  })
}

export const deleteProduct = (id) => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios.delete(`${API_BASE_URL}/product/${id}`, {
      headers: {
        'x-auth-token': authData.token,
        'x-api-client': getDeviceType()
      }
    })
      .then(res => {
        if (res.data.status === 1) {
          resolve(res.data.message)
        } else {
          reject(res.data.message)
        }
      })
      .catch(err => {
        reject(err.message)
      })
  })
}

export const hardDeleteProduct = (id) => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios.delete(`${API_BASE_URL}/product/${id}/permanent`, {
      headers: {
        'x-auth-token': authData.token,
        'x-api-client': getDeviceType()
      }
    })
      .then(res => {
        if (res.data.status === 1) {
          resolve(res.data.message)
        } else {
          reject(res.data.message)
        }
      })
      .catch(err => {
        reject(err.message)
      })
  })
}

export const changeProductStatus = (id,status) => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios.patch(`${API_BASE_URL}/product/${id}/change-status`, {status: status},{
      headers: {
        'x-auth-token': authData.token,
        'x-api-client': getDeviceType()
      }
    })
      .then(res => {
        if (res.data.status === 1) {
          resolve(res.data.message)
        } else {
          reject(res.data.message)
        }
      })
      .catch(err => {
        reject(err.message)
      })
  })
}

export const changeProductPublishStatus = (id,status) => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios.patch(`${API_BASE_URL}/product/${id}/change-publish-status`, {publish_status: status}, {
      headers: {
        'x-auth-token': authData.token,
        'x-api-client': getDeviceType()
      }
    })
      .then(res => {
        if (res.data.status === 1) {
          resolve(res.data.message)
        } else {
          reject(res.data.message)
        }
      })
      .catch(err => {
        reject(err.message)
      })
  })
}

export const hardDeleteQuestions = (id) => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios.delete(`${API_BASE_URL}/product-qa/${id}/permanent`, {
      headers: {
        'x-auth-token': authData.token,
        'x-api-client': getDeviceType()
      }
    })
      .then(res => {
        if (res.data.status === 1) {
          resolve(res.data.message)
        } else {
          reject(res.data.message)
        }
      })
      .catch(err => {
        reject(err.message)
      })
  })
}

export const bulkImport = (data) => {
  const authData = getLocalAuthData()
  return new Promise((resolve, reject) => {
    axios.post(`${API_BASE_URL}/product/import`, objectToFormData(data), {
      headers: {
        'x-auth-token': authData.token,
        'x-api-client': getDeviceType()
      }
    })
      .then(res => {
        if(res.data.status === 1) {
          resolve(res.data)
        } else {
          reject(res.data.message)
        }
      })
      .catch(err => {
        console.error(err)
        reject(`Something went wrong`)
      })
  })
}

export const getSkuProductList = (paramsObj = {}) => {
  const authData = getLocalAuthData();
  cancelToken = axios.CancelToken.source();

  return new Promise((resolve, reject) => {
    axios.get(API_BASE_URL + `/sku-product`, {
      headers: {
        'x-auth-token': authData.token,
        'x-api-client': getDeviceType()
      },
      params: paramsObj,
      cancelToken: cancelToken.token
    }).then(res => {
      if (res.data.status === 1 || res.data.status === 2) {
        resolve(res.data);
      }
    }).catch(err => {
      reject(err.message)
    })
  })
}
