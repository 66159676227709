import React, {Component} from 'react';
import App from "../../App";
import DateRangePicker from "react-bootstrap-daterangepicker";
import {Button, Form, Modal, Row, Col} from "react-bootstrap";
import moment from "moment";
import {Link, withRouter} from "react-router-dom";
import {getDisputes} from "../../services/disputeServices";
import Pagination from "react-js-pagination";
import LoadingSpinner from "../../components/LoadingSpinner";
import {getLocalAuthData} from "../../helper/utils";

class Disputes extends Component {
  state = {
    pagination: {},
    isLoading: true,
    settings: {
      user_type: 'merchant',
      // limit: 20
    },
    isDataPartLoading: false,
    dispute_list: [],
    isDisputeResolvedModalShow: false,
  }

  componentDidMount() {
    getDisputes()
      .then(res => {
        this.setState({dispute_list: res.item, isLoading: false}, () => {})
      })
      .catch(errMsg => {
        console.error(errMsg)
        this.setState({isLoading: false})
      });
  }

  renderBadge = (status) => {
    let badge_status = {
      Pending:"warning",
      Cancelled:"secondary",
      Closed:"success",
      Resolved:"success",
    }
    return badge_status[status];
  }

  getOrderStatus = (status) => {
    const payment_status = {
      0: "Unpaid",
      1: "Partially Paid",
      2: "Paid",
      3: "Cancelled",
      4: "Failed"
    }
    return payment_status[status];
  }

  handleDisputeResolveInfoModalOpen = (id, item_info) => {
    console.log(item_info);
    this.setState({
      isDisputeResolvedModalShow: true,
      item_info: item_info,
      item_id: id
    }, () => {
      console.log(this.state);
    });
  }
  handleDisputeResolveInfoModalClose = () => {
    this.setState({isDisputeResolvedModalShow: false})
  }

  render() {
    const {dispute_list} = this.state;
    return (
      <App layout="boxed">
        <div className="main-content">
          <div className="page-header">
            <h2 className="page-title">Dispute List</h2>
          </div>

          <div className="card-block position-relative">
            <div className="block-body">
              {!this.state.isLoading ?
                <React.Fragment>
                  {this.state.isDataPartLoading &&
                  <LoadingSpinner/>
                  }

                  <Modal show={this.state.isDisputeResolvedModalShow} onHide={this.handleDisputeResolveInfoModalClose}>
                    <Modal.Header closeButton>
                      <Modal.Title>Product Dispute Status</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <span>Message: Dispute {this.state?.item_info?.status}</span>

                      {this.state?.item_info?.status === 'Resolved' &&
                      <>
                        <br/><span>Method: {this.state?.item_info?.resolve_info?.method}</span>
                        {this.state?.item_info?.disputeResolveInfo?.method === 'MFS' &&
                        <>
                          <br/><span><strong>Mobile Number:</strong> {this.state?.item_info?.resolve_info?.mobile_number}</span>
                          <br/><span><strong>Operator Info:</strong> {this.state?.item_info?.resolve_info?.operator_name}</span>
                          <br/><span><strong>Account Type:</strong> {this.state?.item_info?.resolve_info?.account_type}</span>
                        </>
                        }
                        <br/><span>Note: {this.state?.item_info?.resolve_info?.note}</span>
                        <br/><span>Refund Amount: {this.state?.item_info?.refund_amount}</span>
                      </>
                      }
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="default" onClick={this.handleDisputeResolveInfoModalClose}>
                        Ok
                      </Button>
                    </Modal.Footer>
                  </Modal>

                  <table className="table table-striped">

                    <thead>
                    <tr>
                      <th style={{width: '1%'}}>#</th>
                      <th>Dispute Info</th>

                      <th>Customer Info</th>
                      <th>Reason</th>
                      <th>Store Info</th>
                      <th>Order Info</th>
                      <th>Product Info</th>
                      <th style={{width: '1%'}}/>
                    </tr>
                    </thead>

                    <tbody>

                    { dispute_list.length > 0 ?
                      dispute_list.map((item, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>
                            <strong>{item.dispute_id}</strong>
                            <br/><span>{moment(item.createdAt).format('ll')}</span>
                            <br/>Status: <span className={`badge badge-${this.renderBadge(item.status)}`}>{item.status}</span>
                          </td>


                          <td>
                            {item?.user_info?.name}
                            {item?.user_info?.phone && <><br/><span>{item?.user_info?.phone}</span></>}
                            {item?.user_info?.email && <><br/><span>{item?.user_info?.email}</span></>}
                          </td>


                          <td>
                            {item.message}
                          </td>


                          <td>
                            <Link to={`/stores/details/${item?.store_info?._id}`} target={"_blank"}>{item?.store_info?.store_name}</Link>
                          </td>

                          <td>
                            <Link to={`/orders/parcel/${item?.order_parcel_info?._id}`} target={"_blank"}>{item?.order_parcel_info?.order_parcel_code}</Link>
                            {item?.order_parcel_info && <><br/><span>Status: {item?.order_parcel_info?.status}</span></>}
                            {item?.order_parcel_info && <><br/><span>Payment Status: {this.getOrderStatus(item?.order_parcel_info?.payment_status)}</span></>}
                          </td>
                          <td><Link to={`/products/details/${item?.product_info?._id}`} target={"_blank"}>{item.product_info.name}</Link></td>
                          <td className={'nowrap'}>
                            {item?.status === 'Pending' &&
                            <><Button onClick={() => this.handleDisputeResolveInfoModalOpen(item?._id, item)} size={"sm"} variant="outline-secondary">Dispute Info</Button></>
                            }
                            {item?.status === 'Resolved' &&
                            <><Button onClick={() => this.handleDisputeResolveInfoModalOpen(item?._id, item)} size={"sm"} variant="outline-secondary">Dispute Info</Button></>
                            }
                          </td>
                        </tr>
                      )) :
                      <tr>
                        <td colSpan={6}>No dispute found</td>
                      </tr>
                    }

                    </tbody>
                  </table>

                </React.Fragment>
                :
                <div>
                  Loading...
                </div>
              }
            </div>
          </div>
        </div>
      </App>
    );
  }
}

export default withRouter(Disputes);
