import axios from "axios";
import {API_BASE_URL} from "../helper/env";
import {getDeviceType, getLocalAuthData} from "../helper/utils";

export const getMerchantDetails = () => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios.get(`${API_BASE_URL}/merchant/${authData.merchant_id}`, {
      headers: {
        "x-auth-token": authData.token,
        "x-api-client": "web"
      }
    })
      .then(res => {
        if(res.data.status === 1) {
          resolve(res.data.item)
        } else {
          reject(res.data.message)
        }
      })
      .catch(err => {
        reject(err.message)
      })
  })
}

export const updateProfile = (data) => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios.put(`${API_BASE_URL}/merchant/${authData.merchant_id}`, data, {
      headers: {
        "x-auth-token": authData.token,
        "x-api-client": "web"
      }
    })
      .then(res => {
        if(res.data.status === 1) {
          resolve(res.data)
        } else {
          reject(res.data.message)
        }
      })
      .catch(err => {
        console.log(err);
        reject('Something went wrong')
      })
  })
}
