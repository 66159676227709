import React, {Component} from 'react';
import App from "../../App";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import Moment from "moment";
import {Button, Dropdown, DropdownButton, Form, FormControl, InputGroup, Modal} from "react-bootstrap";

const today = Moment(new Date()).format('M/D/Y');

class Finance extends Component {
  state = {
    startDate: today,
    endDate: today,
    disburseModal: false
  }

  handleOnApply = (e, picker) => {
    const startDate = Moment(picker.startDate).format('M/D/Y');
    const endDate = Moment(picker.endDate).format('M/D/Y')
    this.setState({
      startDate: startDate,
      endDate: endDate,
    })
  }

  handleCloseDisburseModal = () => {
    this.setState({
      disburseModal: false
    })
  }

  handleShowDisburseModal = () => {
    this.setState({
      disburseModal: true
    })
  }

  render() {
    return (
      <App layout="boxed">
        <div className="main-content">
          <div className="card-block">
            <div className="block-header">
              <h4 className="block-title">Buyer Commission</h4>
            </div>

            <div className="block-body">
              <div className="d-flex">
                <div className="form-inline">
                  <DateRangePicker onApply={this.handleOnApply} startDate={this.state.startDate}
                                   endDate={this.state.endDate}>
                    <button
                      className="form-control form-control-sm">{`${this.state.startDate} to ${this.state.endDate}`}</button>
                  </DateRangePicker>

                  <select name="" className="form-control form-control-sm ml-2 mr-2">
                    <option value="">All Store</option>
                    <option value="1">Store one</option>
                    <option value="1">Store Two</option>
                    <option value="1">Store Three</option>
                  </select>

                  <Button size={"sm"} variant={"primary"}>Generate</Button>
                </div>

                <div className="ml-auto">
                  <DropdownButton variant={"default"} size={"sm"} id="dropdown-basic-button" title="Export">
                    <Dropdown.Item href="#/action-1">CSV</Dropdown.Item>
                    <Dropdown.Item href="#/action-2">EXCEL</Dropdown.Item>
                  </DropdownButton>
                </div>
              </div>

              <div className="commission-info-cards mt-4">
                <div className="commission-info">
                  <span className="title">Total Orders</span>
                  <span className="value">120</span>
                </div>
                <div className="commission-info">
                  <span className="title">Total Amount</span>
                  <span className="value">৳ 12,000.00</span>
                </div>
                <div className="commission-info">
                  <span className="title">Total Commission</span>
                  <span className="value">৳ 120.00</span>
                </div>
              </div>

              <Modal size={"lg"} show={this.state.disburseModal} onHide={this.handleCloseDisburseModal}>
                <Modal.Header closeButton>
                  <Modal.Title>Commission Disbursement</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <table className="table table-striped">
                    <thead>
                    <tr>
                      <th>Order Code</th>
                      <th>Total Order Value	(৳)</th>
                      <th>Total Commission (৳)</th>
                    </tr>
                    </thead>

                    <tbody>
                    <tr>
                      <td>PSEF19364</td>
                      <td>2,300.00</td>
                      <td>112.50</td>
                    </tr>
                    </tbody>
                  </table>

                  <table className="info-table table-lg">
                    <tbody>
                    <tr>
                      <td className="text-bold">Commission for the selected month (৳)</td>
                      <td className="text-bold">:</td>
                      <td className="text-bold">112.5</td>
                    </tr>
                    <tr>
                      <td className="text-bold">Commission due for previous month(s) (৳)</td>
                      <td className="text-bold">:</td>
                      <td className="text-bold">112.5</td>
                    </tr>
                    <tr>
                      <td className="text-bold">Total disbursement amount (৳)</td>
                      <td className="text-bold">:</td>
                      <td className="text-bold"><Form.Control size={"sm"}/></td>
                    </tr>
                    </tbody>
                  </table>

                  <p className="text-bold text-danger mb-2 mt-4">This udc does not have any Bkash/Rocket/Nagad/Bank Asia number</p>

                  <table className="info-table table-lg">
                    <tbody>
                    <tr>
                      <td className="text-bold">Select bKash/Rocket/Nagad/Bank Asia</td>
                      <td className="text-bold">:</td>
                      <td className="text-bold">
                        <InputGroup>
                          <InputGroup.Prepend>
                            <FormControl size={"sm"} as={"select"}>
                              <option value="1">bKash</option>
                              <option value="1">Roket</option>
                              <option value="1">Nagad</option>
                              <option value="1">Bank Asia</option>
                            </FormControl>
                          </InputGroup.Prepend>
                          <FormControl size={"sm"} aria-describedby="basic-addon1" />
                        </InputGroup>
                      </td>
                    </tr>
                    <tr>
                      <td className="text-bold">Transaction Number: <span className="text-danger">*</span></td>
                      <td className="text-bold">:</td>
                      <td className="text-bold"><Form.Control size={"sm"}/></td>
                    </tr>
                    <tr>
                      <td className="text-bold">Note</td>
                      <td className="text-bold">:</td>
                      <td className="text-bold"><Form.Control as={"textarea"} rows={3}/></td>
                    </tr>
                    </tbody>
                  </table>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="default" onClick={this.handleCloseDisburseModal}>
                    Close
                  </Button>
                  <Button variant="primary" onClick={this.handleCloseDisburseModal}>
                    Submit
                  </Button>
                </Modal.Footer>
              </Modal>

              <div className="mt-4">
                <table className="order-table table table-striped">
                  <thead>
                  <tr>
                    <th style={{width: '1%'}}>#</th>
                    <th>Buyer Info</th>
                    <th>Banking Numbers</th>
                    <th>Orders</th>
                    <th>Sales</th>
                    <th>Disbursed Commission (৳)</th>
                    <th>Outstanding Due (৳)</th>
                    <th>Total Commission (৳)</th>
                    <th style={{width: '1%'}}/>
                  </tr>
                  </thead>

                  <tbody>
                  <tr>
                    <td>1</td>
                    <td>
                      <div className="order-item">
                        <h4 className="order-id">Md. Jalil Uddin</h4>
                        <span className="order-date">+880123456789</span>
                      </div>
                    </td>
                    <td>0123456789</td>
                    <td>52</td>
                    <td>62,300</td>
                    <td>6,230</td>
                    <td>1,230</td>
                    <td>6,230</td>
                    <td style={{width: '1%'}}>
                      <Button
                        onClick={this.handleShowDisburseModal}
                        size={"sm"} variant="outline-secondary">Disburse</Button>
                    </td>
                  </tr>

                  <tr>
                    <td>2</td>
                    <td>
                      <div className="order-item">
                        <h4 className="order-id">Md. Jalil Uddin</h4>
                        <span className="order-date">+880123456789</span>
                      </div>
                    </td>
                    <td>0123456789</td>
                    <td>52</td>
                    <td>62,300</td>
                    <td>6,230</td>
                    <td>1,230</td>
                    <td>6,230</td>
                    <td style={{width: '1%'}}>
                      <Button
                        onClick={this.handleShowDisburseModal}
                        size={"sm"} variant="outline-secondary">Disburse</Button>
                    </td>
                  </tr>

                  <tr>
                    <td>3</td>
                    <td>
                      <div className="order-item">
                        <h4 className="order-id">Md. Jalil Uddin</h4>
                        <span className="order-date">+880123456789</span>
                      </div>
                    </td>
                    <td>0123456789</td>
                    <td>52</td>
                    <td>62,300</td>
                    <td>6,230</td>
                    <td>1,230</td>
                    <td>6,230</td>
                    <td style={{width: '1%'}}>
                      <Button
                        onClick={this.handleShowDisburseModal}
                        size={"sm"} variant="outline-secondary">Disburse</Button>
                    </td>
                  </tr>

                  <tr>
                    <td>4</td>
                    <td>
                      <div className="order-item">
                        <h4 className="order-id">Md. Jalil Uddin</h4>
                        <span className="order-date">+880123456789</span>
                      </div>
                    </td>
                    <td>0123456789</td>
                    <td>52</td>
                    <td>62,300</td>
                    <td>6,230</td>
                    <td>1,230</td>
                    <td>6,230</td>
                    <td style={{width: '1%'}}>
                      <Button
                        onClick={this.handleShowDisburseModal}
                        size={"sm"} variant="outline-secondary">Disburse</Button>
                    </td>
                  </tr>

                  <tr>
                    <td>5</td>
                    <td>
                      <div className="order-item">
                        <h4 className="order-id">Md. Jalil Uddin</h4>
                        <span className="order-date">+880123456789</span>
                      </div>
                    </td>
                    <td>0123456789</td>
                    <td>52</td>
                    <td>62,300</td>
                    <td>6,230</td>
                    <td>1,230</td>
                    <td>6,230</td>
                    <td style={{width: '1%'}}>
                      <Button
                        onClick={this.handleShowDisburseModal}
                        size={"sm"} variant="outline-secondary">Disburse</Button>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </App>
    );
  }
}

export default Finance;
