import React from "react";
import {Button, Col, Form, Modal, Row} from "react-bootstrap";
import slideSample from "../assets/img/slide-sample.jpg";
import "../assets/scss/main-slide.scss";
import {FiMove, FiPlus, FiTrash} from "react-icons/all";
import BrowseFiles from "./BrowseFiles";
import styleReactSelect from "../assets/styleReactSelect";
import Select from "react-select";

const PageTopBanners = ({
                            prps,
                            handleFormData,
                            handleImage,
                            handleSliderSubmitForm,
                            showSliderModal,
                            handleSliderModal,
                            deleteSlider,
                            storeList,
                            selectOnChange
                        }) => {
    return (
        <>
            <Modal show={showSliderModal} onHide={handleSliderModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Page Top Slide</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Form.Group as={Row} controlId="formPromotionName">
                        <Form.Label column sm="3">
                            Store <span className="text-danger">*</span>
                        </Form.Label>
                        <Col sm="9" md={8} lg={7}>
                            <Select
                                styles={styleReactSelect}
                                placeholder="Select store..."
                                onChange={(value) => selectOnChange(value, 'store')}
                                options={storeList}
                                name="attributes"
                                noOptionsMessage={() => 'Select a Store'}
                            />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="formPromotionName">
                        <Form.Label column sm="3">
                            Slide Image <span className="text-danger">*</span>
                        </Form.Label>
                        <Col sm="9" md={8} lg={7}>
                            <BrowseFiles
                                size={"sm"}
                                onGetFiles={handleImage}
                                helpText={"Image size 1500 x 556"}
                                image_category={'slider'}/>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="formPromotionName">
                        <Form.Label column sm="3">
                            Slide Link
                        </Form.Label>
                        <Col sm="9" md={9} lg={9}>
                            <Form.Control
                                type="text"
                                placeholder=""
                                name="link"
                                onChange={handleFormData}
                            />
                        </Col>
                    </Form.Group>

                </Modal.Body>

                <Modal.Footer>
                    <Button variant="default" onClick={handleSliderModal}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleSliderSubmitForm}>
                        Save
                    </Button>
                </Modal.Footer>

            </Modal>

            <Row>

                {prps?.length > 0 ? prps.map((slider, index) => {
                    return (
                        <Col md={3} key={index}>
                            <div className="page-top-banner">
                                <img src={slider.image} alt=""/>
                                <div className="banner-actions">
                                    <div className="actions">
                                        <span className="action" onClick={() => deleteSlider(slider._id)}><FiTrash/></span>
                                        <span className="action"><FiMove/></span>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    );
                }) : "No Slider Found"}

                <Col md={3}>
                    <div onClick={handleSliderModal} className="main-slide btn-add-slide">
                        <img src={slideSample} alt=""/>
                        <div className="slide-actions">
                            <div className="actions">
                                <span className="action"><FiPlus/></span>
                            </div>
                        </div>
                    </div>
                </Col>

            </Row>
        </>
    )
}

export default PageTopBanners;