import React from 'react';
import {FiFileText} from "react-icons/all";
import "../assets/scss/info-card.scss";

const InfoCard = ({icon, title, value, className}) => {
  return (
    <div className={className ? `${className} info-card` : 'info-card'}>
      {icon &&
      <span className={'icon'}>
        {icon}
      </span>
      }
      {value &&
        <span className="value">{value}</span>
      }

      {title && <h3 className="title">{title}</h3>}
    </div>
  );
};

export default InfoCard;