import React from "react";
import {Spinner} from "react-bootstrap";
import "../assets/scss/loading-spinner.scss";

const LoadingSpinner = ({variant, position, size, layout, text, ...rest}) => {
  return (
    <div {...rest}
         style={{backgroundColor: 'rgba(255, 255, 255, .8)', color: '#252525'}}
         className={`loading-spinner ${position || 'absolute'}  ${layout || ''}`}>
      <div className={"loading-inner-content"}>
        <Spinner animation="border" size={size ? size : ''} variant={variant ? variant : 'default'} /> <br/>
        <span className="loading-text">{text ? text : 'Loading...'}</span>
      </div>
    </div>
  )
}

export default LoadingSpinner;
