import React, {Component} from 'react';
import Rating from "react-rating";
import star from "../assets/img/star.svg";
import starFull from "../assets/img/star-full.svg";
import {GoReply, GoVerified, IoMdClose} from "react-icons/all";
import {Button, Form} from "react-bootstrap";
import {replyOnReview} from "../services/reviewServices";
import {toast} from "react-toastify";
import moment from "moment";

class Review extends Component {
  state = {
    isShowingReplyForm: false,
    replyFormData: {
      review_id: this.props.data._id
    },
    isReplying: false,
    isFormValidated: false
  }

  handleToggleReplyForm = () => {
    this.setState({
      isShowingReplyForm: !this.state.isShowingReplyForm
    })
  }

  handleCloseReplyForm = () => {
    this.setState({
      isShowingReplyForm: false
    })
  }

  render() {
    const {data, getReviews} = this.props;

    return (
      <div className="product-review">
        <div className="review-header">
          <div className="review-info">
            <Rating
              className="review-rating rating-sm mr-3"
              readonly={true}
              emptySymbol={
                <img src={star} className="rating-icon" alt=""/>
              }
              fullSymbol={
                <img
                  src={starFull}
                  className="rating-icon"
                  alt=""
                />
              }
              initialRating={data.score}/>

            <span className="reviewer">
              by {data.user?.name ? data.user?.name : 'Anonymous'}
              <span className={'ml-2 text-success'}><GoVerified/></span>
            </span>

            <small className="text-muted">{moment(data.createdAt).fromNow()}</small>
          </div>

          <small>
            <span onClick={this.handleToggleReplyForm} className="text-info d-inline-block">
              {this.state.isShowingReplyForm ? <IoMdClose/> : <GoReply/>} REPLY</span>
            {/*<span className="ml-3 text-danger d-inline-block"><FiTrash/> DELETE</span>*/}
          </small>
        </div>

        <div className="review-body">
          <div className="review">
            <p className="review-text">{data.review_text}</p>
            {data.images && <div className="review-photos">
              {data.images.map((img, index) => (
                <div key={index} className="review-photo">
                  <img
                    src={img.mid_img}
                    alt=""/>
                </div>
              ))}
            </div>}
          </div>

          {data.review_replies.length > 0 &&
          data.review_replies.map((reply, index) => (
            <div key={index} className={index === 0 ? 'has-arrow review-reply' : 'review-reply'}>
              <div className="store-reply">
                reply by <strong>{reply?.user.name === 'Super Admin' ? 'Admin' : reply?.user.name}</strong>, {moment(reply.createdAt).fromNow()}</div>
              <div className="reply-text">{reply?.message || reply?.review_text}</div>
            </div>
          ))
          }

          {this.state.isShowingReplyForm &&
          <Form className="mt-3"
                noValidate
                validated={this.state.isFormValidated}
                onSubmit={(e) => {
                  e.preventDefault()
                  const form = e.currentTarget;
                  if (form.checkValidity() === false) {
                    e.preventDefault();
                    e.stopPropagation();
                    this.setState({isFormValidated: true})
                  } else {
                    replyOnReview(this.state.replyFormData)
                      .then(res => {
                        toast.success(res.message)
                        getReviews()
                        this.setState({isShowingReplyForm: false})
                      })
                      .catch(errMsg => {
                        toast.error(errMsg)
                      })
                  }
                }}>
            <Form.Group controlId="reviewReply">
              <Form.Control rows={3}
                            name={'reply_message'}
                            required
                            minLength={3}
                            onChange={(e) => {
                              const replyFormData = {...this.state.replyFormData}
                              replyFormData[e.target.name] = e.target.value
                              this.setState({replyFormData})
                            }} as="textarea" placeholder="Reply"/>
              <Form.Control.Feedback type="invalid">
                Please provide a valid reply.
              </Form.Control.Feedback>
            </Form.Group>

            <div className="text-right">
              <Button onClick={this.handleCloseReplyForm} className="mr-2" variant={"default"}>Close</Button>
              <Button type={'submit'}
                      disabled={this.state.isReplying}
                      variant={"primary"}>{this.state.isReplying ? 'Submitting Reply' : 'Reply Now'}</Button>
            </div>
          </Form>
          }
        </div>
      </div>
    );
  }
}

export default Review;
