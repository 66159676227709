import React, {Component} from 'react';
import App from "../../App";
import {Button, Col, Form, Modal, Row} from "react-bootstrap";
import {Link, withRouter} from "react-router-dom";
import {FaLongArrowAltLeft, IoMdCheckmark} from "react-icons/all";
// import DatetimeRangePicker from "react-datetime-range-picker";
import Select from "react-select";
import styleReactSelect from "../../assets/styleReactSelect";
import {getProductList} from "../../services/productServices";
import {getStoreList} from "../../services/storeServices";
import DateRangePicker from "react-bootstrap-daterangepicker";
import Moment from "moment";
import {getBundleOfferDetails, submitBundleOffer, updateBundleOffer} from "../../services/promotionServices";
import {toast} from "react-toastify";

const today = Moment(new Date()).format('Y-M-D');


class EditBundleOffer extends Component {
  state = {
    formData: {
      promotion_type: '1',
      apply_to: '2',
      start_time: today,
      end_time: today,
    },
    bundleOffer: {},
    isProductModalProductShowing: false,
    products: [],
    storeList: [],
    gift_products: [],
    productList: [],
    isLoading: true,
    productType: '',
    selectedProducts: [],
    isFormValidated: false,
    isSubmitting: false,
    startDate: today,
    endDate: today,
    selectedStores: []
  }

  componentDidMount() {
    let bundle_id = this.props.match.params.id;

    const storeList = getStoreList()
      .then(res => {
        const storeList = [...this.state.storeList];
        res.items.forEach(item => {
          const store = {
            id: item._id,
            value: item._id,
            label: item.store_name
          }
          storeList.push(store)
          this.setState({storeList})
        })
      })

    const productList = getProductList()
      .then(res => {
        this.setState({productList: res.products}, () => {
          getBundleOfferDetails(bundle_id)
            .then(res => {
              const formData = {
                offer_name: res.offer_name,
                start_time: Moment(res.start_time).format('Y-M-D'),
                end_time: Moment(res.end_time).format('Y-M-D'),
                promotion_type: res?.promotion_type,
                discount_type: res?.discount_type,
                maximum_discount_amount: res?.maximum_discount_amount,
                offer_value: res?.offer_value,
                product_type: res?.product_type,
                products: res?.products,
                gift_products: res?.gift_products,
                apply_to: res?.apply_to,
                stores: res?.stores,
                product_skus: res?.product_skus,
                minimum_spend_amount: res?.minimum_spend_amount
              }

              const products = []
              const gift_products = []
              const selectedStores = []

              res.products.forEach(item => {
                const product = this.state.productList.filter(obj => {
                  return obj._id === item
                })
                products.push(product[0])
              })

              res.gift_products.forEach(item => {
                const product = this.state.productList.filter(obj => {
                  return obj._id === item
                })
                if(product.length > 0) {
                  gift_products.push(product[0])
                }
              })

              console.log('dd: ', gift_products)

              res.stores.forEach(item => {
                const store = this.state.storeList.filter(obj => {
                  return obj.id === item
                });
                selectedStores.push(store[0])
              })

              this.setState({
                formData,
                products,
                gift_products,
                selectedStores,
                isLoading: false
              })
            })
            .catch(errMsg => {
              console.log(errMsg);
              this.setState({isLoading: false})
            })
        })
      })
      .catch(errMsg => {
        console.log(errMsg)
      })

    Promise.all([storeList, productList])
      .then(res => {
        this.setState({isLoading: false})
      })
      .catch((err) => {
        this.setState({isLoading: false})
      })
  }

  handlePromotionTypeOnChange = (e) => {
    const formData = {...this.state.formData}
    formData.products = []
    formData.gift_products = []
    formData[e.target.name] = e.target.value
    this.setState({formData, products: [], gift_products: []})
  }

  handleInputOnChange = (e) => {
    const formData = {...this.state.formData};
    formData[e.target.name] = e.target.value;
    this.setState({formData})
  }

  handleOfferTypeOnChange = (e) => {
    const formData = {...this.state.formData};
    formData.offer_value = [{
      item: '',
      discount_value: ''
    }];
    formData[e.target.name] = e.target.value;
    this.setState({formData})
  }

  addMoreOfferValue = () => {
    const formData = {...this.state.formData};
    formData.offer_value.push({
      item: '',
      discount_value: ''
    });
    this.setState({formData})
  }

  handleRemoveItem = (index) => {
    const formData = {...this.state.formData};
    formData.offer_value.splice(index, 1);
    this.setState({formData})
  }

  handleSelectMultiOnChange = (array, name) => {
    const formData = {...this.state.formData};
    const data = [];
    if (array) {
      array.forEach(val => {
        data.push(val.id)
      })
    }
    formData[name] = data;
    this.setState({formData})
  }

  handleOnChangeApplicableFor = (e) => {
    const formData = {...this.state.formData}
    formData.apply_to = e.target.value
    this.setState({
      formData,
    })
    if (e.target.value === '6') {
      this.setState({isApplyForCat: true})
    } else {
      this.setState({isApplyForCat: false})
    }
  }

  handleShowSelectProductModal = (type) => {
    this.setState({isProductModalProductShowing: true, productType: type})
  }

  handleHideProductModal = () => {
    this.setState({isProductModalProductShowing: false})
  }

  handleAddProduct = (productType, selectedProducts) => {
    const formData = {...this.state.formData};
    formData[productType] = selectedProducts;

    this.setState({formData}, () => {
      const products = []
      selectedProducts.forEach(item => {
        const product = this.state.productList.filter(obj => {
          return obj._id === item
        })
        products.push(product[0])
      })
      this.setState({
        [productType]: products,
        isProductModalProductShowing: false,
        selectedProducts: []
      })
    })
  }

  handleSelectedProductOnChange = (e, id) => {
    const selectedProducts = [...this.state.selectedProducts];

    if (e.target.checked) {
      if (e.target.type === 'checkbox') {
        selectedProducts.push(id);
      } else {
        selectedProducts[0] = id;
      }
    } else {
      selectedProducts.splice(selectedProducts.indexOf(id), 1);
    }

    this.setState({selectedProducts});
  }

  handleRemoveSelectedProduct = (type, index) => {
    const selectedProducts = [...this.state[type]]
    selectedProducts.splice(index, 1);
    this.setState({[type]: selectedProducts})
  }

  handleOnApply = (e, picker) => {
    const formData = {...this.state.formData};
    const startDate = Moment(picker.startDate).format('Y-M-D');
    const endDate = Moment(picker.endDate).format('Y-M-D')

    formData.start_time = startDate;
    formData.end_time = endDate;

    this.setState({
      formData,
      startDate: startDate,
      endDate: endDate,
    })
  }

  handleDiscountValueOnChange = (e, index) => {
    const formData = {...this.state.formData};
    formData.offer_value[index][e.target.name] = e.target.value;
    this.setState({formData})
  }

  handleSubmitForm = (e) => {
    e.preventDefault();
    const offerId = this.props.match.params.id;

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      this.setState({isFormValidated: true})
    } else {
      updateBundleOffer(offerId, this.state.formData)
        .then(res => {
          this.props.history.push(`${process.env.PUBLIC_URL}/promotions/bundle-offer`);
          toast.success(res.message);
        })
        .catch(errMsg => {
          toast.error(errMsg)
        })
      this.setState({isSubmitting: true});
      setTimeout(() => {
        this.setState({isSubmitting: false, isFormValidated: false})
      }, 2000)
    }
  }

  render() {
    return (
      <App layout="boxed">
        <div className="page-header">
          <h2 className="page-title">Edit Bundle Offer</h2>
          <div className="ml-auto">
            <Link to={`${process.env.PUBLIC_URL}/promotions/bundle-offer`}
                  className="btn btn-sm btn-link"><FaLongArrowAltLeft/> Back to Bundle
              Offer List</Link>
          </div>
        </div>

        <div className="main-content">
          {!this.state.isLoading ?
            <Form
              noValidate validated={this.state.isFormValidated}
              onSubmit={this.handleSubmitForm}>
              <div className="card-block">
                <div className="block-header">
                  <h4 className="block-title">Define Offer</h4>
                </div>

                <div className="block-body">
                  <Form.Group as={Row} controlId="formPromotionName">
                    <Form.Label column sm="3">
                      Promotion Name <span className="text-danger">*</span>
                    </Form.Label>
                    <Col sm="9" md={8} lg={7}>
                      <Form.Control type="text"
                                    onChange={this.handleInputOnChange}
                                    name="offer_name"
                                    defaultValue={this.state.formData?.offer_name}
                                    required
                                    placeholder=""/>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} controlId="formStartFrom">
                    <Form.Label column sm="3">
                      Date Range <span className="text-danger">*</span>
                    </Form.Label>
                    <Col sm="9" md={8} lg={7}>
                      <DateRangePicker containerStyles={{textAlign: 'left', width: '100%', display: 'block'}}
                                       onApply={this.handleOnApply}>
                        <input type="button"
                               value={`${this.state.formData?.start_time} to ${this.state.formData?.end_time}`}
                               style={{textAlign: 'left'}}
                               className="form-control"/>
                      </DateRangePicker>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} controlId="formVoucherType">
                    <Form.Label column sm="3">
                      Promotion Type <span className="text-danger">*</span>
                    </Form.Label>
                    <Col sm="9" md={8} lg={7}>
                      <Form.Control as="select"
                                    required
                                    value={this.state.formData?.promotion_type}
                                    name={`promotion_type`}
                                    onChange={this.handlePromotionTypeOnChange} placeholder="">
                        <option value="">Quantity</option>
                        <option value="2">Buy 1 Get 1</option>
                        <option value="3">Free Gift</option>
                      </Form.Control>
                    </Col>
                  </Form.Group>
                </div>
              </div>

              <div className="card-block">
                <div className="block-body">
                  <Form.Group as={Row} controlId="applicableFor">
                    <Form.Label column sm="3">
                      Applicable For <span className="text-danger">*</span>
                    </Form.Label>
                    <Col sm="9" md={8} lg={7}>
                      <Form.Control
                        required
                        name="apply_to"
                        value={this.state.formData?.apply_to}
                        onChange={this.handleOnChangeApplicableFor}
                        as="select" placeholder="">
                        <option value="">--Select--</option>
                        <option value="2">Specific Stores</option>
                      </Form.Control>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId="forStores">
                    <Form.Label column sm="3">
                      Store(s) <span className="text-danger">*</span>
                    </Form.Label>
                    <Col sm="9" md={8} lg={7}>
                      {(this.state.formData?.stores?.length === 0 || this.state.selectedStores.length > 0) &&
                      <Select
                        isMulti
                        required
                        defaultValue={this.state.selectedStores}
                        styles={styleReactSelect}
                        placeholder="Select Attribute..."
                        onChange={(value) => {
                          this.handleSelectMultiOnChange(value, 'stores');
                        }}
                        options={this.state.storeList}
                        name="attributes"
                        noOptionsMessage={() => 'Select store...'}
                      />
                      }
                    </Col>
                  </Form.Group>
                  {this.state.isApplyForCat &&
                  <Form.Group as={Row} controlId="forCategories">
                    <Form.Label column sm="3">
                      Categories <span className="text-danger">*</span>
                    </Form.Label>
                    <Col sm="9" md={8} lg={7}>
                      <Select
                        isMulti
                        // value={this.state.attribute}
                        styles={styleReactSelect}
                        placeholder="Select Attribute..."
                        onChange={(value) => {
                          this.handleSelectMultiOnChange(value, 'categories')
                        }}
                        options={[{
                          id: 1,
                          value: 'Men\'s Fashion',
                          label: 'Men\'s Fashion'
                        },
                          {
                            id: 2,
                            value: 'Electronic',
                            label: 'Electronic'
                          }]}
                        name="attributes"
                        noOptionsMessage={() => 'Select store...'}
                      />
                    </Col>
                  </Form.Group>}
                </div>
              </div>

              {this.state.formData?.promotion_type === '1' ?
                <>
                  <div className="card-block">
                    <div className="block-header">
                      <h4 className="block-title">Discount Details</h4>
                    </div>

                    <div className="block-body">
                      <Form.Group as={Row} controlId="formBundleDiscountType">
                        <Form.Label column sm="3">
                          Discount Type
                        </Form.Label>
                        <Col sm="9" md={8} lg={7}>
                          {this.state.formData?.discount_type &&
                          <>
                            <Form.Check
                              custom
                              inline
                              required
                              type="radio"
                              value={`1`}
                              defaultChecked={this.state.formData?.discount_type === '1'}
                              onChange={this.handleOfferTypeOnChange}
                              name="discount_type"
                              id={`money-value-on-quantity`}
                              label={`Money Value`}
                            />
                            <Form.Check
                              custom
                              inline
                              required
                              value={`2`}
                              defaultChecked={this.state.formData?.discount_type === "2"}
                              onChange={this.handleOfferTypeOnChange}
                              type="radio"
                              name="discount_type"
                              id={`percentage-on-quantity`}
                              label={`Percentage`}
                            />
                          </>
                          }
                        </Col>
                      </Form.Group>

                      {this.state.formData?.offer_value?.length > 0 &&
                      <>
                        {this.state.formData?.discount_type === '2' ?
                          <>
                            <table className={`table table-striped`}>
                              <thead className={`sm`}>
                              <tr>
                                <th>Item Quantity</th>
                                <th>Discount Amount (%)</th>
                                <th style={{width: '1%'}}/>
                              </tr>
                              </thead>
                              <tbody>
                              {this.state.formData?.offer_value.map((offer, index) => (
                                <tr key={index}>
                                  <td>
                                    <Form.Control type={`number`}
                                                  name={`item`}
                                                  required
                                                  defaultValue={this.state.formData?.offer_value[index].discount_value}
                                                  onChange={(e) => this.handleDiscountValueOnChange(e, index)}
                                                  placeholder={`Quantity`} size={`sm`}/>
                                  </td>
                                  <td>
                                    <Form.Control type={`number`}
                                                  name={`discount_value`}
                                                  required
                                                  defaultValue={this.state.formData?.offer_value[index].discount_value}
                                                  onChange={(e) => this.handleDiscountValueOnChange(e, index)}
                                                  placeholder={`Percentage`} size={`sm`}/>
                                  </td>
                                  <td>
                                    <span className={`text-danger`}
                                          onClick={() => this.handleRemoveItem(index)}
                                          style={{fontWeight: 'bold', cursor: 'pointer'}}>&times;</span>
                                  </td>
                                </tr>
                              ))}
                              </tbody>
                            </table>

                            <span style={{fontWeight: 'bold', cursor: `pointer`}}
                                  onClick={this.addMoreOfferValue}
                                  className={`text-primary`}>+ Add more</span>

                            <hr/>

                            <Form.Group as={Row} controlId="formBundleDiscountType">
                              <Form.Label column sm="3">
                                Maximum Discount Amount
                              </Form.Label>
                              <Col sm="9" md={8} lg={7}>
                                <Form.Control
                                  onChange={this.handleInputOnChange}
                                  name="maximum_discount_amount"
                                  defaultValue={this.state.formData?.maximum_discount_amount}
                                  required placeholder={`Maximum discount amount`}/>
                              </Col>
                            </Form.Group>
                          </>
                          :
                          <>
                            <table className={`table table-striped`}>
                              <thead className={`sm`}>
                              <tr>
                                <th>Item Quantity</th>
                                <th>Discount Amount</th>
                                <th style={{width: '1%'}}/>
                              </tr>
                              </thead>
                              <tbody>
                              {this.state.formData?.offer_value.map((offer, index) => (
                                <tr key={index}>
                                  <td>
                                    <Form.Control type={`number`}
                                                  name={`item`}
                                                  required
                                                  defaultValue={offer.discount_value}
                                                  onChange={(e) => this.handleDiscountValueOnChange(e, index)}
                                                  placeholder={`Quantity`} size={`sm`}/>
                                  </td>
                                  <td>
                                    <Form.Control type={`number`}
                                                  name={`discount_value`}
                                                  required
                                                  defaultValue={offer.discount_value}
                                                  onChange={(e) => this.handleDiscountValueOnChange(e, index)}
                                                  placeholder={`Percentage`} size={`sm`}/>
                                  </td>
                                  <td><span className={`text-danger`}
                                            onClick={() => this.handleRemoveItem(index)}
                                            style={{fontWeight: 'bold', cursor: 'pointer'}}>&times;</span></td>
                                </tr>
                              ))}
                              </tbody>
                            </table>

                            <span style={{fontWeight: 'bold', cursor: `pointer`}}
                                  onClick={this.addMoreOfferValue}
                                  className={`text-primary`}>+ Add more</span>

                            <hr/>

                            <Form.Group as={Row} controlId="formBundleDiscountType">
                              <Form.Label column sm="3">
                                Minimum Spend Amount
                              </Form.Label>
                              <Col sm="9" md={8} lg={7}>
                                <Form.Control
                                  onChange={this.handleInputOnChange}
                                  defaultValue={this.state.formData?.minimum_spend_amount}
                                  name="minimum_spend_amount"
                                  required placeholder={`Minimum discount amount`}/>
                              </Col>
                            </Form.Group>
                          </>}
                      </>
                      }
                    </div>
                  </div>
                </>
                :
                <>
                  <Modal show={this.state.isProductModalProductShowing}
                         onHide={this.handleHideProductModal}>
                    <Modal.Header closeButton>
                      <Modal.Title>Select Product</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className="mb-3 form-inline">
                        <Form.Group>
                          <Form.Label srOnly>Search</Form.Label>
                          <Form.Control type={`search`}
                                        size={`sm`}
                                        placeholder={`Search...`}/>
                        </Form.Group>

                        <Button type={`button`} size={`sm`} variant={`info`} className={`ml-2`}>Search</Button>
                      </div>

                      <div className="product-checkbox-list">
                        {this.state.productList.map((product, index) => (
                          <label className={`product-item`} key={index} htmlFor={`product-${index}`}>
                            {this.state.formData.promotion_type === '3' ?
                              <input id={`product-${index}`}
                                     name={`product`}
                                     onChange={e => this.handleSelectedProductOnChange(e, product._id)}
                                     type="radio"/>
                              :
                              <input id={`product-${index}`}
                                     onChange={e => this.handleSelectedProductOnChange(e, product._id)}
                                     name={`product`}
                                     type="checkbox"/>
                            }
                            <span className={`product`}>
                            <span className="product-img">
                              <img src={product.thumb_img} alt=""/>
                            </span>
                            <span className="product-short-info">
                              <span className="product-name">{product.name}</span>
                            </span>
                            <span className="icon"><IoMdCheckmark/></span>
                          </span>
                          </label>
                        ))}
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={this.handleHideProductModal}>
                        Close
                      </Button>
                      <Button variant="primary"
                              onClick={() => this.handleAddProduct(this.state.productType, this.state.selectedProducts)}>
                        Save Changes
                      </Button>
                    </Modal.Footer>
                  </Modal>

                  <div className="card-block">
                    <div className="block-header">
                      <h4 className="block-title">Product</h4>
                    </div>

                    <div className="block-body">
                      <table className={`table table-striped`}>
                        <thead className={`sm`}>
                        <tr>
                          <th style={{width: '50px'}}/>
                          <th>Product Name</th>
                          <th style={{width: '1%'}}/>
                        </tr>
                        </thead>

                        <tbody>
                        {this.state.products.length > 0 ?
                          <>
                            {this.state.products.map((product, index) => (
                              <tr key={index}>
                                <td>
                                  <img src={product?.thumb_img} style={{width: '40px'}} alt=""/>
                                </td>
                                <td>{product?.name}</td>
                                <td><span style={{cursor: 'pointer'}}
                                          onClick={() => this.handleRemoveSelectedProduct('products', index)}
                                          className={`text-bold text-danger`}>&times;</span></td>
                              </tr>
                            ))}
                          </>
                          :
                          <tr>
                            <td colSpan={3} className={`text-center text-bold`}>
                              No product selected
                            </td>
                          </tr>
                        }
                        </tbody>
                      </table>

                      <Button size="sm"
                              onClick={() => this.handleShowSelectProductModal('products')}
                              variant="primary"> Select Product</Button>
                    </div>
                  </div>

                  {this.state.formData.promotion_type === '3' &&

                  <div className="card-block">
                    <div className="block-header">
                      <h4 className="block-title">Gift Product</h4>
                    </div>

                    <div className="block-body">
                      <table className={`table table-striped`}>
                        <thead className={`sm`}>
                        <tr>
                          <th style={{width: '50px'}}/>
                          <th>Product Name</th>
                          <th style={{width: '1%'}}/>
                        </tr>
                        </thead>

                        <tbody>
                        {this.state.gift_products.length > 0 ?
                          <>
                            {this.state.gift_products.map((product, index) => (
                              <tr key={index}>
                                <td>
                                  <img src={product?.thumb_img} style={{width: '40px'}} alt=""/>
                                </td>
                                <td>{product?.name}</td>
                                <td><span style={{cursor: 'pointer'}}
                                          onClick={() => this.handleRemoveSelectedProduct('gift_products', index)}
                                          className={`text-bold text-danger`}>&times;</span></td>
                              </tr>
                            ))}
                          </>
                          :
                          <tr>
                            <td colSpan={3} className={`text-center text-bold`}>
                              No product selected
                            </td>
                          </tr>
                        }
                        </tbody>
                      </table>
                      <Button size="sm"
                              onClick={() => this.handleShowSelectProductModal('gift_products')}
                              variant="primary"> + Add Product</Button>
                    </div>
                  </div>
                  }
                </>
              }

              <div className="card-block">
                <div className="block-body text-right">
                  <Link to={`/promotions/bundle-offer`} className="btn btn-lg btn-default mr-3">Cancel</Link>
                  <Button size="lg" type={`submit`}
                          disabled={this.state.isSubmitting}
                          variant="primary">{this.state.isSubmitting ? 'Updating...' : 'Update'}</Button>
                </div>
              </div>
            </Form>
            :
            <div className="card-block">
              <div className="block-body">
                Loading...
              </div>
            </div>
          }
        </div>
      </App>
    );
  }
}

export default withRouter(EditBundleOffer);
