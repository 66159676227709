import React from 'react';
import {Link} from "react-router-dom";
import {
  AiOutlineOrderedList, AiOutlinePercentage,
  AiOutlineTransaction,
    FiBox,
  FiTag, FiUserPlus,
} from "react-icons/all";
import "../assets/scss/report-grid.scss";

import {AiOutlineUserSwitch , BiSearchAlt ,HiOutlineClipboardList} from "@react-icons/all-files/ai/AiOutlineUserSwitch";




const ReportGrid = () => {
  return (
    <div className={'nav-item-grid'}>
      <div className="nav-col">
        <Link className={'nav-item-box'} to={'/reports/summary'}>
                  <span className="nav-icon">
                    <HiOutlineClipboardList/>
                  </span>
          <span className="nav-text">Summery Reports</span>
        </Link>
      </div>

      <div className="nav-col">
        <Link className={'nav-item-box'} to={'/reports/transactions'}>
                  <span className="nav-icon">
                    <AiOutlineTransaction/>
                  </span>
          <span className="nav-text">Transaction Reports</span>
        </Link>
      </div>

      <div className="nav-col">
        <Link className={'nav-item-box'} to={'/reports/order'}>
                  <span className="nav-icon">
                    <AiOutlineOrderedList/>
                  </span>
          <span className="nav-text">Orders Reports</span>
        </Link>
      </div>

      <div className="nav-col">
        <Link className={'nav-item-box'} to={'/reports/seller'}>
                  <span className="nav-icon">
                    <AiOutlineUserSwitch/>
                  </span>
          <span className="nav-text">Seller Reports</span>
        </Link>
      </div>

      <div className="nav-col">
        <Link className={'nav-item-box'} to={'/reports/category'}>
                  <span className="nav-icon">
                    <FiTag/>
                  </span>
          <span className="nav-text">Category Reports</span>
        </Link>
      </div>

      <div className="nav-col">
        <Link className={'nav-item-box'} to={'/reports/commission'}>
                  <span className="nav-icon">
                    <AiOutlinePercentage/>
                  </span>
          <span className="nav-text">Commission Reports</span>
        </Link>
      </div>

      <div className="nav-col">
        <Link className={'nav-item-box'} to={'/reports/search'}>
                  <span className="nav-icon">
                    <BiSearchAlt/>
                  </span>
          <span className="nav-text">Search Reports</span>
        </Link>
      </div>

      <div className="nav-col">
        <Link className={'nav-item-box'} to={'/reports/new-user'}>
                  <span className="nav-icon">
                    <FiUserPlus/>
                  </span>
          <span className="nav-text">New User Reports</span>
        </Link>
      </div>

      <div className="nav-col">
        <Link className={'nav-item-box'} to={'/reports/product'}>
                  <span className="nav-icon">
                    <FiBox/>
                  </span>
          <span className="nav-text">Product Reports</span>
        </Link>
      </div>
    </div>
  );
};

export default ReportGrid;