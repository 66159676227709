// noinspection JSUnusedLocalSymbols, JSVoidFunctionReturnValueUsed, JSUnresolvedVariable, DuplicatedCode

import React, {Component} from 'react';
import App from "../../App";
import {Link, withRouter} from "react-router-dom";
import {GoLinkExternal, MdArrowBack} from "react-icons/all";
import moment from "moment";
import Product from "../../components/Product";
import Store from "../../components/Store";
import Pagination from "react-js-pagination";
import {confirmAlert} from "react-confirm-alert";
import {getDetail, getTableData, getTableDataWithParams, updateItemPartial} from "../../services/commonServices";
import {toast} from "react-toastify";
import {Button, Col, Modal, Row} from "react-bootstrap";
import {STORE_URL} from "../../helper/env";
import {getCampaignStoreList} from "../../services/storeServices";
import styleReactSelect from "../../assets/styleReactSelect";
import Select from "react-select";
import {getStoreList, statusUpdate} from "../../services/storeServices";
import "../../assets/scss/business-block-card.scss"
import "../../assets/scss/count-down-timer.css"
import parser from "html-react-parser"
import CountdownTimer from "../../components/CountdownTimer";
import {FiFileText} from "@react-icons/all-files/fi/FiFileText";
import {FaSellsy} from "@react-icons/all-files/fa/FaSellsy";
import {BsListCheck} from "@react-icons/all-files/bs/BsListCheck";
import {ImCancelCircle} from "@react-icons/all-files/im/ImCancelCircle";
import {FaTruckPickup} from "@react-icons/all-files/fa/FaTruckPickup";
import {FcShipped} from "@react-icons/all-files/fc/FcShipped";
import {GrDeliver} from "@react-icons/all-files/gr/GrDeliver";
import {AiOutlineTransaction} from "@react-icons/all-files/ai/AiOutlineTransaction";
import {GiCash} from "@react-icons/all-files/gi/GiCash";
import {GrTransaction} from "@react-icons/all-files/gr/GrTransaction";
import {FcApprove} from "@react-icons/all-files/fc/FcApprove";
import {AiOutlineUser} from "@react-icons/all-files/ai/AiOutlineUser";
import InfoCardV2 from "../../components/InfoCardV2";
import {AiOutlineHourglass} from "@react-icons/all-files/ai/AiOutlineHourglass";
import {useJsonToCsv} from "react-json-csv";
import {FaFileExport} from "@react-icons/all-files/fa/FaFileExport";
import {FiFile} from "@react-icons/all-files/fi/FiFile";
const {saveAsCsv} = useJsonToCsv();

class CampaignDetails extends Component {
    state = {
        campaignQueryData:{
            campaign_id: this.props.match.params.id,
            report_by: 4,
        },

        isLoading: true,
        activeBlockTab: 'Campaign Detail',
        campaignDetails: {},
        notExisting: false,
        stores: [],

        registered_stores_query: {limit: 20},
        registered_stores: [],
        approved_stores_query: {limit: 20},
        approved_stores: [],

        isShowingProductRegisterModal: false,
        store_products: [],
        store_product_pagination: {},

        registered_products_query: {limit: 20},
        registered_products: [],
        approved_products_query: {limit: 20},
        approved_products: [],
        pagination: {},
        limit: 10,
    }

    renderPubishStatus = (status) => {
        let sta = {1:"2", 2:"3", 3:"2"};
        console.log(status);
        console.log(sta[status]);
        return sta[status];
    }



    getReport = () =>{
        console.log(this.state.campaignQueryData)
        getDetail('/dashboard', this.state.campaignQueryData).then((res)=>{
            this.setState({
                campaign_reports : res?.campaign_reports
            })
        })
    }

    componentDidMount() {
        const campaignId = this.props.match.params.id
        this.getReport();

        getCampaignStoreList({campaign_id: campaignId}).then(res => {
            this.setState({stores: res.items, loading: false}, () => {
                getDetail(`/campaign/${campaignId}`, {}).then(res => {
                    this.setState({campaignDetails: res.campaign, isLoading: false})

                    const registered_stores = this.getRegisteredStores(res.campaign.registered_stores);

                    const approved_stores = this.getApprovedStores(res.campaign.stores);

                    const registered_products = this.getRegisteredProducts(res.campaign.registered_products);

                    const approved_products = this.getApprovedProducts(res.campaign.products);

                    Promise.all([registered_stores, approved_stores, registered_products, approved_products]).then(res => {
                        this.setState({isLoading: false})
                    }).catch((err) => {
                        this.setState({isLoading: false})
                    })
                }).catch(errMsg => {
                    this.setState({isLoading: false, notExisting: true})
                    this.setState({isLoading: false})
                })
            });
        }).catch(errMsg => {
            this.setState({loading: false});
        })
    }

    getRegisteredStores = (ids) => {
        const campaignId = this.props.match.params.id
        getCampaignStoreList({campaign_id: campaignId, registered_stores: true}).then(res => {
            this.setState({registered_stores: res.items, registeredStoresPagination: res.pagination});
        })
    }

    getApprovedStores = (ids) => {
        const campaignId = this.props.match.params.id
        getCampaignStoreList({campaign_id: campaignId, approved_stores: true}).then(res => {
            this.setState({approved_stores: res.items, approvedStoresPagination: res.pagination});
        });
    }

    getRegisteredProducts = (ids) => {
        const campaignId = this.props.match.params.id
        getTableDataWithParams(`/campaign/products`, {campaign_id: campaignId, registered_products: true}).then(res => {
            this.setState({registered_products: res.products, registeredProductsPagination: res.pagination});
        });
    }

    getApprovedProducts = (ids) => {
        const campaignId = this.props.match.params.id
        getTableDataWithParams(`/campaign/products`, {campaign_id: campaignId, approved_products: true}).then(res => {
            this.setState({approved_products: res.products, approvedProductsPagination: res.pagination});
        });
    }

    handleBlockTab = (label) => {
        this.setState({activeBlockTab: label});
    }

    /* START STORE LIST */
    /*approveCampaignStore = (id) => {
        confirmAlert({
            title: 'Approve',
            message: 'Are you sure to approve this store?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        let stores = [...this.state.campaignDetails.stores]
                        if (!stores.includes(id)) {
                            stores.push(id)
                            updateItemPartial(`/campaign/${this.state.campaignDetails._id}/partial`, {stores: stores}).then(res => {
                                if (res.status === 1) {
                                    let campaignDetails = {...this.state.campaignDetails}
                                    campaignDetails['stores'] = stores
                                    this.setState({campaignDetails}, () => {
                                        toast.success(res.message)
                                        this.getApprovedStores(JSON.stringify(stores));
                                    });
                                } else {
                                    toast.warning(res.message)
                                }
                            }).catch(errMsg => {
                                toast.error(errMsg)
                            })
                        } else {
                            toast.warning("Already approved");
                        }
                    }
                },
                {
                    label: 'No',
                }
            ]
        });
    }*/

    removeCampaignRegisteredStore = (id) => {
        confirmAlert({
            title: 'Remove',
            message: 'Are you sure to remove the registered store from the campaign?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        let stores = [...this.state.campaignDetails.registered_stores]
                        stores.splice(stores.indexOf(id), 1);
                        updateItemPartial(`/campaign/${this.state.campaignDetails._id}/partial`, {registered_stores: stores}).then(res => {
                            if (res.status === 1) {
                                let campaignDetails = {...this.state.campaignDetails}
                                campaignDetails['stores'] = stores
                                this.setState({campaignDetails}, () => {
                                    toast.success(res.message)
                                    this.getRegisteredStores(JSON.stringify(stores));
                                });
                            } else {
                                toast.warning(res.message)
                            }
                        }).catch(errMsg => {
                            toast.error(errMsg)
                        })
                    }
                },
                {
                    label: 'No',
                }
            ]
        });
    }

    removeCampaignApprovedStore = (id) => {
        confirmAlert({
            title: 'Remove',
            message: 'Are you sure to remove this approved store from the campaign?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        let stores = [...this.state.campaignDetails.stores]
                        stores.splice(stores.indexOf(id), 1);
                        updateItemPartial(`/campaign/${this.state.campaignDetails._id}/remove`, {store: id}).then(res => {
                            if (res.status === 1) {
                                let campaignDetails = {...this.state.campaignDetails}
                                campaignDetails['stores'] = stores
                                this.setState({campaignDetails}, () => {
                                    toast.success(res.message)
                                    this.getApprovedStores(JSON.stringify(stores));
                                });
                            } else {
                                toast.warning(res.message)
                            }
                        }).catch(errMsg => {
                            toast.error(errMsg)
                        })
                    }
                },
                {
                    label: 'No',
                }
            ]
        });
    }

    /* END STORE LIST */

    /* START PRODUCT LIST RELATED */
    /*registerCampaignProduct = (id) => {
        confirmAlert({
            title: 'Register Product',
            message: 'Are you sure to add the registered product in selected campaign?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        let products = [...this.state.campaignDetails.registered_products]
                        if (!products.includes(id)) {
                            products.push(id)
                            updateItemPartial(`/campaign/${this.state.campaignDetails._id}/partial`, {registered_products: products}).then(res => {
                                if (res.status === 1) {
                                    let campaignDetails = {...this.state.campaignDetails}
                                    campaignDetails['products'] = products
                                    this.setState({campaignDetails}, () => {
                                        toast.success(res.message)
                                        this.getRegisteredProducts(JSON.stringify(products));
                                    });
                                } else {
                                    toast.warning(res.message)
                                }
                            }).catch(errMsg => {
                                toast.error(errMsg)
                            })
                        } else {
                            toast.warning("Already approved");
                        }
                    }
                },
                {
                    label: 'No',
                }
            ]
        });
    }*/

    removeFromCampaignRegisteredProducts = (id) => {
        confirmAlert({
            title: 'Remove',
            message: 'Are you sure to remove the approved product from the campaign?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        let products = [...this.state.campaignDetails.registered_products]
                        products.splice(products.indexOf(id), 1);
                        updateItemPartial(`/campaign/${this.state.campaignDetails._id}/partial`, {registered_products: products}).then(res => {
                            if (res.status === 1) {
                                let campaignDetails = {...this.state.campaignDetails}
                                campaignDetails['products'] = products
                                this.setState({campaignDetails}, () => {
                                    toast.success(res.message)
                                    this.getRegisteredProducts(JSON.stringify(products));
                                });
                            } else {
                                toast.warning(res.message)
                            }
                        }).catch(errMsg => {
                            toast.error(errMsg)
                        })
                    }
                },
                {
                    label: 'No',
                }
            ]
        });
    }

    removeFromCampaignApprovedProducts = (id) => {
        confirmAlert({
            title: 'Remove',
            message: 'Are you sure to remove this product?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        let products = [...this.state.campaignDetails.products]
                        products.splice(products.indexOf(id), 1);
                        updateItemPartial(`/campaign/${this.state.campaignDetails._id}/partial`, {products: products}).then(res => {
                            if (res.status === 1) {
                                let campaignDetails = {...this.state.campaignDetails}
                                campaignDetails['products'] = products
                                this.setState({campaignDetails}, () => {
                                    toast.success(res.message)
                                    this.getApprovedProducts(JSON.stringify(products));
                                });
                            } else {
                                toast.warning(res.message)
                            }
                        }).catch(errMsg => {
                            toast.error(errMsg)
                        })
                    }
                },
                {
                    label: 'No',
                }
            ]
        });
    }

    /*handlePageChange = (pageNumber) => {
        this.setState({queryData: {...this.state.queryData, page: pageNumber}, activePage: pageNumber}, () => {
            this.getQueryData();
        });
    }*/

    /* END PRODUCT LIST RELATED */
    /* START HANDLE STORE SETTING */
    /*handleInputOnChange = (e) => {
        const formData = {...this.state.formData}
        formData[e.target.name] = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        this.setState({formData})
    }*/

    /* END HANDLE STORE SETTING */
    handleProductListModal = (storeId, campaignId) => {
        this.setState({isShowingProductRegisterModal: true, selectedCampaignId: campaignId, store_id: storeId}, () => {
            this.getStoreProducts(storeId, 1);
        })
    }

    getStoreProducts = (storeId, page) => {
        getTableDataWithParams(`/product/`, {store_id: storeId, campaign_product: true, page: page, limit: 20}).then(res => {
            if (res.status === 1) {
                this.setState({store_products: res.products, store_product_pagination: res.pagination})
            } else {
                toast.warning(res.message);
            }
        }).catch(err => {
            toast.error(err.message)
        })
    }

    handleCloseProductRegisterModal = (e) => {
        this.setState({
            isShowingProductRegisterModal: false,
            selectedCampaignId: "",
            store_products: [],
            store_product_pagination: {}
        })
    }

    addProductInCampaign = (productId) => {
        getDetail(`/campaign/${this.state.selectedCampaignId}`).then(res => {
            if (res.status === 1) {
                let campaign_info = res.campaign;
                let registered_products = campaign_info.registered_products;
                if (!registered_products.includes(productId)) {
                    registered_products.push(productId)
                    updateItemPartial(`/campaign/${this.state.selectedCampaignId}/partial`, {registered_products: registered_products}).then(res => {
                        if (res.status === 1) {
                            let campaignDetails = {...this.state.campaignDetails}
                            campaignDetails['registered_products'] = registered_products
                            this.setState({campaignDetails}, () => {
                                toast.success(res.message)
                                this.getRegisteredProducts(JSON.stringify(registered_products));
                            });
                        } else {
                            toast.warning(res.message)
                        }
                    }).catch(err => {
                        toast.error(err.message);
                    })
                } else {
                    toast.warning("product is already registered");
                }
            } else {
                toast.warning(res.message)
            }
        }).catch(errMsg => {
            toast.error(errMsg.message)
        });
    }

    handleStoreProductsPageChange = async (pageNumber) => {
        this.getStoreProducts(this.state.storeId, pageNumber);
    }

    handleRegisteredStorePageChange = async (page) => {
        let registered_stores_query = {...this.state.registered_stores_query}
        registered_stores_query['page'] = page;
        this.setState({...this.state.registered_stores_query, page: page}, () => {
            this.getRegisteredStores(this.state.campaignDetails.registered_stores);
        });
    }

    handleApprovedStorePageChange = async (page) => {
        let approved_stores_query = {...this.state.approved_stores_query}
        approved_stores_query['page'] = page;
        this.setState({approved_stores_query}, () => {
            this.getApprovedStores(this.state.campaignDetails.stores);
        });
    }

    handleRegisteredProductsPageChange = async (page) => {
        let registered_products_query = {...this.state.registered_products_query}
        registered_products_query['page'] = page;
        this.setState({registered_products_query}, () => {
            this.getRegisteredProducts(this.state.campaignDetails.registered_products);
        })
    }

    handleApprovedProductsPageChange = async (page) => {
        let approved_products_query = {...this.state.approved_products_query}
        approved_products_query['page'] = page;
        this.setState({approved_products_query}, () => {
            this.getApprovedProducts(this.state.campaignDetails.products);
        });
    }


    handleExportFileReport = async (fileType) => {
        let mData = [];


        // for quantity
        mData.push({
            '': "Quantity",
            'Total Orders': this.state?.campaign_reports?.total_order,
            'Total Sells': '',
            'Total Pending Order': this.state?.campaign_reports?.pending_order,
            'Total Confirmed Order': this.state?.campaign_reports?.confirmed_order,
            'Total Processing Order': this.state?.campaign_reports?.processing_order,
            'Total Cancel': this.state?.campaign_reports?.cancel_order,
            'Total Picked': this.state?.campaign_reports?.picked_order,
            'Total Shipped': this.state?.campaign_reports?.shipped_order,
            'Total Deliver Order': this.state?.campaign_reports?.delivered_order,
            'Total COD': this.state?.campaign_reports?.total_cod,
            'Total Online Transaction': this.state?.campaign_reports?.total_online_transaction,
            'Total bank Transaction': this.state?.campaign_reports?.total_bank_transaction,
            'Total Approved Bank Transaction': this.state?.campaign_reports?.total_approved_bank_transaction,
            'MuktoPaath User Orders': this.state?.campaign_reports?.total_muktopaath_order,

        });

        //for amount
        mData.push({
            '': "Amount",
            'Total Orders': '',
            'Total Sells': this.state?.campaign_reports?.total_sells,
            'Total Pending Order': this.state?.campaign_reports?.total_pending_sells,
            'Total Confirmed Order': this.state?.campaign_reports?.total_confirmed_sells,
            'Total Processing Order': this.state?.campaign_reports?.processing_sells,
            'Total Cancel': this.state?.campaign_reports?.total_cancel_sells,
            'Total Picked': this.state?.campaign_reports?.total_picked_sells,
            'Total Shipped': this.state?.campaign_reports?.total_shipped_sells,
            'Total Deliver Order': this.state?.campaign_reports?.total_delivered_sells,
            'Total COD': this.state?.campaign_reports?.total_cod_sells,
            'Total Online Transaction': this.state?.campaign_reports?.total_online_transaction_sells,
            'Total bank Transaction': this.state?.campaign_reports?.total_bank_transaction_sells,
            'Total Approved Bank Transaction': this.state?.campaign_reports?.total_approved_transaction_sells,
            'MuktoPaath User Orders': this.state?.campaign_reports?.total_muktopaath_sells,

        });

        const filename = 'EkShop-Campaign Report',
            fields = {
                '': "",
                'Total Orders': "Total Orders",
                'Total Sells': "Total Sells",
                'Total Pending Order': "Total Pending Order",
                'Total Confirmed Order': "Total Confirmed Order",
                'Total Cancel': "Total Cancel",
                'Total Picked': "Total Picked",
                'Total Shipped': "Total Shipped",
                'Total Deliver Order': "Total Deliver Order",
                'Total COD': "Total COD",
                'Total Online Transaction': "Total Online Transaction",
                'Total bank Transaction': "Total bank Transaction",
                'Total Approved Bank Transaction': "Total Approved Bank Transaction",
                'MuktoPaath User Orders': "MuktoPaath User Orders",
            },
            style = {
                padding: "30px",
                maxHeight: "20px"
            },
            data = mData,
            text = "Convert Json to Csv";
        saveAsCsv({data, fields, filename});


    }

    renderStoreId = () => {
        const approved_stores = this.state.stores;
        if (approved_stores) {
            return (
                <>
                    {approved_stores.map((items, index) =>
                        <option key={index} value={`${items.id}`}>{items.label}</option>
                    )}
                </>
            )
        }
    };

    handleInputOnChangeV2 = (e) => {
        this.setState({campaignQueryData: {...this.state.campaignQueryData, [e.target.name]: e.target.value}}, () => {
            console.log(this.state.campaignQueryData)
        });
    }

    publishStatusText = (status) => {
        let sta = {1: "Drafted", 2: "Published", 3: "Unpublished"};
        return sta[status];
    }


    exportList = async (fileType)  =>  {
        let mData = [];


        this.state.approved_products.map((data, index)=>{



            mData.push({
                'Index': index++ ,
                'product Name': data?.name ,
                'Create Date':  moment(data?.createdAt).format('hh:mm a dd/mm/yyyy'),
                'Status': this.publishStatusText(data.publish_status),
                'Store Name': data?.store_info?.store_name,
                'Min Price':data.min_price,
                'Max Price': data.max_price,
                // 'Available': data.sku_info?.quantity,
                // 'Stock': this.renderAvailabilityStatus(data.sku_info?.quantity),
                // 'Rating': data?.avg_score/data?.t_n_of_score,
                'Total Sold': data?.t_sold,
                'Campaign Orders': data?.c_orders ? data?.c_orders : 'Not available',
            });
        })



        const filename = 'EkShop-Approved Product Report',
            fields = {
                'Index': "Index",
                'product Name': "product Name",
                'Create Date': "Create Date",
                'Status': "Status",
                'Store Name': "Store Name",
                'Min Price': "Min Price",
                'Max Price': "Max Price",
                // 'Available': "Available",
                // 'Stock': "Stock",
                // 'Rating': "Rating",
                'Total Sold': "Total Sold",
                'Campaign Orders': "Campaign Orders",
            },
            style = {
                padding: "40px",
                maxHeight: "20px"
            },
            data = mData,
            text = "Convert Json to Csv";
        saveAsCsv({data, fields, filename});


    }


    render() {
        const {
            stores,
            campaignDetails,
            registered_stores,
            approved_stores,
            store_products,
            registered_products,
            approved_products
        } = this.state
        return (
            <App layout={"boxed"}>
                <div className="main-content">
                    <div className="page-header">
                        <h2 className="page-title">
                            Campaign Details ({campaignDetails?.campaign_title})
                            <a target={'_blank'}
                               rel="noopener noreferrer"
                               className={'ml-3 text-primary'}
                               href={`${STORE_URL}/campaigns/${campaignDetails?.campaign_slug}`}><GoLinkExternal/></a>
                        </h2>
                        <div className="ml-auto">
                            <Link to={`${process.env.PUBLIC_URL}/promotions/campaign`}
                                  className={`btn btn-sm btn-link`}><MdArrowBack/> Back to List</Link>
                            {/*<Link to={`${process.env.PUBLIC_URL}/promotions/campaign/add`}
                                  className="btn btn-sm btn-secondary"><FiPlus/> Add Campaign</Link>*/}
                        </div>

                        {/*<button onClick={this.exportList} className="btn btn-sm btn-primary" style={{marginRight : 10}}><FiFile/> Export List</button>*/}

                        {this.state.activeBlockTab === 'Approved Products' ?  <button onClick={this.exportList} className="btn btn-sm btn-primary" style={{marginRight : 10}}><FiFile/> Export List</button> : null}

                    </div>

                    <div className="card-block">
                        <ul className="desc-header-nav">
                            <li className={this.state.activeBlockTab === 'Campaign Detail' ? 'active' : ''} value="">
                                <span onClick={() => this.handleBlockTab('Campaign Detail')}>Campaign Detail</span>
                            </li>
                            <li className={this.state.activeBlockTab === 'Registered Stores' ? 'active' : ''} value="">
                                <span onClick={() => this.handleBlockTab('Registered Stores')}>
                                    Registered Stores
                                    <span className={'text-danger ml-3'}>
                                        {this.state?.registeredStoresPagination?.totalDocs}
                                        {/*{this.state.stores.length}*/}
                                    </span>
                                </span>
                            </li>
                            <li className={this.state.activeBlockTab === 'Approved Stores' ? 'active' : ''} value="">
                                <span onClick={() => this.handleBlockTab('Approved Stores')}>
                                    Approved Stores
                                    <span className={'text-success ml-3'}>
                                        {approved_stores?.length}
                                    </span>
                                </span>
                            </li>
                            <li className={this.state.activeBlockTab === 'Registered Products' ? 'active' : ''}
                                value="">
                                <span onClick={() => this.handleBlockTab('Registered Products')}>
                                    Registered Products
                                    <span className={'text-danger ml-3'}>{this.state?.registeredProductsPagination?.totalDocs}</span>
                                </span>
                            </li>
                            <li className={this.state.activeBlockTab === 'Approved Products' ? 'active' : ''} value="">
                                <span onClick={() => this.handleBlockTab('Approved Products')}>
                                    Approved Products
                                    <span className={'text-success ml-3'}>
                                         {this.state?.approvedProductsPagination?.totalDocs}
                                    </span>
                                </span>
                            </li>
                            <li className={this.state.activeBlockTab === 'Order Report' ? 'active' : ''} value="">
                                <span onClick={() => this.handleBlockTab('Order Report')}>
                                    Order Report
                                    <span className={'text-success ml-3'}>
                                        {/* {this.state?.approvedProductsPagination?.totalDocs}*/}
                                    </span>
                                </span>
                            </li>
                        </ul>

                        <Modal show={this.state.isShowingProductRegisterModal}
                               onHide={this.handleCloseProductRegisterModal} size={'xl'}>
                            <Modal.Header closeButton>
                                <Modal.Title>Join Campaign</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="card-block">
                                    <div className="block-header">
                                        <h4 className="block-title">Store Products</h4>
                                    </div>
                                    <div className="block-body">
                                        {this.state.store_products?.length > 0 ?
                                            <>
                                                <div className="product-list mt-3">
                                                    {this.state.store_products.map((product, index) => (
                                                        <div key={index} className="product-item">
                                                            <Product data={product} registeredProducts={this.state.registered_products} approvedProducts={this.state.approved_products} addProductInCampaign={this.addProductInCampaign}/>
                                                        </div>
                                                    ))}
                                                </div>
                                                <div className="mt-3">
                                                    <Pagination
                                                        itemClass="page-item"
                                                        linkClass="page-link"
                                                        activePage={this.state.store_product_pagination?.page}
                                                        itemsCountPerPage={this.state.store_product_pagination?.limit}
                                                        totalItemsCount={this.state.store_product_pagination?.totalDocs}
                                                        pageRangeDisplayed={20}
                                                        onChange={this.handleStoreProductsPageChange.bind(this)}
                                                    />
                                                </div>
                                            </>
                                            :
                                            <div className="alert alert-warning text-bold text-center">
                                                No product added yet.
                                            </div>
                                        }
                                    </div>
                                </div>

                            </Modal.Body>

                            <Modal.Footer>
                                {/*<Button variant="default" onClick={this.handleCloseJoinStoreModal}>Close</Button>*/}
                            </Modal.Footer>
                        </Modal>

                        {this.state.activeBlockTab === 'Campaign Detail' &&
                        <div className="card-block">
                            <div className="merchant-campaign-details">
                                <div className="campaign-details-banner">
                                    <img src={campaignDetails?.campaign_banner ? campaignDetails?.campaign_banner[0]?.img : ''} alt="banner Image"/>
                                    <div className="campaign-text">
                                        <h4 className="campaign-title">{campaignDetails?.campaign_title}</h4>
                                        <p className="campaign-descriptin">
                                            <strong>Short Description: </strong>{parser('' + campaignDetails?.campaign_details + '')}
                                        </p>
                                    </div>

                                    <div className="campaign-daterange">
                                        {/*Start date : {moment(campaignDetails?.start_date).format('ll')}*/}
                                        {/*TODO: Offer will start*/}
                                        {(moment().diff(campaignDetails?.start_date ,'minutes',true) < 0) ?
                                            <>
                                                <span className="campaign-daterange-title">Campaign will start</span>
                                                <CountdownTimer endTime={campaignDetails?.start_date} decrement={0} type={2}/>
                                            </>
                                            :
                                            <>{moment().diff(campaignDetails?.start_date ,'minutes',true) > 0 &&
                                            moment().diff(campaignDetails?.end_date ,'minutes',true) < 0 &&
                                            /*TODO: Offer will end*/
                                            <>
                                                <span className="campaign-daterange-title">Campaign will end</span>
                                                <CountdownTimer endTime={campaignDetails?.end_date} decrement={0} type={2}/>
                                            </>
                                            }</>
                                        }
                                    </div>
                                    <div className="campaign-date-count-area">
                                        <div className="last-registration-date"><h5>Start date <span><span>: </span>{moment(campaignDetails?.start_date).format('ll')} {moment(campaignDetails?.start_date).format('hh:mm a')} </span></h5></div>
                                        <div className="last-registration-date"><h5>End date <span><span>: </span>{moment(campaignDetails?.end_date).format('ll')} {moment(campaignDetails?.end_date).format('hh:mm a')} </span></h5></div>
                                        <div className="campaing-coundown-date">
                                            <h5>Registration last date <span><span>: </span>{moment(campaignDetails?.registration_last_date).format('ll')} </span></h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        }


                        {this.state.activeBlockTab === 'Registered Stores' && <>
                            <div className="block-body">
                                {registered_stores?.length > 0 ?
                                    <>

                                        <div className="block-header">
                                            <h4 className="block-title">Registered Store List</h4>
                                        </div>

                                        <div className="block-body position-relative">
                                            <Store data={{stores: registered_stores, my_stores: approved_stores, pagingCounter: this.state.registeredStoresPagination.pagingCounter}} removeCampaignRegisteredStore={this.removeCampaignRegisteredStore}/>
                                            <Pagination itemClass="page-item" linkClass="page-link" activePage={this.state.registeredStoresPagination?.page} itemsCountPerPage={this.state.registeredStoresPagination?.limit} totalItemsCount={this.state.registeredStoresPagination?.totalDocs} pageRangeDisplayed={20} onChange={this.handleRegisteredStorePageChange.bind(this)}/>
                                        </div>
                                    </>
                                    :
                                    <div className="alert alert-warning text-bold text-center">No registered store found.</div>
                                }
                            </div>
                        </>}

                        {this.state.activeBlockTab === 'Approved Stores' && <>
                            <div className="block-body">
                                {approved_stores?.length > 0 ?
                                    <>
                                        <div className="block-header">
                                            <h4 className="block-title">Approved Store List</h4>
                                        </div>

                                        <div className="block-body position-relative">
                                            <Store data={{stores: approved_stores, my_stores: approved_stores, pagingCounter: this.state.approvedStoresPagination.pagingCounter}} removeCampaignApprovedStore={this.removeCampaignApprovedStore} handleProductListModal={this.handleProductListModal} campaignInfo={campaignDetails}/>
                                            <Pagination itemClass="page-item" linkClass="page-link" activePage={this.state.approvedStoresPagination?.page} itemsCountPerPage={this.state.approvedStoresPagination?.limit} totalItemsCount={this.state.approvedStoresPagination?.totalDocs} pageRangeDisplayed={20} onChange={this.handleApprovedStorePageChange.bind(this)}/>
                                        </div>
                                    </>
                                    :
                                    <div className="alert alert-warning text-bold text-center">No approved store found.</div>
                                }
                            </div>

                        </>}

                        {this.state.activeBlockTab === 'Registered Products' && <>
                            <div className="block-body">
                                {this.state.registered_products?.length > 0 ?
                                    <>
                                        <div className="product-list mt-3">
                                            {this.state.registered_products.map((product, index) => (
                                                <div key={index} className="product-item">
                                                    <Product data={product} approvedProducts={this.state.approved_products} removeFromCampaignRegisteredProducts={this.removeFromCampaignRegisteredProducts}/>
                                                </div>
                                            ))}
                                        </div>
                                        <div className="mt-3">
                                            <Pagination itemClass="page-item" linkClass="page-link" activePage={this.state?.registeredProductsPagination?.page} itemsCountPerPage={this.state?.registeredProductsPagination?.limit} totalItemsCount={this.state?.registeredProductsPagination?.totalDocs} pageRangeDisplayed={20} onChange={this.handleRegisteredProductsPageChange.bind(this)}/>
                                        </div>
                                    </>
                                    :
                                    <div className="alert alert-warning text-bold text-center">No product registered yet.</div>
                                }
                            </div>
                        </>}

                        {this.state.activeBlockTab === 'Approved Products' && <>
                            <div className="block-body">
                                {this.state.approved_products?.length > 0 ?
                                    <>

                                        <div className="product-list mt-3">
                                            {this.state.approved_products.map((product, index) => (
                                                <div key={index} className="product-item">
                                                    <Product data={product}  showCorders={true}
                                                             removeFromCampaignApprovedProducts={this.removeFromCampaignApprovedProducts}/>
                                                </div>
                                            ))}
                                        </div>

                                        <div className="mt-3">
                                            <Pagination
                                                itemClass="page-item"
                                                linkClass="page-link"
                                                activePage={this.state?.approvedProductsPagination?.page}
                                                itemsCountPerPage={this.state?.approvedProductsPagination?.limit}
                                                totalItemsCount={this.state?.approvedProductsPagination?.totalDocs}
                                                pageRangeDisplayed={20}
                                                onChange={this.handleApprovedProductsPageChange.bind(this)}
                                            />
                                        </div>
                                    </>
                                    :
                                    <div className="alert alert-warning text-bold text-center">
                                        No product approved yet.
                                    </div>
                                }
                            </div>

                        </>}

                        {this.state.activeBlockTab === 'Order Report' && <>
                            <div className="block-body">

                                <form className="form-inline mb-3">

                                    <div className="form-group mr-3 mt-2">
                                        <label htmlFor="barndName" className="sr-only">Sort by Store</label>
                                        <select id="store_id" name="store_id"
                                                onChange={this.handleInputOnChangeV2}
                                                className='form-control form-control-sm'>
                                            <option value="">--Select Store--</option>
                                            {this.renderStoreId()}
                                        </select>
                                    </div>
                                    <Button className="mt-2" size="sm" variant="secondary"
                                            onClick={() => this.getReport() }>Search</Button>
                                </form>



                                <Button
                                    size={"sm"}
                                    variant={"defalut"} className="mb-0 ml-2"
                                    onClick={() => this.handleExportFileReport('xlsx')}><FaFileExport/> Export
                                </Button>

                                <Row>
                                    <Col md={3}>
                                        <InfoCardV2
                                            title="Total Orders" className="blue" icon={<FiFileText/>}
                                            value={this.state?.campaign_reports?.total_order}/>
                                    </Col>

                                    <Col md={3}>
                                        <InfoCardV2
                                            title="Total Sells" className="blue" icon={<FaSellsy/>}
                                            isCurrency={true}
                                            value={this.state?.campaign_reports?.total_sells}/>
                                    </Col>

                                    <Col md={3}>
                                        <InfoCardV2
                                            total={"Pending sells : " + this.state?.campaign_reports?.total_pending_sells}
                                            title="Total Pending Orders" className="orange"
                                            icon={<AiOutlineHourglass/>}
                                            value={this.state?.campaign_reports?.pending_order}/>
                                    </Col>

                                    <Col md={3}>
                                        <InfoCardV2
                                            total={"Confirmed sells : " + this.state?.campaign_reports?.total_confirmed_sells}
                                            title="Total Confirm Orders" className="green" icon={<BsListCheck/>}
                                            value={this.state?.campaign_reports?.confirmed_order}/>
                                    </Col>

                                    <Col md={3}>
                                        <InfoCardV2
                                            total={"Cancelled sells : " + this.state?.campaign_reports?.total_cancel_sells}
                                            title="Total Cancel Orders" className="red" icon={<ImCancelCircle/>}
                                            value={this.state?.campaign_reports?.cancel_order}/>
                                    </Col>

                                    <Col md={3}>
                                        <InfoCardV2
                                            total={"Picked sells : " + this.state?.campaign_reports?.total_picked_sells}
                                            title="Total Picked Orders" className="blue" icon={<FaTruckPickup/>}
                                            value={this.state?.campaign_reports?.picked_order}/>
                                    </Col>

                                    <Col md={3}>
                                        <InfoCardV2
                                            total={"Shipped sells : " + this.state?.campaign_reports?.total_shipped_sells}
                                            title="Total Shipped Orders" className="green" icon={<FcShipped/>}
                                            value={this.state?.campaign_reports?.shipped_order}/>
                                    </Col>

                                    <Col md={3}>
                                        <InfoCardV2
                                            total={"Processing order sells : " + this.state?.campaign_reports?.processing_sells}
                                            title="Processing Order" className="green"
                                            icon={<AiOutlineTransaction/>}
                                            value={this.state?.campaign_reports?.processing_order}/>
                                    </Col>

                                    <Col md={3}>
                                        <InfoCardV2
                                            total={"Delivered sells : " + this.state?.campaign_reports?.total_delivered_sells}
                                            title="Total Delivered Orders" className="orange" icon={<GrDeliver/>}
                                            value={this.state?.campaign_reports?.delivered_order}/>
                                    </Col>


                                    <Col md={3}>
                                        <InfoCardV2
                                            total={"COD sells : " + this.state?.campaign_reports?.total_cod_sells}
                                            title="Total COD" className="blue" icon={<GiCash/>}
                                            value={this.state?.campaign_reports?.total_cod}/>
                                    </Col>

                                    <Col md={3}>
                                        <InfoCardV2
                                            total={"Online Transaction sells : " + this.state?.campaign_reports?.total_online_transaction_sells}
                                            title="Total Online Transaction" className="green" icon={<AiOutlineTransaction/>}
                                            value={this.state?.campaign_reports?.total_online_transaction}/>
                                    </Col>

                                    <Col md={3}>
                                        <InfoCardV2
                                            total={"Total Bank Transaction sells : " + this.state?.campaign_reports?.total_bank_transaction_sells}
                                            title="Total Bank Transaction" className="blue" icon={<GrTransaction/>}
                                            value={this.state?.campaign_reports?.total_bank_transaction}/>
                                    </Col>

                                    <Col md={3}>
                                        <InfoCardV2
                                            total={"Approved Bank Transaction sells : " + this.state?.campaign_reports?.total_approved_transaction_sells}
                                            title="Approved Bank Transaction" className="orange" icon={<FcApprove/>}
                                            value={this.state?.campaign_reports?.total_approved_bank_transaction}/>
                                    </Col>


                                    <Col md={3}>
                                        <InfoCardV2
                                            total={"MuktoPaath sells : " + this.state?.campaign_reports?.total_muktopaath_sells}
                                            title="Muktopaath User Order" className="blue" icon={<AiOutlineUser/>}
                                            value={this.state?.campaign_reports?.total_muktopaath_order}/>
                                    </Col>

                                </Row>

                            </div>

                        </>}
                    </div>
                    {this.state.activeBlockTab === 'Campaign Detail' && <>
                        {campaignDetails !== undefined ? <div className="card-block">
                            <div className="campaign-terms-conditions">
                                <h3 className="term-condition-title">Terms and Conditions</h3>
                                <p className="term-condition-des">{parser('' + campaignDetails?.terms_and_conditions + '')}</p>
                            </div>
                        </div> : null}
                    </>
                    }
                </div>
            </App>
        );
    }
}

export default withRouter(CampaignDetails);
