import axios from 'axios';

import {
  CLEAR_ERR_MSG,
  LOGIN_ERROR,
  LOGIN_REQUESTING,
  LOGIN_SUCCESS,
  LOGOUT_ERROR,
  LOGOUT_REQUESTING,
  LOGOUT_SUCCESS
} from "./actionTypes";
import {API_BASE_URL} from "../../helper/env";

export const login = (data, callback) => {
  return dispatch => {
    dispatch(loginRequesting());
    axios.post(`${API_BASE_URL}/password-login`, data)
      .then(res => {
        if(res.data.status === 1 && res.data?.user_login_session !== undefined) {
          dispatch(loginSuccess('Login successfully!'));
          localStorage.setItem('user', JSON.stringify(res.data.user_login_session));
          callback(res.data.user_login_session);
        } else {
          dispatch(loginErr(res.data.message))
        }
      })
      .catch(err => {
        console.log('Error: ', err);
        dispatch(loginErr('Something went wrong!'))
      })
  }
};

export const loginRequesting = () => {
  return {
    type: LOGIN_REQUESTING
  }
};

export const loginSuccess = successMessage => {
  return {
    type: LOGIN_SUCCESS,
    payload: successMessage
  }
};

export const loginErr = errMsg => {
  return {
    type: LOGIN_ERROR,
    payload: errMsg
  }
};

export const logout = (callback) => {
  return dispatch => {
    dispatch(logoutRequesting());
    setTimeout(() => {
      localStorage.clear();
      dispatch(logoutSuccess('Logout successfully!'));
      callback();
    }, 1000)
  }
};

export const logoutRequesting = () => {
  return {
    type: LOGOUT_REQUESTING
  }
};

export const logoutSuccess = (msg) => {
  return {
    type: LOGOUT_SUCCESS,
    payload: msg
  }
};

export const logoutError = (err) => {
  return {
    type: LOGOUT_ERROR,
    payload: err
  }
};

export const clearErrMsg = () => {
  return {
    type: CLEAR_ERR_MSG
  }
}
