import React, { useEffect, useState } from "react";
import { Accordion, Button, Card } from "react-bootstrap";
import App from "../../App";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { getTableData } from "../../services/commonServices";

const faq = [
  {
    _id: "123",
    question: "What's going on?",
    answer: "Hello! I'm the body",
  },
  {
    _id: "124",
    question: "What's going on?",
    answer: "Hello! I'm the body",
  },
  {
    _id: "125",
    question: "What's going on?",
    answer: "Hello! I'm the body",
  },
];

const SupportFaq = () => {
  const [supportFaqList, setSupportFaqList] = useState([]);
  const [pagination, setPagination] = useState({});
  const [queryData, setQueryData] = useState({
    sort_direction: -1,
    limit: 50,
  });
  const [isLoading, setIsloading] = useState(false);

  useEffect(() =>{
    getSupportFaqs();
  }, [])

  const getSupportFaqs = () => {
    getTableData("/support-faq", queryData)
      .then((res) => {
        setSupportFaqList(res.items);
        setIsloading(true);
        setPagination(res.pagination);
        console.log(res.items);
        // this.setState({supportFaqList: res.items, isLoading: false, pagination: res.pagination})
      })
      .catch((errMsg) => {
        toast.error(errMsg);
      });
  };

  return (
    <App layout={"boxed"}>
      <div className="card-block">
        <div className="block-header">
          <h4 className="block-title">FAQ</h4>
        </div>

        <div className="block-body">
          {supportFaqList?.length > 0 && (
            <Accordion className="mb-4" defaultActiveKey={0}>
              {supportFaqList.map((faq, index) => (
                <Card>
                  <Card.Header>
                    <Accordion.Toggle
                      as={Button}
                      variant="link"
                      eventKey={index}
                    >
                      {faq?.question}
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey={index}>
                    <Card.Body>{faq?.answer}</Card.Body>
                  </Accordion.Collapse>
                </Card>
              ))}
            </Accordion>
          )}
          Do you need more support?{" "}
          <Link to={"/create-ticket"} className="text-bold text-primary">
            Create support Ticket
          </Link>
        </div>
      </div>
    </App>
  );
};

export default SupportFaq;
