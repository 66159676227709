import React from 'react';
import Header from "./layouts/Header";
import Sidebar from "./layouts/Sidebar";
import {connect} from "react-redux";
import {getLocalAuthData} from "./helper/utils";
import {DECLINED, IN_VERIFICATION, INCOMPLETE, NEED_VERIFICATION} from "./helper/variables";
import {Redirect, useHistory} from "react-router-dom";
import { WiDirectionDownRight } from 'react-icons/wi';

function App({layout, children, navbar}) {
  const authData = getLocalAuthData();
  let history = useHistory();

  if(authData){
    if ((authData.profile_status === NEED_VERIFICATION || authData.profile_status === DECLINED || authData.profile_status === IN_VERIFICATION) && history.location.pathname !== '/profile') {
      return <Redirect to={'/profile'}/>
    }
  }else{
    window.location.reload();
  }
  

  return (
    <>
      <Header/>

      <div className={navbar.isNavbarShowing ? 'container-wrap' : 'container-wrap collapsed-sidebar'}>
        <Sidebar/>

        <div className="main-container">
          {layout !== 'boxed' ? children
            :
            <div className="layout-boxed">
              {children}
            </div>
          }

        </div>
      </div>
    </>
  );
}

const mapStateToProps = state => {
  return {
    navbar: state.navbar
  }
};

export default connect(mapStateToProps)(App);
