import React, {Component} from 'react';
import App from "../../App";
import Product from "../../components/Product";
import "../../assets/scss/product-list.scss";
import {FiUpload, FaFileExport, FiPlus} from "react-icons/all";
import {Link} from "react-router-dom";
import {
    deleteProduct,
    hardDeleteProduct,
    getProductList,
    changeProductStatus,
    changeProductPublishStatus, bulkImport
} from "../../services/productServices";
import {Row, Col, Modal, Button, Form} from "react-bootstrap";
import axios from "axios";
import {API_BASE_URL} from "../../helper/env";
import {confirmAlert} from "react-confirm-alert";
import {getChildLocations} from "../../services/locationServices";
import {getDeviceType, getLocalAuthData} from "../../helper/utils";
import {toast} from "react-toastify";
import Pagination from 'react-js-pagination';
import {getStoreList} from "../../services/storeServices";
import Select from "react-select";
import styleReactSelect from "../../assets/styleReactSelect";
import bulkProductSample from "../../assets/resources/bulk-product-sample.xlsx"
import {productPaymentMethods, updateProductPaymentMethods} from "../../services/paymentMethodServices";
import {
  shippingOptions,
  addShippingOption,
  updateShippingOption,
  deleteShippingOption,
  deleteShippingOptionPermanent,
  getSingleShippingOption
} from "../../services/shippingMethodServices";
import {FiTrash} from "@react-icons/all-files/fi/FiTrash";
import {FiEdit} from "@react-icons/all-files/fi/FiEdit";

class ProductList extends Component {
    state = {
        showAdvanceSearchOptions: false,
        entrepreneurType: [],
        productList: [],
        storeList: [],
        queryData: [],
        activePage: 1,
        isLoading: true,
        pagination: {},
        limit: 10,
        locations: [],
        businessChildLocations: [],
        isShowingBulkImportModal: false,
        bulkImportFormData: null,
        isUploading: false,


      merchantList: [],
      isExporting: false,
      isShowingBulkImportSuccessModal: false,
      invalidProducts: [],
      isLoadingStoreData: false,

      selectedProductId: '',
      isShowingProductSettingModal: false,
      isSubmittingPaymentMethods: false,
      defaultPaymentMethod: ["cash on delivery", "pegepay", "Bank Payment", "sslcommerz"],
      selectedPaymentMethods: [],

      activeSettingTab: 'Payment Methods',
      selectedShippingPartners: [],
      shippingChildLocations: [],
      shipping_methods: [],
      shippingFormData: {
        shipping_option: "",
        location_hierarchy: [],
        locations: [],
        destination_location: "",
        estimated_time: "",
        fee: 0,
      },
      existing_locations: [],
      editStatus: false,
      selectedShippingId: '',

      isShowingProductCloneModal: false,
      productCloneFormData: {store_id: null, campaign_product: false, is_featured: false},
      selectedProductDetail: {}
    };

    componentDidMount() {
        getProductList({limit: this.state.limit, page: this.state.pagination.page || 1})
            .then(res => {
                this.setState({productList: res.products, isLoading: false, pagination: res.pagination})
            })
            .catch(err => {
                this.setState({isLoading: false})
            })

        axios.get(`${API_BASE_URL}/merchantType`)
            .then(res => {
                this.setState({entrepreneurType: res.data.merchant_type_obj});
            }).catch(e => this.setState({error: true, isLoading: false}));

        /*axios.get(`${API_BASE_URL}/location?type=Country`)
            .then(res => {
                this.setState({locations: res.data.locations});
            }).catch(e => console.log("Error"));*/

        getStoreList({lite_data: 1})
            .then(res => {
                const storeList = [];
                res.items.forEach(item => {
                    const store = {
                        id: item._id,
                        value: item._id,
                        label: item.store_name
                    }
                    storeList.push(store)
                })
                this.setState({storeList});
            })
            .catch(errMsg => {
                console.log(errMsg)
            })

      axios.get(`${API_BASE_URL}/location?type=Country`).then((res) => {
        let existing_locations = [...this.state.existing_locations];
        existing_locations[0] = res.data.locations;
        this.setState(
            {
              existing_locations,
              locations: res.data.locations,
              countries: res.data.locations,
            },
            () => {
              console.log(this.state);
            }
        );
      }).catch((e) => console.log("Error4"));

    }

    getQueryData = async () => {
        const authData = getLocalAuthData();
        let queryData = "";
        await Object.entries(this.state.queryData).map(([key, val]) => {
                queryData = queryData.concat(key + "=" + val + "&");
            }
        )
        this.setState({isLoading: true});
        axios.get(`${API_BASE_URL}/product?limit=${this.state.limit}&${queryData}`, {
            headers: {
                'x-auth-token': authData.token,
                'x-api-client': getDeviceType()
            }
        })
            .then(res => {
                this.setState({productList: res.data.products, isLoading: false, pagination: res.data.pagination})
            }).catch(e => {
            this.setState({isLoading: false})
        });
    }

    handleSearchFormDataChange = (e) => {
        this.setState({queryData: {...this.state.queryData, [e.target.name]: e.target.value}});
    };


    handleShowAdvanceSearchOptions = () => {
        this.setState({
            showAdvanceSearchOptions: !this.state.showAdvanceSearchOptions
        })
    }

    handleProductDelete = (id) => {
        confirmAlert({
            title: 'Delete',
            message: 'Are you sure to delete this product?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => deleteProduct(id)
                        .then(msg => {
                            toast.success(msg);
                            getProductList({limit: this.state.limit, page: this.state.pagination?.page || 1})
                                .then(res => {
                                    this.setState({productList: res.products, isLoading: false, pagination: res.pagination})
                                })
                        })
                        .catch(errMsg => {
                            toast.error(errMsg)
                        })
                },
                {
                    label: 'No',
                    // onClick: () => alert('Click No')
                }
            ]
        });
    }

    handleHardDeleteProduct = (id) => {
        confirmAlert({
            title: 'Delete',
            message: 'Are you sure to delete this product?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => hardDeleteProduct(id)
                        .then(msg => {
                            toast.success(msg);
                            getProductList()
                                .then(res => {
                                    this.setState({productList: res.products, isLoading: false, pagination: res.pagination})
                                })
                        })
                        .catch(errMsg => {
                            toast.error(errMsg)
                        })
                },
                {
                    label: 'No',
                    // onClick: () => alert('Click No')
                }
            ]
        });
    }

    handlePageChange = (pageNumber) => {
        this.setState({queryData: {...this.state.queryData, page: pageNumber}, activePage: pageNumber}, () => {
            this.getQueryData();
        });
    }

    handleProductStatus = (id, status) => {
        confirmAlert({
            title: 'Change Status',
            message: 'Are you sure to change this product status?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => changeProductStatus(id, status)
                        .then(msg => {
                            toast.success(msg);
                            getProductList(this.state.limit)
                                .then(res => {
                                    this.setState({productList: res.products, isLoading: false, pagination: res.pagination})
                                })
                        })
                        .catch(errMsg => {
                            toast.error(errMsg)
                        })
                },
                {
                    label: 'No',
                    // onClick: () => alert('Click No')
                }
            ]
        });
    }

    handleProductPublishStatus = (id, status) => {
        confirmAlert({
            title: 'Publish/Unpublish Status',
            message: 'Are you sure to change this product status?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => changeProductPublishStatus(id, status)
                        .then(msg => {
                            toast.success(msg);
                            getProductList({limit: this.state.limit, page: this.state.pagination?.page || 1})
                                .then(res => {
                                    this.setState({productList: res.products, isLoading: false, pagination: res.pagination})
                                })
                        })
                        .catch(errMsg => {
                            toast.error(errMsg)
                        })
                },
                {
                    label: 'No',
                    // onClick: () => alert('Click No')
                }
            ]
        });
    }

    handleSearchFormDataChange = (e) => {
        this.setState({queryData: {...this.state.queryData, [e.target.name]: e.target.value, page: 1}});
    };

    handleLocationInputOnChange = (e, index, typePurpose) => {
        const childLocations = [...this.state[typePurpose]];
        if (typePurpose === 'businessChildLocations') {
            this.handleSearchFormDataChange(e)
        } else {
            this.handleSearchFormDataChange(e)
        }
        if (e.target.value !== '') {
            getChildLocations(e.target.value)
                .then(res => {
                    childLocations.splice(index, childLocations.length - index);
                    if (res.length > 0) {
                        childLocations[index] = res
                    }
                    this.setState({[typePurpose]: childLocations})
                })
                .catch(errMsg => {
                    console.log(errMsg)
                    childLocations.splice(index, childLocations.length - index)
                    this.setState({[typePurpose]: childLocations})
                })
        } else {
            childLocations.splice(index, childLocations.length - index);
            this.setState({[typePurpose]: childLocations})
        }
    }

    renderLocations = () => {
        const locations = this.state.locations;
        if (locations) {
            return (
                <>
                    {locations.map((items, index) =>
                        <option value={`${items._id}`}>{items.name}</option>
                    )}
                </>
            )
        }
    };

    selectOnChange = (value, name) => {
        const bulkImportFormData = {...this.state.bulkImportFormData}
        bulkImportFormData[name] = value.value
        this.setState({bulkImportFormData})
    }

    onFileUploadOnChange = (e) => {
        const file = e.target.files[0];
        const bulkImportFormData = {...this.state.bulkImportFormData}
        bulkImportFormData[e.target.name] = file
        this.setState({bulkImportFormData})
    }

    showBulkImporterModal = () => {
        this.setState({isShowingBulkImportModal: true})
    }

    hideBulkImporterModal = () => {
        this.setState({isShowingBulkImportModal: false})
    }

    handleImportBulkProduct = (e) => {
        e.preventDefault()
        this.setState({isUploading: true}, () => {
            bulkImport(this.state.bulkImportFormData)
                .then(res => {
                    this.setState({
                        storeList: [],
                        bulkImportFormData: null,
                        isLoading: true,
                        isShowingBulkImportModal: false,
                        isUploading: false
                    }, () => {
                        getProductList({limit: this.state.limit})
                            .then(res => {
                                this.setState({productList: res.products, isLoading: false, pagination: res.pagination})
                            })
                            .then(errMsg => {
                                this.setState({isLoading: false})
                            })
                    })
                    toast.success(res.message)
                })
                .catch(errMsg => {
                    this.setState({isUploading: false})
                    toast.error(errMsg)
                })
        })
    }



  handleProductSettings = (id, name, store_name) => {
    let promises = [];
    promises.push(
        productPaymentMethods(id).then(res => {
          this.setState({selectedProductId: id, selectedPaymentMethods: res.payment_methods});
        }).catch(errMsg => {
          toast.error(errMsg.message);
        })
    )

    promises.push(
        this.getShippingOptions({product_id: id})
        /*shippingOptions({product_id: id}).then(res => {
          this.setState({selectedProductId: id, shipping_options: res.shipping_options});
        }).catch(errMsg => {
          toast.error(errMsg.message);
        })*/
    )

    Promise.all(promises).then(res => {
      this.setState({selectedProductId: id, isShowingProductSettingModal: true, shippingFormData: {...this.state.shippingFormData, ["product_id"]: id}})
    }).catch(err => {
      console.log(err);
      this.setState({isLoading: false})
    });
  }

  getShippingOptions = (params = {}) => {
    shippingOptions(params).then(res => {
      this.setState({shipping_options: res.items, shippingMethodsPagination: res.pagination});
    }).catch(errMsg => {
      toast.error(errMsg.message);
    });
  }

  handleCloseProductSettingModal = () => {
    this.setState({selectedProductId: '', isShowingProductSettingModal: false});
  }

  submitChangePaymentMethods = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    let selectedPaymentMethods = [...this.state.selectedPaymentMethods];

    this.setState({isSubmittingPaymentMethods: true}, () => {
      console.log(selectedPaymentMethods);
      updateProductPaymentMethods({payment_methods: selectedPaymentMethods}, this.state.selectedProductId).then(res => {
        if (res.status === 1) {
          this.setState({isSubmittingPaymentMethods: false});
          toast.success(res.message);
        } else {
          this.setState({isSubmittingPaymentMethods: false});
          toast.warning(res.message);
        }

      }).catch(errMsg => {
        toast.error(errMsg);
      })
    });
  }

  handleOnChangePaymentMethods = (e, methodName) => {
    const selectedPaymentMethods = [...this.state.selectedPaymentMethods];
    var index = selectedPaymentMethods.indexOf(methodName);
    if (e.target.checked) {
      if (index === -1) {
        selectedPaymentMethods.push(methodName);
      }
    } else {
      if (index > -1) {
        selectedPaymentMethods.splice(index, 1);
      }
    }
    this.setState({selectedPaymentMethods}, () => {
      console.log(this.state.selectedPaymentMethods);
    });
  }
/*

  submitChangeShippingMethods = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    let shippingFormData = {...this.state.shippingFormData};

    this.setState({isSubmittingShippingMethods: true}, () => {
      if (this.state.editStatus === false) {
        addShippingOption(this.state.shippingFormData)
            .then((res) => {
              if (res.status === 1) {
                this.getShippingOptions({product_id: shippingFormData.product_id});
                this.setState({isSubmittingShippingMethods: false});
                this.setNewShippingFormData()
                toast.success(res.message);
              } else {
                this.setState({isSubmittingShippingMethods: false});
                toast.warning(res.message);
              }
            })
            .catch((errMsg) => {
              toast.error(errMsg);
            });
      } else if (this.state.editStatus === true && this.state.selectedShippingId !== '') {
        updateShippingOption(this.state.shippingFormData, this.state.selectedShippingId).then((res) => {
          if (res.status === 1) {
            this.getShippingOptions({product_id: shippingFormData.product_id});
            this.setState({isSubmittingShippingMethods: false});
            this.setNewShippingFormData()
            toast.success(res.message);
          } else {
            this.setState({isSubmittingShippingMethods: false});
            toast.warning(res.message);
          }
        }).catch((errMsg) => {
          toast.error(errMsg);
        });
      } else {
        toast.warning("Required Data missing, refresh and try again or try later.");
      }
    });
  }
*/



    submitChangeShippingMethods = (e) => {
        e.preventDefault();
        const form = e.currentTarget;
        let shippingFormData = {...this.state.shippingFormData};

        this.setState({isSubmittingShippingMethods: true}, () => {
            if (this.state.editStatus === false) {
                addShippingOption(this.state.shippingFormData)
                    .then((res) => {
                        if (res.status === 1) {
                            this.getShippingOptions({product_id: shippingFormData.product_id});
                            this.setState({isSubmittingShippingMethods: false});
                            this.setNewShippingFormData()
                            toast.success(res.message);
                        } else {
                            this.setState({isSubmittingShippingMethods: false});
                            toast.warning(res.message);
                        }
                    })
                    .catch((errMsg) => {
                        toast.error(errMsg);
                    });
            } else if (this.state.editStatus === true && this.state.selectedShippingId !== '') {
                updateShippingOption(this.state.shippingFormData, this.state.selectedShippingId).then((res) => {
                    if (res.status === 1) {
                        this.getShippingOptions({product_id: shippingFormData.product_id});
                        this.setState({isSubmittingShippingMethods: false});
                        this.setNewShippingFormData()
                        toast.success(res.message);
                    } else {
                        this.setState({isSubmittingShippingMethods: false});
                        toast.warning(res.message);
                    }
                }).catch((errMsg) => {
                    toast.error(errMsg);
                });
            } else {
                toast.warning("Required Data missing, refresh and try again or try later.");
            }
        });
    }


    searchShippingOptions = async () => {
    this.getShippingOptions({
      ...this.state.shippingQueryData,
      page: 1,
        product_id: this.state.selectedProductId,
      destination_location: this.state.shippingFormData.destination_location,
      shipping_option: this.state.shippingFormData.shipping_option,
      store_id: this.state.selectedStoreId
    })
  }
/*
  setNewShippingFormData = () => {
    this.setState({
      shippingFormData: {
        shipping_option: "ekShop Fulfillment",
        location_hierarchy: [],
        locations: [],
        destination_location: "",
        estimated_time: "",
        fee: 0,
        product_id: this.state.selectedProductId,
      },
      existing_locations: this.state.existing_locations.splice(0, 1)
    }, () => {

    })
  }*/


    setNewShippingFormData = () => {
        this.setState({
            editStatus: false,
            shippingFormData: {
                location_hierarchy: [],
                locations: [],
                destination_location: "",
                estimated_time: "",
                fee: 0,
                product_id: this.state.selectedProductId,
            },
            existing_locations: this.state.existing_locations.splice(0, 1)
        }, () => {

        })
    }


    handleOnChangeShippingForm = (e) => {
    console.log(e.target.name);
    const shippingFormData = {...this.state.shippingFormData};
    shippingFormData[e.target.name] = e.target.value;
    this.setState({shippingFormData}, () => {
      console.log("this.state");
      console.log(this.state.shippingFormData);
    });
  }

  handleShippingMethodsPageChange = async (pageNumber) => {
    this.setState({shippingQueryData: {...this.state.shippingQueryData, page: pageNumber}}, () => {
      this.getShippingOptions({store_id: this.state.selectedStoreId, limit: 10, page: pageNumber});
    });
  }

  handleBlockTab = (label) => {
    this.setState({activeSettingTab: label})
  }

  handleShippingLocationInputOnChange = (e, index, typePurpose) => {
    const existing_locations = [...this.state.existing_locations];
    if (typePurpose === "shippingChildLocations") {
    } else {
      this.handleReturnInputOnChange(e);
    }

    const shippingFormData = {...this.state.shippingFormData};
    // const updatedShippingAddress = {...this.state.updatedShippingAddress};
    let location_hierarchy = shippingFormData.location_hierarchy;
    let locations = shippingFormData.locations;

    if (e.target.value !== "") {
      if (index >= 0 && existing_locations.length > index) {
        existing_locations.splice(index + 1, existing_locations.length - index);
        location_hierarchy.splice(index, location_hierarchy.length - index);
        locations.splice(index, locations.length - index)
      }

      let selected_location_array = existing_locations[index];
      let selected_location = selected_location_array.find(
          (element) => element._id === e.target.value
      );
      location_hierarchy.push(selected_location._id);
      locations.push(selected_location)
      shippingFormData.location_hierarchy = location_hierarchy;
      shippingFormData.destination_location = shippingFormData.location_hierarchy.slice(-1)[0] //selected_location._id;
      shippingFormData.locations = locations
        console.log("Loaction" , locations)

      //   updatedShippingAddress.location_hierarchy = location_hierarchy
      //   updatedShippingAddress.locations = locations

      this.setState({shippingFormData}, () => {
        console.log(this.state.shippingFormData.location_hierarchy);
      });
      // this.setState({shippingFormData, updatedShippingAddress}, () => {
      //     console.log(this.state.shippingFormData.locations);
      // });

      getChildLocations(e.target.value)
          .then((res) => {
            if (res.length > 0) {
              existing_locations[index + 1] = res;
            }
            this.setState({existing_locations}, () => {
              console.log("locations");
              console.log(this.state);
            });
          })
          .catch((errMsg) => {
            console.log(errMsg);
            // childLocations.splice(index, childLocations.length - index)
            // this.setState({[typePurpose]: childLocations})
          });
    } else {
      if (index > 0 && existing_locations.length > index) {
        existing_locations.splice(index + 1, existing_locations.length - index);
        location_hierarchy.splice(index, location_hierarchy.length - 1);
        // locations.splice(index, locations.length - 1)
      }
      shippingFormData.location_hierarchy = location_hierarchy;
      shippingFormData.destination_location = shippingFormData.location_hierarchy.slice(-1)[0];
      locations = locations.filter((element, index) => index < locations.length - 1);
        console.log("Loaction 2" , locations)
      shippingFormData.locations = locations

      this.setState({shippingFormData, existing_locations}, () => {
        console.log(this.state.shippingFormData.locations);
      });
    }
  };


  editShippingOption = (id) => {
    getSingleShippingOption(id).then((res) => {
      if (res.status === 1) {

          this.setState({
              shippingFormData: res.shipping_option
          },()=>{
              console.log("1010", this.state.shippingFormData)
          })

          this.setState(
            {existing_locations: [this.state.existing_locations[0]], selectedShippingId: id, editStatus: true},
            () => {
              console.log("this.state.selectedShippingId", this.state.selectedShippingId);
              res.shipping_option.location_hierarchy.forEach((item, index) => {
                getChildLocations(item)
                    .then((res) => {
                      const existing_locations = [
                        ...this.state.existing_locations,
                      ];
                      existing_locations[index + 1] = res;
                      this.setState({existing_locations});
                    })
                    .catch((errMsg) => {
                      console.log(errMsg);
                    });

                this.setState({isShowEditShippingAddressModal: true});
              });
              this.setState({shippingFormData: res.shipping_option}, () => {
                console.log("this.state.shippingFormData>>>>>", this.state.shippingFormData);
                console.log("res.shipping_option>>>>>", res.shipping_option);
              });
            }
        );
      } else {
        toast.error("Error2");
      }
    })
        .catch((err) => {
          toast.error("Error3");
        });
  };

  deleteShippingOption = (id) => {
    deleteShippingOptionPermanent(id).then(res => {
      if (res.status === 1) {
        this.getShippingOptions({product_id: this.state.selectedProductId});
        this.setNewShippingFormData();
        toast.warning(res.message);
      } else if (res.status === 2) {
        toast.warning(res.message)
      }
    }).catch(errMsg => {
      console.log(errMsg);
      toast.error(errMsg.message);
    })
  }




  render() {
        return (
            <App layout="boxed">
                <Modal show={this.state.isShowingBulkImportModal}
                       onHide={this.hideBulkImporterModal}>
                    <Form onSubmit={this.handleImportBulkProduct}>
                        <Modal.Header closeButton>
                            <Modal.Title>Modal heading</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form.Group as={Row} controlId="formStore">
                                <Form.Label column sm="3">
                                    Store <span className="text-danger">*</span>
                                </Form.Label>
                                <Col sm="9" md={9} lg={9}>
                                    <Select
                                        styles={styleReactSelect}
                                        placeholder="Select store..."
                                        onChange={(value) => this.selectOnChange(value, 'store_id')}
                                        options={this.state.storeList}
                                        name="attributes"
                                        noOptionsMessage={() => 'No store found'}
                                    />
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} controlId="formFile">
                                <Form.Label column sm="3">
                                    File <span className="text-danger">*</span>
                                </Form.Label>
                                <Col sm="9" md={9} lg={9}>
                                    <Form.Control type={`file`}
                                                  required
                                                  onChange={this.onFileUploadOnChange}
                                                  name={`file`}/>
                                </Col>
                            </Form.Group>

                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.hideBulkImporterModal}>
                                Close
                            </Button>
                            <Button variant="primary"
                                    disabled={this.state.isUploading}
                                    type={`submit`}>
                                {this.state.isUploading ? 'Importing...' : 'Import Now'}
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal>

              <Modal show={this.state.isShowingProductSettingModal} onHide={this.handleCloseProductSettingModal} size={'xl'}>
                <Modal.Header closeButton>
                  <Modal.Title>Product Settings</Modal.Title>
                </Modal.Header>

                <Modal.Body>

                  <div className="card-block mb-0">
                    <ul className="desc-header-nav">
                      <li className={this.state.activeSettingTab === 'Payment Methods' ? 'active' : ''}>
                        <span onClick={() => this.handleBlockTab('Payment Methods')}>Payment Methods</span>
                      </li>

                      <li className={this.state.activeSettingTab === 'Shipping Options' ? 'active' : ''}>
                        <span onClick={() => this.handleBlockTab('Shipping Options')}>Shipping Options</span>
                      </li>

                    </ul>

                    <div className="block-body">

                      {this.state.activeSettingTab === 'Payment Methods' &&
                      <>
                        <div className="card-block">
                          <Form onSubmit={this.submitChangePaymentMethods}>
                            <div className="block-header">
                              <h4 className="block-title">Payment Methods</h4>

                              <Button disabled={this.state.isSubmittingPaymentMethods}
                                      variant="primary" type={`submit`} className={'btn btn-sm'}>
                                {this.state.isSubmittingPaymentMethods ? 'Submitting...' : 'Save'}
                              </Button>
                            </div>

                            <div className="block-body">
                              <Form.Group>
                                <Col sm="12">
                                  <Form.Check
                                      custom inline type="checkbox" name="payment_methods" id={`paymentMethodCOD`}
                                      label={`Cash On Delivery`} value={"cash on delivery"}
                                      onChange={e => this.handleOnChangePaymentMethods(e, "cash on delivery")}
                                      checked={this.state.selectedPaymentMethods.includes("cash on delivery")}/>
                                  <Form.Check
                                      custom inline type="checkbox" name="payment_methods" id={`paymentMethodBankPayment`}
                                      label={`Bank Payment`} value={"Bank Payment"}
                                      onChange={e => this.handleOnChangePaymentMethods(e, "Bank Payment")}
                                      checked={this.state.selectedPaymentMethods.includes("Bank Payment")}/>
                                  <Form.Check
                                      custom inline type="checkbox" name="payment_methods" id={`paymentMethodSSlCommerz`}
                                      label={`sslCommerz (Online Payment)`} value={"sslcommerz"}
                                      onChange={e => this.handleOnChangePaymentMethods(e, "sslcommerz")}
                                      checked={this.state.selectedPaymentMethods.includes("sslcommerz")}/>
                                  <Form.Check
                                      custom inline type="checkbox" name="payment_methods" id={`paymentMethodpegepay`}
                                      label={`pegepay (Malaysia Online Payment)`} value={"pegepay"}
                                      onChange={e => this.handleOnChangePaymentMethods(e, "pegepay")}
                                      checked={this.state.selectedPaymentMethods.includes("pegepay")}/>
                                </Col>
                              </Form.Group>

                            </div>
                          </Form>
                        </div>
                      </>
                      }


                      {this.state.activeSettingTab === 'Shipping Options' &&
                      <>
                        <div className="card-block">
                          <Form onSubmit={this.submitChangeShippingMethods}>
                            <div className="block-header">
                              <h4 className="block-title">Add Shipping Methods</h4>

                              {/*<Button disabled={this.state.isSubmittingShippingMethods}
                                                            variant="primary" type={`submit`} className={'btn btn-sm'}>
                                                        {this.state.isSubmittingShippingMethods ? 'Submitting...' : 'Save'}
                                                    </Button>*/}

                              <div>

                                <Button
                                    disabled={this.state.isSubmittingShippingMethods}
                                    variant="primary"
                                    type={`button`}
                                    onClick={this.searchShippingOptions}
                                    className={"btn btn-sm ml-5"}>
                                  {"Search"}
                                </Button>

                                <Button
                                    disabled={this.state.isSubmittingShippingMethods}
                                    variant="primary"
                                    type={`button`}
                                    onClick={this.setNewShippingFormData}
                                    className={"btn btn-sm ml-5"}>
                                  {"Reset"}
                                </Button>


                                <Button disabled={this.state.isSubmittingShippingMethods}
                                        variant="primary" type={`submit`} className={'btn btn-sm ml-5'}>
                                  {this.state.isSubmittingShippingMethods ? 'Submitting...' : 'Save'}
                                </Button>
                              </div>
                            </div>

                            <div className="block-body">

                              <Form.Group>
                                <Col sm="12">
                                  <Form.Check
                                      checked={this.state.shippingFormData.shipping_option === 'Seller Delivery' ? true : false}
                                      custom inline type="radio" name="shipping_option" id={`shippingSellerDelivery`}
                                      label={`Seller Delivery`} value={"Seller Delivery"}
                                      onChange={e => this.handleOnChangeShippingForm(e)}/>

                                  <Form.Check
                                      checked={this.state.shippingFormData.shipping_option === 'ekShop Fulfillment' ? true : false}
                                      custom inline type="radio" name="shipping_option" id={`shippingEkshopFulfillment`}
                                      label={`ekShop Fulfillment`} value={"ekShop Fulfillment"}
                                      onChange={e => this.handleOnChangeShippingForm(e)}/>
                                </Col>
                              </Form.Group>

                              <Form.Group as={Row} controlId="formCountry">
                                {this.state?.existing_locations.length > 0 &&
                                this.state?.existing_locations.map((location, index) => {
                                  return (
                                      <>
                                        {location?.length > 0 && (
                                            <>
                                              <Form.Label column sm="2">
                                                {location[0].type}
                                              </Form.Label>
                                              <Col sm="4" md={4} lg={4}>
                                                <Form.Control
                                                    as="select"
                                                    className="mb-3"
                                                    name={location[0].type.toLowerCase()}
                                                    required={location[0].type === 'Country' ? true : false}
                                                    value={
                                                      this.state.shippingFormData
                                                          ?.location_hierarchy[index]
                                                          ? this.state.shippingFormData
                                                              ?.location_hierarchy[index]
                                                          : ""
                                                    }
                                                    onChange={(e) =>
                                                        this.handleShippingLocationInputOnChange(
                                                            e,
                                                            index,
                                                            "shippingChildLocations"
                                                        )
                                                    }
                                                >
                                                  <option value="">--Select--</option>
                                                  {location.map((item, index) => (
                                                      <option key={index} value={item._id}>
                                                        {item.name}
                                                      </option>
                                                  ))}
                                                </Form.Control>

                                                <Form.Control.Feedback type="invalid">
                                                  Please select {location[0].type.toLowerCase()}
                                                  .
                                                </Form.Control.Feedback>
                                              </Col>
                                            </>
                                        )}
                                      </>
                                  );
                                })}
                              </Form.Group>

                              <Form.Group key={'deliveryFee'} as={Row}>
                                <Form.Label column sm="2">Fee <span className="text-danger">*</span></Form.Label>
                                <Col sm="4" md={4} lg={4}>
                                  <Form.Control type="number"
                                                onChange={e => this.handleOnChangeShippingForm(e)}
                                                value={this.state.shippingFormData?.fee}
                                                name={'fee'} id={`deliveryFee`}
                                                min={0} step={0.5} required placeholder={`Enter Delivery Fee`}/>
                                </Col>

                                <Form.Label column sm="2">Estimated Time <span className="text-danger">*</span></Form.Label>
                                <Col sm="4" md={4} lg={4}>
                                  <Form.Control type="text"
                                                onChange={e => this.handleOnChangeShippingForm(e)}
                                                value={this.state.shippingFormData?.estimated_time}
                                                name={'estimated_time'} id={`estimatedTime`}
                                                required placeholder={`Estimated Delivery Time`}/>
                                </Col>
                              </Form.Group>

                            </div>
                          </Form>
                        </div>

                        <div className="card-block">
                          <div className="block-header">
                            <h4 className="block-title">Available Shipping Options</h4>
                          </div>

                          <div className="block-body">
                            {this.state.isLoading ?
                                <>Loading...</>
                                :
                                <>
                                  <table className="table table-striped">
                                    <thead>
                                    <tr>
                                      <th style={{width: '20px'}}>#</th>
                                      <th>Shipping Option</th>
                                      <th>Location</th>
                                      <th>Fee</th>
                                      <th>Time</th>
                                      <th style={{width: `1%`}}/>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.shipping_options?.length > 0 ?
                                        this.state.shipping_options.map((item, index) => (
                                            <tr key={index}>
                                              <td>{index + 1}</td>
                                              <td>{item.shipping_option}</td>
                                              <td>{item?.destination_location_info}</td>
                                              <td>{item?.fee}</td>
                                              <td>{item?.estimated_time}</td>

                                              <td className="nowrap text-center">
                                                {/*<span className="action text-bold text-default mr-3" onClick={() => this.editShippingOption(item._id)}><FiEdit/> </span>
                                                                        <span className="action text-bold text-danger mr-3" onClick={() => this.deleteShippingOption(item._id)}><FiTrash/> Delete</span>*/}

                                                <td className="nowrap text-center">
                                                  <span className="action text-bold text-default mr-3 btn btn-outline-info btn-sm" onClick={() => this.editShippingOption(item._id)}><FiEdit/> Edit </span>
                                                  {getLocalAuthData().permission.includes("shipping_options_delete") ? (
                                                      <Button size={`sm`} onClick={() => this.deleteShippingOption(item._id)} variant={`outline-danger`}><FiTrash/>Delete</Button>
                                                  ) : null}
                                                </td>
                                              </td>
                                            </tr>
                                        )) :
                                        <tr>
                                          <td colSpan={7} className={`text-bold text-center`}>No item found</td>
                                        </tr>
                                    }
                                    </tbody>
                                  </table>
                                  <div className="mt-3">
                                    <Pagination
                                        itemClass="page-item"
                                        linkClass="page-link"
                                        activePage={this.state?.shippingMethodsPagination?.page}
                                        itemsCountPerPage={this.state?.shippingMethodsPagination?.limit}
                                        totalItemsCount={this.state?.shippingMethodsPagination?.totalDocs}
                                        pageRangeDisplayed={20}
                                        onChange={this.handleShippingMethodsPageChange.bind(this)}/>
                                  </div>
                                </>
                            }
                          </div>
                        </div>
                      </>
                      }
                    </div>


                  </div>
                </Modal.Body>

                <Modal.Footer>
                  <Button variant="default" onClick={this.handleCloseProductSettingModal}>Close</Button>
                </Modal.Footer>
              </Modal>



                <div className="page-header">
                    <h2 className="page-title">Products</h2>
                    <div className="ml-auto">
                        {/*  <Button size={"sm"} variant={"default"} className="mb-0 mr-2"><FaFileExport/> Export</Button>

            <Button size={`sm`} variant={'default'}
                    onClick={this.showBulkImporterModal}
                    className={`mr-2`}><FiUpload/> Import</Button>

            <a href={bulkProductSample} className="text-muted mr-3" download={`bulk-product-sample.xlsx`}><small>(Download sample file)</small></a>*/}

                        <Link to="/products/add" className="btn btn-sm btn-secondary"><FiPlus/> Add Product</Link>
                    </div>
                </div>

                <div className="main-content">
                    <div className="card-block">
                        <div className="block-header">
                            <h4 className="block-title">Product List</h4>
                        </div>

                        <div className="block-body">
                            <form onSubmit={(e) => e.preventDefault()} className="form-inline mb-3">
                                <div className="form-group mr-3">
                                    <label htmlFor="inputSearch" className={'sr-only'}>Search</label>
                                    <Form.Control type={'search'}
                                                  name={'search_string'}
                                                  size={'sm'}
                                                  onChange={this.handleSearchFormDataChange}
                                                  placeholder={'Search...'}
                                                  onKeyDown={(e) => {
                                                      if (e.keyCode === 13) {
                                                          this.getQueryData();
                                                      }
                                                  }}/>
                                </div>
                                <div className="form-group mr-3">
                                    <label htmlFor="status" className="sr-only">Status</label>
                                    <select id="status" name="status" className='form-control form-control-sm'
                                            onChange={this.handleSearchFormDataChange}>
                                        <option value="">--Select Status--</option>
                                        <option value="2">Blocked</option>
                                        <option value="1">Unblocked</option>
                                    </select>
                                </div>

                                <Form.Control as="select"
                                              className={'mr-3'}
                                              size={"sm"}
                                              name='publish_status'
                                              onChange={this.handleSearchFormDataChange}>
                                    <option value="">--Select--</option>
                                    <option value="2">Published</option>
                                    <option value="3">Unpublished</option>
                                </Form.Control>

                                <Form.Control as="select"
                                              className={'mr-3'}
                                              size={"sm"}
                                              name='campaign_product'
                                              onChange={this.handleSearchFormDataChange}>
                                    <option value="">--Select Product--</option>
                                    <option value="true">Campaign Product</option>
                                    <option value="false">Regular Product</option>
                                </Form.Control>

                                {this.state.storeList?.length > 0 &&
                                <Form.Control as="select"
                                              className={'mr-3'}
                                              size={"sm"}
                                              name="store_id"
                                              onChange={this.handleSearchFormDataChange}>
                                    <option value="">--Select Store--</option>
                                    {this.state.storeList.map((store, index) => (
                                        <option key={index} value={store.id}>{store.label}</option>
                                    ))}
                                </Form.Control>}

                                <Button className="mr-2" size="sm"
                                        variant="secondary"
                                        onClick={this.getQueryData}
                                >Search</Button>
                                <Button size="sm" onClick={this.handleShowAdvanceSearchOptions}
                                        variant={this.state.showAdvanceSearchOptions ? 'default' : 'link text-muted'}>Advance
                                    Search</Button>
                            </form>
                            {this.state.showAdvanceSearchOptions &&
                            <div className="advance-search-options mb-4">
                                <div className="form-inline">
                                    <Form.Group>
                                        <div className="form-group mr-3">
                                            <label htmlFor="status" className="sr-only">Locations</label>
                                            <select id="status" name="country" className='form-control form-control-sm'
                                                    onChange={(e) => this.handleLocationInputOnChange(e, 0, 'businessChildLocations')}>
                                                <option value="">--Select Country--</option>
                                                {this.renderLocations()}
                                            </select>
                                        </div>

                                        {this.state.businessChildLocations.length > 0 &&
                                        this.state.businessChildLocations.map((location, index) => (
                                            <div className="form-group mr-3" key={index}>
                                                <label htmlFor={`location${location[0].type}`}
                                                       className="sr-only">{location[0].type}</label>
                                                <select id={`location${location[0].type}`}
                                                        name={location[0].type.toLowerCase()}
                                                        className='form-control form-control-sm'
                                                        onChange={(e) => this.handleLocationInputOnChange(e, index + 1, 'businessChildLocations')}>
                                                    <option value="">--Select--</option>
                                                    {location.map((item, index) => (
                                                        <option key={index} value={item._id}>{item.name}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        ))}

                                        <Form.Control className="mr-3"
                                                      as="select"
                                                      size={"sm"}
                                                      name="sort_by_sell"
                                                      onChange={this.handleSearchFormDataChange}>
                                            <option value="">--Select--</option>
                                            <option value="desc">High to Low Sell (Descending order)</option>
                                            <option value="asc">Low to High Sell (Ascending Order)</option>
                                        </Form.Control>
                                        <Form.Control as="select"
                                                      size={"sm"}
                                                      name="sort_by_price"
                                                      onChange={this.handleSearchFormDataChange}>
                                            <option value="">--Select--</option>
                                            <option value="desc">Add High rated (Descending order)</option>
                                            <option value="asc">Add Low rated (Ascending Order)</option>
                                        </Form.Control>
                                    </Form.Group>
                                </div>
                            </div>}

                            {this.state.isLoading ?
                                <div>Loading...</div>
                                :
                                <>
                                    {this.state.productList?.length > 0 ?
                                        <>
                                            <div className="product-list">
                                                {this.state.productList.map((product, index) => (
                                                    <div key={index} className="product-item">
                                                        <Product
                                                            handleProductSettings={() => {
                                                                this.handleProductSettings(product._id, product.name, product.store_info.store_name)
                                                            }}
                                                            productDelete={this.handleProductDelete}
                                                            productHardDelete={this.handleHardDeleteProduct}
                                                            handleProductStatus={this.handleProductStatus}
                                                            handleProductPublishStatus={this.handleProductPublishStatus}
                                                            data={product}/>
                                                    </div>
                                                ))}
                                            </div>

                                            <div className="mt-3">
                                                <Pagination
                                                    itemClass="page-item"
                                                    linkClass="page-link"
                                                    activePage={this.state.pagination?.page}
                                                    itemsCountPerPage={this.state.pagination?.limit}
                                                    totalItemsCount={this.state.pagination?.totalDocs}
                                                    pageRangeDisplayed={10}
                                                    onChange={this.handlePageChange.bind(this)}
                                                />
                                            </div>

                                        </>
                                        :
                                        <div className="alert alert-warning text-bold text-center">
                                            No product added yet. Please <Link to={`${process.env.PUBLIC_URL}/products/add`}>Add
                                            product</Link>
                                        </div>
                                    }
                                </>
                            }
                        </div>
                    </div>
                </div>
            </App>
        );
    }
}

export default ProductList;
