import {DASHBOARD_ORDER,} from "./types";

const initialState = {
  dashboardOrder: {
    order_reports: {}, // 1
    order_vs_sales_reports: {}, // 2
    users_reports: {}, // 3
    campaign_reports: {},
    last_month_order_reports: {},
    cancel_order_reports: {},
  },
};

const reducer = (state = initialState, {type, payload}) => {
  // let {dashboardOrder} = payload
  // payload.order_reports
  // payload.order_vs_sales_reports
 // console.log(payload)
  switch (type) {
    case DASHBOARD_ORDER:
      return {
        ...state,
        dashboardOrder: {...state.dashboardOrder, ...payload}
      };
    default:
      return state
  }
};

export default reducer;
