import React, {Component} from 'react';
import App from "../../App";
import {withRouter, Link} from "react-router-dom";
import {BsArrowLeft} from "react-icons/all";
import {Button, Col, Form, Row} from "react-bootstrap";
import BrowseFiles from "../../components/BrowseFiles";
import {getBankList} from "../../services/settingServices";
import {savePaymentMethod} from "../../services/paymentMethodServices";
import {toast} from "react-toastify";

class AddPaymentMethod extends Component {
  state = {
    formData: {
      method_type: 'Bank'
    },
    bankList: [],
    operatorList: []
  }

  componentDidMount() {
    getBankList()
      .then(res => {
        this.setState({bankList: res})
      })
      .catch(errMsg => {
        console.log(errMsg)
      })
  }

  handleMethodOnChange = (e) => {
    const formData = {}
    formData.method_type = e.target.value
    this.setState({formData})
  }

  handleRemoveImg = (key) => {
    const formData = {...this.state.formData};
    formData[key] = "";
    this.setState({formData});
  }

  handleOnBrowsePic = (files, name) => {
    const formData = {...this.state.formData}
    formData[name] = files[0].actual_img;
    this.setState({formData})
  }

  handleInputOnChange = e => {
    const formData = {...this.state.formData};
    formData[e.target.name] = e.target.value;
    if (e.target.name === 'bank_id') {
      const bank = this.state.bankList.filter(obj => {
        return obj._id === e.target.value
      })
      formData.bank_name = bank[0].name
    }
    this.setState({formData});
  }

  handlePaymentMethodTypeSave = (e) => {
    e.preventDefault()

    this.setState({isSaving: true}, () => {
      savePaymentMethod(this.state.formData)
        .then(res => {
          this.setState({isSaving: false})
          this.props.history.push('/payment-methods')
          toast.success(res.message)
        })
        .catch(errMsg => {
          this.setState({isSaving: false})
          toast.error(errMsg)
        })
    })
  }

  render() {
    return (
      <App layout={'boxed'}>
        <div className="page-header">
          <h2 className="page-title">Add Payment Method</h2>
          <div className="ml-auto">
            <Link to="/payment-methods" className="btn btn-sm btn-link"><BsArrowLeft/> Back to Payment Method List</Link>
          </div>
        </div>

        <div className="card-block">
          <div className="block-header">
            <h4 className="block-title">Payment Method Details</h4>
          </div>

          <div className="block-body">
            <Form onSubmit={this.handlePaymentMethodTypeSave}>
              <Form.Group as={Row} controlId="checkboxMethodType">
                <Form.Label column sm="3">
                  Method Type
                </Form.Label>
                <Col sm="9" md={8} lg={7}>
                  <Form.Check
                    custom
                    inline
                    onChange={this.handleMethodOnChange}
                    checked={this.state.formData.method_type === 'Bank'}
                    value={'Bank'}
                    label="Bank"
                    name={'method_type'}
                    type={'radio'}
                    id={`methodType-1`}/>

                  <Form.Check
                    custom
                    inline
                    onChange={this.handleMethodOnChange}
                    checked={this.state.formData.method_type === 'Mobile'}
                    value={'Mobile'}
                    label="Mobile"
                    name={'method_type'}
                    type={'radio'}
                    id={`methodType-2`}/>
                </Col>
              </Form.Group>

              {this.state.formData.method_type === 'Bank' && <>
                <Form.Group as={Row} controlId="inputBankAccountName">
                  <Form.Label column sm="3">
                    Bank Account Name
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <Form.Control type={'text'}
                                  onChange={this.handleInputOnChange}
                                  name={'bank_account_name'}
                                  placeholder={'Bank Account Name'}/>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="inputBankAccountNumber">
                  <Form.Label column sm="3">
                    Bank Account Number
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <Form.Control type={'text'}
                                  onChange={this.handleInputOnChange}
                                  name={'bank_account_number'}
                                  placeholder={'Bank account number'}/>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="inputBank">
                  <Form.Label column sm="3">
                    Bank
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <Form.Control name={'bank_id'}
                                  onChange={this.handleInputOnChange}
                                  as={'select'}>
                      <option label={'--Select a Bank--'}/>
                      {this.state.bankList.map((bank, index) => (
                        <option
                          key={index}
                          value={bank._id}>{bank.name}</option>
                      ))}
                    </Form.Control>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="inputBank">
                  <Form.Label column sm="3">
                    Branch
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <Form.Control type={'text'}
                                  onChange={this.handleInputOnChange}
                                  name={'bank_account_branch'} placeholder={'Branch name'}/>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="inputBankRoutingNumber">
                  <Form.Label column sm="3">Bank Routing Number</Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <Form.Control type={'text'}
                                  onChange={this.handleInputOnChange}
                                  name={'bank_routing_number'}
                                  placeholder={'Bank routing number'}/>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="inputBank">
                  <Form.Label column sm="3">
                    Cheque Image
                  </Form.Label>

                  <Col sm="9" md={8} lg={7}>
                    <BrowseFiles onGetFiles={(files) => {
                      this.handleOnBrowsePic(files, 'bank_account_cheque_img')
                    }}/>

                    {this.state.formData.bank_account_cheque_img &&
                    <div className="existing-images">
                      <div className="existing-img">
                          <span onClick={() => this.handleRemoveImg('bank_account_cheque_img')}
                                className="btn-remove">&times;</span>
                        <img src={this.state.formData.bank_account_cheque_img} alt=""/>
                      </div>
                    </div>}
                  </Col>
                </Form.Group>
              </>}

              {this.state.formData.method_type === 'Mobile' && <>
                <Form.Group as={Row} controlId="selectOperatorName">
                  <Form.Label column sm="3">
                    Operator Name
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <Form.Control name={'operator_name'}
                                  onChange={this.handleInputOnChange}
                                  as={'select'}>
                      <option label={'--Select a Operator--'}/>
                      {/*{this.state.operatorList.map((ope, index) => (
                        <option
                          key={index}
                          value={ope._id}>{ope.name}</option>
                      ))}*/}
                      <option value={'Nagad'}>Nagad</option>
                      <option value={'bKash'}>bKash</option>
                      <option value={'Rocket'}>Rocket</option>
                    </Form.Control>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="radioAccountType">
                  <Form.Label column sm="3">Account Type</Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <Form.Check
                      custom
                      inline
                      onChange={this.handleInputOnChange}
                      checked={this.state.formData.account_type === 'Personal'}
                      value={'Personal'}
                      label="Personal"
                      name={'account_type'}
                      type={'radio'}
                      id={`account-type-1`}/>

                    <Form.Check
                      custom
                      inline
                      onChange={this.handleInputOnChange}
                      checked={this.state.formData.account_type === 'Merchant'}
                      value={'Merchant'}
                      label="Merchant"
                      name={'account_type'}
                      type={'radio'}
                      id={`account-type-2`}/>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="inputMobileNumber">
                  <Form.Label column sm="3">Mobile Number</Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <Form.Control type={'text'}
                                  onChange={this.handleInputOnChange}
                                  name={'mobile_number'}
                                  placeholder={'Enter your mobile number'}/>
                  </Col>
                </Form.Group>
              </>}

              <Form.Group as={Row} controlId="submitButtonGroup">
                <Form.Label column sm="3"/>
                <Col sm="9" md={8} lg={7}>
                  <Button size={'lg'}
                          disabled={this.state.isSaving}
                          type={'submit'} variant={'primary'}>
                    {this.state.isSaving ? 'Saving...' : 'Save'}
                  </Button>
                </Col>
              </Form.Group>
            </Form>
          </div>
        </div>
      </App>
    );
  }
}

export default withRouter(AddPaymentMethod);