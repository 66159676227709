import React, { Component } from 'react';
import { Button } from "react-bootstrap";
import Form from "react-bootstrap/esm/Form";
import { FiArrowLeft } from "react-icons/fi";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import logo from '../assets/img/logo.png';
import "../assets/scss/auth.scss";
import { userIsLoggedin } from "../auth/authUtils";
import CaptchaWithCheckBox from '../components/captcha/CaptchaWithCheckBox';
import InputPassword from "../components/InputPassword";
import { OTP_RESEND_TIME } from "../helper/env";
import { getDeviceType } from "../helper/utils";
import { clearErrMsg, login } from "../redux";
import { changePassword, handleResetPassword } from "../services/authServices";
import { getLocationList } from "../services/locationServices";

class Login extends Component {

  //Captcha
  // const [captchaValue , setCaptchaValue] = useState(null);
  // const [showCaptcha , setShowCaptcha] = useState(false);

  constructor(props) {
    super(props);
    this.state = {
      isForgetPassword: false,
      login_id: '',
      password: '',
      isVerifiedCode: false,
      isLoading: false,
      otp: null, // TODO: Have to remove
      isLoginWithPhone: true,
      countryList: [],
      code: '+880',
      resendTime: OTP_RESEND_TIME,
      isSentOnce: false,
      captchaValue: null,
      showCaptcha: false
    }
  }

  getCaptchaValue = (value)=>{
      this.setState({captchaValue: value});
      console.log(value);
  }


  componentDidMount() {
    getLocationList()
      .then(res => {
        this.setState({countryList: res})
      })
  }

  handleForgetPassword = () => {
    this.props.clearErrMsg()
    this.setState({isForgetPassword: true})
  };

  handleBackToLogin = () => {
    this.props.clearErrMsg()
    this.setState({isForgetPassword: false})
  };

  handleLogin = (e) => {
    e.preventDefault();

    let data = {
      login_id: this.state.login_id,
      password: this.state.password,
      device_id: "",
      device_token: btoa(Math.random() + navigator.userAgent + Date()),
      device_type: getDeviceType(),
      os: navigator.platform
    }

    if (this.state.isLoginWithPhone) {
      data = {...data, login_id: `${this.state.code}-${this.state.phone}`}
    }

    this.props.login(data, (userData) => {
      this.setState({login_id: '', password: ''})
      // if (userData.profile_status === NEED_VERIFICATION) {
      //   this.props.history.push('/profile');
      // }
      this.props.history.push('/');
      // window.location.reload();
    })
  }

  

  handleOnInputChange = (e) => {
    let value = e.target.value
    if (e.target.name === 'phone' || e.target.type === 'text') {
      value = value.replace(/^0+/, '')
    }
    this.setState({
      [e.target.name]: value
    })
  }

  containerHeader = () => {
    if (!this.state.isForgetPassword && !this.state.isVerifiedCode) {
      return (
        <>
          <h4 className="auth-title">Login</h4>
          <p>to continue to admin panel</p>
        </>
      )
    } else if (this.state.isForgetPassword) {
      return (
        <>
          <h4 className="auth-title">Reset Password</h4>
          <p>Enter your registered login ID</p>
        </>
      )
    } else {
      return (
        <>
          <h4 className="auth-title">Reset Password</h4>
          <p>Enter your OTP and new password</p>
        </>
      )
    }
  }

  handleResetPassword = (e) => {
    e.preventDefault();

    this.setState({isLoading: true, resendTime: OTP_RESEND_TIME}, () => {
      let credentialsObj = {
        login_id: this.state.login_id,
        captcha_value: this.state.captchaValue
      };

      if (this.state.isLoginWithPhone) {
        credentialsObj = {
          login_id: `${this.state.code}-${this.state.phone}`,
          captcha_value: this.state.captchaValue
        }
      }

      handleResetPassword(credentialsObj)
        .then(res => {
          toast.success(res.message)
          this.setState({
            isForgetPassword: false,
            isVerifiedCode: true,
            // login_id: '',
            password: '',
            isLoading: false,
            // TODO: HAVE TO REMOVE OPT
            otp: res.otpValue,
            isOTPSent: true,
            isSentOnce: true
          }, () => {
            const resendTimeInterval = setInterval(() => {
              this.setState({resendTime: this.state.resendTime - 1}, () => {
                if (this.state.resendTime <= 0) {
                  clearInterval(resendTimeInterval);
                  this.setState({isOTPSent: false})
                }
              })
            }, 1000)
            document.getElementById('changePassForm').reset();
          });
        })
        .catch(errMsg => {
          toast.error(errMsg);
          this.setState({isLoading: false});
        })
    })
  }

  renderOtpButton = () => {
    if (this.state.isOTPSent && this.state.isSentOnce) {
      return <span className="icon-text before-verify disabled">
                <span className="text">OTP sent! Resend OTP in {this.state.resendTime} <span
                  className={"text-lowercase"}>s</span></span>
              </span>
    } else if (this.state.isLoading) {
      return <span className="icon-text before-verify disabled">
                 <span className="text">Sending OTP...</span>
               </span>
    } else {
      return <span className="icon-text before-verify">
                Did not receive OTP? <span style={{cursor: "pointer"}}
                                           onClick={this.handleResetPassword}
                                           className="text text-primary">Resend OTP</span>
              </span>
    }
  }

  handleChangePassword = (e) => {
    e.preventDefault();
    this.setState({isLoading: true})

    changePassword({token: this.state.token, password: this.state.password})
      .then(res => {
        toast.success(res.message)
        this.setState({isForgetPassword: false, isVerifiedCode: false, login_id: '', password: '', isLoading: false});
        document.getElementById('loginForm').reset();
      })
      .catch(errMsg => {
        toast.error(errMsg);
        this.setState({isLoading: false});
      })
  }

  handleChangeLoginIdType = (e) => {
    this.setState({isLoginWithPhone: e.target.value === 'true', login_id: ''})
  }

  containerForm = () => {
    if (!this.state.isForgetPassword && !this.state.isVerifiedCode) {
      return (
        <Form id={'loginForm'} onSubmit={this.handleLogin}>
          {this.props.authData.errMsg &&
          <div className="mb-2 text-bold text-danger">
            * {this.props.authData.errMsg}
          </div>}

          <div className="login-with">
            <div className="login-id-types">
              <label htmlFor="loginIdPhone" className={`login-type`}>
                <input value={true} type="radio"
                       onChange={this.handleChangeLoginIdType}
                       defaultChecked={this.state.isLoginWithPhone}
                       name={`login_type`} id={'loginIdPhone'}/>
                <span>
                  Phone
                </span>
              </label>
              <label htmlFor="loginIdEmail" className={`login-type`}>
                <input value={false} type="radio"
                       onChange={this.handleChangeLoginIdType}
                       defaultChecked={!this.state.isLoginWithPhone}
                       name={`login_type`} id={'loginIdEmail'}/>
                <span>
                  Email
                </span>
              </label>
            </div>
          </div>

          {this.state.isLoginWithPhone ?
            <Form.Group controlId="loginForm.email">
              <Form.Label className="sr-only">Login ID</Form.Label>
              <div className="input-group">
                <Form.Control as={'select'}
                              name={'code'}
                              required
                              value={this.state.code}
                              onChange={this.handleOnInputChange}
                              style={{
                                width: `80px`,
                                paddingRight: '10px',
                                flex: '0 0 80px'
                              }}>

                  <option value="">---</option>
                  {this.state.countryList.map((country, index) => (
                    <option key={index} value={country.code}>{country.code}</option>
                  ))}
                </Form.Control>

                <Form.Control onChange={this.handleOnInputChange}
                              type="text"
                              name="phone"
                              required
                              placeholder="Phone"/>
              </div>
            </Form.Group>
            :
            <Form.Group controlId="loginForm.email">
              <Form.Label className="sr-only">Login ID</Form.Label>
              <Form.Control onChange={this.handleOnInputChange}
                            type="email"
                            name="login_id"
                            required
                            placeholder="Email address"/>
            </Form.Group>
          }
          <Form.Group controlId="loginForm.password">
            <Form.Label className="sr-only">Password</Form.Label>

            <InputPassword onChange={this.handleOnInputChange}
                           required
                           name="password"
                           autoComplete="off"
                           placeholder="Password"/>
          </Form.Group>
          <span
            onClick={() => this.handleForgetPassword()}
            style={{cursor: 'pointer'}}
            className="d-block text-primary mb-3">Forget your password?</span>
          <Button variant="primary"
                  type="submit"
                  disabled={this.props.authData.loading}
                  block>{this.props.authData.loading ? 'Logging...' : 'login'}</Button>
        </Form>
      )
    } else if (this.state.isForgetPassword) {
      return (
        <Form id={"resetPassForm"} onSubmit={this.handleResetPassword}>
          <div className="login-with">
            <div className="login-id-types">
              <label htmlFor="loginIdPhone" className={`login-type`}>
                <input value={true} type="radio"
                       onChange={this.handleChangeLoginIdType}
                       defaultChecked={this.state.isLoginWithPhone}
                       name={`login_type`} id={'loginIdPhone'}/>
                <span>
                  Phone
                </span>
              </label>
              <label htmlFor="loginIdEmail" className={`login-type`}>
                <input value={false} type="radio"
                       onChange={this.handleChangeLoginIdType}
                       defaultChecked={!this.state.isLoginWithPhone}
                       name={`login_type`} id={'loginIdEmail'}/>
                <span>
                  Email
                </span>
              </label>
            </div>
          </div>

          {this.state.isLoginWithPhone ?
            <Form.Group controlId="loginForm.email">
              <Form.Label className="sr-only">Login ID</Form.Label>

              <div className="input-group">
                <Form.Control as={'select'}
                              name={'code'}
                              required
                              value={this.state.code}
                              onChange={this.handleOnInputChange}
                              style={{
                                width: `80px`,
                                paddingRight: '10px',
                                flex: '0 0 80px'
                              }}>
                  <option value="">---</option>
                  {this.state.countryList.map((country, index) => (
                    <option key={index} value={country.code}>{country.code}</option>
                  ))}
                </Form.Control>

                <Form.Control onChange={this.handleOnInputChange}
                              type="text"
                              name="phone"
                              required
                              placeholder="Phone"/>
              </div>
            </Form.Group>
            :
            <Form.Group controlId="loginForm.email">
              <Form.Label className="sr-only">Login ID</Form.Label>
              <Form.Control onChange={this.handleOnInputChange}
                            type="email"
                            name="login_id"
                            required
                            placeholder="Email address"/>
            </Form.Group>
          }

          <CaptchaWithCheckBox getCaptchaValue={this.getCaptchaValue} className="form-control" ></CaptchaWithCheckBox>
          
          {
            (this.state.captchaValue == null)?
            <Button variant="primary"
                  disabled={true}
                  block>{this.state.isLoading ? 'Sending OTP...' : 'Reset Password'}</Button>
            :
            <Button variant="primary"
                  type={"submit"}
                  disabled={this.state.isLoading}
                  block>{this.state.isLoading ? 'Sending OTP...' : 'Reset Password'}</Button>
          
          }
          
          <Button variant="link"
                  onClick={() => this.handleBackToLogin()}
                  block><FiArrowLeft/> Back to Login</Button>
        </Form>
      )
    } else {
      return (
        <Form onSubmit={this.handleChangePassword} id={"changePassForm"}>
          <Form.Group controlId="changePassForm.OTP">
            <Form.Label className="sr-only">OTP</Form.Label>
            <Form.Control name={"token"}
                          type="text"
                          onChange={this.handleOnInputChange}
                          placeholder="OTP"/>

            <Form.Text>{this.renderOtpButton()}</Form.Text>
            {(this.state.otp && process.env.NODE_ENV === 'development') &&
            <Form.Text>OTP is: {this.state.otp}</Form.Text>}
          </Form.Group>

          <Form.Group controlId="changePassForm.password">
            <Form.Label className="sr-only">New Password</Form.Label>
            <InputPassword
              name={"password"}
              onChange={this.handleOnInputChange}
              placeholder="New Password" minLength={6}/>
          </Form.Group>
          <Button variant="primary"
                  type="submit"
                  disabled={this.state.isLoading}
                  block>{this.state.isLoading ? 'Submitting...' : 'Change Password'}</Button>
        </Form>
      )
    }
  }

  render() {
    if (userIsLoggedin())
      return (<Redirect to={{pathname: '/'}}/>);

    return (
      <div className="auth-wrap">
        <div className="auth-container">
          <div className="auth-header">
            <img className="logo" src={logo} alt="Citygrabs Eat"/>
            {this.containerHeader()}
          </div>

          <div className="auth-body">
            {this.containerForm()}
          </div>

          <div className="auth-footer text-center">
            &copy; 2020 <strong>Ekshop</strong>. All right reserved
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    authData: state.auth
  }
};

const mapDispatchToProps = dispatch => {
  return {
    login: (data, cb) => dispatch(login(data, cb)),
    clearErrMsg: () => dispatch(clearErrMsg())
  }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
