import React, {Component} from 'react';
import '../assets/scss/sidebar-nav.scss';
import {
  FiSettings,
  FaMoneyBill,
  GiSpeaker,
  FaBoxes,
  FiChevronDown,
  FiChevronUp,
  FiFileText,
  MdReportProblem,
  GiVerticalBanner,
  IoMdChatboxes, AiOutlineLineChart, AiOutlineShop,
  FaUser, FaDollarSign, AiOutlineBank, FiHelpCircle, FiMessageCircle, MdLiveTv, MdAddAlert
} from "react-icons/all";
import {Link} from "react-router-dom";
import {getLocalAuthData} from "../helper/utils";
import {getDetail, getTableData} from "../services/commonServices";

class SidebarNav extends Component {


  state = {
    counter: 0,
    campaign_management: false,
    navbar: [
      {
        id: 1,
        campaign_management: false,
        show_in_campaign: true,
        text: 'Products',
        hasSubMenu: true,
        icon: <FaBoxes/>,
        isCollapsed: true,
        subMenu: [
          {
            id: 1,
            text: 'Product List',
            path: '/products/list',
          },
          {
            id: 2,
            text: 'Add Product',
            path: '/products/add'
          }
        ]
      },
      {
        id: 2,
        campaign_management: false,
        show_in_campaign: true,
        text: 'Orders',
        path: '/orders/list',
        icon: <FiFileText/>,
      },
      {
        id: 12,
        campaign_management: false,
        text: 'Disputes',
        path: '/disputes',
        icon: <FaBoxes/>,
        hasSubMenu: false,
        isCollapsed: false,
      },
      /*{
        id: 3,
        text: 'Disputes',
        path: '#',
        icon: <IoMdChatboxes/>,
      },*/
      /*{
        id: 5,
        text: 'Reports',
        path: '/reports',
        icon: <AiOutlineLineChart/>,
        hasSubMenu: true,
        isCollapsed: true,
        subMenu: [
          {
            id: 1,
            text: 'Order Report',
            path: '/reports/order'
          },
          {
            id: 3,
            text: 'Sales Report',
            path: '/reports/sales'
          }
        ]
      },*/
      {
        id: 9,
        campaign_management: false,
        text: 'Stores',
        path: '/stores',
        icon: <AiOutlineShop/>,
        hasSubMenu: true,
        isCollapsed: true,
        subMenu: [
          {
            id: 1,
            text: 'Stores',
            path: '/stores/list'
          },
          {
            id: 3,
            text: 'Create Stores',
            path: '/stores/create'
          }
        ]
      },
      {
        id: 91,
        campaign_management: false,
        text: 'Assisted Sellers',
        path: '/assisted-seller',
        icon: <FaUser/>,
        hasSubMenu: true,
        isCollapsed: true,
        subMenu: [
          {
            id: 911,
            text: 'Assisted Seller',
            path: '/assisted-sellers'
          },
          {
            id: 912,
            text: 'Add Seller',
            path: '/assisted-sellers/add'
          }
        ]
      },
      {
        id: 10,
        campaign_management: false,
        text: 'Bulk Request',
        path: '/bulk-request',
        icon: <FaBoxes/>,
        hasSubMenu: false,
        isCollapsed: false,
      },
      {
        id: 11,
        campaign_management: false,
        text: 'Commissions',
        path: '/commissions',
        icon: <FaDollarSign/>,
        hasSubMenu: false,
        isCollapsed: false,
      },
      {
        id: 4,
        campaign_management: false,
        show_in_campaign: true,
        text: 'Promotions',
        hasSubMenu: true,
        icon: <GiSpeaker/>,
        isCollapsed: true,
        subMenu: [
          /*{
            id: 41,
            text: 'Seller Voucher',
            path: '/promotions/seller-voucher'
          },
          {
            id: 42,
            text: 'Bundle Offer',
            path: '/promotions/bundle-offer'
          }*/
          {
            id: 46,
            text: 'Campaigns',
            path: '/promotions/campaign'
          },
          {
            id: 47,
            text: 'Campaign Details',
            path: '/promotions/campaign-details'
          }
        ]
      },
      {
        id: 5,
        campaign_management: false,
        text: 'Notice',
        path: '/notice/list',
        icon: <MdAddAlert/>,
        hasSubMenu: false,
        isCollapsed: false,
      },
      {
        id: 13,
        campaign_management: false,
        text: 'Payment Methods',
        path: '/payment-methods',
        icon: <AiOutlineBank/>,
        hasSubMenu: false,
        isCollapsed: false,
      },
      {
        id: 14,
        campaign_management: false,
        text: 'Support Ticket',
        path: '/support-ticket',
        icon: <FiHelpCircle/>,
        hasSubMenu: false,
        isCollapsed: false,
      },
      {
        id: 15,
        campaign_management: false,
        text: 'Messages',
        path: '/messages',
        icon: <FiMessageCircle/>,
        hasSubMenu: false,
        isCollapsed: false,
      },
      /*{
        id: 16,
        campaign_management: false,
        text: 'Api Settings',
        path: '/api-settings',
        icon: <FiSettings/>,
        hasSubMenu: false,
        isCollapsed: false,
      },*/
      {
        id: 17,
        campaign_management: false,
        text: 'Live',
        path: '/live',
        icon: <MdLiveTv/>,
        hasSubMenu: false,
        isCollapsed: false,
      },
      {
        id: 17,
        campaign_management: false,
        text: 'Complain List',
        path: '/complain/list',
        icon: <MdReportProblem/>,
        hasSubMenu: false,
        isCollapsed: false,
      },
      /*{
        id: 6,
        campaign_management: false,
        text: 'Store Banner',
        path: '/banners',
        icon: <GiVerticalBanner/>,
      },*/
      /*{
        id: 6,
        text: 'Finance',
        path: '/finance',
        icon: <FaMoneyBill/>,
      },*/
      /*{
        id: 7,
        text: 'Settings',
        path: '/settings',
        icon: <FiSettings/>,
      }*/
    ]
  };


  componentDidMount() {
    const authData = getLocalAuthData();
    if (authData.merchant_type === "5ff1b6ec318b9b5589059eab" || authData.merchant_type === "5ff20281fff77d206c9fa41d") {
      this.setState({campaign_management: true}, () => {
        console.log(this.state.campaign_management);
      });
    }

    let a = 0;
    getDetail('/notice').then((res) => {
      res.items.map((data,i)=>{

        if (data?.notice_board_seen?.seen === false){
          a++
        }
        if (res.items?.length === i+1){
          this.setState({
            counter: a
          })
        }

      })

    })



  }

  toggleSubNavItems = async (id) => {
    const navbar = {...this.state.navbar};
    const itemObj = await this.state.navbar.filter(obj => {
      return obj.id === id
    });
    const index = this.state.navbar.indexOf(itemObj[0]);
    navbar[index].isCollapsed = !navbar[index].isCollapsed;
    this.setState(navbar);
  };

  render() {
    return (
      <div className="sidebar-nav collapsed-sidebar">
        <ul className="navigation">
          {this.state.navbar.map((item, i) => {
            if ((item.campaign_management === this.state.campaign_management) || item.show_in_campaign === true) {
              if (item.hasSubMenu) {
                return (
                    <li key={i} className={item.isCollapsed ? 'has-sub-menu' : 'show-sub-menu has-sub-menu'}>
                  <span
                      onClick={() => this.toggleSubNavItems(item.id)}
                      className="toggleCollapse nav-link">
                    <span className="nav-icon">{item.icon}</span>
                    <span className="nav-text">
                      <span className="text">{item.text}</span>
                      <span className="catet-icon">{item.isCollapsed ? <FiChevronDown/> : <FiChevronUp/>}</span>
                    </span>
                  </span>

                      <ul className="sub-menu">
                        {item.subMenu.map((subItem, i) => {
                          return (<li key={i}><Link className="nav-link" to={subItem.path}><span
                              className="nav-text">{subItem.text}</span></Link></li>)
                        })}
                      </ul>
                    </li>
                )
              } else {
                return (
                    <li key={i}>
                      <Link className="nav-link" to={item.path}>
                        <span className="nav-icon">{item.icon}</span>
                        <span className="nav-text">{item.text}</span>
                        {item.text === 'Notice' && <span className="notice-notification">{this.state.counter}</span> }
                      </Link>
                    </li>
                )
              }
            }
          })}
        </ul>
      </div>
    );
  }
}

export default SidebarNav;
