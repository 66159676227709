import React, {useEffect, useState} from "react";
import {calculateLeftTime} from "../helper/utils";
import '../assets/scss/count-down-timer.css'


const CountdownTimer = ({endTime, type ,decrement}) => {
  const [leftTime, setLeftTime] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  });

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      setInterval(() => {
        setLeftTime(calculateLeftTime(endTime ,parseInt(decrement)))
      }, 1000);
    }

    return () => {
      mounted = false;
    };
  }, [endTime])

  return (
    <div className={'count-down-timer'}>
      <div className="count-time">
        <span className="value">{leftTime.days || 0}</span>
        <span className="text">{type === 1 ? 'D' : 'Day'}</span>
      </div>
      <span className="separation-span">:</span>
      <div className="count-time">
        <span className="value">{leftTime.hours || 0}</span>
        <span className="text">{type === 1 ? 'H' : 'Hours'}</span>
      </div>
      <span className="separation-span">:</span>
      <div className="count-time">
        <span className="value">{leftTime.minutes || 0}</span>
        <span className="text">{type === 1 ? 'M' : 'Minutes'}</span>
      </div>
      <span className="separation-span">:</span>
      <div className="count-time">
        <span className="value">{leftTime.seconds || 0}</span>
        <span className="text">{type === 1 ? 'S' : 'Seconds'}</span>
      </div>

    </div>
  )
}

export default CountdownTimer;
