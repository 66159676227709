import React, {Component} from "react";
import App from "../../App";
import { Link } from "react-router-dom";
import Moment from "moment";
import { FiClock, IoIosArrowForward, RiReplyLine } from "react-icons/all";
import "../../assets/scss/support-ticket.scss";
import {Button, Col, Form, Row ,Badge} from "react-bootstrap";
import BrowseFiles from "../../components/BrowseFiles";
import {getTableData, getDetail, addNewItem, updateItem, updateItemPartial, deleteItem} from "../../services/commonServices";
import {toast} from "react-toastify";
import {confirmAlert} from "react-confirm-alert";
import Pagination from "react-js-pagination";
import { getLocalAuthData } from '../../helper/utils'
import {FiEdit, FiTrash , IoMdVideocam ,AiOutlineLink} from "react-icons/all";
import {GoLinkExternal} from "@react-icons/all-files/go/GoLinkExternal";
import {STORE_URL} from "../../helper/env";



class Live extends Component{

    state = {
        supportTopicData: {
            name: null
        },
        liveList: [],
        supportTopicDetails: null,
        isLoading: false,
        queryData: {
            sort_direction: -1,
            limit: 10,
        },
        activePage: 1,
        limit: 10,
        pagination: {},
        page: 1
    }

    componentDidMount() {
        const history = this.props.history;
        this.getLiveData()
    }

    getLiveData = () => {
        const queryData = {...this.state.queryData};
        queryData.search_string = "";
        this.setState({isLoading: true, queryData: queryData});
        getTableData('/live-for-admin').then(res => {
            console.log(res)
            this.setState({liveList: res.items, isLoading: false, pagination: res.pagination})
        }).catch(errMsg => {
            toast.error(errMsg);
        })
    }

    handlePageChange = (pageNumber) => {
        this.setState({queryData: {...this.state.queryData, page: pageNumber, limit: this.state.limit}}, () => {
            this.getLiveData();
        });
    }

    handleUpdateSupportTopic = () => {
        this.setState({isLoading: true}, () => {
            updateItem(`/support-topic/${this.state.supportTopicDetails._id}`, this.state.supportTopicData)
                .then(res => {
                    this.setState({isLoading: false})
                    this.resetUpdateForm();
                    this.getLiveData();
                    toast.success(res.data.message)
                })
                .catch(errMsg => {
                    toast.error(errMsg);
                    this.setState({isLoading: false});
                })
        });
    }

    handleGetSupportTopicDetails = (id) => {
        getDetail(`/live/${id}`).then(res => {
            this.setState({supportTopicDetails: res.live})
        })
    }

    handleDeleteSupportTopic = (id) => {
        confirmAlert({
            title: 'Permanent Delete',
            message: 'Are you sure to delete permanently this Live?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => deleteItem(`/live/${id}/permanent`).then(res =>
                        {
                            if (res.status === 1){
                                toast(res.message)
                            }else {
                                toast(res.message)
                            }
                            this.getLiveData()
                        }

                    ).catch(err => console.log(err))
                }
                ,
                {
                    label: 'No',
                    // onClick: () => alert('Click No')
                }
            ]
        });
    };


    resetUpdateForm = () => {
        this.setState({supportTopicDetails: null, supportTopicData: {name: null}}, () => {
            document.getElementById('supportTopicForm').reset()
        })
    }

    searchSupportTopic = () => {
        this.getLiveData()
    }

    handleChangeStatus = (e , id)=>{
        console.log(e.target.value)
        let data = null
        if (e.target.value=== "true"){
            data = true
        }else if (e.target.value=== "false"){
            data = false
        }

        updateItem(`/live/${id}`,{ is_finish: data}).then(res => {
            console.log(res)
            if (res.status === 1){
                this.getLiveData();
                toast(res.message)
            }else {
                toast(res.message)
            }
          //  this.setState({liveList: res.items, isLoading: false, pagination: res.pagination})
        }).catch(errMsg => {
            toast.error(errMsg);
        })

    }


    render() {
        return (
            <App layout={"boxed"}>
                <div className="page-header">
                    <h2 className="page-title">Live</h2>

                    <Link to={"/create-live"} className={"btn btn-sm btn-secondary"}>
                        + Create New Live
                    </Link>
                </div>

                <div className="main-content">


                    <div className="card-block">
                        <div className="block-header">
                            <h4 className="block-title">Live List</h4>
                        </div>

                        <div className="block-body">
                            <div className="block-body">
                                {/*{this.renderBank()}*/}
                                {this.state.isLoading ?
                                    <>Loading...</>
                                    :
                                    <table className="table table-striped">
                                        <thead>
                                        <tr>
                                            <th style={{width: '20px'}}>#</th>
                                            <th>Title</th>
                                            <th>Store Name</th>
                                            <th>Live url</th>
                                            <th>Status</th>
                                            <th>Live Status</th>
                                            <th style={{width: `1%`}}/>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        { this.state.liveList?.length > 0 ?
                                            this.state.liveList.map((item, index) => (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{item.title}</td>
                                                    <td>{item?.store_name}</td>
                                                    <td>
                                                        {/*<a href={item?.live_url} target="_blank" ><AiOutlineLink style={{color: "blue"}}/></a> */}
                                                        <a href={item?.live_url} target="_blank" ><AiOutlineLink style={{color: "blue"}}/></a>
                                                        <a href={`${STORE_URL}/live-store/details/` + item?._id} target="_blank"><GoLinkExternal style={{color: "blue"}}/></a>

                                                    </td>
                                                    <td>
                                                        {item.is_finish === true ?
                                                            <Badge  variant="warning">
                                                                <span style={{fontsize: "5px" , padding: "5px"}}>active</span>
                                                            </Badge>

                                                            :  <Badge  variant="danger">
                                                            <span style={{fontsize: "5px", padding: "5px"}}>Inactive</span>
                                                        </Badge>}

                                                    </td>
                                                    <td >
                                                        <select style={{width: "60%"}} id="status" name="status" className='form-control form-control-sm'
                                                                onChange={ (e)=>{this.handleChangeStatus(e , item._id)}}>
                                                            <option value="">--Select Status--</option>
                                                            <option value="true">Active</option>
                                                            <option value="false">Inactive</option>
                                                        </select>
                                                    </td>

                                                    <td className="nowrap text-center">

                                                            <Link
                                                                key={index}
                                                                to={"/edit-live/" + item._id}
                                                                className="ticketing-item">
                                                                <button className="btn btn-sm btn-outline-primary mr-3">Edit</button></Link>
                                                           
                                                            <button className="btn btn-sm btn-outline-danger mr-3" onClick={() => this.handleDeleteSupportTopic(item._id)}>Delete</button>
                                                            
                                                    </td>
                                                </tr>
                                            )) :
                                            <tr>
                                                <td colSpan={7} className={`text-bold text-center`}>No item found</td>
                                            </tr>
                                        }
                                        </tbody>
                                    </table>
                                }
                                <div className="mt-3">
                                    <Pagination
                                        itemClass="page-item"
                                        linkClass="page-link"
                                        activePage={this.state.pagination?.page || 1}
                                        itemsCountPerPage={this.state.pagination?.limit || 10}
                                        totalItemsCount={this.state.pagination?.totalDocs || 10}
                                        pageRangeDisplayed={20}
                                        onChange={this.handlePageChange.bind(this)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </App>
        );
       }
    }

export default Live;
