import React, {Component} from "react"
import "../../assets/scss/business-block-card.scss"
import "../../assets/scss/dashboard.scss"
import Chart from "chart.js"

class OrderCancelChart extends Component {
  orderCanceledStatsChartRef = React.createRef()

  componentDidMount() {
    setTimeout(() => {
      if (Object.keys(this.props.cancelOrderReports).length > 0) {
        const orderCanceledStatsRef = this.orderCanceledStatsChartRef.current.getContext("2d")
        let order_vs_sales_reports = this.props.cancelOrderReports
        let datasets = Object.values(order_vs_sales_reports.dataset)
        new Chart(orderCanceledStatsRef, {
          type: "bar",
          data: {
            labels: Object.keys(order_vs_sales_reports.dataset),
            datasets: [
              {
                label: "Order",
                data: datasets.map(o => o.order),
                borderWidth: 1,
                pointBorderWidth: 0,
                pointBorderColor: '#34BFA3',
                borderColor: '#34BFA3',
                backgroundColor: '#34BFA3'
              },
              {
                label: "Canceled",
                data: datasets.map(o => o.count),
                borderWidth: 1,
                borderColor: '#F4516C',
                backgroundColor: '#F4516C'
              }
            ]
          },
          options: {
            legend: {
              display: false
            },
            tooltips: {
              mode: 'index',
              intersect: false,
            },
            scales: {
              xAxes: {
                display: true,
                scaleLabel: {
                  display: true,
                  labelString: 'Date'
                },
                ticks: {
                  padding: 10
                }
              },
              yAxes: {
                position: 'left',
                display: true,
                scaleLabel: {
                  display: true,
                  labelString: '(BDT)'
                },
                ticks: {
                  beginAtZero: true,
                  userCallback: function (label) {
                    if (Math.floor(label) === label) {
                      return label;
                    }
                  },
                }
              }
            },
          }
        })
      }
    }, 1000)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.componentDidMount()
  }

  render() {
    return (
      <div className="card-block">
        <div className="block-body">
          <div className="stats-cards">
            <div className="stats-card">
              <span className="title">Total Order </span>
              <span className="value text-success">{this.props?.cancelOrderReports?.total_order}</span>
            </div>
            <div className="stats-card">
              <span className="title">Total Canceled</span>
              <span className="value text-danger">{this.props?.cancelOrderReports?.total_cancel_order}</span>
            </div>
          </div>
          <canvas
            style={{height: '320px', width: '100%'}}
            ref={this.orderCanceledStatsChartRef}
          />
        </div>
      </div>
    )
  }
}

export default OrderCancelChart
