import React, {Component} from 'react';
import App from "../../App";
import {Alert, Button, Col, Form, Row} from "react-bootstrap";
import axios from "axios";
import {API_BASE_URL} from "../../helper/env";
import {toast} from "react-toastify";

import {getDeviceType, getLocalAuthData} from "../../helper/utils";
import InputPassword from "../../components/InputPassword";
import { userIsLoggedin } from '../../auth/authUtils';
import { Redirect, withRouter } from 'react-router-dom';


class ResetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: {},
            editFormData: null,
            editStatus: false,
            isAuthenticated: false,
            message: ''
        }
    }

    componentDidMount() {
        this.setState({isAuthenticated: userIsLoggedin(), message: this.props.location?.state?.message || ''});
        console.log(this.props.location?.state?.message)
    }

    handleFormDataChange = (e) => {
        this.setState({formData: {...this.state.formData, [e.target.name]: e.target.value}}, () => {
            console.log(this.state);
        });
    };

    handleSubmitForm = () => {
        const data = this.state.formData;
        let url = `${API_BASE_URL}/change-password`;
        const authData = getLocalAuthData()
        let headers = {headers: {'x-auth-token': authData.token, 'x-api-client': getDeviceType()}}
        axios.post(url, data, headers).then(response => {
            if (response.data.status === 1) {
                toast.success(response.data.message);
                this.setState({formData: {old_password: '', new_password: '', confirm_password: ''}});
                console.log(response.data);
                localStorage.removeItem('user');
                window.location.reload() 
            }
            if (response.data.status === 2) {
                toast.error(response.data.message);
            }
        }).catch(() => {
            toast.error("Something went wrong please try again after refresh the page.");
        });
    };

    render() {
        return this.state.isAuthenticated?  (
            <App layout={"boxed"}>
                {
                    this.state.message && <Alert variant="danger">{this.state.message}</Alert>
                }
                <div className="page-header">
                    <h2 className="page-title">Reset Password</h2>
                </div>

                <div className="main-content">
                    <div className="card-block">

                        <div className="block-body">
                            <Form id={"bankForm"}>
                                <Form.Group as={Row} controlId="oldPassword">
                                    <Form.Label column sm="3">
                                        Old Password <span className="text-danger">*</span>
                                    </Form.Label>
                                    <Col sm="9" md={8} lg={7}>
                                        <InputPassword required
                                                       name="old_password" onChange={this.handleFormDataChange}
                                                       autoComplete="off"
                                                       placeholder={"Old Password"} minLength={6}/>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} controlId="newPassword">
                                    <Form.Label column sm="3">
                                        New Password <span className="text-danger">*</span>
                                    </Form.Label>
                                    <Col sm="9" md={8} lg={7}>
                                        <InputPassword onChange={this.handleFormDataChange}
                                                       required
                                                       name="new_password"
                                                       autoComplete="off"
                                                       placeholder="New Password" minLength={6}/>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} controlId="reEnterNewPassword">
                                    <Form.Label column sm="3">
                                        Re-enter New Password <span className="text-danger">*</span>
                                    </Form.Label>
                                    <Col sm="9" md={8} lg={7}>
                                        <InputPassword onChange={this.handleFormDataChange}
                                                       required
                                                       name="confirm_password"
                                                       autoComplete="off"
                                                       placeholder="Re-enter New Password " minLength={6}/>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} controlId="bankFormSubmit">
                                    <Form.Label column sm="3"/>
                                    <Col sm="9" md={8} lg={7}>
                                        <Button variant={"primary"} onClick={this.handleSubmitForm}>Save</Button>
                                    </Col>
                                </Form.Group>
                            </Form>
                        </div>
                    </div>

                </div>
            </App>
        ) : (<Redirect to={{pathname: '/login'}}/>)
    }
}

export default withRouter(ResetPassword);
