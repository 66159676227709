import React from "react";
import App from "../../App"

class ApiSettings extends React.Component {


  render(){
    return (
      <App layout={"boxed"}>
        <div className="page-header">
          <h2 className="page-title">API Settings</h2>
        </div>

        <div className="card-block">
          <div className="block-header">
            <h4 className="block-title">API Settings</h4>
          </div>
          <div className="block-body">

          <pre>{`
API Link:{EkshopApiBaseURL}/partner/productSync
Method:POST
Headers: {Content-Type: "application/json",
x-auth-token:"x-auth-token will be provided in get session api"}
`}
</pre><div style={{ backgroundColor:'#d9d9d9', marginTop:'40px' }}>
<pre> 
<h5 style={{ color:'#FF9B0D',marginLeft:'15px', marginTop:'15px'}} >Request Body:</h5>
    {`  
    [
          {
            "product_reference_id": "50084",
            "name": "8 in 1 Multifunctional Foldable Cloth Hanger",
            "category": "Appliances & Decoration",
            "brand": "", 
            "model":"",
            "keywords": [],
            "highlights":"8 In 1 multifunctional foldable cloth hanger has 360 degree rotation.
             You can keep on all your clothes on this particular foldable
             hanger.",  
             `}</pre>
             <pre>
             "product_description":
            <ul>
                <li>Retractable hanger.</li>
                <li>Pull clothes off easily.</li>
                <li>Fits in any closet.</li>
                <li>Savestons of space while keeping your closet neat and organized.</li>
                <li>Color: As same as picture.</li>
            </ul>
             </pre>
             
<pre>{`
 "video_url":"",
 "images": [
 {
    "actual_img":
        "https://s3-ap-southeast-1.amazonaws.com/priyoshop/content/Images/thumbs/0083134_8-in-1-
        multifunctional-foldable-cloth-hanger_550.jpeg",
    "mid_img":
        "https://s3-ap-southeast-1.amazonaws.com/priyoshop/content/Images/thumbs/0083134_8-in-1-
        multifunctional-foldable-cloth-hanger_415.jpeg",
    "thumb_img":
        "https://s3-ap-southeast-1.amazonaws.com/priyoshop/content/Images/thumbs/0083134_8-in-1-
        multifunctional-foldable-cloth-hanger_220.jpeg"
 }
 ],
 "attributes": [
     {
         "attribute_name": "Color",   
         "data": [{"val": "Black"}, {"val":  "Blue"}, {"val":"White"}]
     },
     {
           "attribute_name": "size",
           "data": [{"val": "S"},  {"val": "M"},  {"val":"L"}]
     }
 ],
 "sku_information":  [
     {    
         "is_enabled": true,
         "is_default": true,
         "attr_combo": ["Black","S"],
         "sku":"S-1088-50084",
         "sku_reference_id":"S-1088-50084",
         "quantity": 100,
          "minimum_order_quantity": 10
          "maximum_order_quantity":20, 
          "price": 1190,
          "special_price": 0,
           "images": null
     }
  ],
 "faq":  [], 
 "deleted": false,
 "publish_status":2
}   
] 
`}</pre> 
</div> 
<div style={{ backgroundColor:'#f2f2f2',marginTop:'50px', marginBottom:'50px' }}>     
<ul >
    <li>product_reference_id is your system id for product identification</li>
    <li>keywords is an array of string</li>
    <li>sku_reference_id is your system id for identify product sku/variant</li>
    <li>price and special_price in sku_information object: price is defined as regular price.If any product has a sell price that will be the special price</li>
    <li>this is a number type field, possible value 1 or 2. 1= simple product, 2 = variable product</li>
    <li>attributes:Could be null. If skype is 2 for a variable product then "attributes"field will be mandatory.</li>
    <li>publish_status:1 = Drafted, 2= Published, 3= Unpublished</li>
</ul>
</div>
<div style={{ backgroundColor:'#d9d9d9'}}>
<h5 style={{ color:'#FF9B0D', marginLeft:'15px', paddingTop:'15px'}} > Response:</h5><pre>{`
{ 
    "request_status":200,
    "message":"success",
    "data":[
        {
            "product_reference_id":"725699"
            "publish_status":2,
            "deleted":false,
            "sku_information":[
                {
                    "is_enabled":true,
                    "sku_reference_id":"S-1088-50084",
                    "quantity":100,
                    "price":1190,
                    "special_price":0,
                }
        ]
    },

    {
        "product_reference_id":"725699"
        "publish_status":2,
        "deleted":false,
        "sku_information":[
            {
                "is_enabled":true,
                "sku_reference_id":"S-1088-50084",
                "quantity":100,
                "price":1190,
                "special_price":0,
            }
        ]
    }
]
}
`}
     </pre>
 </div>
          </div>
        </div>
      </App>

    );
  }
}


export default ApiSettings;