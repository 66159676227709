import React from 'react';
import "../assets/scss/info-card.scss";
import {numberWithCommas} from "../helper/utils";

const InfoCardV2 = ({icon, title, value, className, isCurrency, total}) => {
    return (
        <div className={className ? `${className} info-card` : 'info-card'}>
            {icon &&
            <span className={'icon'}>
        {icon}
      </span>
            }
            {value &&
            <span className="value">{isCurrency? numberWithCommas(value) : value}</span>
            }

            {total &&
            <span style={{fontSize:'15px'}} className="value">{isCurrency? numberWithCommas(total) : total}</span>
            }

            {title && <h3 className="title">{title}</h3>}
        </div>
    );
};

export default InfoCardV2;