const styleReactSelect = {
  option: (provided) => ({
    ...provided,
    height: '34px',
    padding: '0 8px',
    lineHeight: '32px',
    zIndex: 999
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: 0,
    lineHeight: '28px'
  }),
  placeholder: (provided) => ({
    ...provided, height: '28px', lineHeight: '28px'
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 999
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';
    return {...provided, opacity, transition};
  },
  multiValue: (provided) => ({
    ...provided,
    height: '30px',
    lineHeight: '24px'
  }),
  control: (provided) => ({
    ...provided,
    padding: '0 8px',
    // height: '40px'
  })
}

export default styleReactSelect;
