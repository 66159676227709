
export const objectToFormData = (obj) => {
  let formData = new FormData();
  Object.keys(obj).forEach(key => formData.append(key, obj[key]));
  return formData;
};

export const percentCompleted = (progressEvent) => {
  return Math.round((progressEvent.loaded * 100) / progressEvent.total)
}

export const getDeviceType = () => {
  const ua = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return "tablet";
  }
  if (/Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
    return "mobile";
  }
  return "desktop";
};

export const getLocalAuthData = () => {
  return JSON.parse(localStorage.getItem('user'));
}

// calculate discount percentage
export const discountPercentage = (actualPrice, discountPrice) => {
  const totalDiscount = actualPrice - discountPrice;
  return Math.round((totalDiscount * 100) / actualPrice);
}


export const findByMatchingProperties = (arrOfObj, obj) => {
  return arrOfObj.filter(function (entry) {
    return Object.keys(obj).every(function (key) {
      return entry[key] === obj[key];
    });
  });
}

export const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const calculateLeftTime = (expireDate) => {
  const timeDifference = new Date(expireDate) - new Date();
  let timeLeft = {};

  if (timeDifference > 0) {
    timeLeft = {
      days: Math.floor(timeDifference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((timeDifference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((timeDifference / (1000 * 60)) % 60),
      seconds: Math.floor((timeDifference / 1000) % 60),
    }
  }

  return timeLeft;
};

export const urlPramsToObj = (history) => {
  const search = history.location.search.substring(1);
  return JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}');
}

export const objToUrlPrams = (obj) => {
  const str = [];
  for (let p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return str.join("&");
}
