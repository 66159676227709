import {getDeviceType, getLocalAuthData} from "../helper/utils";
import axios from "axios";
import {API_BASE_URL} from "../helper/env";

export const getCommissionList = (paramsObj = {}) => {
  const authData = getLocalAuthData()
  return new Promise((resolve, reject) => {
    axios.get(`${API_BASE_URL}/commission-balance`, {
      headers: {
        'x-auth-token': authData?.token || '',
        'x-api-client': getDeviceType()
      },
      params: paramsObj
    })
      .then(res => {
        if(res.data.status === 1) {
          resolve(res.data)
        } else {
          reject(res.data.message)
        }
      })
      .catch(err => {
        console.error(err)
        reject(err.message)
      })
  })
}