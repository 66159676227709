import {
  FILE_IS_UPLOADING, FILE_UPLOAD_COMPLETE,
  FILE_UPLOAD_FAILURE,
  FILE_UPLOAD_REQUEST, FILE_UPLOAD_SUCCESS,
  GET_FILE_LIST_FAILURE,
  GET_FILE_LIST_REQUEST,
  GET_FILE_LIST_SUCCESS
} from "./actionTypes";
import axios from "axios";
import {API_BASE_URL} from "../../helper/env";
import {getDeviceType, getLocalAuthData, objectToFormData, percentCompleted} from "../../helper/utils";

export const getFileList = (params, callback) => {
  const authData = getLocalAuthData()

  return dispatch => {
    dispatch(getFileListRequest());
    axios.get(API_BASE_URL + '/images', {
      params: {...params},
      headers: {
        'x-auth-token': authData?.token,
        'x-api-client': getDeviceType()
      }
    })
      .then(res => {
        if (res.data.status === 1) {
          dispatch(getFileListSuccess(res.data.items));
        } else {
          dispatch(getFileListFailure(res.data.message))
        }
      })
      .catch(err => {
        dispatch(getFileListFailure(err.message))
      })
  }
}

/*{
  user_id: '5ec18bd01ba0be28d023ef80',
    image_category: 'product'
}*/

const getFileListRequest = () => {
  return {
    type: GET_FILE_LIST_REQUEST
  }
}

const getFileListSuccess = (files) => {
  return {
    type: GET_FILE_LIST_SUCCESS,
    payload: files
  }
}

const getFileListFailure = (errMsg) => {
  return {
    type: GET_FILE_LIST_FAILURE,
    payload: errMsg
  }
}

export const handleFileUpload = (data, callback) => {
  const authData = getLocalAuthData()

  return dispatch => {
    dispatch(fileUploadRequest());

    axios.post(API_BASE_URL + '/images', objectToFormData(data), {
      headers: {
        'Content-Type': 'multipart/form-data',
        'x-auth-token': authData?.token,
        'x-api-client': getDeviceType()
      },
      onUploadProgress: progressEvent => {
        const uploadPercentage = percentCompleted(progressEvent);
        if (uploadPercentage < 100) {
          dispatch(fileIsUploading(uploadPercentage));
        } else {
          dispatch(fileUploadComplete());
        }
      }
    })
      .then(res => {
        if (res?.data.status === 1) {
          dispatch(fileUploadSuccess());
          callback(res.data.image._id);
        } else {
          dispatch(fileUploadFailure())
        }
      })
  }
}

const fileUploadRequest = () => {
  return {
    type: FILE_UPLOAD_REQUEST
  }
}

const fileUploadSuccess = (msg) => {
  return {
    type: FILE_UPLOAD_SUCCESS,
    payload: msg
  }
}

const fileIsUploading = (percent) => {
  console.log('Upload percentage: ', percent);
  return {
    type: FILE_IS_UPLOADING,
    payload: percent
  }
}

const fileUploadComplete = () => {
  return {
    type: FILE_UPLOAD_COMPLETE
  }
}

const fileUploadFailure = (errMsg) => {
  return {
    type: FILE_UPLOAD_FAILURE,
    payload: errMsg
  }
}
