import React, { Component } from "react";
import App from "../../App";
import { withRouter, Link } from "react-router-dom";
import { toast } from "react-toastify";
import {
    getDeviceType,
    getLocalAuthData,
    urlPramsToObj,
} from "../../helper/utils";
import { Button, Col, Form, InputGroup, Modal, Row } from "react-bootstrap";
import {
    FiCheck,
    FiCheckCircle,
    FiDelete,
    FiEdit,
    FiSearch,
    FiTrash,
    IoMdClose,
} from "react-icons/all";
import Pagination from "react-js-pagination";
import LoadingSpinner from "../../components/LoadingSpinner";
import axios from "axios";
import { API_BASE_URL } from "../../helper/env";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { getStoreList } from "../../services/storeServices";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import { getTableData, updateItemPartial } from "../../services/commonServices";

class ComplainList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            storeList: [],
            complainList: [],
            complains: [],
            userInfo: "",
            storeInfo: "",
            productInfo: "",
            loading: true,
            pagination: {},
            isDataLoading: false,
            status: 1,
            queryData: {
                from_date: '',
                to_date: '',
                limit: 3,
                page: 1,
                store_id: "",
                product_id: ""
            },
        };
    }

    componentDidMount() {
        this.getQueryData();
    }

    deletePermanent(id) {
        const authToken = getLocalAuthData();
        const headers = {
            "Content-Type": "application/json",
            "x-api-client": "web",
            "x-auth-token": authToken.token,
        };
        axios.delete(`${API_BASE_URL}/complain/${id}`).then(res => {
            axios.get(`${API_BASE_URL}/complain`, { headers: headers })
                .then((res) => {
                    this.setState({
                        complainList: res.data.items,
                        pagination: res.data.pagination,
                        loading: false,
                    });
                })
        }).catch(() => {
            toast.error("Something went wrong, please refresh and try again");
            console.log("Error")
        });
    }

    handleStatusChange = async (e, id) => {
        updateItemPartial(`/complain/${id}`, { status: e.target.value }).then(res => {
            console.log(124, res);
        }).catch((err) => {
            console.log(err.message);
            toast.error(err.message)
        });
    }

    handleSearch = (query) => {
        this.setState({ isLoadingStoreData: true }, () => {
            getStoreList({ search_string: query, lite_data: 1 })
                .then(res => {
                    this.setState({ storeList: res.items, isLoadingStoreData: false });
                })
                .catch(errMsg => {
                    this.setState({ isLoadingStoreData: false })
                })
        })
    };

    handleOnApply = (e, picker) => {
        const queryData = { ...this.state.queryData };

        queryData.from_date = moment(picker.startDate).format('DD/MM/YYYY');
        queryData.to_date = moment(picker.endDate).format('DD/MM/YYYY');

        this.setState({ queryData }, () => {
            console.log(this.state)
        })
    }

    handlePageChange = async (pageNumber) => {
        await this.setState({ queryData: { ...this.state.queryData, page: pageNumber }, activePage: pageNumber });
        await this.getQueryData();
    }

    // getQueryData1 = () => {
    //     getTableData(`/complain`, this.state.queryData).then((res) => {
    //         this.setState({
    //             complainList: res.items,
    //             pagination: res.pagination,
    //             loading: false,
    //         });
    //     }).catch((e) => {
    //         this.setState({loading: false})
    //         console.log("Error")
    //     });
    // }

    getQueryData = () => {
        const authData = getLocalAuthData();
        axios.get(`${API_BASE_URL}/complain`, 
        {
          headers: {
              "x-auth-token": authData.token,
              "x-api-client": 'web'
            },       
          }).then(res =>{

            console.log(res);
        })
        
        getTableData(`/complain`, this.state.queryData).then((res) => {
            this.setState({
                complainList: res.items,
                pagination: res.pagination,
                loading: false,
            });
        }).catch((e) => {
            this.setState({loading: false})
            console.log("Error")
        });
    }

    render() {
        return (
            <App layout="">
                {/* {
                    this.state.loading && <LoadingSpinner />
                } */}
                <div className="main-content">
                    <div className="page-header">
                        <h2 className="page-title">Complain List</h2>
                    </div>

                    <div className="card-block">
                        <div className="block-body">
                            <form className="form-inline mb-3">

                                <div className="form-group mr-3">
                                    <label htmlFor="inputStoreList" className={'sr-only'}>Store List</label>
                                    <div className="position-relative">
                                        <AsyncTypeahead
                                            filterBy={() => true}
                                            id="inputStoreList"
                                            isLoading={this.state.isLoadingStoreData}
                                            labelKey="store_name"
                                            minLength={3}
                                            size={'sm'}
                                            onSearch={this.handleSearch}
                                            options={this.state.storeList}
                                            placeholder="Search store..."
                                            renderMenuItemChildren={(store, props) => (
                                                <React.Fragment>
                                                    <span onClick={() => {
                                                        this.setState({ queryData: { ...this.state.queryData, 'store_id': store._id } });
                                                    }}>{store.store_name}</span>
                                                </React.Fragment>
                                            )}
                                        />
                                    </div>
                                </div>

                                <div className="form-group mr-3 mt-2">
                                    <DateRangePicker
                                        initialSettings={{}}
                                        containerStyles={{ textAlign: 'left', width: '100%', display: 'block' }}
                                        onApply={this.handleOnApply}>
                                        <input type="button"
                                            placeholder={`Select date range`}
                                            value={(this.state.queryData.from_date && this.state.queryData.to_date) ? `${this.state.queryData.from_date} - ${this.state.queryData.to_date}` : 'Select date range'}
                                            style={{ textAlign: 'left', minWidth: '150px' }}
                                            className="form-control form-control-sm" />
                                    </DateRangePicker>
                                </div>

                                <Button className="mr-2" size="sm" variant="secondary"
                                    onClick={this.getQueryData}>Search</Button>

                            </form>

                            <table className="order-table table table-striped">
                                <thead>
                                    <tr>
                                        <th style={{ width: "19%" }}>Product Name</th>
                                        <th style={{ width: "13%" }}>User Name</th>
                                        <th style={{ width: "13%" }}>Store Name</th>
                                        <th style={{ width: "30%" }}>Complain</th>
                                        <th style={{ width: "9%" }}>Change Status</th>
                                        <th style={{ width: "12%" }}>Delete</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {this.state.complainList.map((complan) => (
                                        <tr>
                                            <th style={{ width: "19%" }}>{complan?.product_info?.name}</th>
                                            <th style={{ width: "13%" }}>{complan?.user_info?.name}</th>
                                            <th style={{ width: "13%" }}>{complan?.store_info?.store_name}</th>
                                            <th style={{ width: "30%" }}>{complan?.complain?.map((complains) => (
                                                <li key={complains.toString()}>{complains}</li>
                                            ))}</th>

                                            <th style={{ width: "9%" }}>
                                                <select
                                                    className="form-control form-control-sm"
                                                    onChange={(e) => this.handleStatusChange(e, complan._id)}>
                                                    <option value="">--Select--</option>
                                                    <option value="1" className={`text-bold text-danger`}>onReview</option>
                                                    <option value="2" className={`badge badge-success`} >solved</option>
                                                </select>
                                            </th>

                                            <th style={{ width: "1%" }}>
                                                <button onClick={() => this.deletePermanent(complan._id)} className="btn-action btn-block btn-outline-danger">
                                                    Delete
                                            </button>

                                            </th>
                                        </tr>
                                    ))}
                                </tbody>

                            </table>
                        </div>


                        <div className="mt-3">
                            <Pagination
                                itemClass="page-item"
                                linkClass="page-link"
                                activePage={this.state.pagination?.page}
                                itemsCountPerPage={this.state.pagination?.limit}
                                totalItemsCount={this.state.pagination?.totalDocs}
                                onChange={this.handlePageChange.bind(this)}
                            />
                        </div>
                    </div>
                </div>
            </App>
        );
    }

}

export default withRouter(ComplainList);