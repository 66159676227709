import React, {Component} from "react"
import "../../assets/scss/business-block-card.scss"
import "../../assets/scss/dashboard.scss"
import Chart from "chart.js"

class TransactionReport extends Component {
    salesAndOrderChartRef = React.createRef()


    componentDidMount() {

    }

    render() {

        console.log(">>>>>>>>>>>>>TRANSACTION REPORT",this.props)

        return (
            <>
                {this.props.test.transaction_reports && this.props.test.transaction_reports.map((data, i) => {

                    return (<div className="card-block">
                            <div className="block-header">
                                <h4 className="block-title">Transaction Report</h4>
                            </div>

                            <div className="block-body">
                                <table className="table table-striped">
                                    <thead>
                                    <tr>
                                        <th>Type</th>
                                        <th>Total</th>
                                        <th>Unpaid</th>
                                        <th>Partial</th>
                                        <th>Paid Payment</th>
                                        <th>Cancelled Payment</th>
                                        <th>Failed Payment</th>
                                        <th>Refund Payment</th>
                                        <th>Deposit Review</th>
                                        <th>Deposit Declined</th>
                                    </tr>
                                    </thead>

                                    <tbody>
                                    <tr>
                                        <td>COD</td>
                                        <td>{
                                            data?.partially_cod +
                                            data?.paid_cod +
                                            data?.cancelled_cod +
                                            data?.failed_cod +
                                            data?.refund_cod +
                                            data?.bank_deposit_review_cod +
                                            data?.bank_deposit_declined_cod +
                                            data?.unpaid_cod}
                                        </td>
                                        <td>{data?.unpaid_cod}</td>
                                        <td>{data?.partially_cod}</td>
                                        <td>{data?.paid_cod}</td>
                                        <td>{data?.cancelled_cod}</td>
                                        <td>{data?.failed_cod}</td>
                                        <td>{data?.refund_cod}</td>
                                        <td>{data?.bank_deposit_review_cod}</td>
                                        <td>{data?.bank_deposit_declined_cod}</td>
                                    </tr>
                                    <tr>
                                        <td>Bank Payment</td>
                                        <td>{
                                            data?.unpaid_bank_payment +
                                            data?.partially_bank_payment +
                                            data?.unpaid_bank_payment +
                                            data?.cancelled_bank_payment +
                                            data?.failed_bank_payment +
                                            data?.refund_bank_payment +
                                            data?.bank_deposit_review_bank_payment +
                                            data?.bank_deposit_declined_bank_payment +
                                            data?.paid_bank_payment
                                        }</td>
                                        <td>{data?.unpaid_bank_payment}</td>
                                        <td>{data?.partially_bank_payment}</td>
                                        <td>{data?.paid_bank_payment}</td>
                                        <td>{data?.cancelled_bank_payment}</td>
                                        <td>{data?.failed_bank_payment}</td>
                                        <td>{data?.refund_bank_payment}</td>
                                        <td>{data?.bank_deposit_review_bank_payment}</td>
                                        <td>{data?.bank_deposit_declined_bank_payment}</td>
                                    </tr>
                                    <tr>
                                        <td>Online Payment</td>
                                        <td>{
                                            data?.unpaid_online_payment +
                                            data?.partially_online_payment +
                                            data?.paid_online_payment +
                                            data?.cancelled_online_payment +
                                            data?.failed_online_payment +
                                            data?.refund_online_payment +
                                            data?.bank_deposit_review_online_payment +
                                            data?.bank_deposit_declined_online_payment
                                        }</td>
                                        <td>{data?.unpaid_online_payment}</td>
                                        <td>{data?.partially_online_payment}</td>
                                        <td>{data?.paid_online_payment}</td>
                                        <td>{data?.cancelled_online_payment}</td>
                                        <td>{data?.failed_online_payment}</td>
                                        <td>{data?.refund_online_payment}</td>
                                        <td>{data?.bank_deposit_review_online_payment}</td>
                                        <td>{data?.bank_deposit_declined_online_payment}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    )

                })}
                    </>

                    )
                }
                }

    export
    default
    TransactionReport
