import React, {Component} from 'react';
import App from "../../App";
import {Button, Col, Form, Row} from "react-bootstrap";
import {Link, withRouter} from "react-router-dom";
import {FaLongArrowAltLeft} from "react-icons/all";
import DateRangePicker from "react-bootstrap-daterangepicker";
import Moment from "moment";
import styleReactSelect from "../../assets/styleReactSelect";
import Select from "react-select";
import {getSellerVoucherDetails, submitSellerVoucher, updateSellerVoucher} from "../../services/promotionServices";
import {toast} from "react-toastify";
import {getStoreList} from "../../services/storeServices";

const today = Moment(new Date()).format('Y-M-D');

class EditSellerVoucher extends Component {
  state = {
    startDate: today,
    endDate: today,
    isDiscountTypePercentage: false,
    isApplyForCat: false,
    isTypeVoucherCode: false,
    formData: {
      discount_type: "1",
      apply_to: "2",
      product_skus: null,
      voucher_type: "1",
      start_time: today,
      end_time: today,
      collection_start_time: today
    },
    selectedStores: [],
    isLoading: true,
    storeList: [],
    isSubmitting: false
  }

  componentDidMount() {
    const voucherId = this.props.match.params.id

    getStoreList()
      .then(res => {
        const storeList = [];

        res.items.forEach(item => {
          const store = {
            id: item._id,
            value: item._id,
            label: item.store_name
          }

          storeList.push(store)
        })

        this.setState({storeList, isLoading: false}, () => {
          getSellerVoucherDetails(voucherId)
            .then(res => {
              console.log('dd: ', res)
              const formData = {...this.state.formData}
              const selectedStores = []

              formData.voucher_name = res?.voucher_name
              formData.start_time = res?.start_time
              formData.end_time = res?.end_time
              formData.voucher_type = res?.voucher_type.toString()
              if (res?.voucher_code) {
                formData.voucher_code = res?.voucher_code
              }
              formData.discount_type = res?.discount_type.toString()
              formData.discount_value = res?.discount_value
              if (res?.minimum_order_value) {
                formData.minimum_order_value = res?.minimum_order_value
              }
              if (res?.discount_type === "2") {
                formData.maximum_discount_amount = res?.maximum_discount_amount
              }
              formData.collection_start_time = res?.collection_start_time
              formData.display_area = res?.display_area
              formData.stores = res?.stores
              formData.total_voucher = res?.total_voucher
              formData.usage_limit = res?.usage_limit

              res.stores.forEach(item => {
                const stores = this.state.storeList.filter(obj => {
                  return obj.value === item
                })
                if (stores.length > 0) {
                  selectedStores.push(stores[0])
                }
              })

              this.setState({formData, selectedStores, isLoading: false})
            })
            .then(errMsg => {
              console.log(errMsg)
              this.setState({isLoading: false})
            })
        })

      })
      .catch(errMsg => {
        this.setState({isLoading: false})
      })
  }

  handleOnApply = (e, picker) => {
    const formData = {...this.state.formData};
    const startDate = Moment(picker.startDate).format('Y-M-D');
    const endDate = Moment(picker.endDate).format('Y-M-D')

    formData.start_time = startDate;
    formData.end_time = endDate;

    this.setState({
      formData,
      startDate: startDate,
      endDate: endDate,
    })
  }

  handleOnChangeDiscountType = (e) => {
    const formData = {...this.state.formData}
    formData.discount_type = e.target.value
    this.setState({formData});
  }

  handleOnChangeApplicableFor = (e) => {
    const formData = {...this.state.formData}
    formData.apply_to = e.target.value

    if (e.target.value === '6') {
      this.setState({isApplyForCat: true})
    } else {
      this.setState({isApplyForCat: false})
    }
  }

  handleOnChangeVoucherType = (e) => {
    const formData = {...this.state.formData}
    formData.voucher_type = e.target.value;


    if (e.target.value === '2') {
      this.setState({isTypeVoucherCode: true})
    } else {
      this.setState({isTypeVoucherCode: false})
    }
    this.setState({formData})
  }

  handleSelectMultiOnChange = (array, name) => {
    const formData = {...this.state.formData};
    const data = [];
    if (array) {
      array.forEach(val => {
        data.push(val.id)
      })
    }
    formData[name] = data;
    this.setState({formData})
  }

  handleInputOnChange = (e) => {
    const formData = {...this.state.formData};
    formData[e.target.name] = e.target.value;
    this.setState({formData});
  }

  handleFormSubmit = (e) => {
    e.preventDefault()
    const voucherId = this.props.match.params.id

    this.setState({isSubmitting: true}, () => {
      updateSellerVoucher(voucherId, this.state.formData)
        .then(res => {
          this.setState({isSubmitting: false})
          this.props.history.push(`${process.env.PUBLIC_URL}/promotions/seller-voucher`);
          toast.success(res.message);
        })
        .catch(errMsg => {
          this.setState({isSubmitting: false})
          toast.error(errMsg)
        })
    })
  }

  render() {
    const {formData} = this.state

    return (
      <App layout="boxed">
        <div className="page-header">
          <h2 className="page-title">Edit Seller Voucher</h2>
          <div className="ml-auto">
            <Link to={`${process.env.PUBLIC_URL}/promotions/seller-voucher`}
                  className="btn btn-sm btn-link"><FaLongArrowAltLeft/> Back to Seller
              Voucher List</Link>
          </div>
        </div>

        <div className="main-content">
          {this.state.isLoading ?
            <div className="card-block">
              <div className="block-body">Loading...</div>
            </div>
            :
            <>
              <div className="card-block">
                <div className="block-body">
                  <Form>
                    <Form.Group as={Row} controlId="formVoucherName">
                      <Form.Label column sm="3">
                        Voucher Name <span className="text-danger">*</span>
                      </Form.Label>
                      <Col sm="9" md={8} lg={7}>
                        <Form.Control onChange={this.handleInputOnChange}
                                      defaultValue={formData?.voucher_name}
                                      name={"voucher_name"}
                                      type="text" placeholder=""/>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="formStartFrom">
                      <Form.Label column sm="3">
                        Start and End Date <span className="text-danger">*</span>
                      </Form.Label>
                      <Col sm="9" md={8} lg={7}>
                        <label htmlFor="dateRange" className="sr-only">Date Range</label>
                        <DateRangePicker containerStyles={{textAlign: 'left', width: '100%', display: 'block'}}
                                         onApply={this.handleOnApply}>
                          <input type="button"
                                 value={`${Moment(formData.start_time).format('Y-M-D')} to ${Moment(formData.end_time).format('Y-M-D')}`}
                                 style={{textAlign: 'left'}}
                                 className="form-control"/>
                        </DateRangePicker>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="formVoucherType">
                      <Form.Label column sm="3">
                        Voucher Type <span className="text-danger">*</span>
                      </Form.Label>
                      <Col sm="9" md={8} lg={7}>
                        <Form.Control as="select"
                                      value={formData.voucher_type}
                                      onChange={this.handleOnChangeVoucherType}
                                      placeholder="">
                          <option value="1">Collectible</option>
                          <option value="2">Voucher Code</option>
                        </Form.Control>
                      </Col>
                    </Form.Group>
                    {formData?.voucher_type === "2" &&
                    <Form.Group as={Row} controlId="formVoucherCode">
                      <Form.Label column sm="3">
                        Voucher Code <span className="text-danger">*</span>
                      </Form.Label>
                      <Col sm="9" md={8} lg={7}>
                        <Form.Control name={"voucher_code"}
                                      defaultValue={formData?.voucher_code}
                                      onChange={this.handleInputOnChange}
                                      type="text" placeholder=""/>
                      </Col>
                    </Form.Group>}
                    <Form.Group as={Row} controlId="formVoucherDisplayArea">
                      <Form.Label column sm="3">
                        Voucher Display Area
                      </Form.Label>
                      <Col sm="9" md={8} lg={7}>
                        <Form.Check
                          custom
                          inline
                          type="radio"
                          onChange={this.handleInputOnChange}
                          name="display_area"
                          value="1"
                          checked={formData?.display_area === "1"}
                          id={`custom-radio-1`}
                          label={`Logged In User`}
                        />
                        <Form.Check
                          custom
                          inline
                          type="radio"
                          onChange={this.handleInputOnChange}
                          name="display_area"
                          value="2"
                          checked={formData?.display_area === "2"}
                          id={`custom-radio-2`}
                          label={`Guest`}
                        />
                        <Form.Check
                          custom
                          inline
                          type="radio"
                          onChange={this.handleInputOnChange}
                          name="display_area"
                          value="3"
                          checked={formData?.display_area === "3"}
                          id={`custom-radio-3`}
                          label={`Store Follower`}
                        />
                      </Col>
                    </Form.Group>
                  </Form>
                </div>
              </div>

              <div className="card-block">
                <div className="block-body">
                  <Form.Group as={Row} controlId="applicableFor">
                    <Form.Label column sm="3">
                      Applicable For <span className="text-danger">*</span>
                    </Form.Label>
                    <Col sm="9" md={8} lg={7}>
                      <Form.Control
                        value={formData.apply_to}
                        onChange={this.handleOnChangeApplicableFor}
                        as="select" placeholder="">
                        <option value="2">Specific Stores</option>
                      </Form.Control>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId="forStores">
                    <Form.Label column sm="3">
                      Store(s) <span className="text-danger">*</span>
                    </Form.Label>
                    <Col sm="9" md={8} lg={7}>
                      <Select
                        isMulti
                        value={this.state.selectedStores}
                        styles={styleReactSelect}
                        placeholder="Select Attribute..."
                        onChange={(value) => {
                          this.handleSelectMultiOnChange(value, 'stores')
                        }}
                        options={this.state.storeList}
                        name="attributes"
                        noOptionsMessage={() => 'Select store...'}
                      />
                    </Col>
                  </Form.Group>
                  {this.state.isApplyForCat &&
                  <Form.Group as={Row} controlId="forCategories">
                    <Form.Label column sm="3">
                      Categories <span className="text-danger">*</span>
                    </Form.Label>
                    <Col sm="9" md={8} lg={7}>
                      <Select
                        isMulti
                        // value={this.state.attribute}
                        styles={styleReactSelect}
                        placeholder="Select Attribute..."
                        onChange={(value) => {
                          this.handleSelectMultiOnChange(value, 'categories')
                        }}
                        options={[{
                          id: 1,
                          value: 'Men\'s Fashion',
                          label: 'Men\'s Fashion'
                        },
                          {
                            id: 2,
                            value: 'Electronic',
                            label: 'Electronic'
                          }]}
                        name="attributes"
                        noOptionsMessage={() => 'Select store...'}
                      />
                    </Col>
                  </Form.Group>}
                </div>
              </div>

              <div className="card-block">
                <div className="block-header">
                  <h4 className="block-title">Voucher Settings</h4>
                </div>

                <div className="block-body">
                  <Form>
                    <Form.Group as={Row} controlId="formDiscountType">
                      <Form.Label column sm="3">
                        Discount Type <span className="text-danger">*</span>
                      </Form.Label>
                      <Col sm="9" md={8} lg={7}>
                        <Form.Control
                          value={formData?.discount_type}
                          onChange={this.handleOnChangeDiscountType}
                          as="select" placeholder="">
                          <option value="1">Fixed Amount</option>
                          <option value="2">Percentage</option>
                        </Form.Control>
                      </Col>
                    </Form.Group>

                    {this.state.formData?.discount_type === '1' ?
                      <>
                        <Form.Group as={Row} controlId="discountAmount">
                          <Form.Label column sm="3">
                            Discount Amount (BDT) <span className="text-danger">*</span>
                          </Form.Label>
                          <Col sm="9" md={8} lg={7}>
                            <Form.Control onChange={this.handleInputOnChange}
                                          defaultValue={formData?.discount_value}
                                          name={"discount_value"} type="text" placeholder=""/>
                          </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId="minAmountToApplyVoucher">
                          <Form.Label column sm="3">
                            Minimum amount to apply voucher (BDT) <span className="text-danger">*</span>
                          </Form.Label>
                          <Col sm="9" md={8} lg={7}>
                            <Form.Control name={"minimum_order_value"}
                                          defaultValue={formData?.minimum_order_value}
                                          onChange={this.handleInputOnChange}
                                          type="text" placeholder=""/>
                          </Col>
                        </Form.Group>
                      </>
                      :
                      <>
                        <Form.Group as={Row} controlId="discountPercentage">
                          <Form.Label column sm="3">
                            Discount Percentage (%) <span className="text-danger">*</span>
                          </Form.Label>
                          <Col sm="9" md={8} lg={7}>
                            <Form.Control type="text" onChange={this.handleInputOnChange}
                                          defaultValue={formData?.discount_value}
                                          name="discount_value" placeholder=""/>
                          </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId="maxDiscountPercentage">
                          <Form.Label column sm="3">
                            Max discount Amount <span className="text-danger">*</span>
                          </Form.Label>
                          <Col sm="9" md={8} lg={7}>
                            <Form.Control type="text"
                                          defaultValue={formData?.maximum_discount_amount}
                                          name="maximum_discount_amount"
                                          onChange={this.handleInputOnChange}
                                          placeholder=""/>
                          </Col>
                        </Form.Group>
                      </>}

                    <Form.Group as={Row} controlId="totalVouchersToBeIssued">
                      <Form.Label column sm="3">
                        Total vouchers to be issued <span className="text-danger">*</span>
                      </Form.Label>
                      <Col sm="9" md={8} lg={7}>
                        <Form.Control name="total_voucher"
                                      onChange={this.handleInputOnChange}
                                      defaultValue={formData?.total_voucher}
                                      type="text" placeholder=""/>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="usageLimitCustomer">
                      <Form.Label column sm="3">
                        Usage Limit Per Customer <span className="text-danger">*</span>
                      </Form.Label>
                      <Col sm="9" md={8} lg={7}>
                        <Form.Control onChange={this.handleInputOnChange}
                                      defaultValue={formData?.usage_limit}
                                      name="usage_limit"
                                      type="text"/>
                      </Col>
                    </Form.Group>
                  </Form>
                </div>
              </div>

              <div className="card-block">
                <div className="block-body text-right">
                  <Link to={`${process.env.PUBLIC_URL}/promotions/seller-voucher`} className="btn btn-lg btn-default mr-3">Cancel</Link>
                  <Button size="lg"
                          disabled={this.state.isSubmitting}
                          onClick={this.handleFormSubmit} variant="primary">
                    {this.state.isSubmitting ? 'updating...' : 'Update'}
                  </Button>
                </div>
              </div>
            </>
          }
        </div>
      </App>
    );
  }
}

export default withRouter(EditSellerVoucher);
