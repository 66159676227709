import axios from 'axios';
import {getDeviceType, getLocalAuthData} from "../helper/utils";
import {API_BASE_URL} from "../helper/env";

export const getBankList = (paramsObj= {}) => {
  const authData = getLocalAuthData()
  return new Promise((resolve, reject) => {
    axios.get(`${API_BASE_URL}/bank`, {
      headers: {
        'x-auth-token': authData.token,
        'x-api-client': getDeviceType()
      },
      params: paramsObj
    })
      .then(res => {
        if(res.data.status === 1 || res.data.status === 2) {
          resolve(res.data.items)
        } else {
          reject(res.data.message)
        }
      })
      .catch(err => {
        reject(err.message)
      })
  })
}