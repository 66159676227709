import axios from 'axios'
import {getDeviceType, getLocalAuthData} from "../helper/utils";
import {API_BASE_URL} from "../helper/env";

export const getReviewList = (productId, paramsObj) => {
  const authData = getLocalAuthData()
  return new Promise((resolve, reject) => {
    axios.get(`${API_BASE_URL}/product/${productId}/reviews`, {
      headers: {
        'x-auth-token': authData?.token,
        'x-api-client': getDeviceType()
      },
      params: paramsObj
    })
      .then(res => {
        if(res.data.status === 1 || res.data.status === 2) {
          resolve(res.data)
        } else {
          reject(res.data.message)
        }
      })
      .catch(err => {
        reject(err.message)
      })
  })
}

export const replyOnReview = (data) => {
  // /reply-on-review
  const authData = getLocalAuthData()
  return new Promise((resolve, reject) => {
    axios.post(`${API_BASE_URL}/reply-on-review`, data,{
      headers: {
        'x-auth-token': authData?.token,
        'x-api-client': getDeviceType()
      }
    })
      .then(res => {
        if(res.data.status === 1 || res.data.status === 2) {
          resolve(res.data)
        } else {
          reject(res.data.message)
        }
      })
      .catch(err => {
        reject(err.message)
      })
  })
}