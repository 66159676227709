// noinspection JSUnresolvedVariable

import React, {Component} from 'react';
import "../assets/scss/product.scss";
import {Link} from "react-router-dom";
import moment from 'moment';
import {getLocalAuthData} from "../helper/utils";

class Store extends Component {
  state = {
    userType: null
  }

  componentDidMount() {
    const authData = getLocalAuthData()
    this.setState({userType: authData?.type})
  }

  render() {
    const {data} = this.props;

        return (
            <div className="table-responsive">
                <table className="order-table table table-striped">
                    <thead>
                    <tr>
                        <th style={{width: '20px'}}>#</th>
                        <th>Store Name</th>
                        <th>Product</th>
                        <th>Campaign Product</th>
                        <th style={{width: '1%'}}/>
                    </tr>
                    </thead>

          <tbody>
          {data.stores.length > 0 ?
            data.stores.map((store, index) => {
              return (
                <tr key={index}>
                  <td>{data.pagingCounter + index}</td>
                  <td width={"20%"}>
                    <div className="order-item">
                      <h4 className="order-id mb-0">{store.store_name}</h4>
                      <ul className="ordered-items">
                        <li>{"Created At:" + moment(store.createdAt).format('lll')}</li>
                      </ul>
                    </div>
                  </td>

                  <td>{store.total_product ? store.total_product : 0}</td>
                  <td className="nowrap">
                    {/*{this.props.view_products && <Link to={`/products/list?store_id=${store._id}`} className="text-bold text-success mr-3" target={'_blank'}>View Products</Link>}*/}
                    {this.props.view_detail &&
                    <Link to={`/stores/details/${store._id}`} className="text-bold text-default mr-3"
                          target={'_blank'}>View</Link>}

                  {this.props.handleDeleteStore && <button style={{cursor: "pointer"}}
                                     onClick={() => this.props.handleDeleteStore(store._id)}
                                     className="btn btn-outline-danger ml-2">Delete</button>}

                    {this.props.approveCampaignStore && <button style={{cursor: "pointer"}}
                                                                onClick={() => this.props.approveCampaignStore(store._id)}
                                                                className="btn btn-sm btn-outline-success ml-2">Approve</button>}

                    {this.props.removeCampaignStore && <button style={{cursor: "pointer"}}
                                                               onClick={() => this.props.removeCampaignStore(store._id)}
                                                               className="btn btn-outline-danger ml-2">Remove</button>}

                    {(this.props.removeCampaignRegisteredStore && data.my_stores.findIndex((elm) => elm._id === store._id) >= 0) &&
                    <button style={{cursor: "pointer"}}
                            onClick={() => this.props.removeCampaignRegisteredStore(store._id)}
                            className="btn btn-sm btn-outline-danger ml-2">Remove</button>}

                    {(this.props.removeCampaignApprovedStore && data.my_stores.findIndex((elm) => elm._id === store._id) >= 0) &&
                    <button style={{cursor: "pointer"}}
                            onClick={() => this.props.removeCampaignApprovedStore(store._id)}
                            className="btn btn-sm btn-outline-danger ml-2">Remove</button>}
                    {this.props.joinStoreWithCampaign && (data?.storeShowFilter.findIndex(elm => elm.toString() === store._id.toString()) === -1) ?
                      <button style={{cursor: "pointer"}}
                              onClick={() => this.props.joinStoreWithCampaign(store._id)}
                              className="btn btn-sm btn-outline-primary ml-2">Join</button> :
                      <span className={"badge badge-success"}>Already Joined</span>}

                    {this.props.handleProductListModal && <button style={{cursor: "pointer"}}
                                                                  onClick={() => this.props.handleProductListModal(store._id, this.props.campaignInfo._id)}
                                                                  className="btn btn-sm btn-outline-primary ml-2">Store
                      Product</button>}

                                      </td>
                                  </tr>
                                );
                        })
                        :
                        <tr>
                            <td colSpan={5} className={"text-bold text-center"}>
                                No store found. Please create <Link to={"/stores/create"}>new store</Link>
                            </td>
                        </tr>
                    }
                    </tbody>
                </table>
            </div>
        );
    }
}

export default Store;
