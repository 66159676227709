import React, {useState} from "react";
import Form from "react-bootstrap/esm/Form";
import {AiOutlineEye, AiOutlineEyeInvisible} from "react-icons/all";

const InputPassword = ({...rest}) => {
  const [isShowingPass, setIsShowingPass] = useState(false)

  const toggleShowingPass = () => {
    setIsShowingPass(!isShowingPass)
  }

  return (
    <div className={`input-password`}>
      <Form.Control type={isShowingPass ? 'text' : 'password'} {...rest}/>
      <span className={isShowingPass ? 'eye-icon active' : 'eye-icon'} onClick={toggleShowingPass}>
        {isShowingPass ? <AiOutlineEyeInvisible/> : <AiOutlineEye/>}
      </span>
    </div>
  )
}

export default InputPassword
