export const GET_FILE_LIST_REQUEST = 'GET_FILE_LIST_REQUEST';
export const GET_FILE_LIST_SUCCESS = 'GET_FILE_LIST_SUCCESS';
export const GET_FILE_LIST_FAILURE = 'GET_FILE_LIST_FAILURE';


export const FILE_UPLOAD_REQUEST = 'FILE_UPLOAD_REQUEST';
export const FILE_UPLOAD_SUCCESS = 'FILE_UPLOAD_SUCCESS';
export const FILE_UPLOAD_FAILURE = 'FILE_UPLOAD_FAILURE';

export const FILE_IS_UPLOADING = 'FILE_IS_UPLOADING'
export const FILE_UPLOAD_COMPLETE = 'FILE_UPLOAD_COMPLETE'
