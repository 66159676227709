import React, {Component} from 'react';
import "../assets/scss/delivery-progress-timeline.scss";
import moment from "moment";

class DeliveryProgressTimeline extends Component {
  render() {
    const {data} = this.props

    return (
      <div className={data?.length ? `delivery-progress-timeline grid-${data.length}` : `delivery-progress-timeline grid-1`}>
        <span className="progress-line"/>

        {(data && data?.length > 0) ?
          <div className="timeline-inner">
            {data.map((item, index) => (
              <div key={index} className={item.is_completed ? 'progress-block completed' : 'progress-block'}>
                <div className="date">{item.is_completed ? moment(item.createdAt).format('ll') : '-'}</div>
                <div className="circle"/>
                <div className="text">
                  <h4>{item.status}</h4>
                  <p>{item.message}</p>
                </div>
              </div>
            ))}
          </div>
          :
          <div className="timeline-inner">
            <div className="progress-block completed">
              <div className="date">12/03/2020</div>
              <div className="circle"/>
              <div className="text">
                <h4>Pending</h4>
                <p>Lorem ipsum dolor sit amet, consectetur</p>
              </div>
            </div>
          </div>
        }
      </div>
    );
  }
}

export default DeliveryProgressTimeline;
