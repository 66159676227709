import React, {Component} from 'react';
import App from "../../App";
import {Form, Modal, Button} from "react-bootstrap";
import {FaFileExport, FiMessageSquare} from "react-icons/all";
import {Link, withRouter} from "react-router-dom";
import {getDeviceType, getLocalAuthData} from "../../helper/utils";
import "../../assets/scss/order-list.scss";
import {getOrderList, orderStatusChange, parcelStatusChange} from "../../services/orderServices";
import moment from "moment";
import {API_BASE_URL} from "../../helper/env";
import ReactPaginate from 'react-paginate';
import {objToUrlPrams, urlPramsToObj} from "../../helper/utils";
import LoadingSpinner from "../../components/LoadingSpinner";
import DateRangePicker from "react-bootstrap-daterangepicker";
import axios from "axios";
import {toast} from "react-toastify";
import {getChildLocations} from "../../services/locationServices";
import {updateDepositPaymentStatus} from "../../services/orderServices";
import { postData } from '../../services/commonServices';
import {
  JsonToCsv,
  useJsonToCsv
} from 'react-json-csv';



//Socket for emmitting notification
import {SOCKET} from "../../helper/env";
import io from 'socket.io-client'

const adminEvent = 'admin_channel'
/*const adminSocket = io.connect(`${SOCKET}?event=${adminEvent}`)*/



const { saveAsCsv } = useJsonToCsv();

class OrderList extends Component {
  state = {
    activeBlockTab: 'All',
    orderList: [],
    isLoading: true,
    pagination: null,
    isDataLoading: false,
    defaultSetting: {
      limit: 20
    },
    storeList: [],
    queryData: {
      from_date: '',
      to_date: ''
    },
    locations: [],
    businessChildLocations: [],
    status: null,
    changingStoreId: null,
    changeStatusMsg: '',
    isShowingStatusChangeConfirmModal: false,
    showCancelMessageRequired: false,
    isSubmitting: false,
    selected_order_id: "",
    selected_deposit_info: {},
    depositInfoModal: false,
    isSubmittingDepositPaymentStatus: false,
    currentItem : null
  };

  componentDidMount() {
    const history = this.props.history

    this.getOrderList(null, () => {
      let activeStatus = 'All'
      if (history.location.search !== '') {
        const params = urlPramsToObj(history)
        if (params?.status) {
          activeStatus = params.status
        }
      }
      this.setState({isLoading: false, activeBlockTab: activeStatus})
    })

    axios.get(`${API_BASE_URL}/location?type=Country`)
      .then(res => {
        this.setState({locations: res.data.locations});
      }).catch(e => console.log("Error"));
  }

  getQueryData = (paramObj = {}) => {
    this.setState({isDataLoading: true});
    const history = this.props.history
    let queryData = this.state.queryData;

    if (paramObj?.page) {
      queryData.page = paramObj.page
    }

    this.getOrderList(queryData, () => {
      let activeStatus = 'All'
      if (history.location.search !== '') {
        const params = urlPramsToObj(history)
        if (params?.status) {
          activeStatus = params.status
        }
      }
      this.setState({
        isDataLoading: false,
        activeBlockTab: activeStatus
      })
    })
  }

  handleLocationInputOnChange = (e, index, typePurpose) => {
    const childLocations = [...this.state[typePurpose]];
    if (typePurpose === 'businessChildLocations') {
      this.handleSearchFormDataChange(e)
    } else {
      this.handleSearchFormDataChange(e)
    }
    if (e.target.value !== '') {
      getChildLocations(e.target.value)
        .then(res => {
          childLocations.splice(index, childLocations.length - index);
          if (res.length > 0) {
            childLocations[index] = res
          }
          this.setState({[typePurpose]: childLocations})
        })
        .catch(errMsg => {
          console.log(errMsg)
          childLocations.splice(index, childLocations.length - index)
          this.setState({[typePurpose]: childLocations})
        })
    } else {
      childLocations.splice(index, childLocations.length - index);
      this.setState({[typePurpose]: childLocations})
    }
  }

  renderLocations = () => {
    const locations = this.state.locations;
    if (locations) {
      return (
        <>
          {locations.map((items, index) =>
            <option value={`${items._id}`}>{items.name}</option>
          )}
        </>
      )
    }
  };

  handleSearchFormDataChange = (e) => {
    const merchant_type = e.target.name;
    const merchant_type_id = e.target.value;
    if (merchant_type === "merchant_type") {
      const headers = {
        'Content-Type': 'application/json',
        'x-api-client': getDeviceType(),
        'x-auth-token': getLocalAuthData().token,
      }
      axios.get(`${API_BASE_URL}/store?merchant_type=${merchant_type_id}`, {headers: headers})
        .then(res => {
          this.setState({storeList: res.data.items});
        })
        .catch(err => {
          console.log(err)
        });
    }
    this.setState({queryData: {...this.state.queryData, [e.target.name]: e.target.value}});
  };

  handleBlockTab = (label) => {
    const history = this.props.history
    let params = null

    if (history.location.search !== '') {
      params = urlPramsToObj(history)
    }

    this.setState({isDataLoading: true}, () => {
      const paramsObj = {
        ...params,
        ...this.state.queryData,
        status: label
      }
      paramsObj.page = 1
      this.getOrderListAndUpdateUrl(paramsObj)
      this.setState({activeBlockTab: label})
    })
  };

  handleOnApply = (e, picker) => {
    const queryData = {...this.state.queryData};
    queryData.from_date = moment(picker.startDate).format('DD/MM/YYYY');
    queryData.to_date = moment(picker.endDate).format('DD/MM/YYYY');
    this.setState({queryData}, () => {
      console.log(this.state)
    })
  }

  renderPaymentStatus = (status) => {
    let payment_status = {
      0: "Unpaid",
      1: "Partially Paid",
      2: "Paid",
      3: "Cancelled",
      4: "Failed",
      5: "Refund",
      6: "Bank deposit payment in review",
      7: "Bank deposit declined",
    }
    return payment_status[status];
  }

  handlePagination = (pageNo) => {
    const history = this.props.history
    let params = null

    if (history.location.search !== '') {
      params = urlPramsToObj(history)
    }

    this.setState({isDataLoading: true}, () => {
      const paramsObj = {
        ...params,
        page: pageNo.selected + 1
      }

      this.getOrderListAndUpdateUrl(paramsObj)
    })
  }

  getOrderList = (paramsObj, callback) => {
    const history = this.props.history
    let params = null
    if (history.location.search !== '') {
      params = urlPramsToObj(history)
    }

    getOrderList({
      ...this.state.defaultSetting,
      ...params,
      ...paramsObj,
      ...this.state.queryData
    })
      .then(res => {
        this.setState({
          orderList: res.items,
          pagination: res.pagination
        })
        callback()
      })
      .catch(errMsg => {
        console.log(errMsg)
        callback()
      })
  }

  getOrderListAndUpdateUrl = (paramsObj) => {
    const history = this.props.history

    getOrderList({
      ...this.state.defaultSetting,
      ...paramsObj
    })
      .then(res => {
        this.setState({
          orderList: res.items,
          pagination: res.pagination,
          isDataLoading: false
        })
        history.push(`${history.location.pathname}?${objToUrlPrams(paramsObj)}`)
      })
      .catch(errMsg => {
        console.log(errMsg)
        this.setState({isDataLoading: false})
      })
  }

  hideChangeStatusModal = () => {
    this.setState({isShowingStatusChangeConfirmModal: false})
  }

  submitChangeStatusForm = (e) => {
    e.preventDefault()

    if (this.state.changeStatusMsg === '' && this.state.status === 'Cancelled') {
      return this.setState({showCancelMessageRequired: true});
    }
    this.setState({isSubmitting: true, showCancelMessageRequired: false}, () => {
      orderStatusChange(this.state.changingStoreId, {
        status: this.state.status,
        message: this.state.changeStatusMsg
      })
        .then(res => {


          //status changing here lets emit push notification
          console.log(res)
          console.log(this.state.status)

          console.log(this.state.changingStoreId)
          console.log("customer ID ::: "+this.state.currentItem.customer_info?._id)


          var customerID = this.state.currentItem.customer_info?._id


          console.log("Auth Data :: ")

          const data = {
            user: customerID, // user id
            title: "Order status has been changed",
            description: "order id : "+this.state.changingStoreId + " set to status "+this.state.status,
            type: "ORDER_STATUS",
            type_id: this.state.changingStoreId,
            createdAt: new Date()
          }



          postData('/notification', data).then(res => {
            console.log(res)

            //Emitting to Customer panel
            const customerEvent = customerID
            const customerSocket = io.connect(`${SOCKET}?event=${customerEvent}`)
            customerSocket.emit(customerEvent, res.new_notification)

            //Emitting to Admin panel
            io.connect(`${SOCKET}?event=${adminEvent}`).emit('admin_channel', res.new_notification)

          }).catch(err => {
            console.log(err)
          })







          this.setState({
            isDataLoading: false,
            isSubmitting: false,
            changeStatusMsg: '',
            changingStoreId: null,
            currentItem: null,
            isShowingStatusChangeConfirmModal: false
          })
          toast.success(res.message)
        })
        .catch(errMsg => {
          this.setState({isDataLoading: false, isSubmitting: false})
          toast.error(errMsg)
        })
    })
  }

  ShowStatusOption = (Status) => {
    let options = [];
    if (Status === "Delivered") {
      options = [{
        value: 'Delivered',
        label: 'Delivered'
      }
      ]
    } else if (Status === "Cancelled") {
      options = [{
        value: 'Cancelled',
        label: 'Cancelled'
      }
      ]
    } else {
      options = [{
        value: 'Pending',
        label: 'Pending',
      }, {
        value: 'Confirmed',
        label: 'Confirmed'
      }, {
        value: 'Processing',
        label: 'Processing'
      }, {
        value: 'Picked',
        label: 'Picked'
      }, {
        value: 'Shipped',
        label: 'Shipped'
      }, {
        value: 'Delivered',
        label: 'Delivered'
      }, {
        value: 'Cancelled',
        label: 'Cancelled'
      }
      ]
    }
    return options;
  }


  showDepositInfo = (order_id, deposit_info, payment_status) => {
    this.setState({
      selected_order_id: order_id,
      selected_deposit_info: deposit_info,
      depositInfoModal: true,
      current_payment_status: payment_status
    }, () => {
      console.log(this.state.selected_order_id);
      console.log(this.state.selected_deposit_info);
    });
  }

  updateDepositPaymentStatus = (order_id, payment_status) => {
    if (order_id && payment_status) {
      updateDepositPaymentStatus({order_id: order_id, payment_status: payment_status}).then(async (res) => {
        if (res.status === 1) {
          toast.success(res.message);

          /* RECALL LIST */
          await this.getQueryData();
          this.setState({depositInfoModal: false});

        } else {
          toast.success(res.message);
        }
      }).catch(function (e) {
        console.log(e.message);
      });
    } else {
      console.log("order Id or payment status missing");
    }
  }

  handleCloseDepositInfoModal = () => {
    this.setState({depositInfoModal: false});
  }

  handleContactBtn = (e, id, key) => {
    e.preventDefault();
    let data= {};

    if(key === "user_id"){
      data.user_id = id
    }else{
      data.store_id = id
    }

    postData(`/paired`, data)
      .then((res) => {
        localStorage.setItem('pairId', res.paired._id);
        this.props.history.push('/messages');
      })
      .catch((err) => {
        console.log(err);
      });
  }

  handleExportFile = async (fileType) => {
    let queryData = "";
    let mData = [];

    let payment_status = ['Unpaid', 'Partially paid', 'Paid', 'Cancelled', 'Failed', 'Refund', 'Bank Deposit Payment In Review', 'Bank Deposit Declined']
    Object.entries(this.state.queryData).forEach(([key, val]) => {
        queryData = queryData.concat(key + "=" + val + "&");
      }
    )
    await this.setState({isExporting: true});

    this.state.orderList && this.state.orderList.map( (data , index) =>{

      let customer_phone = data?.customer_phone?(data?.customer_phone?.split('-').join('-').split('+')[1]).toString():"--"
      mData.push({
         'index': index,
         'Order Info' : data?.order_parcels?.order_parcel_code,
         'Order Date' : moment(data?.createdAt),
         'Customer Name' : data?.customer_name,
         'Customer Number' : customer_phone.toString(),
         'Customer Email' : data?.customer_email,
         'Payment Method' : data?.payment_method?.name,
         'Payment Status' : payment_status[data?.order_parcels?.payment_status],
         'Shipping Method' : data?.order_parcels?.selected_shipping_option?data.order_parcels?.selected_shipping_option:'',
         'Shipping Charge' : data?.order_parcels?.parcel_shipping_cost,
         'Store Info': data?.order_parcels?.store_info?.store_name,
         'Status' : data?.order_parcels?.status,
         'Price' : data?.order_parcels?.parcel_grand_total,
         'TransactionId': data?.transaction_id
      });

    });
    const filename = 'EkShop-Parcel Order',
    fields = {
      'index': "Index",
      'Order Info' : "Order Info",
      'Order Date' : "Order Date",
      'Customer Name' : "Customer Name",
      'Customer Number' : "Customer Number",
      'Customer Email' : "Customer Email",
      'Payment Method' : "Payment Method",
      'Payment Status': "Payment Status",
      'Shipping Method': "Shipping Method",
      'Shipping Charge': "Shipping Charge",
      'Store Info': "Store Info",
      'Status' : "Status",
      'Price' : "Price"
     },
     style = {
       padding: "15px",
     },
     data = mData
     ,
     text = "Convert Json to Csv";
     saveAsCsv({data, fields, filename});
  }


  render() {
    return (
      <App layout="boxed">
        <div className="main-content">
          <div className="page-header">
            <h2 className="page-title">Order List</h2>
             <div className="ml-auto">
              <Button onClick={() => this.handleExportFile('xlsx')} size={"sm"} variant={"default"} className="mb-0 ml-2"><FaFileExport/> Export</Button>
            </div>
          </div>

          {/*<Modal show={this.state.isShowingStatusChangeConfirmModal} onHide={this.hideChangeStatusModal}>*/}
          {/*  <Modal.Header closeButton>*/}
          {/*    <Modal.Title>Change Status Message</Modal.Title>*/}
          {/*  </Modal.Header>*/}
          {/*  <Form onSubmit={this.submitChangeStatusForm}>*/}
          {/*    <Modal.Body>*/}
          {/*      <Form.Control as={`textarea`}*/}
          {/*                    onChange={(e) => {*/}
          {/*                      this.setState({changeStatusMsg: e.target.value})*/}
          {/*                    }}*/}
          {/*                    placeholder={`Enter you message here...`} rows={3}/>*/}
          {/*    </Modal.Body>*/}
          {/*    <Modal.Footer>*/}
          {/*      <Button variant="default" onClick={this.hideChangeStatusModal}>*/}
          {/*        Close*/}
          {/*      </Button>*/}
          {/*      <Button disabled={this.state.isSubmitting}*/}
          {/*              variant="primary" type={`submit`}>*/}
          {/*        {this.state.isSubmitting ? 'Submitting...': 'Submit'}*/}
          {/*      </Button>*/}
          {/*    </Modal.Footer>*/}
          {/*  </Form>*/}
          {/*</Modal>*/}

          <Modal show={this.state.isShowingStatusChangeConfirmModal} onHide={this.hideChangeStatusModal}>
            <Modal.Header closeButton>
              <Modal.Title>Change Status Message</Modal.Title>
            </Modal.Header>
            <Form onSubmit={this.submitChangeStatusForm}>
              <Modal.Body>
                {this.state.status === 'Cancelled' ?
                  <Form.Control as={`textarea`}
                                onChange={(e) => {
                                  this.setState({changeStatusMsg: e.target.value})
                                }}
                                placeholder={`Enter you message here...`} rows={3}/>
                  : <span><h5>Are you sure you want to change the order status?</h5></span>}
                {this.state.status === 'Cancelled' && this.state.showCancelMessageRequired ?
                  <span><h5 style={{'color': "red"}}><i>Cancel message is required!</i></h5></span>
                  : ''}
              </Modal.Body>
              <Modal.Footer>
                {this.state.status === 'Cancelled' && this.state.showCancelMessageRequired ?
                  <>
                    <Button variant="default" onClick={this.hideChangeStatusModal}>
                      Close
                    </Button>
                    <Button disabled={this.state.isSubmitting}
                            variant="primary" type={`submit`}>
                      {this.state.isSubmitting ? 'Submitting...' : 'Submit'}
                    </Button>
                  </>
                  :
                  <>
                    <Button variant="default" onClick={this.hideChangeStatusModal}>
                      No
                    </Button>
                    <Button disabled={this.state.isSubmitting}
                            variant="primary" type={`submit`}>
                      {this.state.isSubmitting ? 'Submitting...' : 'Yes'}
                    </Button>
                  </>
                }
              </Modal.Footer>
            </Form>
          </Modal>


          <Modal size={"lg"} show={this.state.depositInfoModal} onHide={this.handleCloseDepositInfoModal}>
            <Modal.Header closeButton>
              <Modal.Title>Deposit Payment Information</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              {(this.state?.current_payment_status == 2 || this.state?.current_payment_status == 6 || this.state?.current_payment_status == 7) ?
                <table className="info-table table-lg">
                  <tbody>

                  <tr>
                    <td className="text-bold">Deposit By: <span className="text-danger">*</span></td>
                    <td className="text-bold">:</td>
                    <td className="text-bold">{this.state?.selected_deposit_info?.deposit_by}</td>
                  </tr>

                  <tr>
                    <td className="text-bold">Deposit Date: <span className="text-danger">*</span></td>
                    <td className="text-bold">:</td>
                    <td
                      className="text-bold">{moment(this.state?.selected_deposit_info?.deposit_date).format('ll')}</td>
                  </tr>

                  <tr>
                    <td className="text-bold">Bank Name: <span className="text-danger">*</span></td>
                    <td className="text-bold">:</td>
                    <td className="text-bold">{this.state?.selected_deposit_info?.bank_name}</td>
                  </tr>

                  <tr>
                    <td className="text-bold">Account Number: <span className="text-danger">*</span></td>
                    <td className="text-bold">:</td>
                    <td className="text-bold">{this.state?.selected_deposit_info?.account_number}</td>
                  </tr>

                  <tr>
                    <td className="text-bold">Branch Name: <span className="text-danger">*</span></td>
                    <td className="text-bold">:</td>
                    <td className="text-bold">{this.state?.selected_deposit_info?.branch_name}</td>
                  </tr>

                  <tr>
                    <td className="text-bold">Slip Number: <span className="text-danger">*</span></td>
                    <td className="text-bold">:</td>
                    <td className="text-bold">{this.state?.selected_deposit_info?.slip_number}</td>
                  </tr>


                  <tr>
                    <td className="text-bold">Desposit Status:</td>
                    <td className="text-bold">:</td>
                    <td className="text-bold">{this.state?.selected_deposit_info?.deposit_status}</td>
                  </tr>

                  <tr>
                    <td colSpan={"3"}>
                      <img src={this.state?.selected_deposit_info?.deposit_slip}
                           style={{maxWidth: '100%', height: "auto"}} alt=""/>
                    </td>
                  </tr>

                  </tbody>
                </table>
                :
                <>
                  {this.state?.current_payment_status === 0 ? <><span>Please wait for deposit upload</span></> : ''}
                </>
              }


            </Modal.Body>

            <Modal.Footer>
              <Button variant="default" onClick={this.handleCloseDepositInfoModal}>
                Close
              </Button>

              {(this.state?.current_payment_status === 6) &&
              <>
                <Button variant="primary" type={`button`} disabled={this.state.isSubmittingDepositPaymentStatus === 2}
                        onClick={() => this.updateDepositPaymentStatus(this.state.selected_order_id, 2)}>
                  {this.state.isSubmittingDepositPaymentStatus ? 'Please Wait...' : 'Approve'}
                </Button>
                <Button variant="warning" type={`button`} disabled={this.state.isSubmittingDepositPaymentStatus === 7}
                        onClick={() => this.updateDepositPaymentStatus(this.state.selected_order_id, 7)}>
                  {this.state.isSubmittingDepositPaymentStatus ? 'Please Wait...' : 'Declined'}
                </Button>
              </>
              }

            </Modal.Footer>
          </Modal>


          {this.state.isLoading ?
            <div className={`card-block`}>
              <div className="block-body">
                Loading...
              </div>
            </div>
            :
            <div className="card-block">
              <ul className="desc-header-nav">
                <li className={this.state.activeBlockTab === 'All' ? 'active' : ''}>
                  <span onClick={() => this.handleBlockTab('All')}>All</span>
                </li>
                <li className={this.state.activeBlockTab === 'Pending' ? 'active' : ''}>
                  <span onClick={() => this.handleBlockTab('Pending')}>Pending</span>
                </li>
                <li className={this.state.activeBlockTab === 'Confirmed' ? 'active' : ''}>
                  <span onClick={() => this.handleBlockTab('Confirmed')}>Confirmed</span>
                </li>
                <li className={this.state.activeBlockTab === 'Processing' ? 'active' : ''}>
                  <span onClick={() => this.handleBlockTab('Processing')}>Processing</span>
                </li>
                <li className={this.state.activeBlockTab === 'Picked' ? 'active' : ''}>
                  <span onClick={() => this.handleBlockTab('Picked')}>Picked</span>
                </li>
                <li className={this.state.activeBlockTab === 'Shipped' ? 'active' : ''}>
                  <span onClick={() => this.handleBlockTab('Shipped')}>Shipped</span>
                </li>
                <li className={this.state.activeBlockTab === 'Delivered' ? 'active' : ''}>
                  <span onClick={() => this.handleBlockTab('Delivered')}>Delivered</span>
                </li>
                <li className={this.state.activeBlockTab === 'Cancelled' ? 'active' : ''}>
                  <span onClick={() => this.handleBlockTab('Cancelled')}>Cancelled</span>
                </li>
              </ul>

              <div className="block-body position-relative">


                <form className="form-inline mb-3">
                  <div className="form-group mr-3 mt-2">
                    <label htmlFor="status" className="sr-only">Locations</label>
                    <select id="status" name="country" className='form-control form-control-sm'
                            onChange={(e) => this.handleLocationInputOnChange(e, 0, 'businessChildLocations')}>
                      <option value="">--Select Country--</option>
                      {this.renderLocations()}
                    </select>
                  </div>

                  {this.state.businessChildLocations.length > 0 &&
                  this.state.businessChildLocations.map((location, index) => (
                    <div className="form-group mr-3 mt-2" key={index}>
                      <label htmlFor={`location${location[0].type}`}
                             className="sr-only">{location[0].type}</label>
                      <select id={`location${location[0].type}`}
                              name={location[0].type.toLowerCase()}
                              className='form-control form-control-sm'
                              onChange={(e) => this.handleLocationInputOnChange(e, index + 1, 'businessChildLocations')}>
                        <option value="">--Select--</option>
                        {location.map((item, index) => (
                          <option key={index} value={item._id}>{item.name}</option>
                        ))}
                      </select>
                    </div>
                  ))
                  }

                  {this.state.storeList?.length > 0 &&
                  <div className="form-group mr-3 mt-2">
                    <label htmlFor="barndName" className="sr-only">Store</label>
                    <select
                      id="store"
                      name="store_id"
                      className='form-control form-control-sm'
                      onChange={this.handleSearchFormDataChange}
                    >
                      <option value="">--Select Store--</option>
                      {this.renderStoreList()}
                    </select>
                  </div>}

                  <div className="form-group mr-3 mt-2">
                    <label htmlFor="searchInput" className="sr-only">Order Number</label>
                    <input type="search" id="orderNumber" className='form-control form-control-sm'
                           placeholder="Order Number" name="order_parcel_code"
                           onChange={this.handleSearchFormDataChange}
                           onKeyDown={(e)=>{
                             if(e.keyCode === 13){
                              this.getQueryData({page: 1})
                             }
                           }}/>
                  </div>

                  <div className="form-group mr-3 mt-2">
                    <label htmlFor="sellerSKU" className="sr-only">Customer</label>
                    <input type="search" id="customer" name="customer_search"
                           onChange={this.handleSearchFormDataChange}
                           className='form-control form-control-sm'
                           placeholder="Customer"
                           onKeyDown={(e)=>{
                            if(e.keyCode === 13){
                             this.getQueryData({page: 1})
                            }
                          }}/>
                  </div>

                  <div className="form-group mr-3 mt-2">
                    <label htmlFor="shopSKU" className="sr-only">Product</label>
                    <input type="search" id="product" name="product_search"
                           onChange={this.handleSearchFormDataChange}
                           className='form-control form-control-sm'
                           placeholder="Product"
                           onKeyDown={(e)=>{
                            if(e.keyCode === 13){
                             this.getQueryData({page: 1})
                            }
                          }}/>
                  </div>

                  <div className="form-group mr-3 mt-2">
                    <DateRangePicker
                      initialSettings={{}}
                      containerStyles={{textAlign: 'left', width: '100%', display: 'block'}}
                      onApply={this.handleOnApply}>
                      <input type="button"
                             placeholder={`Select date range`}
                             value={(this.state.queryData.from_date && this.state.queryData.to_date) ? `${this.state.queryData.from_date} - ${this.state.queryData.to_date}` : 'Select date range'}
                             style={{textAlign: 'left', minWidth: '150px'}}
                             className="form-control form-control-sm"/>
                    </DateRangePicker>
                  </div>

                  <Button className="mt-2" size="sm" variant="secondary"
                          onClick={() => this.getQueryData({page: 1})}>Search</Button>
                </form>


                {this.state.isDataLoading &&
                <LoadingSpinner text={`Loading...`}/>
                }

                <table className="order-table table table-striped">
                  <thead>
                  <tr>
                    <th style={{width: '1%'}}>#</th>
                    <th>Order Info</th>
                    <th>Customer Info</th>
                    <th className={`text-right`}>Order Amount (৳)</th>
                    <th>Payment Method</th>
                    <th>Payment Status</th>
                    <th style={{width: '150px'}}>Status</th>
                    <th style={{width: '120px'}}/>
                  </tr>
                  </thead>
                  <tbody>
                  {this.state.orderList.length > 0 ?
                    this.state.orderList.map((item, index) => {
                      return (<tr key={index}>
                        <td>{this.state.pagination.pagingCounter + index}</td>
                        <td>
                          <div className="order-item">
                            <h4 className="order-id">
                              <Link
                                to={`${process.env.PUBLIC_URL}/orders/details/${item.order_parcels._id}`}
                                target={'_blank'}>{item?.order_parcels?.order_parcel_code}</Link>
                            </h4>
                            <span className="order-date">{moment(item.createdAt).format(`ll`)}</span>
                            <span className="order-time">{moment(item.createdAt).format(`LT`)}</span>
                          </div>
                        </td>

                        <td>
                          <div className="order-item">
                            <h4 className="order-id">{item.customer_info?.name}</h4>
                            <ul className="ordered-items">
                              <li>{item.customer_info?.phone}</li>
                              <li>{item.customer_info?.email}</li>
                              <li>
                                <Link onClick={(e)=>this.handleContactBtn(e, item?.customer_info?._id, "user_id")} to={`/`} target={'_blank'}>
                                    <span className="icon"><FiMessageSquare/></span> Contact Customer
                                </Link>
                              </li>

                            </ul>
                          </div>
                        </td>
                        <td className={`text-right`}>{item.order_parcels.parcel_grand_total}</td>
                        <td>{item.payment_method?.name ? item.payment_method?.name : 'COD'}</td>
                        <td>{this.renderPaymentStatus(item.payment_status)}</td>
                        <td>
                          {/* disabling status selection dropdown if deposit form is not updated */}
                          <td>
                                {
                                  ((item?.payment_method?.name === "Bank Payment" && item?.payment_status === 6) || item?.payment_method?.name === "COD") &&
                                  <>
                                  <select style={{width: '150px'}}
                                  value={item.order_parcels.status}
                                  className="form-control form-control-sm"
                                  onChange={(e) => {
                                    this.setState({
                                      status: e.target.value,
                                      changingStoreId: item.order_parcels._id,
                                      currentItem : item,
                                      isShowingStatusChangeConfirmModal: true
                                    })
                                  }}>
                                  {this.ShowStatusOption(item.order_parcels.status).map((option, index) => (
                                  <option key={index} selected={item.order_parcels.status === option.value}
                                      value={option.value}>{option.label}</option>
                                  ))}
                                  </select>
                                  {item?.payment_status === 6 ? <><span>Please check uploaded deposit</span></> : ''}
                                  </>
                                }
                                {
                                  (item?.payment_method?.name === "Bank Payment" && item?.payment_status === 0) &&
                                  <>
                                  {item?.payment_status === 0 ? <><span>Please wait for deposit upload</span></> : ''}
                                  </>
                                }
                              </td>
                        </td>
                        <td>
                          <Link to={`/orders/details/${item.order_parcels._id}`}
                                className="btn btn-block btn-sm btn-outline-info" target={'_blank'}>View</Link>
                          <Link to={`/orders/invoice/${item.order_parcels._id}`}
                                className="btn btn-block btn-sm btn-outline-secondary" target={'_blank'}>Invoice</Link>

                          {(item?.payment_method?.name === "Bank Payment") && <button
                            onClick={() => this.showDepositInfo(item?._id, item?.deposit_info, item?.payment_status)}
                            className="btn btn-block btn-sm btn-outline-info" target={'_blank'}>Deposit Info</button>}
                        </td>
                      </tr>)
                    })
                    :
                    <tr>
                      <td colSpan={7} className={`text-bold text-center`}>
                        No order found
                      </td>
                    </tr>
                  }
                  </tbody>
                </table>

                {(this.state.pagination && this.state?.pagination?.totalPages > 1) &&
                <div className="mt-3">
                  <ReactPaginate
                    previousLabel={'«'}
                    nextLabel={'»'}
                    breakLabel={'...'}
                    pageClassName={'page-item'}
                    pageLinkClassName={'page-link'}
                    pageCount={this.state.pagination.totalPages}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    forcePage={this.state.pagination.page - 1}
                    onPageChange={this.handlePagination}
                    containerClassName={'pagination'}
                    activeClassName={'active'}
                    activeLinkClassName={`disabled`}
                    disabledClassName={`disabled`}
                  />
                </div>
                }
              </div>
            </div>
          }
        </div>
      </App>
    );
  }
}

export default withRouter(OrderList);
