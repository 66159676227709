import React, {useRef, useState} from 'react';
import headerBg from "../assets/img/bg-1.jpg";
import {Link} from "react-router-dom";

const HeaderPartItem = ({title, focused, icon, pulse, pulseColor, children, actionTo}) => {
  const [isShowingDropdown, setIsShowingDropdown] = useState(false)
  const dropdownRef = useRef(null)

  const handleToggleDropdown = () => {
    setIsShowingDropdown(!isShowingDropdown)
    document.addEventListener("click", handleClickOutside, true);
  }

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      // console.log(event);
      setIsShowingDropdown(false);
    }
  };

  return (
    <div
        style={{marginRight: '30px'}}
        ref={dropdownRef}
        className={focused ? "part-item focused" : "part-item"}>
      <span onClick={handleToggleDropdown} className={pulse ? `icon pulse pulse-${pulseColor || 'success'}` : 'icon'}>
        {icon}

        {pulse && <span className="pulse-ring"/>}
      </span>

      {isShowingDropdown &&
      <div className="part-item-dropdown">
        <div className="dropdown-header" style={{backgroundImage: `url(${headerBg})`}}>
          {title && <h4 className="title">{title}</h4>}
        </div>

        <div className="dropdown-body">
          {children}
        </div>

        {actionTo &&
        <div className="dropdown-footer text-center">
          <Link to={actionTo} className={'btn btn-sm btn-primary'}>View All</Link>
        </div>}
      </div>}
    </div>
  );
};

export default HeaderPartItem;