import React, {Component} from "react";
import App from "../App";

class Settings extends Component {
  render() {
    return (
      <App>
        Settings
      </App>
    );
  }
}

export default Settings;
