import axois from "axios";
import {API_BASE_URL} from "../helper/env";
import {getDeviceType, getLocalAuthData} from "../helper/utils";


export const getNotifications = () => {
    const authData = getLocalAuthData();
    console.log("Token ::: "+authData == null ? "" : authData.token)

    return new Promise((resolve, reject) => {
        axois.get(`${API_BASE_URL}/notification`, {
            headers: {
                'x-auth-token': authData?.token,
                'x-api-client': getDeviceType()
            }
        }).then(res => {
            if (res.data.status) {

                console.log("response")
                console.log(res.data)

                resolve(res.data)
            }
        }).catch(err => {
            reject(err.message)
        })
    })
}

export const updateReadStatus = (id) => {
    const authData = getLocalAuthData();
    return new Promise((resolve, reject) => {
        axois.patch(`${API_BASE_URL}/notification/changeStatus/${id}`, {"read_from":"merchant"}, {
            headers: {
                'x-auth-token': authData.token,
                'x-api-client': getDeviceType()
            }
        }).then(res => {
            if (res.data.status === 1) {
                resolve(res.data)
            } else {
                reject(res.data)
            }
        }).catch(err => {
            reject(err.message)
        })
    })
}
