import React, {Component} from 'react';
import {getCollectionList} from "../services/collectionServices";
import {Col, Form, Row} from "react-bootstrap";
import styleReactSelect from "../assets/styleReactSelect";
import Select from "react-select";

class SelectCollection extends Component {
  state = {
    collections: [],
    selectedCollections: []
  }

  componentDidMount() {
    getCollectionList()
      .then(res => {
        const collections = [];
        const data = this.props.data || [];
        const selectedCollections = [];

        res.forEach(collection => {
          collections.push({
            id: collection._id,
            label: collection.name,
            value: collection._id
          });
        })

        data.forEach(item => {
          const collection = collections.filter(obj => {
            return obj.id === item
          })
          selectedCollections.push(collection[0]);
        })

        this.setState({collections, selectedCollections}, () => {
          console.log('Hello: ', this.state)
        })
      })
      .catch(errMsg => {
        console.log('Collection error: ', errMsg)
      })
  }

  render() {
    return (
      <div className="card-block">
        <div className="block-header">
          <h4 className="block-title">Collections</h4>
        </div>

        <div className="block-body">
          <Form.Group as={Row} controlId="bulkVariant">
            <Form.Label column sm="3">
              Collections
            </Form.Label>

            <Col sm="9" md={9} lg={7}>
              {(this.props?.data && this.state.selectedCollections.length > 0) ?
                <Select
                  key={1}
                  isMulti
                  defaultValue={this.state.selectedCollections}
                  styles={styleReactSelect}
                  placeholder="Select collections..."
                  onChange={(value) => this.props.onChange(value)}
                  options={this.state.collections}
                  name="attributes"/>
                :
                <Select
                  key={2}
                  isMulti
                  styles={styleReactSelect}
                  placeholder="Select collections..."
                  onChange={(value) => this.props.onChange(value)}
                  options={this.state.collections}
                  name="attributes"
                />
              }
              {/*<Select
                isMulti
                // value={this.state.attribute}
                styles={styleReactSelect}
                placeholder="Select Attribute..."
                onChange={(value) => this.props.onChange(value)}
                options={this.state.collections}
                name="attributes"
                noOptionsMessage={() => 'Please insert new value...'}
              />*/}
            </Col>
          </Form.Group>
        </div>
      </div>
    );
  }
}

export default SelectCollection;
