import React, {Component} from 'react';
import watch from "../../temp/watch.jpg";
import App from "../../App";
import {Button, Col, Form, InputGroup, Modal, Pagination, Row} from "react-bootstrap";
import {
    FaFileExport,
    FiCheck,
    FiEdit,
    FiPlus,
    FiSearch,
    FiTrash,
    FiUpload,
    GoLinkExternal,
    IoMdClose
} from "react-icons/all";
import {Link, withRouter} from "react-router-dom";
import {urlPramsToObj} from "../../helper/utils";
import {confirmAlert} from "react-confirm-alert";
import {toast} from "react-toastify";
import {getTableData, getDetail, deleteItem, updateItemPartial} from "../../services/commonServices";
import LoadingSpinner from "../../components/LoadingSpinner";
import moment from "moment"
import DateRangePicker from "react-bootstrap-daterangepicker";
import Store from "../../components/Store";
import {getStoreList} from "../../services/storeServices";
import {joinStoreWithCampaign} from "../../services/promotionServices";

class CampaignOfferList extends Component {
    state = {
        campaignList: [],
        loading: false,
        pagination: null,
        isDataLoading: false,
        merchant: '',
        queryData: {
            limit: 50
        },

        locations: [],
        selectedCampaignId: '',
        isShowingStoreJoinModal: false,
        storeShowFilter: [],

        showRegisteredStoresModal: false,
        registeredStoreList: [],

        showApprovedStoresModal: false,
        approvedStoreList: [],

        showRegisteredProductsModal: false,
        registeredProductList: [],

        showApprovedProductsModal: false,
        approvedProductList: [],

    }

    componentDidMount() {
        let merchant = null;
        this.setState({loading: true});
        const history = this.props.history;
        if (history.location.search) {
            let queryObj = urlPramsToObj(history);
            if (queryObj.merchant) {
                merchant = queryObj.merchant;
                this.setState({merchant: queryObj.merchant});
            }
        }

        this.getTableData()

        getStoreList().then(res => {
            this.setState({stores: res.items, loading: false},);
        }).catch(errMsg => {
            this.setState({loading: false});
        })
    }

    handleOnApply = (e, picker) => {
        const queryData = {...this.state.queryData};

        queryData.from_date = moment(picker.startDate).format('DD/MM/YYYY');
        queryData.to_date = moment(picker.endDate).format('DD/MM/YYYY');

        this.setState({queryData}, () => {
            console.log(this.state)
        })
    }

    renderStatus = (status) => {
        if (status === -1) {
            return <span className={"badge badge-danger"}>Expired</span>
        } else if (status === 0) {
            return <span className={"badge badge-primary"}>Pending</span>
        } else if (status === "1") {
            return <span className={"badge badge-success"}>Active</span>
        } else if (status === "2") {
            return <span className={"badge badge-warning"}>Inactive</span>
        } else {
            return <span>--</span>
        }
    }

    handleDeleteItem = (id) => {
        confirmAlert({
            title: 'Delete',
            message: 'Are you sure to delete this campaign permanently?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => deleteItem(`/campaign/${id}/permanent`).then(res => {
                        this.setState({isDataLoading: true}, () => {
                            this.getTableData()
                        })

                        toast.success(res.message);
                    }).catch(errMsg => {
                        toast.error(errMsg);
                    })
                }, {label: 'No',}
            ]
        });
    }

    getTableData = (query = {}) => {
        getTableData(`/campaign`, {...this.state.queryData, ...query}).then(res => {
            this.setState({campaignList: res.items, pagination: res.pagination, isDataLoading: false});
        }).catch(errMsg => {
            this.setState({isDataLoading: false});
        })
    }

    handleShowJoinStoreWithCampaignModal = (id) => {
        getDetail(`/campaign/${id}`, {}).then(res => {
            if (res.status === 1) {
                console.log('test by whom', res);
                // toast.success(res.message);
                this.setState({
                    isShowingStoreJoinModal: true,
                    selectedCampaignId: id,
                    selectedCampaignDetail: res.campaign,
                    storeShowFilter: res?.campaign?.registered_stores ?? []
                })
            } else {
                toast.warning(res.message);
            }

        }).catch(err => {
            console.log(err);
            toast.error(err.message)
        })
    }

    handleCloseJoinStoreModal = (e) => {
        this.setState({isShowingStoreJoinModal: false, selectedCampaignId: "", storeShowFilter: []})
    }

    joinStoreWithCampaign = (storeId) => {
        joinStoreWithCampaign({store_id: storeId, campaign_id: this.state.selectedCampaignId}).then(res => {
            if (res.status === 1) {
                getDetail(`/campaign/${this.state.selectedCampaignId}`, {}).then(res => {
                    if (res.status === 1) {
                        this.setState({
                            selectedCampaignDetail: res.campaign,
                            storeShowFilter: res?.campaign?.registered_stores ?? []
                        })
                    } else {
                        toast.warning(res.message);
                    }

                }).catch(err => {
                    console.log(err);
                    toast.error(err.message)
                })
                toast.success(res.message)
            } else {
                toast.warning(res.message)
            }
        }).catch(errMsg => {
            console.log(errMsg);
            toast.error(errMsg.message)
        });


        /*confirmAlert({
            title: 'Remove',
            message: 'Are you sure to join the store with the campaign?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {

                    }
                },
                {
                    label: 'No',
                    // onClick: () => alert('Click No')
                }
            ]
        });*/
    }

    getQueryData = (paramObj = {}) => {
        const history = this.props.history
        let queryData = this.state.queryData;

        if (paramObj?.page) {
            queryData.page = paramObj.page
        }
        this.getTableData(queryData);
    }

    handleSearchFormDataChange(e) {
        this.setState({queryData: {...this.state.queryData, [e.target.name]: e.target.value}})
    }

    render() {
        const {stores} = this.state
        const {storeShowFilter} = this.state
        return (
            <App layout="">
                <div className="main-content">
                    <div className="page-header">
                        <h2 className="page-title">Campaign</h2>
                        <div className="ml-auto">
                            {/*<Button size={"sm"} variant={"default"} className="mb-0 mr-2"><FaFileExport/> Export</Button>*/}
                            {/*<Link to={`${process.env.PUBLIC_URL}/promotions/campaign/add`} className="btn btn-sm btn-secondary"><FiPlus/> Add Campaign Offer</Link>*/}
                        </div>
                    </div>

                    <div className="card-block">
                        <div className="block-header">
                            <h4 className="block-title">Campaign List</h4>
                        </div>
                        <div className="block-body position-relative">
                            {this.state.isDataLoading &&
                            <LoadingSpinner/>
                            }

                            {this.state.isDataLoading ?
                                <div>Loading...</div>
                                :
                                <>
                                    <div className={`d-flex mb-3`}>
                                        <div className="form-inline">
                                            <form className="form-inline mb-3" onSubmit={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation()
                                            }}>
                                                <Form.Group>
                                                    <DateRangePicker
                                                        style={{display: 'inline-block'}}
                                                        initialSettings={{}}
                                                        containerStyles={{
                                                            textAlign: 'left',
                                                            width: '100%',
                                                            display: 'block'
                                                        }}
                                                        onApply={this.handleOnApply}>
                                                        <input type="button"
                                                               placeholder={`Select date range`}
                                                               value={(this.state.queryData.from_date && this.state.queryData.to_date) ? `${this.state.queryData.from_date} - ${this.state.queryData.to_date}` : 'Select date range'}
                                                               style={{textAlign: 'left', minWidth: '150px'}}
                                                               className="form-control form-control-sm mr-3"/>
                                                    </DateRangePicker>
                                                </Form.Group>


                                                <div className="form-group mr-3">
                                                    <label htmlFor="barndName" className="sr-only">Status</label>
                                                    <select
                                                        id="status" name="status"
                                                        className='form-control form-control-sm'
                                                        onChange={(e) => this.handleSearchFormDataChange(e)}>
                                                        <option value="">--Select Status--</option>
                                                        <option value="1">Active</option>
                                                        <option value="2">Inactive</option>
                                                    </select>
                                                </div>

                                                <div className="form-group mr-3">
                                                    <label htmlFor="searchInput" className="sr-only">Search Key</label>
                                                    <input
                                                        type="search"
                                                        id="orderNumber"
                                                        className='form-control form-control-sm'
                                                        placeholder="Search Key"
                                                        name="search_string"
                                                        onChange={(e) => this.handleSearchFormDataChange(e)}
                                                        onKeyDown={(e) => {
                                                            if (e.keyCode === 13) {
                                                                this.getQueryData();
                                                            }
                                                        }}
                                                    />
                                                </div>

                                                <Button className="mr-2" size="sm" variant="secondary"
                                                        onClick={this.getQueryData}>Search</Button>
                                            </form>
                                        </div>
                                    </div>

                                    <div className="table-responsive">
                                        <table className="order-table table table-striped">
                                            <thead>
                                            <tr>
                                                <th style={{width: '1%'}}>#</th>
                                                <th>Campaign Title</th>
                                                <th>Campaign Banner</th>
                                                <th>Joining Date</th>
                                                <th>Start</th>
                                                <th>End</th>
                                                <th>status</th>
                                                <th style={{width: '120px'}}/>
                                            </tr>
                                            </thead>

                                            <Modal show={this.state.isShowingStoreJoinModal}
                                                   onHide={this.handleCloseJoinStoreModal} size={'lg'}>
                                                <Modal.Header closeButton>
                                                    <Modal.Title>Join Campaign</Modal.Title>
                                                </Modal.Header>

                                                <Modal.Body>

                                                    <div className="card-block">
                                                        <div className="block-header">
                                                            <h4 className="block-title">My Stores</h4>
                                                        </div>

                                                        <div className="block-body">
                                                            <Store data={{stores: stores, pagingCounter: 1, storeShowFilter: storeShowFilter}}
                                                                   joinStoreWithCampaign={this.joinStoreWithCampaign}/>
                                                        </div>
                                                    </div>
                                                </Modal.Body>

                                                <Modal.Footer>
                                                    {/*<Button variant="default" onClick={this.handleCloseJoinStoreModal}>Close</Button>*/}
                                                </Modal.Footer>
                                            </Modal>

                                            <tbody>
                                            {this.state.campaignList?.length > 0 ?
                                                this.state.campaignList?.map((item, index) => (
                                                    <tr key={index}>
                                                        <td>{this.state.pagination.pagingCounter + index}</td>
                                                        <td width={"20%"}>
                                                            <div className="order-item">
                                                                <h4 className="order-id mb-0">
                                                                    <Link
                                                                        to={`${process.env.PUBLIC_URL}/promotions/campaign/details/${item._id}`}>{item.campaign_title}</Link>
                                                                    <a target={'_blank'}
                                                                       rel="noopener noreferrer"
                                                                       className={'ml-3 text-primary'}
                                                                       href={`${process.env.PUBLIC_URL}/campaigns/${item?.campaign_slug}`}><GoLinkExternal/></a>
                                                                </h4>
                                                                <ul className="ordered-items">
                                                                    <li>{"Created At:" + moment(item.createdAt).format('lll')}</li>
                                                                </ul>
                                                            </div>
                                                        </td>
                                                        <td><img src={item?.campaign_cover_image} width={'80px'}
                                                                 alt=""/></td>
                                                        <td>
                                                            {moment(item?.registration_last_date).format('hh:mm a Y-M-D')}
                                                            <br/>
                                                            {(moment().diff(item?.registration_last_date ,'minutes',true) < 0) ? '' : <text style={{color: "red", fontSize: 13, marginLeft: 12}}>Date Expired</text>}
                                                        </td>
                                                        <td>{moment(item?.start_date).format('hh:mm a Y-M-D')}</td>
                                                        <td>{moment(item?.end_date).format('hh:mm a Y-M-D')}</td>
                                                        <td className={`text-center`}>
                                                            {moment(item?.end_date) < moment(new Date()) ? this.renderStatus(-1) : this.renderStatus(item.status)}
                                                        </td>
                                                        <td className="nowrap">
                                                            <Link to={`/promotions/campaign/details/${item._id}`}
                                                                  className="text-bold text-default mr-3"
                                                                  target={'_blank'}>View</Link>
                                                            {(moment().diff(item?.registration_last_date ,'minutes',true) < 0) ?
                                                                <button className={'btn btn-primary'}
                                                                        onClick={() => this.handleShowJoinStoreWithCampaignModal(item?._id)}>Join
                                                                    Store</button>
                                                                : ''
                                                            }
                                                        </td>
                                                    </tr>
                                                ))
                                                :
                                                <tr>
                                                    <td colSpan={5} className={"text-bold text-center"}>
                                                        No campaign found. Please create <Link
                                                        to={`${process.env.PUBLIC_URL}/promotions/campaign/add`}>new
                                                        Campaign</Link>
                                                    </td>
                                                </tr>
                                            }
                                            </tbody>
                                        </table>
                                    </div>

                                    {this.state.pagination?.totalDocs > 0 &&
                                    <Pagination
                                        itemClass="page-item"
                                        linkClass="page-link"
                                        activePage={this.state.pagination?.page}
                                        itemsCountPerPage={this.state.pagination?.limit}
                                        totalItemsCount={this.state.pagination?.totalDocs}
                                        onChange={(page) => {
                                            const settings = {...this.state.settings}
                                            settings.page = page
                                            this.setState({isDataLoading: true, settings}, () => {
                                                getTableData({...this.state.settings}).then(res => {
                                                    this.setState({
                                                        campaignList: res.items,
                                                        pagination: res.pagination,
                                                        isDataLoading: false
                                                    });
                                                }).catch(errMsg => {
                                                    this.setState({isDataLoading: false});
                                                })
                                            })
                                        }}
                                    />
                                    }
                                </>
                            }
                        </div>
                    </div>
                </div>
            </App>
        );
    }
}

export default withRouter(CampaignOfferList);
