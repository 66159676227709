import axios from 'axios'
import {DASHBOARD_ORDER} from "./types"
import {API_BASE_URL} from "../../helper/env"
import {getDeviceType, getLocalAuthData} from "../../helper/utils";

export const fetchDashboardOrder = (params) => {
  const authData = getLocalAuthData();
  return dispatch => {
    axios.get(`${API_BASE_URL}/dashboard`, {
      headers: {
        'x-auth-token': authData.token,
        'x-api-client': getDeviceType()
      },
      params: params
    }).then(res => {
      dispatch(dashboardOrderAction(res.data))
    }).catch(() => {})
  }
}

export const fetchDashboardOrderV2 = (params) => {
  const authData = getLocalAuthData();
  return dispatch => {
    axios.get(`${API_BASE_URL}/dashboard/v2`, {
      headers: {
        'x-auth-token': authData.token,
        'x-api-client': getDeviceType()
      },
      params: params
    }).then(res => {
      dispatch(dashboardOrderAction(res.data.data[0]))
    }).catch(() => {})
  }
}

export const dashboardOrderAction = data => {
  return {
    type: DASHBOARD_ORDER,
    payload: data
  }
}
