import React, {Component} from 'react';
import moment from "moment";

class OrderActivities extends Component {
  render() {
    const {data} = this.props

    return (
      <>
        <h4 className="mb-3 title">Activities</h4>
        <ul className="update-list">
          {data?.length > 0 ?
            data.map((item, index) => (
              <li key={index}>
              <span className="datetime">
                <span className="date">{moment(item.createdAt).format('ll')}</span>
                <span className={"time"}>{moment(item.createdAt).format('LT')}</span>
              </span>

                <span className={"text"}><strong>{item.status}</strong> <br/> {item.message}</span>
              </li>
            ))
            :
            <>
              <li>
                <span className="datetime"><span className="date">20 July, 2020</span> <span
                  className={"time"}>10:30 pm</span></span>
                <span className={"text"}><strong>Pending</strong> <br/> Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet asperiores sequi ullam?</span>
              </li>
              <li>
                <span className="datetime"><span className="date">20 July, 2020</span> <span
                  className={"time"}>10:30 pm</span></span>
                <span className={"text"}><strong>Confirm</strong> <br/> Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet asperiores sequi ullam?</span>
              </li>
              <li>
                <span className="datetime"><span className="date">20 July, 2020</span> <span
                  className={"time"}>10:30 pm</span></span>
                <span className={"text"}><strong>Processing</strong> <br/> Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consectetur dolore laborum maxime, mollitia, nemo non officiis praesentium provident ratione rem sed sequi ullam voluptatibus! Amet dignissimos dolor illum molestias quis recusandae similique!</span>
              </li>
            </>
          }
        </ul>
      </>
    );
  }
}

export default OrderActivities;
